/* REACT PACKAGE IMPORT */
import { useState } from "react";
import { Form, Button, Stack, InputGroup } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

/* INTERNAL IMPORTS */
import {
  createOption,
  updateOption,
  deleteOption,
} from "../../../Features/Questions/Actions/QuestionActions";
import { uploadImg } from "../../../Features/Uploads/Actions/UploadsActions";
import {
  handleMouseEnter,
  handleMouseLeave,
} from "../../../Features/Questions/Slices/questionSlice";
import { openModal } from "../../../Features/Errors/Slices/errorSlice";


export default function SortableList({ question }) {
  const { isVisible, answerNum } = useSelector(store => store.question);
  const { uploadId } = useSelector(store => store.uploads);

  const [options, setOptions] = useState(question.options || []);
  const [option, setOption] = useState("");
  const [optionImg, setOptionImg] = useState({});
  const dispatch = useDispatch();

  let { id } = useParams();
  const qhid = parseInt(id);
  const qid = question.qid;

  const handleFileUpload = (e) => {
    if (e.target.files.length > 0) {
      const fileSize = e.target.files[0].size / 1024 / 1024; // in MiB
      if (fileSize > 120) {
        dispatch(
          openModal({
            message: "File size exceeds 120 MiB",
            severity: "error",
          })
        );
      } else {
        const newImg = URL.createObjectURL(e.target.files[0]);
        let reader = new FileReader();
        reader.readAsDataURL(e.target.files[0]);
        reader.onload = function () {
          var base64data = reader.result;
          setOptionImg(() => {
            return {
              url: newImg,
              filedesc: e.target.files[0].name,
              filename: e.target.files[0].name,
              filecontents: base64data,
            };
          });
        };
        reader.onerror = function (error) {
          dispatch(
            openModal({
              message: error,
              severity: "error",
            })
          );
        };
      }
    }
  };

  const handleAddOption = () => {
    let newOption = {};
    if (option.length > 0) {
      newOption = { qid: qid, opttext: option };
      // setOptions((arr) => {
      //   return [...arr, newOption];
      // });
    } else if (uploadId) {
      newOption = { qid: qid, optimage: uploadId };
      // setOptions((arr) => {
      //   return [...arr, { optimage: { imid: uploadId } }];
      // });
    }
    setOptionImg({});
    setOption("");
    dispatch(createOption({ qhid, newOption }))
      .then(response => {
        setOptions(
          response.payload.payload.questions
            .filter(item => item.qid === qid)[0].options
        );
      });
  };

  const handleDeleteOption = (e) => {
    setOptions(options => options.filter(currOpt => currOpt.qoid !== option.qoid));
    dispatch(
      deleteOption({
        qhid,
        oid: option.qoid,
      })
    );
  };

  const handleSaveOption = () => {
    dispatch(
      updateOption({
        qhid,
        oid: option.qoid,
        option: { opttext: option.opttext },
      })
    );
  };

  return (
    <div>
      {
        options.length === 0 && Object.keys(optionImg).length === 0 ?
          <p className="text-muted fst-italic">There are no options to preview yet...</p> :
          null
      }
      <ol className="list-group flex-fill">
        {
          options &&
          options.map((option, i) => (
            <li
              key={i}
              className="list-group-item sortable-list-item"
              onMouseEnter={() => dispatch(handleMouseEnter(i))}
              onMouseLeave={() => dispatch(handleMouseLeave(i))}
            >
              <div className="my-1 d-flex align-items-center">
                {
                  option.opttext ? (
                    <Form.Control
                      type="text"
                      aria-describedby="option"
                      aria-label={option.opttext}
                      value={option.opttext}
                      onChange={e => {
                        setOptions((options) => {
                          let newOptions = [...options];
                          let newOption = {
                            ...newOptions[i],
                            opttext: e.target.value,
                          };
                          newOptions[i] = newOption;
                          return [...newOptions];
                        });
                      }
                      }
                    />
                  ) :
                    <img
                      alt={`Img_${option.optimage.imid}`}
                      style={{ height: "15vw" }}
                      src={option.optimage.direct_url}
                      id={parseInt(option.optimage.imid)}
                      key={parseInt(option.optimage.imid)}
                      className="col-3"
                    />
                }
              </div>
              <Stack
                direction="horizontal"
                gap={3}
                className={isVisible && answerNum === i ? "shown" : "hidden"}
              >
                <Button
                  variant="dark"
                  size="sm"
                  onClick={handleSaveOption}
                >
                  Save option
                </Button>
                <Button
                  variant="dark"
                  size="sm"
                  onClick={handleDeleteOption}
                >
                  Delete option
                </Button>
              </Stack>
            </li>
          ))}
      </ol>
      {
        Object.keys(optionImg).length > 0 &&
        <div className="mx-auto my-5 d-flex justify-content-center align-items-center">
          <img width="300" src={optionImg.url} alt="Question" />
        </div>
      }
      <hr />
      <Form.Group className="my-3" controlId="controlInput">
        <h3 className="fw-bold mt-4 mb-0 fs-5">Add New Option</h3>
        <p className="text-muted mb-3">Add a new option (Text or Image) for the question</p>
        <Form.Control
          as="textarea"
          rows={3}
          onChange={e => setOption(e.target.value)}
          value={option}
          placeholder="Type some text..."
        />
        <div className="my-2 fs-6 text-center">OR</div>
        <InputGroup>
          <Form.Control
            type="file"
            id="fileControlInput"
            aria-label="File Upload"
            onChange={handleFileUpload}
          />
          <Button
            variant="dark"
            onClick={() => dispatch(uploadImg(optionImg))}
          >
            Upload
          </Button>
        </InputGroup>
      </Form.Group>
      <Button
        className="mt-4"
        variant="dark"
        onClick={handleAddOption}
      >
        Add option
      </Button>
    </div>
  );
}
