/* REACT PACKAGE IMPORTS */
import { useState } from "react";
import { Form, Offcanvas } from "react-bootstrap";

/* MUI PACKAGE IMPORTS */
import Divider from '@mui/material/Divider';

/* INTERNAL IMPORTS */
import DropdownField from "./DropdownField";
import CheckboxField from "./CheckboxField";
import InputField from "./InputField";
import SelectField from "./SelectField";
import SubmitButton from "./SubmitButton";
import SwitchField from "./SwitchField";
import TextAreaField from "./TextAreaField";


export default function FormBuilder({
  formTitle,
  formAction,
  fieldData,
  isFormVisible,
  setIsFormVisible,
}) {
  const [validated, setValidated] = useState(false);

  const handleSubmit = (event) => {
    const form = event.currentTarget;

    if (form.checkValidity()) {
      const formData = new FormData(form);
      const formObject = Object.fromEntries(formData);

      event.preventDefault();
      event.stopPropagation();

      formAction(formObject);
    };

    setValidated(true);
  };

  const handleHide = () => {
    setValidated(false);
    setIsFormVisible(false);
  };

  /* Output the correct type of UI needed for the form */
  // * Quirk with checkbox & switch fields as they do not generate a field ID whe checked
  const createField = (field) => {
    const { fieldCategory, fieldId } = field;

    switch (fieldCategory) {
      case ("input"): return <InputField key={fieldId} input={field} />;
      case ("textArea"): return <TextAreaField key={fieldId} textArea={field} />;
      case ("select"): return <SelectField key={fieldId} select={field} />;
      case ("dropdown"): return <DropdownField key={fieldId} dropdown={field} />;
      case ("checkbox"): return <CheckboxField key={fieldId} checkbox={field} />;
      case ("switch"): return <SwitchField key={fieldId} checkbox={field} />;
      default:
        console.log("The default case for creating a field has been hit");
        break;
    };
  };

  return (
    <Offcanvas
      show={isFormVisible}
      onHide={handleHide}
      placement="end"
      className="background-secondary"
      aria-label={`${formTitle} Form`}
    >
      <Offcanvas.Header closeButton>
        <Offcanvas.Title id="Offcanvas-styling-title">
          <p className="text-theme fw-bold m-0 h4">{formTitle}</p>
        </Offcanvas.Title>
      </Offcanvas.Header>
      {/* <Divider /> Undecided on which version is better, with or without */}
      <Offcanvas.Body>
        <Form
          id="form"
          onSubmit={handleSubmit}
          className="d-flex flex-column flex-nowrap"
          validated={validated}
          noValidate
        >
          {fieldData.map(field => createField(field))}
          <SubmitButton
            formTitle={formTitle}
            buttonText={formTitle}
          />
        </Form>
      </Offcanvas.Body>
    </Offcanvas>
  );
};