import { useState, useEffect } from "react";
import { Form } from "react-bootstrap";

export default function FreeWriting({ question, answer, setUserAnswerArray }) {
  const [url] = useState(question.image);
  const [src] = useState(question.src);

  const handleOnChange = (e) => {
    const value = e.target.value;

    const newCurrentAnswer = {
      qid: question.qid,
      answer: { freetext: value },
      qhid: question.qhid,
    };
    
    setUserAnswerArray((current) => {
      // Create an array of previously answered questions
      let newCurr = current.filter(answer => answer.qid !== question.qid);
      return [...newCurr, newCurrentAnswer];
    });
    
  };

  return (
    <div>
      <p className="fs-4 mb-4"><span className="fw-bold">Question</span>: {question.question}</p>
      <p className="fs-6 fst-italic mb-1 text-muted">Write your answer below</p>
      {
        url ? 
          <div className="text-center m-4">
            <img width="500" src={url} alt="Question" />
          </div> :
          null
      }
      {
        src ? 
          <div className="text-center m-4">
            <video width="800" controls>
              <source src={src} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div> :
          null
      }
      <Form.Control
        as="textarea"
        rows={3}
        defaultValue={answer.length > 0 ? answer[0].answer.freetext : ""}
        onChange={handleOnChange}
        aria-label="textarea"
      />
    </div>
  );
}
