/* REACT PACKAGE IMPORTS */
import { useEffect, useRef, useState, useContext } from "react";
import { Container, Form, InputGroup, Button } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";

/* MUI PACKAGE IMPORTS */
import { useTheme } from "@mui/material/styles";
import Divider from "@mui/material/Divider";

/* INTERNAL IMPORTS */
import "../styles/Profile.css";
import { ThemeContext } from "../Theme/ThemeContext";
import Image from "../Components/Image";
import Header from "../Components/Header";
import { languages } from "../utils/languages";

import { startWalkthrough } from "../Features/Walkthroughs/Actions/WalkthroughsActions";
import { uploadImg } from "../Features/Uploads/Actions/UploadsActions";
import { updateUser } from "../Features/Users/Actions/UsersActions";
import { openModal } from "../Features/Errors/Slices/errorSlice";
import { useNavigate } from "react-router-dom";

export default function Settings({ paragraph, title, user }) {
  const mode = useTheme();
  const context = useContext(ThemeContext);
  const [url, setUrl] = useState({});
  const { userInfo } = useSelector((store) => store.auth);
  const [language, setLanguage] = useState("en");
  const [logo, setLogo] = useState(null);
  const [logoId, setLogoId] = useState(null);
  const [primarycolor, setprimarycolor] = useState("#fafafa");
  const [secondarycolor, setsecondarycolor] = useState("#ffffff");
  const [fontcolor, setfontcolor] = useState("#020208");
  const inputRef = useRef(null);
  const mounted = useRef(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const sessionSteps = [
    {
      target: '.plus',
      content: 'Clicking on the plus button opens a form allowing you to create a new exam session.',
      title: 'Session Creation',
      hideCloseButton: true,
      placement: 'top',
    },
  ];

  const assessmentSteps = [
    {
      target: '.plus',
      content: 'Clicking the plus button allows you to create a new assessment.',
      title: 'Session Creation',
      hideCloseButton: true,
      placement: 'top',
    },
  ];

  const questionSteps = [
    {
      target: '.plus',
      content: 'Clicking the plus button allows you to create a new question for an assessment',
      title: 'Session Creation',
      hideCloseButton: true,
      placement: 'top',
    }
  ];

  const coreSteps = [
    {
      target: '.header-title',
      content: 'This is the primary homepage for admins',
      title: 'Session Creation',
      hideCloseButton: true,
      placement: 'top',
    }
  ];

  const handleSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();

    const theme = JSON.stringify({
      language,
      logo,
      primarycolor,
      secondarycolor,
      fontcolor,
      mode: mode.palette.mode,
    });
    dispatch(updateUser({ theme }));
  };

  const handleStartWalkthrough = (pathname, steps) => {
    dispatch(startWalkthrough(steps));
    navigate(`/${pathname}`, { replace: true });
  };

  useEffect(() => {
    mounted.current = true;
    if (mounted.current) {
      document.title = "Settings";
      if (Object.keys(userInfo).length > 0) {
        const { theme } = userInfo;
        if (theme.length === 0) {
          return;
        } else {
          const themeObj = JSON.parse(theme);
          const primarycolor = themeObj.primarycolor;
          const secondarycolor = themeObj.secondarycolor;
          const fontcolor = themeObj.fontcolor;
          const logo = themeObj.logo;
          const mode = themeObj.mode;
          setprimarycolor(primarycolor);
          setsecondarycolor(secondarycolor);
          setfontcolor(fontcolor);
          if (mode !== null) context.toggleColorMode(mode);
          if (logo !== null) setLogoId(logo);
          return;
        }
      } else {
      }
    }
    return () => (mounted.current = false);
  }, [userInfo]);

  return (
    <Container className="py-4 position-relative">
      <Header paragraph={paragraph} title={title}></Header>
      <div className="box p-4 background-secondary">
        <Form id="form" onSubmit={handleSubmit}>
          <Form.Group className={"mb-3"} key="logo" controlId={"logo"}>
            <Form.Label>Logo</Form.Label>
            <div className="row my-4">
              <div className="col-12 col-md-6">
                <div
                  className="mx-2 mx-md-auto my-5 d-flex justify-content-center align-items-center background-tertiary"
                  style={{
                    width: "auto",
                    height: "200px",
                  }}
                >
                  {logoId && (
                    <Image
                      id={parseInt(logoId)}
                      style={{ height: "100px" }}
                      className="mx-auto my-5"
                    />
                  )}
                </div>
              </div>
              <div className="col-12 col-md-6">
                <div
                  className="mx-2 mx-md-auto my-5 d-flex justify-content-center align-items-center background-tertiary"
                  style={{
                    width: "auto",
                    height: "200px",
                  }}
                >
                  {Object.keys(url).length > 0 && (
                    <img height="100" src={url.url} alt="Logo" />
                  )}
                </div>
              </div>
            </div>
            <InputGroup>
              <Form.Control
                ref={inputRef}
                type="file"
                onChange={(e) => {
                  if (e.target.files.length > 0) {
                    const fileSize = e.target.files[0].size / 1024 / 1024; // in MiB
                    if (fileSize > 120) {
                      dispatch(
                        openModal({
                          message: "File size exceeds 120 MiB",
                          severity: "error",
                        })
                      );
                    } else {
                      const newImg = URL.createObjectURL(e.target.files[0]);
                      let reader = new FileReader();
                      reader.readAsDataURL(e.target.files[0]);
                      reader.onload = function () {
                        var base64data = reader.result;
                        setUrl(() => {
                          return {
                            url: newImg,
                            filedesc: e.target.files[0].name,
                            filename: e.target.files[0].name,
                            filecontents: base64data,
                          };
                        });
                      };
                      reader.onerror = function (error) {
                        dispatch(
                          openModal({
                            message: error,
                            severity: "error",
                          })
                        );
                      };
                    }
                  }
                }}
              />
              <Button
                variant="dark"
                onClick={() => {
                  dispatch(uploadImg(url)).then((response) => {
                    setLogo(response.payload);
                    inputRef.current.value = null;
                  });
                }}
              >
                Upload
              </Button>
            </InputGroup>
          </Form.Group>
          <Form.Group className={"mb-3"} controlId={"language"}>
            <Form.Label>Language</Form.Label>
            <Form.Select
              aria-label="Default select"
              value={language}
              onChange={(e) => {
                setLanguage(e.target.value);
              }}
            >
              <option hidden>Select a language</option>
              {languages.map((language, i) => {
                return (
                  <option key={i} value={language.code}>
                    {language.name} ({language.nativeName})
                  </option>
                );
              })}
            </Form.Select>
          </Form.Group>
          <ThemeContext.Consumer>
            {({ toggleColorMode }) => (
              <Form.Group
                className={"mb-3"}
                key="primarycolor"
                controlId="primarycolor"
              >
                <Form.Label>Theme</Form.Label>
                <div
                  className="d-flex"
                  role="group"
                  aria-label="Basic radio toggle button group"
                  id="switchCheckDefault"
                >
                  <Form.Check
                    type="switch"
                    label={mode.palette.mode === "light" ? "Light" : "Dark"}
                    id="custom-switch"
                    checked={mode.palette.mode === "light"}
                    onChange={(e) => {
                      var r = document.querySelector("body");
                      let isChecked = e.target.checked;
                      toggleColorMode(isChecked ? "light" : "dark");
                      setprimarycolor(isChecked ? "#fafafa" : "#212529");
                      r.style.setProperty(
                        "--bkg-color",
                        isChecked ? "#fafafa" : "#212529"
                      );
                      setsecondarycolor(isChecked ? "#ffffff" : "#2a2e32");
                      r.style.setProperty(
                        "--bkgsecondary",
                        isChecked ? "#ffffff" : "#2a2e32"
                      );
                      setfontcolor(isChecked ? "#020208" : "#ffffff");
                      r.style.setProperty(
                        "--text-color",
                        isChecked ? "#020208" : "#ffffff"
                      );
                    }}
                  />
                </div>
                <Form.Text id="modeHelp" muted>
                  This sets a default mode. You can customise it with the inputs
                  below.
                </Form.Text>
              </Form.Group>
            )}
          </ThemeContext.Consumer>
          <Form.Group
            className={"mb-3"}
            key="primarycolor"
            controlId="primarycolor"
          >
            <Form.Label>Primary color</Form.Label>
            <Form.Control
              type="color"
              value={primarycolor}
              onChange={(e) => {
                var r = document.querySelector("body");
                setprimarycolor(e.target.value);
                r.style.setProperty("--bkg-color", e.target.value);
              }}
            />
          </Form.Group>
          <Form.Group
            className={"mb-3"}
            key="secondarycolor"
            controlId={"secondarycolor"}
          >
            <Form.Label>Secondary color</Form.Label>
            <Form.Control
              type="color"
              value={secondarycolor}
              onChange={(e) => {
                var r = document.querySelector("body");
                setsecondarycolor(e.target.value);
                r.style.setProperty("--bkgsecondary", e.target.value);
              }}
            />
          </Form.Group>
          <Form.Group
            className={"mb-3"}
            key="fontcolor"
            controlId={"fontcolor"}
          >
            <Form.Label>Font color</Form.Label>
            <Form.Control
              type="color"
              value={fontcolor}
              onChange={(e) => {
                var r = document.querySelector("body");
                setfontcolor(e.target.value);
                r.style.setProperty("--text-color", e.target.value);
              }}
            />
          </Form.Group>
          <div className="text-end">
            <Button
              variant="dark"
              onClick={() => {
                const theme = "";
                dispatch(updateUser({ theme }));
                setprimarycolor("#fafafa");
                setsecondarycolor("#ffffff");
                setfontcolor("#020208");
                setLogoId(null);
                setUrl({});
                context.toggleColorMode("light");
                inputRef.current.value = null;
              }}
              className="me-3"
            >
              Reset
            </Button>
            <Button variant="dark" type="submit">
              Save
            </Button>
          </div>
          <Divider className="my-3"/>
          <div className="d-flex flex-column my-2">
            <Button
              variant="dark"
              onClick={() => handleStartWalkthrough("admin/sessions", sessionSteps)}
              className="m-2"
            >
              Session Walkthrough
            </Button>
            <Button
              variant="dark"
              onClick={() => handleStartWalkthrough("admin/assessments", assessmentSteps)}
              className="m-2"
            >
              Assessment Walkthrough
            </Button>
            <Button
              variant="dark"
              onClick={() => handleStartWalkthrough("admin/question_bank", questionSteps)}
              className="m-2"
            >
              Question Walkthrough
            </Button>
            <Button
              variant="dark"
              onClick={() => handleStartWalkthrough("admin/sessions", coreSteps)}
              className="m-2"
            >
              Core Features Walkthrough
            </Button>
          </div>
        </Form>
      </div>
    </Container>
  );
}
