/* REACT PACKAGE IMPORTS */
import { useEffect, useState } from 'react';
const reactStringReplace = require("react-string-replace");


export default function FillInBlank({ question, answer, setUserAnswerArray }) {

  const regEx = /\{(.*?)\}/;
  const blanksMapping = {};

  useEffect(() => {
    createBlanksObject();
  }, []);

  useEffect(() => {

  }, [answer])

  const createBlanksObject = (match, index) => {
    if (answer.length > 0) {
      const adjustedIndex = ((index - 1) / 2);
      blanksMapping[index] = answer[0].answer.fillblanks[adjustedIndex];
      return (
        <input
          aria-label="Blank Input"
          key={index}
          type="text"
          className="col input m-2"
          defaultValue={answer[0].answer.fillblanks[adjustedIndex]}
          onChange={event => handleOnChange(event, index)}
        />
      )
    } else {
      if (index) blanksMapping[index] = "";
      return (
        <input
          aria-label="Blank Input"
          key={index}
          type="text"
          className="col input m-2"
          defaultValue={blanksMapping[index]}
          onChange={event => handleOnChange(event, index)}
        />
      )
    }
  }

  const handleOnChange = (e, id) => {
    const value = e.target.value;
    blanksMapping[id] = value;
    const blanksArray = Object.values(blanksMapping);

    // Existing answer adds undefined onto mapping
    if (blanksArray[-1] === undefined && answer.length > 0) blanksArray.pop();
    
    const newCurrentAnswer = {
      qid: question.qid,
      answer: { fillblanks: blanksArray },
      qhid: question.qhid,
    };

    setUserAnswerArray((current) => {
      const newCurr = current.filter(answer => answer.qid !== question.qid);
      return [...newCurr, newCurrentAnswer];
    });
  };

  return (
    <div className="row">
      <p className="fs-4 mb-4"><span className="fw-bold">Fill in the Blanks</span></p>
      <p className="fs-6 fst-italic text-muted">Type in your answer into each of the blanks below</p>
      <p className="m-auto lh-base">
        {
          reactStringReplace(question.question, regEx, (match, index) => createBlanksObject(match, index))
        }
      </p>
    </div>
  );
}
