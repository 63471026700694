export const LocalDateTime = (timeUTC) => {
  var date = new Date(timeUTC);
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  var options = {
    timeZone: timezone,
  };
  return date.toLocaleString(options);
};

export const DateTimeFormatter = (timeUTC) => {
  var date = new Date(timeUTC);
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  var options = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
    timeZone: timezone,
  };
  return date.toLocaleString("en-GB", options);
};

function padTo2Digits(num) {
  return num.toString().padStart(2, "0");
}
export const formatDate = (date) => {
  return [
    date.getFullYear(),
    padTo2Digits(date.getMonth() + 1),
    padTo2Digits(date.getDate()),
  ].join("-");
};

// --- Main function
export const timeAgo = (dateParam) => {
  if (!dateParam) {
    return null;
  }

  const date = typeof dateParam === "object" ? dateParam : new Date(dateParam);
  const today = new Date();

  const years = Math.floor(
    ((today - date) % (1000 * 60 * 60 * 24 * 30 * 12 * 365)) /
      (1000 * 60 * 60 * 24 * 30 * 12)
  );
  const months = Math.floor(
    ((today - date) % (1000 * 60 * 60 * 24 * 30 * 12)) /
      (1000 * 60 * 60 * 24 * 30)
  );
  const days = Math.floor(
    ((today - date) % (1000 * 60 * 60 * 24 * 30)) / (1000 * 60 * 60 * 24)
  );

  const hours = Math.floor(
    ((today - date) % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
  );
  const minutes = Math.floor(((today - date) % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor(((today - date) % (1000 * 60)) / 1000);

  const f = ([years, months, days, hours, minutes, seconds]) => {
    return (
      (years > 0 ? `${years}Y ` : "") +
      (months > 0 ? `${months}M ` : "") +
      (days > 0 ? `${days}d ` : "") +
      (hours > 0 ? `${hours}h ` : "") +
      (minutes > 0 ? `${minutes}m ` : "") +
      `${seconds}s ago`
    );
  };
  return f([years, months, days, hours, minutes, seconds]);
};
