import { createSlice } from "@reduxjs/toolkit";
import {
  fetchSchedules,
  fetchSchedule,
  updateSchedule,
  deleteSchedule,
  fetchAuditSchedule,
  createSchedule,
  fetchSessionsBySchedule,
  fetchSession,
  startSession,
  pauseSession,
  finishSession,
  addNewSession,
  deleteSession,
  //fetchSessionsByUser,
  fetchAnswersBySession,
  postAnswer,
  endSessionHandle,
  fetchMarks,
  fetchOrganisation,
} from "../Actions/SessionsActions";

const initialState = {
  schedules: [],
  schedule: {},
  scheduleAudit: [],
  sessions: [],
  session: {},
  answers: [],
  marks: [],
  answersSaved: JSON.parse(sessionStorage.getItem("answers")) || [],
  pages: JSON.parse(sessionStorage.getItem("pages")) || [],
  sessionStatus: sessionStorage.getItem("sessionStatus") || null, //pending, started, paused or finished
  scheduleStatus: sessionStorage.getItem("scheduleStatus") || null, //pending, live or finished
  statusPause: null,
  starttime: null,
  laststarttime: null,
  status: "idle", //'idle' | 'loading' | 'succeeded' | 'failed'
  error: null,
};

const sessionsSlice = createSlice({
  name: "sessions",
  initialState,
  reducers: {
    hideError(state, action) {
      return {
        ...state,
        status: "idle",
        error: null,
      };
    },
    tagPage(state, action) {
      let newStatePages = JSON.parse(sessionStorage.getItem("pages"));
      const index = newStatePages.findIndex((page) => {
        return (
          page.page === action.payload.page &&
          page.section === action.payload.section
        );
      });
      newStatePages[index] = {
        ...newStatePages[index],
        tagged: !newStatePages[index].tagged,
      };
      sessionStorage.setItem("pages", JSON.stringify(newStatePages));
      return {
        ...state,
        pages: newStatePages,
        error: null,
      };
    },
    completePage(state, action) {
      let newStatePages = [...state.pages];
      const index = newStatePages.findIndex((page) => {
        return (
          page.page === action.payload.page &&
          page.section === action.payload.section
        );
      });
      newStatePages[index] = { ...newStatePages[index], completed: true };

      sessionStorage.setItem("pages", JSON.stringify(newStatePages));
      return {
        ...state,
        pages: newStatePages,
        error: null,
      };
    },
    markPagesAsCompleted(state, action) {
      let newStatePages = [...state.pages];
      const completedQuestions = [...state.answersSaved];

      if (completedQuestions.length > 0) {
        completedQuestions.forEach((question) => {
          const index = newStatePages.findIndex((page) => {
            return page.qhid === question.qhid;
          });
          newStatePages[index] = { ...newStatePages[index], completed: true };
        });
      }

      sessionStorage.setItem("pages", JSON.stringify(newStatePages));
      return {
        ...state,
        pages: newStatePages,
        error: null,
      };
    },
    savePages(state, action) {
      let countedPages = JSON.parse(sessionStorage.getItem("pages")) || [];

      return {
        ...state,
        pages: countedPages,
        error: null,
      };
    },

    newAnswer(state, action) {
      let newState = state.answersSaved.filter(
        (answer) => answer.qid !== action.payload.qid
      );

      sessionStorage.setItem(
        "answers",
        JSON.stringify([...newState, action.payload])
      );

      return {
        ...state,
        status: "succeeded",
        answersSaved: [...newState, action.payload],
        error: null,
      };
    },
    
    newAnswerTwo(state, action) {
      let newState = state.answersSaved.filter(
        (answer) => answer.qid !== action.payload.qid
      );

      sessionStorage.setItem(
        "answers",
        JSON.stringify([...newState, action.payload])
      );

      return {
        ...state,
        status: "succeeded",
        answersSaved: [...newState, action.payload],
        error: null,
      };
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchSession.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.session = action.payload;
        state.statusPause = action.payload.pauseleft ? true : false;
        state.starttime = action.payload.schedule.starttime;
        state.laststarttime = action.payload.schedule.laststarttime;
        state.scheduleStatus = action.payload.schedule.status;
        state.sessionStatus = action.payload.status;
      })
      .addCase(fetchSession.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })
      .addCase(fetchSession.pending, (state, action) => {
        state.status = "loading";
      })

      .addCase(startSession.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(fetchSchedules.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(fetchSchedules.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.schedules = action.payload;
      })
      .addCase(fetchSchedules.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })
      .addCase(fetchSchedule.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(fetchSchedule.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.schedule = action.payload;
      })
      .addCase(fetchSchedule.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })
      .addCase(updateSchedule.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(updateSchedule.fulfilled, (state, action) => {
        state.status = "succeeded";
      })
      .addCase(updateSchedule.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })
      .addCase(deleteSchedule.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(deleteSchedule.fulfilled, (state, action) => {
        state.status = "succeeded";
      })
      .addCase(deleteSchedule.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })

      .addCase(fetchAuditSchedule.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(fetchAuditSchedule.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.scheduleAudit = action.payload;
      })
      .addCase(fetchAuditSchedule.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })
      .addCase(fetchSessionsBySchedule.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(fetchSessionsBySchedule.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.sessions = action.payload;
      })
      .addCase(fetchSessionsBySchedule.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })

      .addCase(startSession.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.sessionStatus = "started";
      })
      .addCase(startSession.rejected, (state, action) => {
        state.status = "failed";
        state.sessionStatus = "pending";
        state.error = action.payload;
      })
      .addCase(pauseSession.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(pauseSession.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.sessionStatus = "paused";
      })
      .addCase(pauseSession.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })
      .addCase(finishSession.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(finishSession.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.sessionStatus = "finished";
      })
      .addCase(finishSession.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })
      .addCase(createSchedule.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(createSchedule.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })
      .addCase(createSchedule.fulfilled, (state, action) => {
        state.status = "succeeded";
        // state.schedules.push({});
      })
      .addCase(addNewSession.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(addNewSession.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })
      .addCase(addNewSession.fulfilled, (state, action) => {
        state.status = "succeeded";
      })
      .addCase(deleteSession.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(deleteSession.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })
      .addCase(deleteSession.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.sessions = state.sessions.filter(
          (session) => session.user.userid !== action.payload
        );
      })
      // .addCase(fetchSessionsByUser.pending, (state, action) => {
      //   state.status = "loading";
      // })
      // .addCase(fetchSessionsByUser.rejected, (state, action) => {
      //   state.status = "failed";
      //   state.error = action.payload;
      // })
      // .addCase(fetchSessionsByUser.fulfilled, (state, action) => {
      //   state.status = "succeeded";
      //   state.sessions = action.payload;
      // })
      .addCase(fetchAnswersBySession.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(fetchAnswersBySession.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
        state.answers = [];
      })
      .addCase(fetchAnswersBySession.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.answers = action.payload;
      })
      .addCase(postAnswer.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(postAnswer.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })
      .addCase(postAnswer.fulfilled, (state, action) => {
        state.status = "succeeded";
      })
      .addCase(endSessionHandle.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(endSessionHandle.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })
      .addCase(endSessionHandle.fulfilled, (state, action) => {
        state.status = "succeeded";
      })
      .addCase(fetchMarks.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(fetchMarks.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })
      .addCase(fetchMarks.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.marks = action.payload;
      })
      .addCase(fetchOrganisation.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(fetchOrganisation.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })
      .addCase(fetchOrganisation.fulfilled, (state, action) => {
        state.status = "succeeded";
      });
  },
});

export const {
  hideError,
  newAnswer,
  newAnswerTwo,
  tagPage,
  completePage,
  markPagesAsCompleted,
  savePages,
} = sessionsSlice.actions;

export default sessionsSlice.reducer;
