/* REACT PACKAGE IMPORTS */
import { useEffect, useState, useRef } from "react";
import { Container } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

/* MUI PACKAGE IMPORTS */
import Marking from "./Marking";
import { IconButton } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import OpenInFullIcon from "@mui/icons-material/OpenInFull";
import Tooltip from "@mui/material/Tooltip";

/* INTERNAL IMPORTS */
import DataTable from "../../Components/DataTable";
import {
  fetchSessionsBySchedule,
  fetchAnswersBySession,
} from "../../Features/Sessions/Actions/SessionsActions";
import { fetchAssessment } from "../../Features/Assessments/Actions/AssessmentsActions";


export default function ListResults() {
  const { 
    schedule, 
    sessions, 
    answers } = useSelector(store => store.sessions);
  const { questions } = useSelector(store => store.assessments);
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 10,
    page: 0,
  });

  const [show, setShow] = useState(false);
  const [sessionId, setSessionId] = useState(null);
  const [columns] = useState([
    {
      field: "esuid",
      headerName: "ID",
      width: 90,
    },
    {
      field: "username",
      headerName: "Username",
      flex: 1,
      valueGetter: (params) => params?.row?.user?.displayname,
    },
    {
      field: "name",
      headerName: "Full name",
      flex: 1,
      valueGetter: (params) =>
        params?.row?.user?.firstname + " " + params?.row?.user?.lastname,
    },
    {
      field: "completed",
      headerName: "Status",
      flex: 1,
      valueGetter: (params) => params?.row?.status,
      valueFormatter: ({ value }) =>
        value.charAt(0).toUpperCase() + value.slice(1),
    },
    {
      field: "score",
      headerName: "Score",
      headerAlign: "center",
      align: "center",
      flex: 1,
      valueGetter: ({ row }) => row.result?.marks,
    },
    {
      field: "pass",
      headerName: "Pass",
      headerAlign: "center",
      align: "center",
      width: 90,
      valueGetter: ({ row }) => row.result?.passed,
      renderCell: ({ value }) =>
        value ? (
          <CheckIcon className="right" />
        ) : (
          <CloseIcon className="wrong" />
        ),
    },
    {
      field: "id",
      headerName: "",
      headerAlign: "center",
      align: "center",
      width: 90,
      renderCell: ({ row, id }) => (
        <Tooltip title="Open results">
          <IconButton
            aria-label="Open results"
            onClick={(e) => handleShow(e, id)}
          >
            <OpenInFullIcon fontSize="small" />
          </IconButton>
        </Tooltip>
      ),
    },
  ]);
  const [rows, setRows] = useState([]);
  const [type] = useState("results");
  const [ID] = useState("esuid");
  const mounted = useRef(null);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  let { id } = useParams();
  const schId = parseInt(id);

  const handleShow = (e, id) => {
    const { exam } = schedule;
    const { exid } = exam;
    if (mounted.current) {
      e.stopPropagation();
      dispatch(fetchAnswersBySession(id));
      dispatch(fetchAssessment(exid));
      setShow((currShow) => {
        if (currShow) setSessionId(null);
        else setSessionId(id);
        return !currShow;
      });
    }
  };

  useEffect(() => {
    mounted.current = true;
    if (sessions.length > 0) return;
    if (mounted.current) {
      if (isNaN(schId)) navigate("/admin/error", { replace: true });
      else dispatch(fetchSessionsBySchedule(schId));
    }

    return () => (mounted.current = false);
  }, [sessions]);

  useEffect(() => {
    mounted.current = true;
    if (sessions.length <= 0) return;
    if (mounted.current) setRows(sessions);
  }, [sessions]);

  return (
    <Container className="p-0">
      <DataTable
        columns={columns}
        rows={rows}
        type={type}
        getRowId={(row) => row[ID]}
        setPaginationModel={setPaginationModel}
        paginationModel={paginationModel}
      />
      {
        sessions.length > 0 &&
        sessions.map((session, i) => {
          return (
            session.esuid == sessionId &&
            show &&
            questions.length > 0 &&
            answers.length > 0 ? 
              <Marking key={i} esId={session.esuid} /> : 
              ""
          );
        })
      }
    </Container>
  );
}
