import { useEffect, useState, useRef } from "react";
import {
  Form,
  Button,
  InputGroup,
  ButtonGroup,
  ToggleButton,
} from "react-bootstrap";
import { useStyles, PrettoSlider } from "../../../styles/StyledComponents";
import { format } from "../../../utils/tools.js";
import Grid from "@material-ui/core/Grid";
import TourIcon from "@mui/icons-material/Tour";
import Typography from "@material-ui/core/Typography";
import Paper from "@mui/material/Paper";
import Duration from "../../Duration";
import ReactPlayer from "react-player";
import hazard1 from "../../../assets/videos/hazard1.mp4";

export default function EditVideo({ question }) {
  const [url, setUrl] = useState(hazard1);
  const [valueUrl, setValueUrl] = useState("");
  const [file, setFile] = useState("");
  const [playbackRate, setPlaybackRate] = useState(1.0);
  const [pip, setPip] = useState(false);
  const [playing, setPlaying] = useState(false);
  const [controls, setControls] = useState(true);
  const [loop, setLoop] = useState(false);
  const [muted, setMuted] = useState(false);
  const [seeking, setSeeking] = useState(false);
  const [played, setPlayed] = useState(0);
  const [speed, setSpeed] = useState("1");
  const [duration, setDuration] = useState(0);
  const [marks, setMarks] = useState([]);
  const [bookmarks, setBookmarks] = useState([]);
  const classes = useStyles();
  const playerRef = useRef(null);
  const containerRef = useRef(null);
  const canvasRef = useRef(null);

  const load = (url) => {
    setUrl(url);
    setPip(false);
  };

  const handleToggleControls = () => {
    const src = url;
    setControls(!controls);
    setUrl(null);
    load(src);
  };

  const handleToggleLoop = () => {
    setLoop(!loop);
  };

  const handleToggleMuted = () => {
    setMuted(!muted);
  };

  const handleSeekMouseDown = (e) => {
    setSeeking(true);
  };

  const handleSeekChange = (e) => {
    setPlayed(parseFloat(e.target.value));
  };

  const handleSeekMouseUp = (e) => {
    setSeeking(false);
    playerRef.current.seekTo(parseFloat(e.target.value));
  };

  const handleProgress = (state) => {
    // We only want to update time slider if we are not currently seeking
    if (!seeking) {
      setPlayed(state.played);
    }
  };

  const handleDuration = (duration) => {
    setDuration(duration);
  };

  useEffect(() => {}, [url, controls, muted, loop, marks]);

  return (
    <div className="Box background-secondary box">
      <InputGroup className="">
        <Form.Control
          ref={(input) => {
            setValueUrl(input);
          }}
          type="text"
        />
        <Button
          variant="dark"
          placeholder="Enter URL"
          onClick={() => {
            setUrl(valueUrl.value);
          }}
        >
          Update URL
        </Button>
      </InputGroup>
      <div className="text-center my-4">or</div>
      <InputGroup className="mb-5">
        <Form.Control
          ref={(input) => {
            setFile(input);
          }}
          type="file"
        />
        <Button
          variant="dark"
          onClick={() => {
            setUrl(URL.createObjectURL(file.files[0]));
          }}
        >
          Upload video
        </Button>
      </InputGroup>

      <Form.Group className="my-3" controlId="controlsForm">
        <Form.Check
          type="checkbox"
          label="Controls"
          checked={controls}
          onChange={handleToggleControls}
        />
      </Form.Group>
      <Form.Group className="my-3" controlId="controlsForm">
        <Form.Check
          type="checkbox"
          label="Muted"
          checked={muted}
          onChange={handleToggleMuted}
        />
      </Form.Group>
      <Form.Group className="my-3" controlId="controlsForm">
        <Form.Check
          type="checkbox"
          label="Loop"
          checked={loop}
          onChange={handleToggleLoop}
        />
      </Form.Group>
      <Form.Group className="my-5" controlId="seekForm">
        <InputGroup>
          <Form.Label className="align-self-center me-3">Seek</Form.Label>
          <Form.Control
            type="range"
            min={0}
            max={0.999999}
            step="any"
            value={played}
            onMouseDown={handleSeekMouseDown}
            onChange={handleSeekChange}
            onMouseUp={handleSeekMouseUp}
          />
          <ButtonGroup className="">
            <ToggleButton
              id="speed-0"
              type="radio"
              variant="outline-secondary"
              name="speed"
              value={"1"}
              checked={speed === "1"}
              onChange={(e) => {
                const src = url;
                setSpeed(e.currentTarget.value);
                setPlaybackRate(parseFloat(e.target.value));
                setUrl(null);
                load(src);
              }}
            >
              1x
            </ToggleButton>
            <ToggleButton
              id="speed-1"
              type="radio"
              variant="outline-secondary"
              name="speed"
              value={"1.5"}
              checked={speed === "1.5"}
              onChange={(e) => {
                const src = url;
                setSpeed(e.currentTarget.value);
                setPlaybackRate(parseFloat(e.target.value));
                setUrl(null);
                load(src);
              }}
            >
              1.5x
            </ToggleButton>
            <ToggleButton
              id="speed-2"
              type="radio"
              variant="outline-secondary"
              name="speed"
              value={"2"}
              checked={speed === "2"}
              onChange={(e) => {
                const src = url;
                setSpeed(e.currentTarget.value);
                setPlaybackRate(parseFloat(e.target.value));
                setUrl(null);
                load(src);
              }}
            >
              2x
            </ToggleButton>
          </ButtonGroup>
          <Button
            variant="dark"
            placeholder="Enter URL"
            onClick={() => {
              if (playerRef.current) {
                const canvas = canvasRef.current;
                canvas.width = 160;
                canvas.height = 90;
                const ctx = canvas.getContext("2d");
                ctx.drawImage(
                  playerRef.current.getInternalPlayer(),
                  0,
                  0,
                  canvas.width,
                  canvas.height
                );
                const dataUri = canvas.toDataURL();
                canvas.width = 0;
                canvas.height = 0;
                const currentTime = playerRef.current.getCurrentTime();
                const duration = playerRef.current.getDuration();

                setBookmarks((currentBookmarks) => {
                  const bookmarksCopy = [...currentBookmarks];
                  bookmarksCopy.push({
                    time: currentTime,
                    display: format(currentTime),
                    image: dataUri,
                  });
                  return bookmarksCopy;
                });
                setMarks((currentMarks) => {
                  const marksCopy = [...currentMarks];
                  marksCopy.push({
                    value: currentTime / duration,
                    label: (
                      <TourIcon
                        color="error"
                        onMouseDown={() => {
                          setPlaying(false);
                          setSeeking(true);
                          setPlayed(parseFloat(currentTime / duration));
                        }}
                        onMouseUp={() => {
                          setSeeking(false);
                          playerRef.current.seekTo(
                            parseFloat(currentTime / duration)
                          );
                        }}
                      />
                    ),
                  });
                  return marksCopy;
                });
              }
            }}
          >
            Add mark
          </Button>
        </InputGroup>
        <Grid item xs={12} className="mt-5">
          <PrettoSlider
            value={played}
            min={0}
            max={0.999999}
            aria-label="Default"
            valueLabelDisplay="auto"
            marks={marks}
            className="mb-0"
          />
        </Grid>
        <div className="d-flex justify-content-between">
          <div>
            <Duration seconds={duration * played} /> /{" "}
            <Duration seconds={duration * (1 - played)} />
          </div>
          <Duration seconds={duration} />
        </div>
      </Form.Group>

      <div ref={containerRef} className={classes.playerWrapper}>
        <ReactPlayer
          ref={playerRef}
          className="react-player"
          width="100%"
          height="100%"
          url={url}
          pip={false}
          playing={playing}
          controls={controls}
          playbackRate={playbackRate}
          onProgress={handleProgress}
          onDuration={handleDuration}
        />
      </div>

      {/* Bookmarks */}
      <Grid
        container
        justifyContent="center"
        style={{ marginTop: 20 }}
        spacing={3}
      >
        {bookmarks.map((bookmark, i) => {
          return (
            <Grid key={i} item>
              <Paper>
                <img
                  crossOrigin="anonymous"
                  src={bookmark.image}
                  alt={`Bookmark ${i}`}
                />
                <Typography variant="body2" align="center">
                  Bookmark at {bookmark.display}
                </Typography>
              </Paper>
            </Grid>
          );
        })}
        <canvas ref={canvasRef} />
      </Grid>
    </div>
  );
}
