/* REACT PACKAGE IMPORTS */
import { useState } from "react";
import { Button } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

/* MUI PACKAGE IMPORTS */
import Tooltip from "@mui/material/Tooltip";

/* INTERNAL IMPORT */
import { deleteAssessment } from "../../Features/Assessments/Actions/AssessmentsActions";
import Modal from "../Modal";


export default function Overview({ children }) {
  const [showModal, setShowModal] = useState(false);
  const [button, setButton] = useState("");
  const [msg, setMsg] = useState("");

  const { assessment, sections } = useSelector(store => store.assessments);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  let { id } = useParams();
  const exid = parseInt(id);

  const deleteAssessmentHandler = () => {
    dispatch(deleteAssessment({ exid, navigate }));
    setShowModal(false);
  };

  return (
    <div className="box my-4 p-4 position-relative">
      <h2 className="fw-bold fs-4 mb-3">Assessment Details</h2>
      <p><span className="fs-5">Duration:</span> {assessment?.duration?.stringshort}</p>
      <p><span className="fs-5">Instructions:</span> {assessment?.instructions ? assessment.instructions : <span className="text-muted fst-italic">No instructions set</span>}</p>
      <p><span className="fs-5">Sections:</span> {sections.length}</p>
      <p><span className="fs-5">Pass Mark:</span> {assessment?.passmark}</p>
      <p><span className="fs-5">Pass Percentage (%):</span> {assessment?.passpercent}% </p>
      <p><span className="fs-5">Total Assessment Score:</span> {assessment?.score}</p>
      <div className="mt-3 text-end">
        <Tooltip title="Delete assessment">
          <Button
            variant="dark"
            aria-label="Update section name"
            onClick={() => {
              setShowModal(true);
              setMsg(
                `Are you sure you want remove the assessment "${assessment.examtitle}"? This is an irreversible action.`
              );
              setButton("Delete");
            }}
          >
            Delete assessment
          </Button>
        </Tooltip>
      </div>
      {children}
      <Modal
        setShowModal={setShowModal}
        show={showModal}
        handleSubmit={deleteAssessmentHandler}
        msg={msg}
        button={button}
      />
    </div>
  );
}
