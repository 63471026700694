/* REACT PACKAGE IMPORTS */
import { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Form } from "react-bootstrap";

/* INTERNAL IMPORTS */
import { setOrganisationByUser } from "../../Features/Auth/Actions/AuthActions";
import { fetchAssessments } from "../../Features/Assessments/Actions/AssessmentsActions";
import { updateProfileImage } from "../../Features/Users/Actions/UsersActions";
import { setIsLoading } from "../../Features/Auth/Slices/authSlice";
import avatar from "../../assets/blank-profile-picture.png";
import update from "../../assets/group-picture.svg";
import ImageCrop from "../ImageCrop";


export default function Overview({ children }) {
  const dispatch = useDispatch();

  const { organisation, organisations } = useSelector(store => store.auth);
  const { user } = useSelector(store => store.users);

  const [userAddress, setUserAddress] = useState("No Address Supplied");
  const [show, setShow] = useState(false);
  const [image, setImage] = useState("");
  const [imageData, setImageData] = useState("");
  const [imageURL, setImageURL] = useState("");
  const [profileSource, setProfileSource] = useState(avatar);

  const updateRef = useRef(null);
  const newProfile = useRef(null);

  const organisationDropdown =
    <div className="bottom-border-2 py-4 d-flex flex-column align-items-center">
      <p className="font-bold">Organisation</p>
      <Form.Group className="m-0 w-50" controlId="selectOrg">
        <Form.Select
          aria-label="Default select"
          value={organisation}
          onChange={e => handleChangeOrganisation(e)}
        >
          {
            organisations
              .map((organisation, index) => {
                return (
                  <option key={index} value={organisation.orgid} className="py-4">
                    {organisation.orgname}
                  </option>
                );
              })
          }
        </Form.Select>
      </Form.Group>
    </div>;

  useEffect(() => {
    let address = `${user.country.name}`;
    if (user.region) address += `, ${user.region}`;
    if (user.locality) address += `, ${user.locality}`;
    if (user.address1) address += `, ${user.address1}`;
    if (user.address2) address += `, ${user.address2}`;
    if (user.postcode) address += `, ${user.postcode}`;
    setUserAddress(address);
  }, []);

  useEffect(() => {
    if (user.profileimage.url !== "") setProfileSource(user.profileimage.url);
  }, [image]);

  /* HELPER FUNCTIONS */
  const handleChangeOrganisation = (e) => {
    const orgid = e.target.value;
    dispatch(setOrganisationByUser(orgid))
      .then(() => {
        setTimeout(() => dispatch(setIsLoading(true)), 500);
        dispatch(fetchAssessments());
      });
  };

  const handleClose = () => setShow(false);

  const handleShow = () => setShow(true);

  const updateProfile = () => updateRef.current.click();

  const saveChanges = () => {
    setImageURL(newProfile.current.src);
    setProfileSource(newProfile.current.src);
    dispatch(updateProfileImage(imageData));
    handleClose();
  };

  const showCroppedImage = (e) => {
    handleShow();
    const newImage = e.target.files[0];
    const newImageURL = URL.createObjectURL(newImage);
    setImage(newImage);
    setImageURL(newImageURL);
    e.target.value = null;
  };

  return (
    <div className="box my-4 p-4 position-relative d-lg-flex justify-content-evenly">
      <div className="p-5 text-center">
        <form
          method="post"
          encType="multipart/form-data"
          id="form-upload"
          className="position-relative"
        >
          <img
            className="avatar-picture-change position-absolute"
            id="change-picture"
            src={update}
            alt="Update"
            onClick={updateProfile}
          />
          <div className="avatar-picture-message position-absolute top-50 start-50 translate-middle py-5">
            <p className="text-center text-white">Update image</p>
            <p className="text-center text-white">(500px x 500px)</p>
          </div>
          <img
            id="myProfile"
            className="avatar-picture"
            src={profileSource}
            alt="Avatar"
          />
          <input
            type="file"
            name="fileProfile"
            className="input-choose-new-picture d-none"
            ref={updateRef}
            accept="image/*"
            onChange={showCroppedImage}
          />
        </form>
        <p className="h3 mt-3">{user?.title} {user.firstname + " " + user.lastname}</p>
      </div>

      <div className="p-4" style={{ minWidth: "500px" }}>
        <p className="h3 fw-bold">Profile Details</p>

        <div className="bottom-border-2 py-4">
          <div className="d-flex justify-content-between p-1">
            <div>
              <p className="my-1 font-bold d-flex flex-column">Username
                {
                  user.username ?
                    <span className="my-1 font-secondary fw-normal">{user.username}</span> :
                    <span className="my-1 font-secondary fw-lighter">Username not added</span>
                }
              </p>
              <p className="my-1 font-bold d-flex flex-column">Email
                {
                  user.email ?
                    <span className="my-1 font-secondary fw-normal">{user.email}</span> :
                    <span className="my-1 font-secondary fw-lighter">Email not added</span>
                }
              </p>
            </div>
            <div>
              <p className="my-1 font-bold d-flex flex-column">Telephone
                {
                  user.telephone ?
                    <span className="my-1 font-secondary fw-normal">{user.telephone}</span> :
                    <span className="my-1 font-secondary fw-lighter">Telephone number not added</span>
                }
              </p>
              <p className="my-1 font-bold d-flex flex-column">Mobile
                {
                  user.mpn ?
                    <span className="my-1 font-secondary fw-normal">{user.mpn}</span> :
                    <span className="my-1 font-secondary fw-lighter">Mobile number not added</span>
                }
              </p>
            </div>

          </div>
        </div>

        {
          organisations.length > 1 &&
          organisationDropdown
        }

        <div className="py-4">
          <p className="font-bold my-1">Address</p>
          <p className="font-secondary mb-2 d-flex flex-column w-50">{userAddress}</p>
          <div className="d-flex justify-content-between">
            <div>
              <p className="my-1 font-bold d-flex flex-column">Country
                {
                  user.country.name ?
                    <span className="my-1 font-secondary fw-normal">{user.country.name}</span> :
                    <span className="my-1 font-secondary fw-lighter">Country not added</span>
                }
              </p>
              <p className="my-1 font-bold d-flex flex-column">Region
                {
                  user.region ?
                    <span className="my-1 font-secondary fw-normal">{user.region}</span> :
                    <span className="my-1 font-secondary fw-lighter">Region not added</span>
                }

              </p>
              <p className="my-1 font-bold d-flex flex-column">Locality
                {
                  user.locality ?
                    <span className="my-1 font-secondary fw-normal">{user.locality}</span> :
                    <span className="my-1 font-secondary fw-lighter">Locality not added</span>
                }
              </p>
            </div>
            <div>
              <p className="my-1 font-bold d-flex flex-column">Address Line 1
                {
                  user.address1 ?
                    <span className="my-1 font-secondary fw-normal">{user.address1}</span> :
                    <span className="my-1 font-secondary fw-lighter">Address Line 1 not added</span>
                }
              </p>
              <p className="my-1 font-bold d-flex flex-column">Address Line 2
                {
                  user.address2 ?
                    <span className="my-1 font-secondary fw-normal">{user.address2}</span> :
                    <span className="my-1 font-secondary fw-lighter">Address Line 2 not added</span>
                }
              </p>
              <p className="my-1 font-bold d-flex flex-column">Postal Code
                {
                  user.postcode ?
                    <span className="my-1 font-secondary fw-normal">{user.postcode}</span> :
                    <span className="my-1 font-secondary fw-lighter">Postal code not added</span>
                }
              </p>
            </div>

          </div>
        </div>
      </div>

      <ImageCrop
        src={imageURL}
        show={show}
        handleClose={handleClose}
        avatar={avatar}
        saveChanges={saveChanges}
        newProfile={newProfile}
        setImageData={setImageData}
      />
      {children}
    </div>
  );
}
