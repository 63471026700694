/* REACT PACKAGE IMPORTS */
import { useState, useEffect, useRef } from "react";

/* DND KIT IMPORTS */
import {
  DndContext,
  KeyboardSensor,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import { sortableKeyboardCoordinates } from "@dnd-kit/sortable";

/* INTERNAL IMPORTS */
import Droppable from "./DragDrop/Droppable";
import Draggable from "./DragDrop/Draggable";
import Item from "./DragDrop/Item";
import { shuffleArray, dynamicSort } from "../../../utils/tools.js";
const reactStringReplace = require("react-string-replace");


export default function DropBlanks({ question, answer, setUserAnswerArray }) {

  const [isLoading, setIsLoading] = useState(true);
  const [draggableItems, setDraggableItems] = useState({});
  const [parents, setParents] = useState([]);

  const [isHovering, setIsHovering] = useState(false);
  const [activeId, setActiveId] = useState(null);

  const blanksTable = {};

  const mounted = useRef(null);
  const sensors = useSensors(
    useSensor(MouseSensor),
    useSensor(TouchSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const handleDragOver = ({ active, over }) => {
    const overId = over?.id;
    const item = active.data.current;
    const activeIndex = active.id;

    if (!overId) {
      setActiveId(null);
      setIsHovering(false);
      return;
    } else {
      setActiveId(overId);
      setIsHovering(true);
    }

    setDraggableItems(currItems => {
      let newItems = { ...currItems };
      newItems[activeIndex] = item;
      return newItems;
    });

    setParents(currParent => {
      let newParents = currParent.filter(par => par.child.id !== activeIndex);
      return over ? newParents : currParent;
    });


    blanksTable[overId] = item;
    const blanksArray = [];
    for (const property in blanksTable) {
      if (blanksTable[property] === undefined) blanksArray.push("");
      else blanksArray.push(blanksTable[property]);
    };

    setUserAnswerArray(curr => {
      const newCurr = curr.filter(answer => answer.qid !== question.qid);
      const newCurrentAnswer = {
        qid: question.qid,
        answer: { dropblanks: blanksArray },
        qhid: question.qhid,
      };

      if (newCurr) return [...newCurr, newCurrentAnswer];
      else return [newCurrentAnswer];
    });
  };

  const handleDragEnd = ({ active, over }) => {
    if (!over) return;
    setActiveId(null);
    setIsHovering(false);

    if (active.id !== over.id) {
      const item = active.data.current;
      const overIndex = over.id;
      const activeIndex = active.id;

      setDraggableItems(currItems => {
        let newItems = { ...currItems };
        delete newItems[activeIndex];
        const newParents = parents.filter(par => par.parent === overIndex);
        if (newParents.length > 0) newItems[newParents[0].child.id] = newParents[0].child.value;
        return newItems;
      });

      setParents(currParents => {
        let newParents = currParents.filter(par => par.parent !== overIndex);
        const newParent = {
          parent: overIndex,
          child: { id: activeIndex, value: item },
        };
        return over ? [...newParents, newParent] : currParents;
      });

      const newParent = {
        parent: overIndex,
        child: { id: activeIndex, value: item },
      };

      const newParents = [...parents, newParent]
        .sort(dynamicSort("parent"))
        .map(item => {
          if (item.child.value) return item.child.value
          else return "";
        });

      blanksTable[overIndex] = item;
      const blanksArray = [];
      for (const property in blanksTable) {
        if (blanksTable[property] === undefined) blanksArray.push("");
        else blanksArray.push(blanksTable[property]);
      };

      setUserAnswerArray(curr => {
        const newCurr = curr.filter(answer => answer.qid !== question.qid);
        const newAnswer = {
          qid: question.qid,
          answer: { dropblanks: blanksArray },
          qhid: question.qhid,
        };

        if (newCurr) return [...newCurr, newAnswer];
        else return [newAnswer];
      });
    }
  };

  useEffect(() => {
    mounted.current = true;
    if (!isLoading) return;
    if (mounted.current) {
      const { blanks } = question;

      // Case where the user has already submitted an answer
      if (answer.length > 0) {
        const blanksAnswer = answer[0].answer.dropblanks;
        let questionString = question.question;

        reactStringReplace(
          questionString,
          /\{(.*?)\}/,
          (match, index, offset) => createBlanksTable(match, index, offset)
        );

        // setParents(() => {
        //   let newParents = [];
        //   blanksAnswer.forEach(item => {
        //     console.log(item, "<<<");
        //     questionString = questionString.replace(/\{-\}/, function (str, offset, s) {
        //       newParents.push({
        //         child: { id: item, value: item },
        //         parent: `drop_${offset}`,
        //       });
        //       return item;
        //     });
        //   });
        //   console.log(newParents);
        //   return newParents;
        // });

        setDraggableItems(() => {
          const newItems = {};
          blanks
            .filter(blank => !blanksAnswer.includes(blank))
            .forEach(blank => newItems[blank] = blank);
          return newItems;
        });
      }

      // Case where there is no prexisting answer
      else {
        setDraggableItems(() => {
          const newItems = {};
          const shuffledMatches = shuffleArray(blanks);
          shuffledMatches.forEach(item => newItems[item] = item);
          return newItems;
        });
      }
      setIsLoading(false);
    }
    return () => (mounted.current = false);
  }, [draggableItems]);

  const createBlanksTable = (match, index, offset) => {
    const adjustedIndex = ((index - 1) / 2);
    const dropOffset = `drop_${offset}`;

    (answer.length === 0) ?
      blanksTable[dropOffset] = "" :
      blanksTable[dropOffset] = answer[0].answer.dropblanks[adjustedIndex];

    return (
      <Droppable key={`drop_${index}`} id={dropOffset}>
        {
          // get the correct draggable
          parents.filter(({ parent }) => {
            if (parent === dropOffset) return parent
          }).length > 0 ?
            <Draggable
              id={
                parents.filter(par => par.parent === dropOffset)[0].child.id
              }
              value={
                parents.filter(par => par.parent === dropOffset)[0].child.value
              }
              isHovering={isHovering && activeId === dropOffset}
            />
            :
            <Item
              id={index}
              isHovering={isHovering && activeId === `drop_${offset}`}
              value=""
            >
              Drop here
            </Item>
        }
        {/* {
          blanksTable[dropOffset].length > 0 ?
            <Draggable
              id={adjustedIndex}
              value={blanksTable[dropOffset]}
              isHovering={isHovering && activeId === dropOffset}
            />
            :
            <Item
              id={index}
              isHovering={isHovering && activeId === `drop_${offset}`}
              value=""
            >
              Drop here
            </Item>

        } */}
      </Droppable>
    );
  };

  return (
    <div>
      <p className="fs-4 mb-4"><span className="fw-bold">Drop in the Blanks</span></p>
      <p className="fs-6 fst-italic text-muted">Drag and drop your answer into each of the blanks below</p>
      <DndContext
        sensors={sensors}
        onDragOver={handleDragOver}
        onDragEnd={handleDragEnd}
      >
        <div style={{ overflow: "hidden", clear: "both" }}>
          {/* Question with droppable inputs */}
          <div className="d-flex align-items-center">
            {
              reactStringReplace(
                question.question,
                /\{(.*?)\}/,
                (match, index, offset) => createBlanksTable(match, index, offset)
              )
            }
          </div>

          {/* Container for unset/initial droppable items */}
          <div className="d-flex justify-content-center align-items-center my-2">
            {
              Object
                .keys(draggableItems)
                .map((key, id) => <Draggable key={`drag_${id}`} id={key} value={draggableItems[key]} />)
            }
          </div>
        </div>
      </DndContext>
    </div>
  );
}
