/* REACT PACKAGE IMPORTS */
import  { useState } from 'react';
import Joyride, { BeaconRenderProps, ACTIONS, EVENTS, LIFECYCLE, ORIGIN, STATUS } from 'react-joyride';
import { useDispatch, useSelector } from "react-redux";
import { Outlet, useNavigate } from "react-router-dom";

/* INTERNAL IMPORTS */
// Components
import NavBar from "./NavBar";
import IdleTimeOutModal from "./IdleTimeOutModal";
import { endWalkthrough } from '../Features/Walkthroughs/Actions/WalkthroughsActions';
import { LogOutAuthAction } from "../Features/Auth/Actions/AuthActions";

// Images
import logoLogout from "../assets/logout.svg";
import logoUser from "../assets/user.svg";


const Layout = ({ tabs, logo, isEmbedded }) => {
  const joyrideOptions = {
    options: {
      arrowColor: '#fafafa',
      backgroundColor: '#fafafa',
      overlayColor: '#323232',
      primaryColor: '#1976d2',
      textColor: '#020208',
      width: 400,
      zIndex: 1000,
    },
    
  };
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { run, steps } = useSelector(store => store.walkthroughs);

  const handleJoyrideCallback = (props) => {
    const { action, index, status, type } = props;
    console.log(action, "joyride action");
    console.log(index, "joyride index");
    console.log(status, "joyride status");
    console.log(type, "joyride type");
    if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
      // Need to set our running state to false, so we can restart if we click start again.
      dispatch(endWalkthrough());
    }
  };
  const appClass = isEmbedded ? "Embedded-App" : "App";

  const handleLogout = () => {
    dispatch(LogOutAuthAction())
      .then(() => {
        navigate("/login", { replace: true });
        if (document.fullscreenElement) document.exitFullscreen();
        window.location.reload(true);
        return false;
      });
  };


  return (
    <main className={appClass}>
      {/* shows inactivity */}
      <IdleTimeOutModal handleLogout={handleLogout} />
      {
        !isEmbedded &&
        <NavBar
          tabs={tabs}
          logo={logo}
          logoLogout={logoLogout}
          logout={handleLogout}
          logoUser={logoUser}
        />
      }
      <Joyride
        callback={handleJoyrideCallback}
        run={run}
        steps={steps}
        styles={joyrideOptions}
        defaultStyles={{ buttonNext: { backgroundColour: "#020208" }}}
        showSkipButton
        continuous
      />
      <Outlet />
    </main>
  );
};

export default Layout;
