import { useState, useRef } from "react";
import "../../styles/Calculator.css";
import Wrapper from "./Wrapper";
import Screen from "./Screen";
import ButtonBox from "./ButtonBox";
import Button from "./Button";
import { IconButton, Tooltip } from "@mui/material";
import { Offcanvas } from "react-bootstrap";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

const btnValues = [
  // ["sin", "cos", "tan", "C", "+-"],
  // [7, 8, 9, "(", ")"],
  [7, 8, 9, "C", "+-"],
  [4, 5, 6, "-", "+"],
  [1, 2, 3, "/", "*"],
  [0, ".", "%", "√", "="],
];

const toLocaleString = (num) =>
  String(num).replace(/(\..*)(\d)(?=(?:\d{3})+(?:\.|$))/g, "$1 ");
// ?<!
const removeSpaces = (num) => num.toString().replace(/\s/g, "");

export default function Calculator({ show, setShow }) {
  let [calc, setCalc] = useState({
    sign: "",
    num: 0,
    res: 0,
  });

  const refCalc = useRef(null);
  const [isClicked, setIsClicked] = useState(false);
  const [showMsg, setShowMsg] = useState(false);
  const [msg, setMsg] = useState(false);
  const [type, setType] = useState(false);

  const handleClick = (e) => {
    if (!isClicked) {
      setMsg("You should copy the content to the clipboard before closing.");
      setShowMsg(true);
      setType("error");
    } else {
      setIsClicked(false);
    }
  };

  const numClickHandler = (e) => {
    e.preventDefault();
    handleClick();
    const value = e.target.innerHTML;

    if (removeSpaces(calc.num).length < 16) {
      setCalc({
        ...calc,
        num:
          calc.num === 0 && value === "0"
            ? "0"
            : removeSpaces(calc.num) % 1 === 0
            ? toLocaleString(Number(removeSpaces(calc.num + value)))
            : toLocaleString(calc.num + value),
        res: !calc.sign ? 0 : calc.res,
      });
    }
  };

  const commaClickHandler = (e) => {
    e.preventDefault();
    handleClick();
    const value = e.target.innerHTML;
    setCalc({
      ...calc,
      num: !calc.num.toString().includes(".") ? calc.num + value : calc.num,
    });
  };

  const bracketsClickHandler = (e) => {
    e.preventDefault();
    handleClick();
    const value = e.target.innerHTML;

    setCalc({
      ...calc,
      num: !calc.num.toString().includes("(") ? value : calc.num,
    });
  };

  const signClickHandler = (e) => {
    e.preventDefault();
    handleClick();
    const value = e.target.innerHTML;

    setCalc({
      ...calc,
      sign: value,
      res: !calc.res && calc.num ? calc.num : calc.res,
      num: 0,
    });
  };

  const equalsClickHandler = () => {
    handleClick();
    if (calc.sign && calc.num) {
      const math = (a, b, sign) =>
        sign === "+"
          ? a + b
          : sign === "-"
          ? a - b
          : sign === "*"
          ? a * b
          : a / b;

      setCalc({
        ...calc,
        res:
          calc.num === "0" && calc.sign === "/"
            ? "Can't divide with 0"
            : toLocaleString(
                math(
                  Number(removeSpaces(calc.res)),
                  Number(removeSpaces(calc.num)),
                  calc.sign
                )
              ),
        sign: "",
        num: 0,
      });
    }
  };

  const invertClickHandler = () => {
    handleClick();
    setCalc({
      ...calc,
      num: calc.num ? toLocaleString(removeSpaces(calc.num) * -1) : 0,
      res: calc.res ? toLocaleString(removeSpaces(calc.res) * -1) : 0,
      sign: "",
    });
  };

  const percentClickHandler = () => {
    handleClick();
    let num = calc.num ? parseFloat(removeSpaces(calc.num)) : 0;
    let res = calc.res ? parseFloat(removeSpaces(calc.res)) : 0;

    setCalc({
      ...calc,
      num: (num /= Math.pow(100, 1)),
      res: (res /= Math.pow(100, 1)),
      sign: "",
    });
  };

  const squareRootsHandler = () => {
    handleClick();
    let num = calc.num ? parseFloat(removeSpaces(calc.num)) : 0;
    let res = calc.res ? parseFloat(removeSpaces(calc.res)) : 0;

    setCalc({
      ...calc,
      num: Math.sqrt(num),
      res: Math.sqrt(res),
      sign: "",
    });
  };

  const trigonometryClickHandler = (e) => {
    e.preventDefault();
    handleClick();
    const sign = e.target.innerHTML;
    let num = calc.num ? parseFloat(removeSpaces(calc.num)) : 0;
    let res = calc.res ? parseFloat(removeSpaces(calc.res)) : 0;

    const math = (num, sign) =>
      sign === "sin"
        ? Math.sin(num)
        : sign === "cos"
        ? Math.cos(num)
        : Math.tan(num);

    setCalc({
      ...calc,
      num: math(num, sign),
      res: math(res, sign),
      sign: "",
    });
  };

  const resetClickHandler = () => {
    handleClick();
    setCalc({
      ...calc,
      sign: "",
      num: 0,
      res: 0,
    });
  };

  return (
    <Offcanvas
      show={show}
      onHide={() => setShow(false)}
      scroll={true}
      placement={"end"}
      backdrop={false}
      aria-label="Calculator"
    >
      <Offcanvas.Header closeButton>
        <Offcanvas.Title>Calculator</Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body>
        <div className="container-calculator d-flex justify-content-center align-items-center p-3">
          <Wrapper>
            <Screen refCalc={refCalc} value={calc.num ? calc.num : calc.res} />
            <ButtonBox>
              {btnValues.flat().map((btn, i) => {
                return (
                  <Button
                    variant="dark"
                    key={i}
                    className={btn === "=" ? "equals" : "buttonCalc"}
                    value={btn}
                    onClick={
                      btn === "C"
                        ? resetClickHandler
                        : btn === "+-"
                        ? invertClickHandler
                        : btn === "%"
                        ? percentClickHandler
                        : btn === "="
                        ? equalsClickHandler
                        : btn === "/" ||
                          btn === "*" ||
                          btn === "-" ||
                          btn === "+"
                        ? signClickHandler
                        : btn === "√"
                        ? squareRootsHandler
                        : btn === "."
                        ? commaClickHandler
                        : btn === "sin" || btn === "cos" || btn === "tan"
                        ? trigonometryClickHandler
                        : btn === "(" || btn === ")"
                        ? bracketsClickHandler
                        : numClickHandler
                    }
                  />
                );
              })}
            </ButtonBox>
          </Wrapper>
        </div>
        <div className="d-flex justify-content-end align-items-center">
          {showMsg && <div className={type + " me-2"}>{msg}</div>}
          <Tooltip title="Copy to clipboard">
            <IconButton
              aria-label="copy"
              onClick={(e) => {
                setShowMsg(true);
                setIsClicked(true);
                window.navigator.clipboard
                  .writeText(refCalc.current.innerText)
                  .then(() => {
                    setMsg("Copied!");
                    setType("success");
                  })
                  .catch(() => {
                    setMsg("Something went wrong!");
                    setType("error");
                  });
              }}
            >
              <ContentCopyIcon />
            </IconButton>
          </Tooltip>
        </div>
      </Offcanvas.Body>
    </Offcanvas>
  );
}
