import React, { useState, useEffect, useRef } from "react";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import { formatterDropInTheBlanks, flattenArr } from "../../utils/tools.js";
import { IconButton, Tooltip } from "@mui/material";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import TableRowsIcon from "@mui/icons-material/TableRows";
import Collapse from "@mui/material/Collapse";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import CheckIcon from "@mui/icons-material/Check";
import OpenInFullIcon from "@mui/icons-material/OpenInFull";
import PendingIcon from "@mui/icons-material/Pending";
import { fetchAnswersBySession } from "../../Features/Sessions/Actions/SessionsActions";

import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

export default function PrintResults() {
  const { session, answers, status } = useSelector((store) => store.sessions);
  const { questions } = useSelector((store) => store.assessments);
  const [show, setShow] = useState(false);
  const [questionId, setQuestionId] = useState(null);

  const mounted = useRef(null);
  const resultsRef = useRef(null);

  const dispatch = useDispatch();

  const { id } = useParams();
  const sessionId = parseInt(id);

  const printResults = () => {
    const pdf = new jsPDF("landscape", "pt", "a4");

    html2canvas(resultsRef.current).then((canvas) => {
      const img = canvas.toDataURL("image/png");
      const imgProperties = pdf.getImageProperties(img);
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = (imgProperties.height * pdfWidth) / imgProperties.width;
      pdf.addImage(img, "PNG", 0, 0, pdfWidth, pdfHeight);
      pdf.save("download.pdf");
    });
  };

  const exportExcel = () => {
    const questionsArr = questions.map((section) => section.questions);
    var mergedQuestions = [].concat
      .apply([], questionsArr)
      .map((question) => question.questions);
    var totalQuestions = flattenArr(mergedQuestions);

    const csvData = totalQuestions.map((item, i) => {
      const { options, qtype, question, score, qid } = item;
      const currAnswers = answers.filter((answer) => answer.qid == qid)[0];
      const { answer, answered, marked, marks } = currAnswers;

      const correct_answers = options
        ? options
            .filter((option) => option.iscorrect)
            .map((option) => option.opttext)
            .join(", ")
        : question.substring(question.indexOf("{") + 1, question.indexOf("}"));
      const newAnswer = options
        ? options
            .filter((option) => answer.includes(option.qoid))
            .map((option) => option.opttext)
            .join(", ")
        : answer.join(", ");

      const newQuestion = {
        Id: qid,
        Question: question,
        Type: qtype.typedesc,
        Answer: newAnswer,
        "Correct answer": correct_answers,
        Marked: marked,
        Marks: marks,
        Score: score,
        Answered: answered,
      };
      return newQuestion;
    });

    const ws = XLSX.utils.json_to_sheet(csvData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8",
    });
    FileSaver.saveAs(data, "results.xlsx");
  };

  useEffect(() => {
    mounted.current = true;
    if (answers.length > 0 || status === "failed") return;
    if (mounted.current) {
      dispatch(fetchAnswersBySession(sessionId));
    }

    return () => (mounted.current = false);
  }, [answers]);

  return (
    answers.length > 0 && (
      <TableContainer className="box" ref={resultsRef}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell className="fw-bold">Question</TableCell>
              <TableCell className="fw-bold">Type</TableCell>
              <TableCell className="fw-bold" align="center">
                Answer
              </TableCell>
              {session.schedule.showanswer && (
                <TableCell className="fw-bold" align="center">
                  Correct answer
                </TableCell>
              )}
              <TableCell className="fw-bold" align="center">
                Marked
              </TableCell>
              <TableCell className="fw-bold" align="center">
                Score
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {questions.length > 0 &&
              questions.map((section, index) => {
                return (
                  section.questions.length > 0 &&
                  section.questions.map((questionHeader, index) => {
                    return questionHeader.questions.map((question, i) => {
                      return (
                        <React.Fragment key={i}>
                          <TableRow key={i}>
                            <TableCell component="th">
                              {question.qtype.typename === "fillblanks" ||
                              question.qtype.typename === "draganddropchoice"
                                ? question.question.replace(/\{(.*?)\}/, "...")
                                : question.question}
                            </TableCell>
                            <TableCell component="th">
                              {question.qtype.typedesc}
                            </TableCell>

                            {question.qtype.typename === "freetext" ? (
                              <TableCell
                                align="center"
                                colSpan={session.schedule.showanswer ? 2 : 1}
                              >
                                <Tooltip title="Read answer">
                                  <IconButton
                                    aria-label="Read answer"
                                    onClick={(e) => {
                                      setShow(!show);
                                      setQuestionId(question.qid);
                                    }}
                                  >
                                    <OpenInFullIcon fontSize="small" />
                                  </IconButton>
                                </Tooltip>
                              </TableCell>
                            ) : (
                              <React.Fragment>
                                <TableCell align="center" component="th">
                                  {(question.qtype.typename === "singlechoice" ||
                                    question.qtype.typename === "multichoice" ||
                                    question.qtype.typename === "dropdownchoice" ||
                                    question.qtype.typename === "draganddropchoice") &&
                                    question.options &&
                                    question.options.map((option, i) => {
                                      return (
                                        <li
                                          className="list-group-item d-flex justify-content-between"
                                          key={i}
                                        >
                                          {option.optimage ? (
                                            <img
                                              key={parseInt(
                                                option.optimage.imid
                                              )}
                                              style={{
                                                width: "70px",
                                                objectFit: "cover",
                                              }}
                                              src={option.optimage.direct_url}
                                              alt="Question"
                                            />
                                          ) : (
                                            option.opttext
                                          )}
                                          {answers.filter((answer) => {
                                            return answer.qid == question.qid;
                                          }).length > 0 &&
                                          answers.filter((answer) => {
                                            return answer.qid == question.qid;
                                          })[0].answer &&
                                          answers
                                            .filter((answer) => {
                                              return answer.qid == question.qid;
                                            })[0]
                                            .answer.includes(option.qoid) ? (
                                            <CheckIcon
                                              fontSize="small"
                                              className="right"
                                            />
                                          ) : (
                                            ""
                                          )}
                                        </li>
                                      );
                                    })}
                                  {question.qtype.typename === "sorting" &&
                                    answers.filter((answer) => {
                                      return answer.qid == question.qid;
                                    }).length > 0 &&
                                    answers
                                      .filter((answer) => {
                                        return answer.qid == question.qid;
                                      })[0]
                                      .answer.map((answer, i) => {
                                        return (
                                          <li
                                            className="list-group-item d-flex justify-content-between"
                                            key={i}
                                          >
                                            {question.options
                                              .filter(
                                                (option) =>
                                                  answer === option.qoid
                                              )
                                              .map((option) => option.opttext)}
                                          </li>
                                        );
                                      })}

                                  {question.qtype.typename === "matching" &&
                                    answers.filter((answer) => {
                                      return answer.qid == question.qid;
                                    }).length > 0 &&
                                    answers
                                      .filter((answer) => {
                                        return answer.qid == question.qid;
                                      })[0]
                                      .answer.map((answer, i) => {
                                        return (
                                          <li
                                            className="list-group-item text-start d-flex justify-content-between"
                                            key={i}
                                          >
                                            {question.options
                                              .filter(
                                                (option) =>
                                                  answer.optid === option.qoid
                                              )
                                              .map((option) => option.opttext)}
                                            {" - "}
                                            {answer.answer}
                                          </li>
                                        );
                                      })}

                                  {question.qtype.typename === "fillblanks" &&
                                    answers.filter((answer) => {
                                      return answer.qid == question.qid;
                                    }).length > 0 &&
                                    answers
                                      .filter((answer) => {
                                        return answer.qid == question.qid;
                                      })[0]
                                      .answer.map((answer, i) => {
                                        return answer;
                                      })}

                                  {question.qtype.typename === "dropblanks" &&
                                    answers.filter((answer) => {
                                      return answer.qid == question.qid;
                                    }).length > 0 &&
                                    formatterDropInTheBlanks(
                                      question.question,
                                      answers.filter((answer) => {
                                        return answer.qid == question.qid;
                                      })[0].answer
                                    )}
                                </TableCell>
                                {session.schedule.showanswer && (
                                  <TableCell align="center">
                                    {(question.qtype.typename === "singlechoice" ||
                                      question.qtype.typename === "multichoice" ||
                                      question.qtype.typename === "dropdownchoice" ||
                                      question.qtype.typename === "draganddropchoice") &&
                                      question.options &&
                                      question.options.map((option, i) => {
                                        return (
                                          <li
                                            className="list-group-item d-flex justify-content-between"
                                            key={i}
                                          >
                                            {option.optimage ? (
                                              <img
                                                key={parseInt(
                                                  option.optimage.imid
                                                )}
                                                style={{
                                                  width: "70px",
                                                  objectFit: "cover",
                                                }}
                                                src={option.optimage.direct_url}
                                                alt="Question"
                                              />
                                            ) : (
                                              option.opttext
                                            )}
                                            {option.iscorrect ? (
                                              <CheckIcon
                                                fontSize="small"
                                                className="right"
                                              />
                                            ) : (
                                              ""
                                            )}
                                          </li>
                                        );
                                      })}
                                    {question.qtype.typename === "matching" &&
                                      question.options.filter((option) => {
                                        return option.qid == question.qid;
                                      }).length > 0 &&
                                      question.options.map((option, i) => {
                                        return (
                                          <li
                                            className="list-group-item text-start d-flex justify-content-between"
                                            key={i}
                                          >
                                            {option.opttext}
                                            {" - "}
                                            {option.optanswer}
                                          </li>
                                        );
                                      })}
                                    {question.qtype.typename === "sorting" &&
                                      question.options &&
                                      question.options.map((option, i) => {
                                        return (
                                          <li
                                            className="list-group-item d-flex justify-content-between"
                                            key={i}
                                          >
                                            {option.opttext}
                                          </li>
                                        );
                                      })}
                                    {question.qtype.typename === "fillblanks" &&
                                      question.question.substring(
                                        question.question.indexOf("{") + 1,
                                        question.question.indexOf("}")
                                      )}
                                    {question.qtype.typename === "dropblanks" &&
                                      formatterDropInTheBlanks(
                                        question.question,
                                        question.blanks
                                      )}
                                  </TableCell>
                                )}
                              </React.Fragment>
                            )}

                            <TableCell align="center">
                              {answers.filter((answer) => {
                                return answer.qid == question.qid;
                              }).length > 0 &&
                              answers.filter((answer) => {
                                return answer.qid == question.qid;
                              })[0].marked ? (
                                <CheckIcon className="right" />
                              ) : (
                                <Tooltip title="Pending...">
                                  <PendingIcon
                                    fontSize="small"
                                    className="pending"
                                  />
                                </Tooltip>
                              )}
                            </TableCell>
                            <TableCell align="center">
                              {answers.filter((answer) => {
                                return answer.qid == question.qid;
                              }).length > 0 &&
                                answers.filter((answer) => {
                                  return answer.qid == question.qid;
                                })[0].marks}
                              /{question.score}
                            </TableCell>
                          </TableRow>
                          {question.qtype.typename === "freetext" && (
                            <TableRow>
                              <TableCell
                                style={{ padding: 0, paddingTop: 0 }}
                                colSpan={8}
                              >
                                <Collapse
                                  in={show && question.qid == questionId}
                                  timeout="auto"
                                  unmountOnExit
                                  className="Box background-secondary p-0"
                                >
                                  <div className="m-3">
                                    {answers.filter((answer) => {
                                      return answer.qid == questionId;
                                    }).length > 0 &&
                                      answers
                                        .filter((answer) => {
                                          return answer.qid == questionId;
                                        })[0]
                                        .answer.map((answer, i) => {
                                          return answer;
                                        })}
                                  </div>
                                </Collapse>
                              </TableCell>
                            </TableRow>
                          )}
                        </React.Fragment>
                      );
                    });
                  })
                );
              })}
          </TableBody>
        </Table>
        <div className="text-end">
          <Tooltip title="Export pdf">
            <IconButton aria-label="Export pdf" onClick={printResults}>
              <FileDownloadIcon fontSize="small" />
            </IconButton>
          </Tooltip>
          <Tooltip title="Export excel">
            <IconButton aria-label="Export excel" onClick={exportExcel}>
              <TableRowsIcon fontSize="small" />
            </IconButton>
          </Tooltip>
        </div>
      </TableContainer>
    )
  );
}
