import { useNavigate } from "react-router-dom";
import { Container, Button } from "react-bootstrap";

export default function NoMatch() {
  const navigate = useNavigate();
  const goBack = () => navigate(-1);

  return (
    <Container style={{ height: "80vh" }}>
      <div
        className="h-100 d-flex flex-column justify-content-center align-items-center"
        style={{ height: "100%" }}
      >
        <h1>Page not found</h1>
        <p>The page you are trying to access does not exist.</p>
        <div className="flexGrow">
          <Button variant="dark" onClick={goBack}>
            Go Back
          </Button>
        </div>
      </div>
    </Container>
  );
}
