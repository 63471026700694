/* REACT PACKAGE IMPORTS */
import { useState } from "react";
import { Form } from "react-bootstrap";
import Select from "react-select";


export default function DropdownField({ dropdown }) {
  const [validationStatus, setValidationStatus] = useState(null);

  const fieldLabel = (dropdown.isRequired) ?
    <Form.Label className="fw-bold m-0">{dropdown.label}
      <span className="asterisk-red"> *</span>
    </Form.Label> :
    <Form.Label className="fw-bold m-0">{dropdown.label}</Form.Label>;

  return (
    <Form.Group
      className={"mb-4"}
      key={dropdown.id}
      controlId={dropdown.id}
    >
      <div className="d-flex flex-column">
        {fieldLabel}
        {
          dropdown.message &&
          <Form.Text className="text-muted mt-0 mb-1">{dropdown.message}</Form.Text>
        }
      </div>
      <Select 
        name={dropdown.id}
        options={dropdown.options}
      />
      {
        dropdown.invalidFeedback &&
        <Form.Control.Feedback type="invalid">
          {dropdown.invalidFeedback}
        </Form.Control.Feedback>
      }
      {
        dropdown.validFeedback &&
        <Form.Control.Feedback type="valid">
          {dropdown.validFeeback}
        </Form.Control.Feedback>
      }
    </Form.Group>
  );
};