/* REACT PACKAGE IMPORTS */
import { useEffect, useRef, useState } from 'react';
import { Button, Col, Form, InputGroup, Row, Spinner } from "react-bootstrap";
import { useDispatch } from 'react-redux';
import { Link, useNavigate } from "react-router-dom";

/* MUI PACKAGE IMPORTS */
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

/* INTERNAL IMPORTS */
import { getCountries, RegisterAuthAction, verifyEmail, verifyUsername } from '../Features/Auth/Actions/AuthActions';
import { openModal } from "../Features/Errors/Slices/errorSlice";
import "../styles/Registration.css";
import RegistrationIcon from "../assets/authentication/candidate.png"


export default function Registration({ logo }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const emailRef = useRef();
  const passwordRef = useRef();
  const usernameRef = useRef();
  const firstnameRef = useRef();
  const lastnameRef = useRef();

  const defaultFields = {
    username: '',
    email: '',
    password: '',
    confirmPassword: '',
    firstname: '',
    lastname: '',
    title: '',
    country: '',
    region: '',
    locality: '',
    addressOne: '',
    addressTwo: '',
    postcode: '',
    mobileNumber: '',
    telephone: '',
    orgid: 1
  };

  const [registrationDetails, setRegistrationDetails] = useState(defaultFields);
  const [isVisible, setIsVisible] = useState(false);
  const [isConfirmVisible, setIsConfirmVisible] = useState(false);

  const [countriesArray, setCountriesArray] = useState([]);

  const [isValidUsername, setIsValidUsername] = useState(null);
  const [isValidPassword, setIsValidPassword] = useState(null);
  const [isValidConfirmPassword, setIsValidConfirmPassword] = useState(null);
  const [isValidEmail, setIsValidEmail] = useState(null);
  const [isValidFirstname, setIsValidFirstname] = useState(null);
  const [isValidLastname, setIsValidLastname] = useState(null);

  const [checkingEmailAvailability, setCheckingEmailAvailability] = useState(false);
  const [checkingUsernameAvailability, setCheckingUsernameAvailability] = useState(false);
  const [emailVerificationMessage, setEmailVerificationMessage] = useState(null);
  const [usernameVerificationMessage, setUsernameVerificationMessage] = useState(null);

  /* USEEFFECT FUNCTIONS */
  // Initial Render side effects
  useEffect(() => {
    emailRef.current.focus();
    dispatch(getCountries())
      .then(response => {
        const countries = response.payload.map(country => {
          const countryCode = country['alpha2Code'];
          const countryName = country['name'];
          return { code: countryCode, name: countryName };
        })
        setCountriesArray(countries);
      });
  }, []);

  // Side effects for Email changes
  useEffect(() => {
    setIsValidEmail(null);
    if (registrationDetails.email.length > 4 && registrationDetails.email.includes('@')) {
      setCheckingEmailAvailability(true);
    } else {
      setCheckingEmailAvailability(false);
    };

    const timeout = setTimeout(() => {
      emailVerification();
    }, 1500);

    return () => clearTimeout(timeout);
  }, [registrationDetails.email]);

  // Side effects for Username changes
  useEffect(() => {
    setIsValidUsername(null);
    if (registrationDetails.username.length < 3) {
      setCheckingUsernameAvailability(false);
    } else {
      setCheckingUsernameAvailability(true);
    }

    const timeout = setTimeout(() => {
      usernameVerification();
    }, 1500);

    return () => clearTimeout(timeout);
  }, [registrationDetails.username]);

  // Side effects for confirm password and password changes
  useEffect(() => validatePassword(), [registrationDetails.password]);

  // Side effects for confirm password and password changes
  useEffect(() => {
    if (registrationDetails.confirmPassword === "") setIsValidConfirmPassword(null)
    else if (registrationDetails.password === registrationDetails.confirmPassword) {
      setIsValidConfirmPassword("true");
    } else setIsValidConfirmPassword("false");
  }, [registrationDetails.password, registrationDetails.confirmPassword]);

  useEffect(() => {
    if (registrationDetails.firstname.length > 1) {
      setIsValidFirstname(true);
    } else setIsValidFirstname(null);
  }, [registrationDetails.firstname]);

  useEffect(() => {
    if (registrationDetails.lastname.length > 1) {
      setIsValidLastname(true);
    } else setIsValidLastname(null);
  }, [registrationDetails.lastname]);


  /* HELPER FUNCTIONS */
  const handleFieldChange = (fieldName, event) => {
    setRegistrationDetails({ ...registrationDetails, [fieldName]: event.target.value })
  };

  const emailVerification = () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (emailRegex.test(registrationDetails.email)) {
      dispatch(verifyEmail(registrationDetails.email))
        .then(response => {
          if (response.payload === "not_found") {
            setIsValidEmail("true");
            setCheckingEmailAvailability(false);
            setEmailVerificationMessage("Email address available for registration");
          } else if (response.payload === "found_vx") {
            setIsValidEmail("false");
            setCheckingEmailAvailability(false);
            setEmailVerificationMessage("Email address already registered");
          } else if (response.payload === "found_vauth") {
            setIsValidEmail("true");
            setCheckingEmailAvailability(false);
            setEmailVerificationMessage("Email address already registered with VICTVS, your existing log in will be used");
          }
        });
    }
  };

  const usernameVerification = () => {
    if (registrationDetails.username.length > 2) {
      dispatch(verifyUsername(registrationDetails.username))
        .then(response => {
          if (response.payload === "not_found_username") {
            setIsValidUsername("true");
            setCheckingUsernameAvailability(false);
            setUsernameVerificationMessage("Username available for registration");
          } else if (response.payload === "found_vx_username") {
            setIsValidUsername("false");
            setCheckingUsernameAvailability(false);
            setUsernameVerificationMessage("Username is already registered");
          } else if (response.payload === "found_vauth_username") {
            setIsValidUsername("true");
            setCheckingUsernameAvailability(false);
            setUsernameVerificationMessage("Username is already registered");
          }
        });
    }
  };

  const validatePassword = () => {
    const passwordRegex = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$ %^&*-]).{8,255}$/;
    const isValidPassword = passwordRegex.test(registrationDetails.password);
    if (registrationDetails.password === "") setIsValidPassword(null)
    else if (isValidPassword) setIsValidPassword("true");
    else setIsValidPassword("false");
  };

  const handleValidation = () => {
    if (isValidEmail === "false") {
      dispatch(openModal({
        message: "Please choose a valid Email Address",
        severity: "warning"
      }));
      emailRef.current.focus();
      return false;
    } else if (isValidUsername === "false") {
      dispatch(openModal({
        message: "Please choose a valid Username",
        severity: "warning"
      }));
      usernameRef.current.focus();
      return false;
    } else if (isValidConfirmPassword === "false") {
      dispatch(openModal({
        message: "Password and Confirm Password fields do not match",
        severity: "warning"
      }));
      passwordRef.current.focus();
      return false;
    } else if (isValidPassword === "false") {
      dispatch(openModal({
        message: "Password does not meet the security criteria",
        severity: "warning"
      }));
      passwordRef.current.focus();
      return false;
    } else if (!isValidFirstname) {
      firstnameRef.current.focus();
      return false;
    } else if (!isValidLastname) {
      lastnameRef.current.focus();
      return false;
    } else return true;
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (handleValidation()) {
      dispatch(openModal({
        message: "VX account successfully registered! Redirecting to the Sign In page",
        severity: "success"
      }));
      dispatch(RegisterAuthAction(registrationDetails))
        .then(() => setTimeout(() => navigate("/login"), 2500));
    }
  };

  return (
    <div className="py-md-5">
      <div className="Register-container box mx-auto p-4">
        <div className="d-flex flex-column mx-5 my-3 justify-content-between align-items-center">
          {/* Invisible h1 added for accessibility requirements */}
          <h1 className="visually-hidden">victvs vx registration</h1>
          <div className="mb-2">
            <img src={logo} alt="Logo" height="128" />
          </div>
          <img className="mt-2" src={RegistrationIcon} alt="" style={{ height: "40px", width: "40px" }} />
          <p className="h5 mt-1 mb-0">Candidate Registration</p>
        </div>

        <Form id="form" className="mx-5 my-5" onSubmit={handleSubmit}>
          <p className="h5 mt-3 mb-0 fw-bold">Core Information</p>
          <Form.Text>
            <span className="text-danger mx-1">*</span>Required fields
          </Form.Text>

          <Row className="mt-2 mb-3">
            <Form.Group as={Col}>
              <Form.Label>Email <span className="text-danger">*</span></Form.Label>
              <Form.Control
                type="email"
                required
                ref={emailRef}
                onChange={event => handleFieldChange("email", event)}
                isInvalid={isValidEmail === "false"}
                isValid={isValidEmail === "true"}
              />
              {
                checkingEmailAvailability ?
                  <div className='mt-1 d-flex align-items-center'>
                    <Form.Text className='m-0'>Checking availability</Form.Text>
                    <Spinner animation="border" role="status" size='sm' className="mx-2" />
                  </div> :
                  null
              }
              <Form.Control.Feedback type="valid">
                {emailVerificationMessage}
              </Form.Control.Feedback>
              <Form.Control.Feedback type="invalid">
                {emailVerificationMessage}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group as={Col} controlId='formRegisterUsername'>
              <Form.Label>Username <span className="text-danger">*</span></Form.Label>
              <Form.Control
                type="text"
                ref={usernameRef}
                required
                onChange={event => handleFieldChange("username", event)}
                isInvalid={isValidUsername === "false"}
                isValid={isValidUsername === "true"}
              />
              {
                checkingUsernameAvailability ?
                  <div className='mt-1 d-flex align-items-center'>
                    <Form.Text className='m-0'>Checking availability</Form.Text>
                    <Spinner animation="border" role="status" size='sm' className="mx-2" />
                  </div> :
                  null
              }
              <Form.Control.Feedback type="valid">
                {usernameVerificationMessage}
              </Form.Control.Feedback>
              <Form.Control.Feedback type="invalid">
                {usernameVerificationMessage}
              </Form.Control.Feedback>
            </Form.Group>
          </Row>

          <Row className="mb-3">
            <Form.Group as={Col}>
              <Form.Label>Password <span className="text-danger">*</span></Form.Label>
              <InputGroup>
                <Form.Control
                  type={isVisible ? "text" : "password"}
                  required
                  ref={passwordRef}
                  aria-describedby="passwordHelpBlock"
                  onChange={event => handleFieldChange("password", event)}
                  isInvalid={isValidPassword === "false"}
                  isValid={isValidPassword === "true"}
                />
                <InputGroup.Text className="visibility-icon">
                  <i onClick={() => setIsVisible(!isVisible)} className="hover-pointer">
                    {
                      !isVisible ?
                        <VisibilityIcon fontSize="small" /> :
                        <VisibilityOffIcon fontSize="small" />
                    }
                  </i>
                </InputGroup.Text>
              </InputGroup>
              <Form.Text id="passwordHelpBlock" muted className='fs-small'>
                Your password must contain at least 8 characters 
                and include at least one lowercase letter, uppercase letter, 
                number, and special character (!@#$&*%).
              </Form.Text>

            </Form.Group>

            <Form.Group as={Col}>
              <Form.Label>Confirm Password <span className="text-danger">*</span></Form.Label>
              <InputGroup>
                <Form.Control
                  type={isConfirmVisible ? "text" : "password"}
                  required
                  onChange={event => handleFieldChange("confirmPassword", event)}
                  isInvalid={isValidConfirmPassword === "false"}
                  isValid={isValidConfirmPassword === "true"}
                />
                <InputGroup.Text className="visibility-icon">
                  <i onClick={() => setIsConfirmVisible(!isConfirmVisible)} className="hover-pointer">
                    {
                      !isConfirmVisible ?
                        <VisibilityIcon fontSize="small" /> :
                        <VisibilityOffIcon fontSize="small" />
                    }
                  </i>
                </InputGroup.Text>
                <Form.Control.Feedback type="valid">
                  Password and Confirm Password fields match!
                </Form.Control.Feedback>
                <Form.Control.Feedback type="invalid">
                  Password and Confirm Password fields do not match
                </Form.Control.Feedback>
              </InputGroup>
            </Form.Group>
          </Row>

          <Row className="mb-3">
            <Form.Group as={Col}>
              <Form.Label>First Name <span className="text-danger">*</span></Form.Label>
              <Form.Control
                type="text"
                required
                ref={firstnameRef}
                onChange={event => handleFieldChange("firstname", event)}
                isValid={isValidFirstname}
              />
            </Form.Group>

            <Form.Group as={Col}>
              <Form.Label>Last Name <span className="text-danger">*</span></Form.Label>
              <Form.Control
                type="text"
                required
                ref={lastnameRef}
                onChange={event => handleFieldChange("lastname", event)}
                isValid={isValidLastname}
              />
            </Form.Group>
          </Row>

          <Form.Group as={Col} className="mb-5">
            <Form.Label>Organisation <span className="text-danger">*</span></Form.Label>
            <Form.Control
              type="text"
              required
              readOnly
              value={"CIPS"}
            />
          </Form.Group>

          <p className="h5 mt-3 mb-0 fw-bold">Additional Information</p>
          <Form.Text>Additional account information</Form.Text>
          <Form.Group className="mt-2 mb-3">
            <Form.Label>Title</Form.Label>
            <Form.Control
              type="text"
              onChange={event => handleFieldChange("title", event)}
            />
          </Form.Group>

          <Row className="mb-3">
            <Form.Group as={Col}>
              <Form.Label>Country <span className="text-danger">*</span></Form.Label>
              <Form.Select onChange={event => handleFieldChange("country", event)} required>
                <option key="defaultOption" value="">Choose a country...</option>
                {
                  countriesArray.length > 1 ? 
                    countriesArray.map(country => <option key={country.code} value={country.code}>{country.name}</option>) :
                    <option value="0">loading countries...</option>
                }
              </Form.Select>
            </Form.Group>

            <Form.Group as={Col}>
              <Form.Label>Region</Form.Label>
              <Form.Control
                type="text"
                onChange={event => handleFieldChange("region", event)}
              />
            </Form.Group>

            <Form.Group as={Col}>
              <Form.Label>Locality</Form.Label>
              <Form.Control
                type="text"
                onChange={event => handleFieldChange("locality", event)}
              />
            </Form.Group>
          </Row>

          <Form.Group className="mb-3">
            <Form.Label>Address Line 1</Form.Label>
            <Form.Control
              type="text"
              onChange={event => handleFieldChange("addressOne", event)}
            />
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Address Line 2</Form.Label>
            <Form.Control
              type="text"
              onChange={event => handleFieldChange("addressTwo", event)}
            />
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label>Postcode</Form.Label>
            <Form.Control
              type="text"
              onChange={event => handleFieldChange("postcode", event)}
            />
          </Form.Group>

          <Row>
            <Form.Group as={Col} className="mb-3">
              <Form.Label>Telephone</Form.Label>
              <Form.Control
                type="text"
                onChange={event => handleFieldChange("telephone", event)}
              />
            </Form.Group>

            <Form.Group as={Col} className="mb-3">
              <Form.Label>Mobile Number</Form.Label>
              <Form.Control
                type="text"
                onChange={event => handleFieldChange("mobileNumber", event)}
              />
            </Form.Group>
          </Row>
          <div className="my-4">
            <Button
              variant="dark"
              type="submit"
              className="container"
            >
              Register Account
            </Button>
          </div>
          <div className="d-flex flex-column">
            <Form.Text>
              Already Registered?
              <Link
                className="mx-2 text-decoration-none link-font-size"
                to="/login"
              >
                Sign in to your account
              </Link>
            </Form.Text>
          </div>
        </Form>
      </div>
    </div>
  );
}
