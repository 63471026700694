import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isOpen: false,
  severity: "error",
  message: "",
};

const errorSlice = createSlice({
  name: "modal",
  initialState,
  reducers: {
    openModal: (state, action) => {
      state.message = action.payload.message;
      state.severity = action.payload.severity;
      state.isOpen = true;
    },
    closeModal: (state, action) => {
      state.message = "";
      state.isOpen = false;
    },
  },
});

export const { openModal, closeModal } = errorSlice.actions;

export default errorSlice.reducer;
