/* REACT PACKAGE IMPORTS */
import { Form } from "react-bootstrap";


export default function CheckboxField({ checkbox }) {
  const fieldLabel = (checkbox.isRequired) ?
    <Form.Label className="fw-bold m-0">{checkbox.label}
      <span className="asterisk-red"> *</span>
    </Form.Label> :
    <Form.Label className="fw-bold m-0">{checkbox.label}</Form.Label>;


  return (
    <Form.Group
      className={"mb-4"}
      key={checkbox.id}
      controlId={checkbox.id}
    >
      <div className="d-flex flex-column">
        {fieldLabel}
        {
          checkbox.message &&
          <Form.Text className="text-muted mt-0 mb-1">{checkbox.message}</Form.Text>
        }
      </div>
      <Form.Check
        data-cy={checkbox.label}
        type={checkbox.type}
        defaultValue={checkbox.value}
        name={checkbox.id}
      />
      {
        checkbox.invalidFeedback &&
        <Form.Control.Feedback type="invalid">
          {checkbox.invalidFeedback}
        </Form.Control.Feedback>
      }
      {
        checkbox.validFeedback &&
        <Form.Control.Feedback type="valid">
          {checkbox.validFeeback}
        </Form.Control.Feedback>
      }
    </Form.Group>
  )
};