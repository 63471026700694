import axios from "axios";

const OPEN_AI_API_KEY = "sk-proj-TrxeE3A86B6bBmED8usrT3BlbkFJmV1xtc4nfJ18AwSEfMc1";
const OPEN_AI_URL = "https://api.openai.com/v1";


const fetchQuestionText = 
  async () => {
    try {
      const Axios = axios.create({ baseURL: OPEN_AI_URL });
      const systemMessage = {
        "role": "system",
        "content": "You are a helpful assistant designed to output JSON.",
      };
      const messageOne = {
        "role": "user", 
        "content": "generate three suggestions for interesting, unique, random quiz topics in an array called topic_suggestions. The output must be in JSON format with each topic containing a unique topic id, topic name, example question and answer"
      };
      const requestBody = {
        "messages": [systemMessage, messageOne],
        "model":"gpt-4o",
        "max_tokens": 700,
        "temperature": 0.7,
        "response_format": { type: "json_object" },
        // "seed": 12345
      };
      const headers = {
        headers: { 
          "Authorization": `Bearer sk-proj-bOB6kmjwX9UNVUsoEHmlT3BlbkFJzIa9DPAeLrxLzR4APBlb`,
          "Content-Type": "application/json"
        }
      };

      const response = await Axios.post("/chat/completions", requestBody, headers);
      return response.data.choices[0].message.content;
    } catch (error) {
      console.log(error, "this is the error");
    }
};

const generateImage = async () => {
  try {
    const Axios = axios.create({ baseURL: OPEN_AI_URL });

    const promptOne = "generate a cartoon of a toad sitting next to a frog and both are sitting on a lilypad within a pond. ";
    const promptTwo= "generate a diagram of an anatomically correct human heart";
    const promptThree = "Generate a simple diagram of a plant with important parts of the plant labelled correctly";

    const requestBody = {
      "prompt": promptThree,
      "n": 1,
      "size": "512x512",
      "response_format": "url" ,
      "style": "natural"
    };

    const headers = {
      headers: {
        "Authorization": `Bearer ${OPEN_AI_API_KEY}`,
        "Content-Type": "application/json"
      }
    };

    const response = await Axios.post("/images/generations", requestBody, headers);
    return response.data.data[0].url;
  } catch (error) {
    console.log(error, "this is the error");
  }
};
generateImage().then(response => console.log(response, "this is the response"));

export {
  fetchQuestionText,
  generateImage
};