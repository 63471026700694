import { useEffect, useState, useRef } from "react";
import { Container } from "react-bootstrap";
import DataTable from "../Components/DataTable";
import SubNavbar from "../Components/SubNavbar";
import Header from "../Components/Header";
import FormComponent from "../Components/Form";
import { LocalDateTime } from "../Components/DateTimeFormatter";
import plus from "../assets/plus.svg";
import { setIsLoading } from "../Features/Auth/Slices/authSlice";
import {
  fetchAssessments,
  fetchAssessmentsByAuthor,
  createAssessment,
} from "../Features/Assessments/Actions/AssessmentsActions";
import { useDispatch, useSelector } from "react-redux";

export default function Assessments({ paragraph, subParagraph, title, tabs }) {
  const { status, isLoading } = useSelector((store) => store.auth);
  const [displayAssessments, setDisplayAssessments] = useState([]);
  const [isFormVisible, setFormVisible] = useState(false);
  const [radioValue, setRadioValue] = useState("1");
  const mounted = useRef(null);
  const dispatch = useDispatch();

  const [paginationModel, setPaginationModel] = useState({
    pageSize: 10,
    page: 0,
  });

  const columns = [
    { field: "exid", headerName: "ID", width: 50 },
    {
      field: "examtitle",
      headerName: "Title",
      flex: 2,
    },
    {
      field: "score",
      headerName: "Score",
      width: 80,
    },
    {
      field: "duration",
      headerName: "Duration",
      flex: 1,
      valueGetter: (params) => params?.row?.duration?.stringshort,
    },
    {
      field: "createdby",
      headerName: "Owner",
      flex: 1,
      valueGetter: (params) =>
        params?.row?.createdby?.firstname +
        " " +
        params?.row?.createdby?.lastname,
    },
    {
      field: "created",
      headerName: "Created",
      flex: 1,
      valueFormatter: ({ value }) => LocalDateTime(value),
    },
  ];

  const handleChange = (e) => {
    let value = e.currentTarget.value;
    setRadioValue(value);
    dispatch(setIsLoading(true));
  };

  const createAssessmentHandler = (data) => {
    if (mounted.current) {
      dispatch(createAssessment(data)).unwrap();
      setFormVisible(false);
    }
  };

  useEffect(() => {
    mounted.current = true;
    if (mounted.current) {
      dispatch(setIsLoading(true));
    }
    return () => (mounted.current = false);
  }, []);

  useEffect(() => {
    mounted.current = true;
    document.title = "Assessments";
    if (!isLoading) {
      return;
    }
    if (mounted.current) {
      if (radioValue === "1") {
        dispatch(fetchAssessmentsByAuthor())
          .then((response) => {
            if (
              response.payload.length > 0 &&
              typeof response.payload[0] === "object"
            ) {
              setDisplayAssessments(response.payload);
            } else {
              setDisplayAssessments([]);
            }
            dispatch(setIsLoading(false));
          })
          .catch((error) => {
            console.log(error);
          });

      } else if (radioValue === "2") {
        dispatch(fetchAssessments())
          .then((response) => {
            if (
              response.payload.length > 0 &&
              typeof response.payload[0] === "object"
            ) {
              setDisplayAssessments(response.payload);
            } else {
              setDisplayAssessments([]);
            }
            dispatch(setIsLoading(false));
          })
          .catch(error => console.log(error));
      }
    }
    return () => (mounted.current = false);
  }, [dispatch, isLoading, radioValue]);

  return (
    <Container className="py-4 position-relative">
      <Header paragraph={paragraph} title={title} subParagraph={subParagraph}>
        <SubNavbar
          radioValue={radioValue}
          tabs={tabs}
          handleChange={handleChange}
          className=""
        />
      </Header>
      <DataTable
        columns={columns}
        rows={displayAssessments}
        type={"assessments"}
        loading={isLoading}
        radioValue={radioValue}
        getRowId={displayAssessments.length <= 0 ? null : (row) => row.exid}
        setPaginationModel={setPaginationModel}
        paginationModel={paginationModel}
      />
      <FormComponent
        title="Create assessment"
        selects={[]}
        multipleValues={[]}
        inputs={[
          {
            label: "Assessment title",
            type: "text",
            id: "examtitle",
            value: "",
            required: true,
          },
          {
            label: "Assessment instructions",
            type: "text",
            id: "instructions",
            value: "",
            required: false,
          },
          {
            label: "Assessment duration (hh:mm)",
            type: "time",
            id: "duration",
            value: "",
            required: true,
          },
          {
            label: "Pass mark",
            type: "number",
            id: "passmark",
            value: "",
            required: false,
          },
          {
            label: "or Pass percentage (%)",
            type: "number",
            id: "passpercent",
            value: "",
            required: false,
          },
        ]}
        multipleSelects={[]}
        button="Add assessment"
        logo={plus}
        logoClass="plus position-fixed"
        action={createAssessmentHandler}
        isFormVisible={isFormVisible}
        setFormVisible={setFormVisible}
        isLoading={false}
      />
    </Container>
  );
}
