import { getURL, setHeaders } from "../../../Apis/axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { openModal } from "../../Errors/Slices/errorSlice";

const fetchNotes = createAsyncThunk(
  "questions/fetchNotes",
  async ({ type, id }, thunkAPI) => {
    try {
      const Axios = getURL();
      const notes = await Axios.get(`/${type}/${id}/objectnotes`, setHeaders());
      return notes.data.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.errormessage);
    }
  }
);

const addNotes = createAsyncThunk(
  "questions/addNotes",
  async ({ type, id, data }, thunkAPI) => {
    try {
      const Axios = getURL();
      await Axios.post(`/${type}/${id}/objectnotes`, data, setHeaders());
      thunkAPI.dispatch(
        openModal({
          message: "This note has been added correctly",
          severity: "success",
        })
      );
      return thunkAPI.dispatch(fetchNotes({ type, id }));
    } catch (error) {
      thunkAPI.dispatch(
        openModal({
          message: error.response.data.errormessage,
          severity: "error",
        })
      );
      return thunkAPI.rejectWithValue(error.response.data.errormessage);
    }
  }
);

const updateNotes = createAsyncThunk(
  "questions/updateNotes",
  async ({ type, id, onid, data }, thunkAPI) => {
    try {
      const Axios = getURL();
      await Axios.put(`/${type}/${id}/objectnotes/${onid}`, data, setHeaders());
      thunkAPI.dispatch(
        openModal({
          message: "This note has been updated correctly",
          severity: "success",
        })
      );
      return thunkAPI.dispatch(fetchNotes(type, id));
    } catch (error) {
      thunkAPI.dispatch(
        openModal({
          message: error.response.data.errormessage,
          severity: "error",
        })
      );
      return thunkAPI.rejectWithValue(error.response.data.errormessage);
    }
  }
);

const deleteNotes = createAsyncThunk(
  "questions/deleteNotes",
  async ({ type, id, onid }, thunkAPI) => {
    try {
      const Axios = getURL();
      await Axios.delete(`/${type}/${id}/objectnotes/${onid}`, setHeaders());
      thunkAPI.dispatch(
        openModal({
          message: "This note has been deleted correctly",
          severity: "success",
        })
      );
      return thunkAPI.dispatch(fetchNotes(type, id));
    } catch (error) {
      thunkAPI.dispatch(
        openModal({
          message: error.response.data.errormessage,
          severity: "error",
        })
      );
      return thunkAPI.rejectWithValue(error.response.data.errormessage);
    }
  }
);

export { fetchNotes, addNotes, updateNotes, deleteNotes };
