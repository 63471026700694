import { createSlice } from "@reduxjs/toolkit";
import {
  fetchNotes,
  addNotes,
  updateNotes,
  deleteNotes,
} from "../Actions/NotesActions";
const initialState = {
  notes: [],
  status: "idle", //'idle' | 'loading' | 'succeeded' | 'failed'
  error: null,
};

const venuesSlice = createSlice({
  name: "venues",
  initialState,
  reducers: {
    hideError(state, action) {
      return {
        ...state,
        status: "idle",
        error: null,
      };
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchNotes.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(fetchNotes.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.notes = action.payload;
      })
      .addCase(fetchNotes.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
        state.notes = [];
      })
      .addCase(addNotes.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(addNotes.fulfilled, (state, action) => {
        state.status = "succeeded";
      })
      .addCase(addNotes.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })
      .addCase(updateNotes.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(updateNotes.fulfilled, (state, action) => {
        state.status = "succeeded";
      })
      .addCase(updateNotes.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })
      .addCase(deleteNotes.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(deleteNotes.fulfilled, (state, action) => {
        state.status = "succeeded";
      })
      .addCase(deleteNotes.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      });
  },
});

export const { hideError, countPages } = venuesSlice.actions;

export default venuesSlice.reducer;
