import { useEffect } from "react";

export default function ConfirmBrowserExit() {
  useEffect(() => {
    function listener(e) {
      e.preventDefault();
      e.returnValue = "";
    }

    if (typeof window.addEventListener === "undefined") {
      window.addEventListener = function (e, callback) {
        return window.attachEvent("on" + e, callback);
      };
    }

    window.addEventListener("beforeunload", listener);

    return () => {
      window.removeEventListener("beforeunload", listener);
    };
  });

  return null;
}
