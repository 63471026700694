/* REACT PACKAGE IMPORTS */
import { useEffect, useState, useRef } from "react";
import { Navbar, Container, Nav, Form } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { NavLink, useNavigate } from "react-router-dom";

/* MUI PACKAGE IMPORTS */
import { IconButton } from "@mui/material";
import SettingsIcon from "@mui/icons-material/Settings";
import FullscreenSharpIcon from '@mui/icons-material/FullscreenSharp';
import FullscreenExitSharpIcon from '@mui/icons-material/FullscreenExitSharp';
import ExitToAppOutlinedIcon from '@mui/icons-material/ExitToAppOutlined';
import PersonIcon from '@mui/icons-material/Person';

/* INTERNAL IMPORTS */
import {
  fetchOrganisationsByUser,
  fetchOrganisationByUser,
  setOrganisationByUser,
} from "../Features/Auth/Actions/AuthActions";
import { setIsLoading } from "../Features/Auth/Slices/authSlice";
import { fetchAssessments } from "../Features/Assessments/Actions/AssessmentsActions";

import LANDSCAPELOGO from "../assets/VICTVS-ONE-LOGO-LANDSCAPE.png";
import Image from "./Image";
import TabLink from "./TabLink";
import "../styles/Navbar.css";


export default function NavBar({ tabs, logo, logout }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { organisation, organisations } = useSelector(store => store.auth);
  const [isFullscreen, setIsFullscreen] = useState(false);
  const mounted = useRef(null);

  const isAdmin = window.location.pathname.includes("admin");
  const pathname = isAdmin ? "/admin" : "/candidate";
  const home = isAdmin ? `${pathname}/sessions` : `${pathname}/assessments`;

  const iconStyle = { fontSize: '26px', color: '#212529'};

  // Watches for fullscreenchange
  useEffect(() => {
    const onFullscreenChange = () => setIsFullscreen(Boolean(document.fullscreenElement));
    document.addEventListener("fullscreenchange", onFullscreenChange);
    return () => document.removeEventListener("fullscreenchange", onFullscreenChange);
  }, []);

  useEffect(() => {
    mounted.current = true;
    if (organisations.length > 0) return;
    if (mounted.current) dispatch(fetchOrganisationsByUser());
    return () => mounted.current = false;
  }, [organisations]);
  
  useEffect(() => {
    mounted.current = true;
    if (organisation > 0) return;
    if (mounted.current) dispatch(fetchOrganisationByUser());
    return () => mounted.current = false;
  }, [organisation]);

  const navigateToSettings = (pathname) => navigate(`${pathname}/settings`, { replace: true });
  const navigateToProfile = (pathname) => navigate(`${pathname}/profile`, { replace: true });

  return (
    <Navbar expand="lg" className="Navbar" title="navigation bar">
      <Container>
        <Navbar.Brand>
          <NavLink to={home}>
            {
              Number.isNaN(parseInt(logo)) ?
                <img src={LANDSCAPELOGO} alt="VX logo" height="64" className="logo" /> :
                <Image
                  id={parseInt(logo)}
                  style={{ height: "36px" }}
                  className=""
                />
            }
          </NavLink>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav" className="justify-content-end">
          <Nav>
            {tabs.map(tab => <TabLink key={tab} tab={tab}>{tab}</TabLink>)}
          </Nav>
          <div className="d-flex justify-content-center align-items-center m-2">
            <IconButton 
                aria-label="Settings"
                onClick={() => navigateToProfile(pathname)}
            >
              <PersonIcon sx={iconStyle} />
            </IconButton>
            {
              pathname === "/admin" && 
              <IconButton 
                aria-label="Settings"
                onClick={() => navigateToSettings(pathname)}
              >
                <SettingsIcon sx={iconStyle} />
              </IconButton>
            }
            <IconButton
              aria-label="Fullscreen"
              onClick={() => {
                if (isFullscreen) {
                  if (document.fullscreenElement) document.exitFullscreen();
                } else document.body.requestFullscreen();
                setIsFullscreen(currFullscreen => !currFullscreen);
              }}
            >
              { 
                isFullscreen ? 
                  <FullscreenExitSharpIcon sx={iconStyle}/> : 
                  <FullscreenSharpIcon sx={iconStyle}/>
              }
            </IconButton>
            <IconButton
              aria-label="Log Out"
              className="align-self-center p-2 logo"
              onClick={logout}
            >
              <ExitToAppOutlinedIcon sx={iconStyle}/>
            </IconButton>
          </div>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}
