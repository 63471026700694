import { getURL, setHeaders } from "../../../Apis/axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { openModal } from "../../Errors/Slices/errorSlice";

const fetchQuestionHeader = createAsyncThunk(
  "questions/fetchQuestionHeader",
  async (id, thunkAPI) => {
    try {
      const Axios = getURL();
      const questionHeader = await Axios.get(
        `/questionheaders/${id}`,
        setHeaders()
      );
      return questionHeader.data.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.errormessage);
    }
  }
);

const fetchQuestionHeaderImage = createAsyncThunk(
  "questions/fetchQuestionHeaderImage",
  async (id, thunkAPI) => {
    try {
      const Axios = getURL();
      const questionHeader = await Axios.get(
        `/questionheaders/${id}/images`,
        setHeaders()
      );
      return questionHeader.data.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.errormessage);
    }
  }
);

const addQuestionHeaderImage = createAsyncThunk(
  "questions/addQuestionHeaderImage",
  async ({ qhid, imid }, thunkAPI) => {
    try {
      const Axios = getURL();
      const questionHeader = await Axios.post(
        `/questionheaders/${qhid}/images`,
        { imid },
        setHeaders()
      );
      thunkAPI.dispatch(
        openModal({
          message: "This image has been added to the question correctly",
          severity: "success",
        })
      );
      return thunkAPI.dispatch(fetchQuestionHeader(qhid));
    } catch (error) {
      thunkAPI.dispatch(
        openModal({
          message: error.response.data.errormessage,
          severity: "error",
        })
      );
      return thunkAPI.rejectWithValue(error.response.data.errormessage);
    }
  }
);

const deleteQuestionHeaderImage = createAsyncThunk(
  "questions/deleteQuestionHeaderImage",
  async ({ qhid, imid }, thunkAPI) => {
    try {
      const Axios = getURL();
      const questionHeader = await Axios.delete(
        `/questionheaders/${qhid}/images/${imid}`,
        setHeaders()
      );
      thunkAPI.dispatch(
        openModal({
          message: "This image has been deleted correctly",
          severity: "success",
        })
      );
      return thunkAPI.dispatch(fetchQuestionHeader(qhid));
    } catch (error) {
      thunkAPI.dispatch(
        openModal({
          message: error.response.data.errormessage,
          severity: "error",
        })
      );
      return thunkAPI.rejectWithValue(error.response.data.errormessage);
    }
  }
);

const fetchQuestionHeaderVideo = createAsyncThunk(
  "questions/fetchQuestionHeaderVideo",
  async (id, thunkAPI) => {
    try {
      const Axios = getURL();
      const questionHeader = await Axios.get(
        `/questionheaders/${id}/videos`,
        setHeaders()
      );
      return questionHeader.data.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.errormessage);
    }
  }
);

const addQuestionHeaderVideo = createAsyncThunk(
  "questions/addQuestionHeaderVideo",
  async ({ qhid, vid }, thunkAPI) => {
    try {
      const Axios = getURL();
      const questionHeader = await Axios.post(
        `/questionheaders/${qhid}/videos`,
        { vid },
        setHeaders()
      );
      thunkAPI.dispatch(
        openModal({
          message: "This video has been added to the question correctly",
          severity: "success",
        })
      );
      return questionHeader.data.data;
    } catch (error) {
      thunkAPI.dispatch(
        openModal({
          message: error.response.data.errormessage,
          severity: "error",
        })
      );
      return thunkAPI.rejectWithValue(error.response.data.errormessage);
    }
  }
);

const deleteQuestionHeaderVideo = createAsyncThunk(
  "questions/deleteQuestionHeaderVideo",
  async ({ qhid, vid }, thunkAPI) => {
    try {
      const Axios = getURL();
      const questionHeader = await Axios.delete(
        `/questionheaders/${qhid}/videos/${vid}`,
        setHeaders()
      );
      thunkAPI.dispatch(
        openModal({
          message: "This video has been deleted correctly",
          severity: "success",
        })
      );
      return thunkAPI.dispatch(fetchQuestionHeader(qhid));
    } catch (error) {
      thunkAPI.dispatch(
        openModal({
          message: error.response.data.errormessage,
          severity: "error",
        })
      );
      return thunkAPI.rejectWithValue(error.response.data.errormessage);
    }
  }
);

const fetchQuestionHeaderAudit = createAsyncThunk(
  "questions/fetchQuestionHeaderAudit",
  async (name, thunkAPI) => {
    try {
      const Axios = getURL();
      const audit = await Axios.get(
        `/questionheaders/${name}/changes`,
        setHeaders()
      );
      return audit.data.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.errormessage);
    }
  }
);

const fetchCategoriesByQuestionHeader = createAsyncThunk(
  "questions/fetchCategoriesByQuestionHeader",
  async (qhid, thunkAPI) => {
    try {
      const Axios = getURL();
      const cats = await Axios.get(
        `/questionheaders/${qhid}/categories`,
        setHeaders()
      );
      return cats.data.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.errormessage);
    }
  }
);

const addCategoryToQuestion = createAsyncThunk(
  "questions/addCategoryToQuestion",
  async ({ qhid, catid }, thunkAPI) => {
    try {
      const Axios = getURL();
      await Axios.post(
        `/questionheaders/${qhid}/categories`,
        { catid },
        setHeaders()
      );
      thunkAPI.dispatch(fetchCategoriesByQuestionHeader(qhid));
      return thunkAPI.dispatch(
        openModal({
          message: "This category has been added to the question correctly",
          severity: "success",
        })
      );
    } catch (error) {
      thunkAPI.dispatch(
        openModal({
          message: error.response.data.errormessage,
          severity: "info",
        })
      );
      return thunkAPI.rejectWithValue(error.response.data.errormessage);
    }
  }
);

const removeCategoryFromQuestion = createAsyncThunk(
  "questions/removeCategoryFromQuestion",
  async ({ qhid, catid }, thunkAPI) => {
    try {
      const Axios = getURL();
      await Axios.delete(
        `/questionheaders/${qhid}/categories/${catid}`,
        setHeaders()
      );
      thunkAPI.dispatch(fetchCategoriesByQuestionHeader(qhid));
      return thunkAPI.dispatch(
        openModal({
          message: "This category has been removed from the question correctly",
          severity: "success",
        })
      );
    } catch (error) {
      thunkAPI.dispatch(
        openModal({
          message: error.response.data.errormessage,
          severity: "info",
        })
      );
      return thunkAPI.rejectWithValue(error.response.data.errormessage);
    }
  }
);

const fetchAllCategories = createAsyncThunk(
  "questions/fetchAllCategories",
  async (name, thunkAPI) => {
    try {
      const Axios = getURL();
      const cats = await Axios.get("/questioncats", setHeaders());
      return cats.data.data;
    } catch (error) {
      thunkAPI.dispatch(
        openModal({
          message: "There are no categories created yet.",
          severity: "info",
        })
      );
      return thunkAPI.rejectWithValue(error.response.data.errormessage);
    }
  }
);

const createCategory = createAsyncThunk(
  "questions/createCategory",
  async ({ qhid, catname }, thunkAPI) => {
    try {
      const Axios = getURL();
      const cat = await Axios.post("/questioncats", { catname }, setHeaders());
      thunkAPI.dispatch(
        openModal({
          message: "This category has been created correctly",
          severity: "success",
        })
      );
      const url = cat.data.data.api_url;
      const urlArr = url.split("/");
      const catid = urlArr[urlArr.length - 1];
      thunkAPI.dispatch(addCategoryToQuestion({ qhid, catid }));
      return { value: catid, label: catname };
    } catch (error) {
      thunkAPI.dispatch(
        openModal({
          message: error.response.data.errormessage,
          severity: "info",
        })
      );
      return thunkAPI.rejectWithValue(error.response.data.errormessage);
    }
  }
);

const deleteCategory = createAsyncThunk(
  "questions/deleteCategory",
  async (catid, thunkAPI) => {
    try {
      const Axios = getURL();
      await Axios.delete(`/questioncats/${catid}`, setHeaders());
      return thunkAPI.dispatch(fetchAllCategories());
    } catch (error) {
      thunkAPI.dispatch(
        openModal({
          message: error.response.data.errormessage,
          severity: "info",
        })
      );
      return thunkAPI.rejectWithValue(error.response.data.errormessage);
    }
  }
);

const createQuestionHeader = createAsyncThunk(
  "questions/createQuestionHeader",
  async (data, thunkAPI) => {
    try {
      const Axios = getURL();
      const response = await Axios.post("/questionheaders", data, setHeaders());
      const url = response.data.data.api_url;
      const urlArr = url.split("/");
      const id = urlArr[urlArr.length - 1];
      const pathname = window.location.pathname;
      if (pathname.includes("question"))
        window.location.href = `${pathname}/${id}`;
      thunkAPI.dispatch(
        openModal({
          message: "This question was successfully created.",
          severity: "success",
        })
      );
      return id;
    } catch (error) {
      thunkAPI.dispatch(
        openModal({
          message: error.response.data.errormessage,
          severity: "error",
        })
      );
      return thunkAPI.rejectWithValue(error.response.data.errormessage);
    }
  }
);

const updateQuestionHeader = createAsyncThunk(
  "questions/updateQuestionHeader",
  async ({ qhid, formDataHeader }, thunkAPI) => {
    try {
      const Axios = getURL();
      await Axios.put(`/questionheaders/${qhid}`, formDataHeader, setHeaders());
      thunkAPI.dispatch(
        openModal({
          message: "This question header has been updated correctly",
          severity: "success",
        })
      );
      return thunkAPI.dispatch(fetchQuestionHeader(qhid));
    } catch (error) {
      thunkAPI.dispatch(
        openModal({
          message: error.response.data.errormessage,
          severity: "error",
        })
      );
      return thunkAPI.rejectWithValue(error.response.data.errormessage);
    }
  }
);

const cloneQuestionHeader = createAsyncThunk(
  "questions/cloneQuestionHeader",
  async ({ questionId, navigate }, thunkAPI) => {
    try {
      const Axios = getURL();
      const response = await Axios.post(
        `/questionheaders/${questionId}/clone`,
        {},
        setHeaders()
      );
      const urlArr = response.data.data.api_url.split("/");
      const id = urlArr[urlArr.length - 1];
      return navigate(`/admin/question_bank/${id}`, { replace: true });
    } catch (error) {
      thunkAPI.dispatch(
        openModal({
          message: error.response.data.errormessage,
          severity: "error",
        })
      );
      return thunkAPI.rejectWithValue(error.response.data.errormessage);
    }
  }
);

const createQuestion = createAsyncThunk(
  "questions/createQuestion",
  async (data, thunkAPI) => {
    try {
      const Axios = getURL();
      const { qhid } = data;
      const response = await Axios.post("/questions", data, setHeaders());
      thunkAPI.dispatch(fetchQuestionHeader(qhid));
      return response.data.data;
    } catch (error) {
      thunkAPI.dispatch(
        openModal({
          message: error.response.data.errormessage,
          severity: "error",
        })
      );
      return thunkAPI.rejectWithValue(error.response.data.errormessage);
    }
  }
);

const updateQuestion = createAsyncThunk(
  "questions/updateQuestion",
  async ({ qid, qhid, formData }, thunkAPI) => {
    try {
      const Axios = getURL();
      await Axios.put(`/questions/${qid}`, formData, setHeaders());

      thunkAPI.dispatch(
        openModal({
          message: "This question has been successfully updated",
          severity: "success",
        })
      );
      return thunkAPI.dispatch(fetchQuestionHeader(qhid));
    } catch (error) {
      thunkAPI.dispatch(
        openModal({
          message: error.response.data.errormessage,
          severity: "error",
        })
      );
      return thunkAPI.rejectWithValue(error.response.data.errormessage);
    }
  }
);

const deleteQuestionHeader = createAsyncThunk(
  "questions/deleteQuestionHeader",
  async ({ qhid, navigate }, thunkAPI) => {
    try {
      const Axios = getURL();
      await Axios.delete(`/questionheaders/${qhid}`, setHeaders());

      navigate("/admin/question_bank", { replace: true });
      return thunkAPI.dispatch(
        openModal({
          message: "The question has been deleted correctly",
          severity: "success",
        })
      );
    } catch (error) {
      thunkAPI.dispatch(
        openModal({
          message: error.response.data.errormessage,
          severity: "error",
        })
      );
      return thunkAPI.rejectWithValue(error.response.data.errormessage);
    }
  }
);

const deleteQuestion = createAsyncThunk(
  "questions/deleteQuestion",
  async ({ qid, qhid }, thunkAPI) => {
    try {
      const Axios = getURL();
      await Axios.delete(`/questions/${qid}`, setHeaders());
      thunkAPI.dispatch(fetchQuestionHeader(qhid));
      return thunkAPI.dispatch(
        openModal({
          message: "The question has been deleted correctly",
          severity: "success",
        })
      );
    } catch (error) {
      thunkAPI.dispatch(
        openModal({
          message: error.response.data.errormessage,
          severity: "error",
        })
      );
      return thunkAPI.rejectWithValue(error.response.data.errormessage);
    }
  }
);

const createOption = createAsyncThunk(
  "questions/createOption",
  async ({ qhid, newOption }, thunkAPI) => {
    try {
      const Axios = getURL();
      const response = await Axios.post(
        "/questionoptions",
        newOption,
        setHeaders()
      );
      thunkAPI.dispatch(
        openModal({
          message: "The new option has been successfully added",
          severity: "success",
        })
      );
      return thunkAPI.dispatch(fetchQuestionHeader(qhid));
    } catch (error) {
      thunkAPI.dispatch(
        openModal({
          message: error.response.data.errormessage,
          severity: "error",
        })
      );
      return thunkAPI.rejectWithValue(error.response.data.errormessage);
    }
  }
);

const updateOption = createAsyncThunk(
  "questions/updateOption",
  async ({ qhid, oid, option }, thunkAPI) => {
    try {
      const Axios = getURL();
      await Axios.put(`/questionoptions/${oid}`, option, setHeaders());
      thunkAPI.dispatch(
        openModal({
          message: "This option has been updated correctly",
          severity: "success",
        })
      );
      return thunkAPI.dispatch(fetchQuestionHeader(qhid));
    } catch (error) {
      thunkAPI.dispatch(
        openModal({
          message: error.response.data.errormessage,
          severity: "error",
        })
      );
      return thunkAPI.rejectWithValue(error.response.data.errormessage);
    }
  }
);

const deleteOption = createAsyncThunk(
  "questions/deleteOption",
  async ({ qhid, oid }, thunkAPI) => {
    try {
      const Axios = getURL();
      await Axios.delete(`/questionoptions/${oid}`, setHeaders());
      thunkAPI.dispatch(
        openModal({
          message: "This option has been deleted correctly",
          severity: "success",
        })
      );
      return thunkAPI.dispatch(fetchQuestionHeader(qhid));
    } catch (error) {
      thunkAPI.dispatch(
        openModal({
          message: error.response.data.errormessage,
          severity: "error",
        })
      );
      return thunkAPI.rejectWithValue(error.response.data.errormessage);
    }
  }
);

export {
  fetchQuestionHeader,
  fetchQuestionHeaderImage,
  addQuestionHeaderImage,
  deleteQuestionHeaderImage,
  addQuestionHeaderVideo,
  deleteQuestionHeaderVideo,
  fetchQuestionHeaderVideo,
  fetchQuestionHeaderAudit,
  createQuestionHeader,
  updateQuestionHeader,
  cloneQuestionHeader,
  deleteQuestionHeader,
  fetchCategoriesByQuestionHeader,
  addCategoryToQuestion,
  removeCategoryFromQuestion,
  fetchAllCategories,
  createCategory,
  deleteCategory,
  createQuestion,
  updateQuestion,
  deleteQuestion,
  createOption,
  updateOption,
  deleteOption,
};
