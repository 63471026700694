import { getURL, setHeaders } from "../../../Apis/axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { openModal } from "../../Errors/Slices/errorSlice";

const fetchVenues = createAsyncThunk(
  "assessments/fetchVenues",
  async (name, thunkAPI) => {
    try {
      const Axios = getURL();
      const venues = await Axios.get("/venues", setHeaders());
      return venues.data.data;
    } catch (error) {
      thunkAPI.dispatch(
        openModal({
          message: "No venues have been added yet.",
          severity: "info",
        })
      );
      return thunkAPI.rejectWithValue(error.response.data.errormessage);
    }
  }
);

export { fetchVenues };
