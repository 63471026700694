import { useEffect, useState, useRef } from "react";
import { useStyles, PrettoSlider } from "../../../styles/StyledComponents";
import ReactPlayer from "react-player";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Paper from "@mui/material/Paper";
import { Bookmark, PlayArrow, Fullscreen } from "@mui/icons-material";
import IconButton from "@material-ui/core/IconButton";
import TourIcon from "@mui/icons-material/Tour";
import screenfull from "screenfull";
import Duration from "../../Duration";
import hazard1 from "../../../assets/videos/hazard1.mp4";

export default function VideoPlayer({ question, answer, setUserAnswerArray }) {
  // const { options } = useSelector((store) => store.question);

  const [src, setSrc] = useState(hazard1);
  const [bookmarks, setBookmarks] = useState([]);
  const [marks, setMarks] = useState([]);
  const [playing, setPlaying] = useState(false);
  const [played, setPlayed] = useState(null);
  const [seeking, setSeeking] = useState(false);
  const [duration, setDuration] = useState(0);
  const [controls, setControls] = useState(false);
  const [pip, setPip] = useState(false);
  const [muted, setMuted] = useState(false);
  const [loop, setLoop] = useState(false);
  const [playbackRate, setPlaybackRate] = useState(0);
  const classes = useStyles();
  const containerRef = useRef(null);
  const playerRef = useRef(null);
  const canvasRef = useRef(null);

  const goToMark = (mark) => {
    setPlayed(mark.time);
    playerRef.current.seekTo(mark.time);
  };

  const handleProgress = (state) => {
    // We only want to update time slider if we are not currently seeking
    if (!seeking) {
      setPlayed(state.played);
    }
  };

  const handleDuration = (duration) => {
    console.log("onDuration", duration);
    setDuration(duration);
  };

  // const handleProgress = (state) => {
  //   console.log("onProgress", state);
  //   // We only want to update time slider if we are not currently seeking
  //   if (!this.state.seeking) {
  //     this.setState(state);
  //   }
  // };
  // const handleDuration = (duration) => {
  //   console.log("onDuration", duration);
  //   this.setState({ duration });
  // };

  const handlePlayPause = () => {
    this.setState({ playing: !this.state.playing });
  };

  const handleStop = () => {
    this.setState({ url: null, playing: false });
  };

  const handleToggleControls = () => {
    const url = this.state.url;
    this.setState(
      {
        controls: !this.state.controls,
        url: null,
      },
      () => this.load(url)
    );
  };

  const handleToggleLight = () => {
    this.setState({ light: !this.state.light });
  };

  const handleToggleLoop = () => {
    this.setState({ loop: !this.state.loop });
  };

  const handleVolumeChange = (e) => {
    this.setState({ volume: parseFloat(e.target.value) });
  };

  const handleToggleMuted = () => {
    this.setState({ muted: !this.state.muted });
  };

  const handleSetPlaybackRate = (e) => {
    this.setState({ playbackRate: parseFloat(e.target.value) });
  };

  const handleOnPlaybackRateChange = (speed) => {
    setPlaybackRate(parseFloat(speed));
  };

  const handleTogglePIP = () => {
    this.setState({ pip: !this.state.pip });
  };

  const handlePlay = () => {
    console.log("onPlay");
    setPlaying(true);
  };

  const handleEnablePIP = () => {
    console.log("onEnablePIP");
    this.setState({ pip: true });
  };

  const handleDisablePIP = () => {
    console.log("onDisablePIP");
    this.setState({ pip: false });
  };

  const handlePause = () => {
    console.log("onPause");
    setPlaying(false);
  };

  const handleSeekMouseDown = (e) => {
    this.setState({ seeking: true });
  };

  const handleSeekChange = (e) => {
    this.setState({ played: parseFloat(e.target.value) });
  };

  const handleSeekMouseUp = (e) => {
    this.setState({ seeking: false });
    this.player.seekTo(parseFloat(e.target.value));
  };

  const handleEnded = () => {
    console.log("onEnded");
    this.setState({ playing: this.state.loop });
  };

  const handleClickFullscreen = () => {
    // screenfull.request(findDOMNode(this.player));
    screenfull.toggle(containerRef.current);
  };

  const addBookmark = () => {
    console.log(playerRef.current);
    if (playerRef.current) {
      const canvas = canvasRef.current;
      canvas.width = 160;
      canvas.height = 90;
      const ctx = canvas.getContext("2d");
      ctx.drawImage(
        playerRef.current.getInternalPlayer(),
        0,
        0,
        canvas.width,
        canvas.height
      );
      const dataUri = canvas.toDataURL();
      canvas.width = 0;
      canvas.height = 0;
      const currentTime = playerRef.current.getCurrentTime();
      const duration = playerRef.current.getDuration();
      setBookmarks((currentBookmarks) => {
        const bookmarksCopy = [...currentBookmarks];
        bookmarksCopy.push({
          time: currentTime,
          display: format(currentTime),
          image: dataUri,
        });
        return bookmarksCopy;
      });
      setMarks((currentMarks) => {
        const id = Math.floor(Math.random() * 100);
        const marksCopy = [...currentMarks];
        marksCopy.push({
          value: (currentTime * 100) / duration,
          label: <TourIcon key={id} color="error" />,
        });
        return marksCopy;
      });
      // setAnswers((curr) => {
      //   const newAnswer = Math.round(currentTime);
      //   return [...curr, newAnswer];
      // });
    } else {
    }
  };

  const format = (seconds) => {
    if (Number.isNaN(parseInt(seconds))) {
      return `00:00`;
    }
    const date = new Date(seconds * 1000);
    const hh = date.getUTCHours();
    const mm = date.getUTCMinutes();
    const ss = date.getUTCSeconds().toString().padStart(2, "0");
    if (hh) {
      return `${hh}:${mm.toString().padStart(2, "0")}:${ss}`;
    }
    return `${mm}:${ss}`;
  };

  useEffect(() => {}, [playing, played, bookmarks, controls]);

  return (
    <div className="list-group">
      {/* Top controls*/}
      <Grid
        container
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        style={{ padding: 16 }}
      >
        <Grid item></Grid>
        <Grid item>
          <Button
            variant="contained"
            color="primary"
            startIcon={<Bookmark />}
            onClick={addBookmark}
          >
            Bookmark
          </Button>
        </Grid>
      </Grid>
      {src ? (
        <div ref={containerRef} className={classes.playerWrapper}>
          <ReactPlayer
            ref={playerRef}
            className="react-player"
            width="100%"
            height="100%"
            url={src}
            // pip={false}
            playing={playing}
            controls={controls}
            // // light={light}
            // loop={loop}
            // playbackRate={playbackRate}
            // // volume={volume}
            // muted={muted}
            // onReady={() => console.log("onReady")}
            // onStart={() => console.log("onStart")}
            // onPlay={handlePlay}
            // // onEnablePIP={handleEnablePIP}
            // // onDisablePIP={handleDisablePIP}
            // onPause={handlePause}
            // onBuffer={() => console.log("onBuffer")}
            // onPlaybackRateChange={handleOnPlaybackRateChange}
            // onSeek={(e) => console.log("onSeek", e)}
            // onEnded={handleEnded}
            // onError={(e) => console.log("onError", e)}
            onProgress={handleProgress}
            onDuration={handleDuration}
          />
          {/* Middle controls*/}
          {!playing && (
            <div className={classes.controlsWrapper}>
              <Grid
                container
                direction="row"
                alignitems="center"
                justifyContent="center"
              >
                <IconButton
                  className={classes.controlIcons}
                  aria-label="reqind"
                  onClick={() => {
                    console.log("play!");
                    setPlaying(true);
                  }}
                >
                  <PlayArrow
                    fontSize="inherit"
                    className={classes.controlIcons}
                  />
                </IconButton>
              </Grid>
            </div>
          )}
        </div>
      ) : (
        <div ref={containerRef} className={classes.playerWrapper}>
          <div className={classes.playerNone}></div>
        </div>
      )}

      {/* Bottom controls*/}
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        style={{ padding: 16 }}
      >
        <Grid item xs={11}>
          <PrettoSlider
            defaultValue={20}
            value={played * 100}
            min={0}
            max={100}
            aria-label="Default"
            valueLabelDisplay="auto"
            marks={marks}
          />
        </Grid>
        <Grid item>
          <IconButton
            className={classes.bottomIcons}
            aria-label="reqind"
            onClick={(e) => {
              screenfull.toggle(containerRef.current);
            }}
          >
            <Fullscreen
              className={classes.bottomIcons}
              color="inherit"
              fontSize="medium"
            />
          </IconButton>
        </Grid>
        <Grid item xs={11}>
          <div className="d-flex justify-content-between">
            <div>
              <Duration seconds={duration * played} /> /{" "}
              <Duration seconds={duration * (1 - played)} />
            </div>
            <Duration seconds={duration} />
          </div>
        </Grid>
      </Grid>

      {/* Bookmarks */}
      <Grid
        container
        justifyContent="center"
        style={{ marginTop: 20 }}
        spacing={3}
      >
        {bookmarks.map((bookmark, i) => {
          return (
            <Grid key={i} item onClick={() => goToMark(bookmark)}>
              <Paper>
                <img
                  crossOrigin="anonymous"
                  src={bookmark.image}
                  alt={`Bookmark ${i}`}
                />
                <Typography variant="body2" align="center">
                  Bookmark at {bookmark.display}
                </Typography>
              </Paper>
            </Grid>
          );
        })}
        <canvas ref={canvasRef} />
      </Grid>
    </div>
  );
}
