/* REACT PACKAGE IMPORTS */
import Button from "react-bootstrap/Button";


export default function SubmitButton({ buttonText, formTitle }) {

  return (
    <Button
      data-cy={`${formTitle}-Submit-Button`}
      variant="dark"
      type="submit"
      className="w-100 mt-4"
    >
      {buttonText}
    </Button>
  )
};