import { useNavigate } from "react-router-dom";
import { Container, Button } from "react-bootstrap";

const Unauthorized = () => {
  const navigate = useNavigate();
  const goBack = () => navigate(-1);

  return (
    <Container style={{ height: "100vh" }}>
      <div
        className="h-100 d-flex flex-column justify-content-center align-items-center"
        style={{ height: "100%" }}
      >
        <h1>Unauthorized</h1>
        <p>You do not have access to the requested page.</p>
        <div className="flexGrow">
          <Button variant="dark" onClick={goBack}>
            Go Back
          </Button>
        </div>
      </div>
    </Container>
  );
};

export default Unauthorized;
