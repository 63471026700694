/* REACT PACKAGE IMPORTS */
import { useState } from "react";
import { useIdleTimer } from "react-idle-timer";
import { Modal, Button } from "react-bootstrap";


export default function IdleTimeOutModal({ handleLogout }) {
  const timeOutDuration = 1000 * 60 * 30;
  const [timeOut] = useState(timeOutDuration);
  const [show, setShowModal] = useState(false);

  /* Handler functions */
  const handleStay = () => {
    reset();
    setShowModal(false);
  };

  const handleOnIdle = () => setShowModal(true);

  const { reset } = useIdleTimer({
    timeout: timeOut,
    onIdle: handleOnIdle,
    debounce: 250,
  });

  
  return (
    <Modal show={show} onHide={handleStay}>
      <Modal.Header>
        <Modal.Title className="fw-bold">You've been idle</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        You've been idle for more than 30 minutes. Would you like to stay logged in?
      </Modal.Body>
      <Modal.Footer className="d-flex justify-content-between">
        <Button
          className="w-25"
          variant="danger" 
          onClick={handleLogout}
        >
          Log out
        </Button>
        <Button
          className="w-25"
          variant="primary" 
          onClick={handleStay}
        >
          Stay
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
