import { getURL, setHeaders } from "../../../Apis/axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { openModal } from "../../Errors/Slices/errorSlice";
import { fetchQuestionHeader } from "../../Questions/Actions/QuestionActions";

const fetchAssessments = createAsyncThunk(
  "assessments/fetchAssessments",
  async (name, thunkAPI) => {
    try {
      const Axios = getURL();
      const exams = await Axios.get("/exams", setHeaders());
      return exams.data.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.errormessage);
    }
  }
);

const fetchAssessmentsByAuthor = createAsyncThunk(
  "assessments/fetchAssessmentsByAuthor",
  async (name, thunkAPI) => {
    try {
      const userLocal = JSON.parse(sessionStorage.getItem("user"));
      const userId = userLocal?.userid;
      const Axios = getURL();
      const exams = await Axios.get(`/exams?createdby=${userId}`, setHeaders());
      return exams.data.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.errormessage);
    }
  }
);

const fetchAssessmentsToday = createAsyncThunk(
  "assessments/fetchAssessmentsToday",
  async (name, thunkAPI) => {
    try {
      const Axios = getURL();
      const today = Date.now();
      const exams = await Axios.get(
        `/exams?createdfrom=${today}&createdto=${today}`,
        setHeaders()
      );
      return exams.data.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.errormessage);
    }
  }
);

const fetchAssessment = createAsyncThunk(
  "assessments/fetchAssessment",
  async (name, thunkAPI) => {
    try {
      const Axios = getURL();
      const exam = await Axios.get(`/exams/${name}`, setHeaders());
      thunkAPI.dispatch(fetchSections(name));
      return exam.data.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.errormessage);
    }
  }
);

const fetchAssessmentAudit = createAsyncThunk(
  "assessments/fetchAssessmentAudit",
  async (name, thunkAPI) => {
    try {
      const Axios = getURL();
      const audit = await Axios.get(`/exams/${name}/changes`, setHeaders());
      return audit.data.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.errormessage);
    }
  }
);

const fetchSections = createAsyncThunk(
  "assessments/fetchSections",
  async (name, thunkAPI) => {
    try {
      const Axios = getURL();
      const sections = await Axios.get(`/exams/${name}/sections`, setHeaders());
      sections.data.data.forEach((section) => {
        thunkAPI.dispatch(fetchQuestionsBySection(section.esid));
      });
      return sections.data.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.errormessage);
    }
  }
);

const fetchSection = createAsyncThunk(
  "assessments/fetchSection",
  async (esId, thunkAPI) => {
    try {
      const Axios = getURL();
      const section = await Axios.get(`/examsections/${esId}`, setHeaders());
      const questions = await Axios.get(
        `/examsections/${esId}/questions`,
        setHeaders()
      );
      return section.data.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.errormessage);
    }
  }
);

const addSection = createAsyncThunk(
  "assessments/addSection",
  async (name, thunkAPI) => {
    try {
      const Axios = getURL();
      const sections = await Axios.post(`/examsections`, name, setHeaders());
      const url = sections.data.data.api_url.split("/");
      const esid = url[url.length - 1];
      return esid;
    } catch (error) {
      thunkAPI.dispatch(
        openModal({
          message: error.response.data.errormessage,
          severity: "error",
        })
      );
      return thunkAPI.rejectWithValue(error.response.data.errormessage);
    }
  }
);

const fetchQuestionsBySection = createAsyncThunk(
  "assessments/fetchQuestionsBySection",
  async (name, thunkAPI) => {
    try {
      const Axios = getURL();
      const questions = await Axios.get(
        `/examsections/${name}/questions`,
        setHeaders()
      );
      return { sectionid: name, questions: questions.data.data };
    } catch (error) {
      return { sectionid: name, questions: [] };
    }
  }
);

const addQuestionBySection = createAsyncThunk(
  "assessments/addQuestionBySection",
  async ({ esid, qhid }, thunkAPI) => {
    try {
      const Axios = getURL();
      await Axios.post(
        `/examsections/${esid}/questions`,
        { qhid },
        setHeaders()
      );
      return thunkAPI.dispatch(fetchQuestionHeader(qhid)).then((question) => {
        return { sectionid: esid, question: question.payload };
      });
    } catch (error) {
      thunkAPI.dispatch(
        openModal({
          message: error.response.data.errormessage,
          severity: "error",
        })
      );
      return thunkAPI.rejectWithValue(error.response.data.errormessage);
    }
  }
);

const deleteQuestionBySection = createAsyncThunk(
  "assessments/deleteQuestionBySection",
  async ({ esid, qhid }, thunkAPI) => {
    try {
      const Axios = getURL();
      await Axios.delete(
        `/examsections/${esid}/questions/${qhid}`,
        setHeaders()
      );
      return { sectionid: esid, qhid };
    } catch (error) {
      thunkAPI.dispatch(
        openModal({
          message: error.response.data.errormessage,
          severity: "error",
        })
      );
      return thunkAPI.rejectWithValue(error.response.data.errormessage);
    }
  }
);

const createAssessment = createAsyncThunk(
  "assessments/createAssessment",
  async (data, thunkAPI) => {
    try {
      const Axios = getURL();
      const response = await Axios.post("/exams", data, setHeaders());
      const url = response.data.data.api_url;
      const urlArr = url.split("/");
      const id = urlArr[urlArr.length - 1];
      const pathname = window.location.pathname;
      window.location.href = `${pathname}/${id}`;
      
      return response.data.data;
    } catch (error) {
      thunkAPI.dispatch(
        openModal({
          message: error.response.data.errormessage,
          severity: "error",
        })
      );
      return thunkAPI.rejectWithValue(error.response.data.errormessage);
    }
  }
);

const updateAssessment = createAsyncThunk(
  "assessments/updateAssessment",
  async ({ data, examId }, thunkAPI) => {
    try {
      const Axios = getURL();
      const exams = await Axios.put(`/exams/${examId}`, data, setHeaders());
      thunkAPI.dispatch(
        openModal({
          message: "This assessment has been successfully updated",
          severity: "success",
        })
      );
      return thunkAPI.dispatch(fetchAssessment(examId));
    } catch (error) {
      thunkAPI.dispatch(
        openModal({
          message: error.response.data.errormessage,
          severity: "error",
        })
      );
      return thunkAPI.rejectWithValue(error.response.data.errormessage);
    }
  }
);

const deleteAssessment = createAsyncThunk(
  "assessments/deleteAssessment",
  async ({ exid, navigate }, thunkAPI) => {
    try {
      const Axios = getURL();
      await Axios.delete(`/exams/${exid}`, setHeaders());
      navigate("/admin/assessments", { replace: true });
      return;
    } catch (error) {
      thunkAPI.dispatch(
        openModal({
          message: "This assessment cannot be deleted, it may still be in use.",
          severity: "error",
        })
      );
      return thunkAPI.rejectWithValue(error.response.data.errormessage);
    }
  }
);

const updateSection = createAsyncThunk(
  "assessments/updateSection",
  async ({ esid, sectionState }, thunkAPI) => {
    try {
      const Axios = getURL();
      await Axios.put(`/examsections/${esid}`, sectionState, setHeaders());
      thunkAPI.dispatch(
        openModal({
          message: "This section has been updated correctly.",
          severity: "success",
        })
      );
      return thunkAPI.dispatch(fetchSection(esid));
    } catch (error) {
      thunkAPI.dispatch(
        openModal({
          message: error.response.data.errormessage,
          severity: "error",
        })
      );
      return thunkAPI.rejectWithValue(error.response.data.errormessage);
    }
  }
);

const deleteSection = createAsyncThunk(
  "assessments/deleteSection",
  async ({ esid }, thunkAPI) => {
    try {
      const Axios = getURL();
      await Axios.delete(`/examsections/${esid}`, setHeaders());

      return esid;
    } catch (error) {
      thunkAPI.dispatch(
        openModal({
          message: error.response.data.errormessage,
          severity: "error",
        })
      );
      return thunkAPI.rejectWithValue(error.response.data.errormessage);
    }
  }
);

const adjustOrder = createAsyncThunk(
  "assessments/adjustOrder",
  async ({ esid, qhid, adjust_order }, thunkAPI) => {
    try {
      const Axios = getURL();
      const exams = await Axios.put(
        `/examsections/${esid}/questions/${qhid} `,
        { adjust_order },
        setHeaders()
      );
      return thunkAPI.dispatch(
        openModal({
          message: "The order of this question has been correctly adjusted.",
          severity: "success",
        })
      );
    } catch (error) {
      thunkAPI.dispatch(
        openModal({
          message: error.response.data.errormessage,
          severity: "error",
        })
      );
      return thunkAPI.rejectWithValue(error.response.data.errormessage);
    }
  }
);

export {
  fetchAssessments,
  fetchAssessmentsByAuthor,
  fetchAssessmentsToday,
  fetchAssessment,
  fetchAssessmentAudit,
  createAssessment,
  updateAssessment,
  deleteAssessment,
  fetchSections,
  fetchSection,
  addSection,
  updateSection,
  deleteSection,
  fetchQuestionsBySection,
  addQuestionBySection,
  deleteQuestionBySection,
  adjustOrder,
};
