/* REACT PACKAGE IMPORTS */
import { useState } from "react";
import { Form, Button, Stack, InputGroup } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

/* INTERNAL IMPORTS */
import {
  handleMouseEnter,
  handleMouseLeave,
} from "../../../Features/Questions/Slices/questionSlice";
import {
  createOption,
  updateOption,
  deleteOption,
} from "../../../Features/Questions/Actions/QuestionActions";
import { uploadImg } from "../../../Features/Uploads/Actions/UploadsActions";
import { openModal } from "../../../Features/Errors/Slices/errorSlice";


export default function Matching({ question }) {
  const dispatch = useDispatch();

  const { isVisible, answerNum } = useSelector(store => store.question);

  const [optionImgLeft, setOptionImgLeft] = useState({});
  const [optionImgLeftId, setOptionImgLeftId] = useState(null);
  const [optionImgRight, setOptionImgRight] = useState({});
  const [optionImgRightId, setOptionImgRightId] = useState(null);
  const [stateLeft, setStateLeft] = useState(question.options || []);
  const [stateRight, setStateRight] = useState(question.options || []);
  const [matchLeft, setMatchLeft] = useState("");
  const [matchRight, setMatchRight] = useState("");


  let { id } = useParams();
  const qhid = parseInt(id);
  const qid = question.qid;


  /* Handler functions */ 
  const handleAddOption = () => {
    let newOption = {};
    if (matchLeft.length > 0) {
      newOption = {
        qid: question.qid,
        opttext: matchLeft,
        optanswer: matchRight,
      };
    } else if (optionImgLeftId && optionImgRightId) {
      newOption = {
        qid: question.qid,
        optimage: optionImgLeftId,
        optanswer: optionImgRightId,
      };
    }
    setMatchLeft("");
    setOptionImgLeft({});
    setMatchRight("");
    setOptionImgRight({});
    dispatch(createOption({ qhid, newOption }))
      .then((response) => {
        const options = response.payload.payload.questions
                          .filter(item => item.qid === qid)[0].options;
        setStateRight(options);
        setStateLeft(options);
      });
  };

  const handleDeleteOption = (option) => {
    setStateLeft(options => {
      return options.filter(currOpt => currOpt.qoid !== option.qoid);
    });
    setStateRight(options => {
      return options.filter(currOpt => currOpt.qoid !== option.qoid);
    });
    dispatch(deleteOption({qhid, oid: option.qoid}));
  };

  const handleSaveOption = (i, option) => {
    dispatch(
      updateOption({
        qhid,
        oid: option.qoid,
        option: {
          opttext: option.opttext,
          optanswer: stateRight[i].optanswer,
        },
      })
    );
  };

  const handleFileUpload = (e) => {
    if (e.target.files.length > 0) {
      const fileSize = e.target.files[0].size / 1024 / 1024; // in MiB
      if (fileSize > 120) {
        dispatch(
          openModal({
            message: "File size exceeds 120 MiB",
            severity: "error",
          })
        );
      } else {
        const newImg = URL.createObjectURL(e.target.files[0]);
        let reader = new FileReader();
        reader.readAsDataURL(e.target.files[0]);
        reader.onload = function () {
          var base64data = reader.result;
          setOptionImgLeft(() => {
            return {
              url: newImg,
              filedesc: e.target.files[0].name,
              filename: e.target.files[0].name,
              filecontents: base64data,
            };
          });
        };
        reader.onerror = function (error) {
          dispatch(
            openModal({
              message: error,
              severity: "error",
            })
          );
        };
      }
    }
  };

  
  return (
    <div>
      <div className="list-group flex-fill mb-3">
        {
          stateLeft &&
          stateLeft.map((option, i) => {
            return (
              <div
                key={i}
                className="list-group-item"
                onMouseEnter={() => dispatch(handleMouseEnter(i))}
                onMouseLeave={() => dispatch(handleMouseLeave(i))}
              >
                <div className="my-1 d-flex align-items-center justify-content-between"> 
                  {
                    option.opttext ?
                      <Form.Control
                        type="text"
                        aria-describedby="answer"
                        aria-label={option.opttext}
                        value={option.opttext}
                        onChange={(e, i) => {
                          setStateLeft((currState) => {
                            let newState = [...currState];
                            let newOption = {
                              ...newState[i],
                              opttext: e.target.value,
                            };
                            newState[i] = newOption;
                            return newState;
                          })}
                        }
                      /> :
                      <div className="list-group-item">
                        <img
                          alt={`Img_${option.optimage.imid}`}
                          style={{ width: "300px", objectFit: "cover" }}
                          src={option.optimage.direct_url}
                          id={parseInt(option.optimage.imid)}
                          key={parseInt(option.optimage.imid)}
                          className="col-3"
                        />
                      </div>
                  }
                  {
                    Number.isNaN(parseInt(stateRight[i].optanswer))  ?
                      <Form.Control
                        type="text"
                        aria-describedby="answer"
                        value={stateRight[i].optanswer}
                        aria-label={stateRight[i].optanswer}
                        onChange={(e, i) => {
                          setStateRight((currState) => {
                            let newState = [...currState];
                            let newOption = {
                              ...newState[i],
                              optanswer: e.target.value,
                            };
                            newState[i] = newOption;
                            return newState;
                          });
                        }}
                      /> :
                      <div className="list-group-item">
                        <img
                          alt={`Img_${stateRight[i].imid}`}
                          style={{ width: "300px", objectFit: "cover" }}
                          src={stateRight[i].direct_url}
                          id={parseInt(stateRight[i].optanswer)}
                          key={parseInt(stateRight[i].optanswer)}
                          className="col-3"
                        />
                      </div>
                  }
                </div>
                <Stack
                  direction="horizontal"
                  gap={3}
                  className={isVisible && answerNum === i ? "shown" : "hidden"}
                >
                  <Button
                    variant="dark"
                    size="sm"
                    onClick={() => handleSaveOption(i, option)}
                  >
                    Save option
                  </Button>
                  <Button
                    variant="dark"
                    size="sm"
                    onClick={() => handleDeleteOption(option)}
                  >
                    Delete option
                  </Button>
                </Stack>
              </div>
            );
          })}
      </div>
      <div>
      <hr />
      <Form.Group 
        className="mb-3 flex-fill"
        controlId="exampleForm.ControlInput1">
        <h3 className="fw-bold mt-4 mb-0 fs-5">Add New Option</h3>
        <p className="text-muted mb-3">Add a new option (Text or Image) for the question</p>
          <Form.Label>Option</Form.Label>
          {
            Object.keys(optionImgLeft).length > 0 && 
            <div className="mx-auto my-5 d-flex justify-content-center align-items-center">
              <img width="300" src={optionImgLeft.url} alt="Question" />
            </div>
          }

          <Form.Control
            as="textarea"
            rows={3}
            onChange={e => setMatchLeft(e.target.value)}
            value={matchLeft}
            placeholder="Type in option text..."
            id="option-text-area"
          />
          <div className="my-2 fs-6 text-center">OR</div>
          <InputGroup>
            <Form.Control
              type="file"
              onChange={handleFileUpload}
            />
            <Button
              variant="dark"
              onClick={() => {
                dispatch(uploadImg(optionImgLeft))
                  .then(response => setOptionImgLeftId(parseInt(response.payload)));
              }}
            >
              Upload
            </Button>
          </InputGroup>
        </Form.Group>

        <Form.Group
          className="mt-4 mb-3 flex-fill"
          controlId="exampleForm.ControlInput"
        >
          <Form.Label>Matching answer</Form.Label>
          {
            Object.keys(optionImgRight).length > 0 &&
            <div className="mx-auto my-5 d-flex justify-content-center align-items-center">
              <img width="300" src={optionImgRight.url} alt="Question" />
            </div>
          }
          <Form.Control
            as="textarea"
            rows={3}
            onChange={e => setMatchRight(e.target.value)}
            value={matchRight}
            placeholder="Type matching answer text..."
            id="answer-text-area"
          />
          <div className="my-2 fs-6 text-center">OR</div>
          <InputGroup>
            <Form.Control
              type="file"
              onChange={handleFileUpload}
            />
            <Button
              variant="dark"
              onClick={() => {
                dispatch(uploadImg(optionImgRight))
                  .then(response => setOptionImgRightId(parseInt(response.payload)));
              }}
            >
              Upload
            </Button>
          </InputGroup>
        </Form.Group>
        <Button
          variant="dark"
          onClick={() => handleAddOption()}
          className="mt-4"
        >
          Add option
        </Button>
      </div>
    </div>
  );
}
