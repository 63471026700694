/* REACT PACKAGE IMPORTS */
import { useState, useEffect, useRef } from "react";
import { ReactSortable } from "react-sortablejs";

/* MUI PACKAGE IMPORTS */
import SortIcon from "@mui/icons-material/Sort";

/* INTERNAL IMPORTS */
import Image from "../../Image";
import { shuffleArray } from "../../../utils/tools.js";


export default function SortableList({ question, answer, setUserAnswerArray }) {
  const [list, setList] = useState([]);
  const mounted = useRef(null);
  const { options } = question;
  const newCurrentAnswer = {
    qid: question.qid,
    answer: { sorting: [] },
    qhid: question.qhid,
  };

  const handleOnChange = (e) => {
    const htmlCollection = e.target.children;
    let optionsElementsArray = Array.from(htmlCollection);

    const optionIdList = optionsElementsArray
      .map((element) => {
        const selectedOption = options
          .filter((option) => option.opttext === element.textContent)
          .map((option) => option.qoid);
        return selectedOption[0];
      });
    newCurrentAnswer.answer = { sorting: optionIdList };

    setUserAnswerArray((curr) => {
      const newCurrentAnswer = {
        qid: question.qid,
        answer: { sorting: optionIdList },
        qhid: question.qhid,
      };
      if (curr) return [...curr, newCurrentAnswer];
      else return [newCurrentAnswer];
    });
  };

  useEffect(() => {
    mounted.current = true;
    if (list.length > 0 || !question.options) return;
    const newAnswer = answer[0]?.answer?.sorting;

    if (mounted.current) {

      if (answer.length > 0) {
        setList((curr) => {
          
          return newAnswer?.map((option, i) => {
            const { options } = question;
            const optionText = options
              .filter((item) => item.qoid === option)
              .map((item) => item.opttext);
            return optionText[0];
          });
        });
      }

      // Shuffle list of options when the user has not provided an answer
      else {
        const shuffledOptions = shuffleArray(question.options || []);
        setList(() =>
          shuffledOptions.map((option, i) => {
            if (option.opttext) return option.opttext;
            else if (option.optimage) return option.optimage.imid;
            else return "";
          })
        );
        setUserAnswerArray((curr) => {
          const newValue = shuffledOptions.map((option, i) => option.qoid);
          const newAnswer = {
            qid: question.qid,
            answer: { sorting: newValue },
            qhid: question.qhid,
          };
          let newCurr = curr.filter((answer) => answer.qid !== question.qid);
          if (curr) return [...newCurr, newAnswer];
          else return [newAnswer];
        });
      }
    }

    return () => (mounted.current = false);
  }, [list, answer]);

  return (
    <div>
      <p className="fs-4 mb-4"><span className="fw-bold">Question</span>: {question.question}</p>
      <p className="fs-6 fst-italic m-1 text-muted">Drag and sort the items into the correct order below</p>
      <div className="list-group flex-fill" aria-label="Unordered sortable list">
        <ReactSortable
          list={list}
          setList={setList}
          group="groupName"
          animation={200}
          easing={"cubic-bezier(1, 0, 0, 1)"}
          delayOnTouchStart={true}
          delay={2}
          dragClass="pointer"
          onChange={handleOnChange}
        >
          {
            list.length > 0 ? 
              list?.map((item, i) => (
                  <div
                    key={i}
                    className="list-group-item sortable-list-item flex-fill background-secondary"
                  >
                    <SortIcon
                      fontSize="small"
                      sx={{ color: "var(--bkgterciary)", marginRight: "10px" }}
                    />
                    {
                      Number.isNaN(parseInt(item)) ? 
                        item : 
                        <Image
                          id={parseInt(item)}
                          style={{ width: "300px", objectFit: "cover" }}
                        />
                    }
                  </div>
              )) :
              null
          }
        </ReactSortable>
      </div>
    </div>
  );
}
