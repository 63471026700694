import { useState } from "react";
import MaterialTable from "@material-table/core";

export default function DataTable({ rows, columns, type, loading, getRowId }) {
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const handleChangeFile = (selectorFiles) => {
    if (selectorFiles.length > 0) {
      var reader = new FileReader();
      reader.onload = function (e) {
        // Use reader.result
        const text = e.target.result;
        const data = csvToArray(text);
        // createData(reader.result);
      };
      reader.readAsText(selectorFiles[0]);
    }
  };

  const csvToArray = (str, delimiter = ",") => {
    // slice from start of text to the first \n index
    // use split to create an array from string by delimiter
    const headers = str.slice(0, str.indexOf("\n")).split(delimiter);

    // slice from \n index + 1 to the end of the text
    // use split to create an array of each csv value row
    const rows = str.slice(str.indexOf("\n") + 1, -1).split("\n");
    const arr = rows.map(function (row) {
      const values = row.split(delimiter);
      const el = headers.reduce(function (object, header, index) {
        object[header.replace("//", "")] = values[index];
        return object;
      }, {});
      return el;
    });

    // return the array
    return arr;
  };

  return (
    <div className="my-4 box" style={{ width: "100%" }}>
      <MaterialTable
        options={{
          search: true,
          grouping: false,
          pageSize: rowsPerPage,
          pageSizeOptions: [5, 10, 20, 50, 100],
          showTitle: false,
          idSynonym: "qid",
          exportButton: true,
          rowStyle: {
            fontSize: "14px",
            fontFamily: "Inter",
          },
        }}
        pagination
        columns={columns}
        data={rows}
        title="Question Bank"
        getRowId={getRowId}
        onChangeRowsPerPage={(newPageSize) => setRowsPerPage(newPageSize)}
        isLoading={loading}
      />
    </div>
  );
}
