import { useState } from "react";
import { Container, Form, Button } from "react-bootstrap";
import upload from "../assets/close-upload.svg";
import "../styles/Imagery.css";

export default function Imagery({ data }) {
  const [newImg, setNewImg] = useState(data);

  const uploadImg = (e) => {
    const newImageURL = URL.createObjectURL(e.target.files[0]);
    const newImageName = e.target.files[0].name;
    const img = {
      src: newImageURL,
      name: newImageName,
    };
    setNewImg((arr) => {
      return [...arr, img];
    });
    e.target.value = null;
  };
  return (
    <Container className="box mb-5 background-secondary">
      <div className="p-5 text-center">
        <div
          id="drop-area-img"
          className="card dashed-border justify-content-center align-items-center"
          style={{ height: "200px" }}
        >
          <Form
            id="imagery"
            className="my-form d-flex flex-column justify-content-center align-items-center"
            encType="multipart/form-data"
          >
            <Form.Control
              type="file"
              className="d-none"
              name="file"
              id="fileImg"
              multiple
              accept="image/*"
              onChange={uploadImg}
            />
            <img src={upload} alt="upload" />
            <Form.Label htmlFor="fileImg">Drag file to upload</Form.Label>
          </Form>
        </div>
      </div>

      <div
        id="progressContainerImg"
        className="row p-5 container-upload text-center d-none"
      >
        <div className="card justify-content-center align-items-center col-12 col-sm p-5 background-secondary">
          <p className="font-bold">Items uploading</p>
          <progress id="progress-bar-img" max={100} value={40}></progress>
        </div>
      </div>
      <div
        id="galleryImg"
        className="d-flex flex-wrap mx-auto justify-content-around p-5"
      >
        {newImg.map((img, i) => {
          return (
            <div
              key={i}
              className="card card-borderless m-4 background-secondary"
            >
              <img
                className="card-img-top position-relative"
                src={img.src}
                alt={img.name}
                onClick={(e) => {
                  window.open(e.target.src);
                }}
              />
              <Button
                type="button"
                className="btn-close position-absolute top-0 start-100 translate-middle background-secondary"
                aria-label="Close"
                data-bs-toggle="modal"
                data-bs-target={`#deleteImg_${img.id}`}
              ></Button>
              <div className="card-body">
                <p
                  className="card-text title-card font-secondary text-center"
                  id={img.id}
                  data-id={img.id}
                  data-name={img.name}
                >
                  {img.name}
                </p>
              </div>
            </div>
          );
        })}
      </div>
    </Container>
  );
}
