/* REACT PACKAGE IMPORTS */
import { Form } from "react-bootstrap";


export default function InputField({ input, handleChange }) {

  const fieldLabel = (input.isRequired) ? 
    <Form.Label className="fw-bold m-0">{input.label}
      <span className="asterisk-red"> *</span>
    </Form.Label> :
    <Form.Label className="fw-bold m-0">{input.label}</Form.Label>;
  
  return (
    <Form.Group
      className={"mb-4"}
      key={input.id}
      controlId={input.id}
    >
      <div className="d-flex flex-column">
        {fieldLabel}
        {
          input.message &&
          <Form.Text className="text-muted mt-0 mb-1">{input.message}</Form.Text>
        }
      </div>

      <Form.Control
        data-cy={input.label}
        type={input.type}
        required={input.isRequired}
        defaultValue={input.value}
        onChange={handleChange}
        name={input.id}
      />
      {
        input.invalidFeedback &&
        <Form.Control.Feedback type="invalid">
          {input.invalidFeedback}
        </Form.Control.Feedback>
      }
      {
        input.validFeedback &&
        <Form.Control.Feedback type="valid">
          {input.validFeeback}
        </Form.Control.Feedback>
      }
    </Form.Group>
  )
};