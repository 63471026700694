import { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { fetchImg } from "../Features/Uploads/Actions/UploadsActions";

export default function Image({ id, width, style, className }) {
  const { downloads } = useSelector((store) => store.uploads);
  const [src, setSrc] = useState("");
  const mounted = useRef(null);

  const dispatch = useDispatch();

  useEffect(() => {
    mounted.current = true;
    if (mounted.current) {
      dispatch(fetchImg(id));
    }
    return () => (mounted.current = false);
  }, [id, dispatch]);

  useEffect(() => {
    mounted.current = true;
    if (mounted.current) {
      if (downloads.length > 0) {
        setSrc(() => {
          const url = downloads.filter((download) => {
            return download.id === id;
          })[0];
          if (url) {
            return url.url;
          } else {
            return "";
          }
        });
      }
    }
    return () => (mounted.current = false);
  }, [id, downloads]);

  return (
    src.length > 0 && (
      <img
        key={id}
        width={width}
        src={src}
        alt={`Img_${id}`}
        style={style}
        className={className}
      />
    )
  );
}
