/* REACT PACKAGE IMPORTS */
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

/* MUI PACKAGE IMPORTS */
import { IconButton, Button } from "@mui/material";
import InfoIcon from '@mui/icons-material/Info';

/* INTERNAL IMPORTS */
import Assessment from "./Assessment";
import SectionlessAssessment from "./SectionlessAssessment";
import { fetchAnswersBySession, fetchFlaggedQuestions } from "../../Features/Sessions/Actions/SessionsActions";
import { newAnswer } from "../../Features/Sessions/Slices/sessionsSlice";


// function SectionCard({
//   isSessionRunning,
//   sessionExpired,
//   isConducted,
//   section,
//   index
// }) {
//   const [showSection, setShowSection] = useState(false);
//   const [page, setPage] = useState(1);
//   const [showAssessment, setShowAssessment] = useState(false);
//   const [questionsBySection, setQuestionsBySection] = useState([]);

//   const [sectionNum, setSection] = useState(null);

//   const {
//     sections,
//     questions,
//   } = useSelector(store => store.assessments);
//   const { pages } = useSelector(store => store.sessions);

//   const showSectionHandler = (e, i) => {
//     setShowSection(!showSection);
//     setQuestionsBySection(() => {
//       let newQuestions = questions
//         .filter(section => section.sectionid === i)
//         .map(section => section.questions);
//       return newQuestions[0];
//     });
//     if (!showSection) setSection(i);
//   };

//   const goSection = (i) => {
//     setShowAssessment(true);
//     setQuestionsBySection(() => {
//       let newQuestions = questions
//         .filter(section => section.sectionid === i)
//         .map(section => section.questions);
//       return newQuestions[0];
//     });
//     setPage(1);
//     setSection(i);
//   };

//   const checkSectionState = (section) => {
//     var state = "";
//     var pagesSection = pages.filter((page) => page.section === section);
//     var tagged = pagesSection.map((item) => item.tagged);
//     var completed = pagesSection.map((item) => item.completed);

//     if (tagged.some((value) => value)) state = "tagged";
//     else if (completed.every((value) => value)) state = "completed";
//     else state = "pending";
//     return `page_${state}`;
//   };


//   return (
//     <li className="border-0 list-group-item my-2" key={index}>
//       <div className="d-flex justify-content-between align-items-center">
//         <span className="d-flex align-items-center">

//           <IconButton
//             aria-label="show section"
//             onClick={(e) => showSectionHandler(e, section.esid)}
//           >
//             <InfoIcon fontSize="medium" />
//           </IconButton>

//           <p className="fw-bold fs-4 mx-2 my-0">
//             {sections.sectionname ? section.sectionname : `Section ${index + 1}`}
//           </p>

//         </span>
//         <Button
//           className={checkSectionState(section.esid)}
//           variant="contained"
//           onClick={() => goSection(section.esid)}
//           disabled={!isSessionRunning || sessionExpired || isConducted}
//         >
//           Start Section
//         </Button>
//       </div>

//       {
//         showSection &&
//         sectionNum === section.esid &&
//         questionsBySection.length > 0 &&
//         (
//           <div className="mx-5">
//             <p>
//               <strong>Number of questions: </strong>
//               {questionsBySection.length}
//             </p>
//           </div>
//         )
//       }
//     </li>
//   );

// };

export default function SessionStart({
  isSessionRunning,
  sessionExpired,
  isConducted,
  sessionStatus,
  targetDate,
  setSessionExpired,
}) {
  const [showSection, setShowSection] = useState(false);
  const [page, setPage] = useState(1);
  const [showAssessment, setShowAssessment] = useState(false);
  const [questionsBySection, setQuestionsBySection] = useState([]);
  const [userAnswerArray, setUserAnswerArray] = useState([]);
  const [userFlaggedArray, setUserFlaggedArray] = useState([]);

  const [sectionNum, setSection] = useState(null);

  const {
    sections,
    questions,
  } = useSelector(store => store.assessments);
  const { pages } = useSelector(store => store.sessions);

  const { id } = useParams();
  const sessionId = parseInt(id);
  const dispatch = useDispatch();


  useEffect(() => {
    dispatch(fetchAnswersBySession(sessionId))
      .then(response => {
        if (!response.payload) return;
        const answerArray = response.payload.map(answer => ({
          qid: answer.qid,
          answer: answer.answercontent
        }));
        answerArray.forEach(answer => dispatch(newAnswer(answer)));
        setUserAnswerArray(answerArray);
      });

    dispatch(fetchFlaggedQuestions({ esId: sessionId }))
      .then(response => {
        if (!response) return;
        setUserFlaggedArray(response.payload.data.flagged);
      });
  }, []);

  const showSectionHandler = (e, i) => {
    setShowSection(!showSection);
    setQuestionsBySection(() => {
      let newQuestions = questions
        .filter(section => section.sectionid === i)
        .map(section => section.questions);
      return newQuestions[0];
    });
    if (!showSection) setSection(i);
  };

  const goSection = (i) => {
    setShowAssessment(true);
    setQuestionsBySection(() => {
      let newQuestions = questions
        .filter(section => section.sectionid === i)
        .map(section => section.questions);
      return newQuestions[0];
    });
    setPage(1);
    setSection(i);
  };

  const checkSectionState = (section) => {
    var state = "";
    var pagesSection = pages.filter((page) => page.section === section);
    var tagged = pagesSection.map((item) => item.tagged);
    var completed = pagesSection.map((item) => item.completed);

    if (tagged.some((value) => value)) state = "tagged";
    else if (completed.every((value) => value)) state = "completed";
    else state = "pending";
    return `page_${state}`;
  };


  return (
    <div className="list-group box border-0 mb-2">

      {/* <ol className="p-0">
         {
          sections.map(
            (section, index) => {
              return (
                <li className="border-0 list-group-item my-2" key={index}>
                  <div className="d-flex justify-content-between align-items-center">
                    <span className="d-flex align-items-center">
            
                      <IconButton
                        aria-label="show section"
                        onClick={(e) => showSectionHandler(e, section.esid)}
                      >
                        <InfoIcon fontSize="medium" />
                      </IconButton>
            
                      <p className="fw-bold fs-4 mx-2 my-0">
                        {sections.sectionname ? section.sectionname : `Section ${index + 1}`}
                      </p>
            
                    </span>
                    <Button
                      className={checkSectionState(section.esid)}
                      variant="contained"
                      onClick={() => goSection(section.esid)}
                      disabled={!isSessionRunning || sessionExpired || isConducted}
                    >
                      Start Section
                    </Button>
                  </div>
            
                  {
                    showSection &&
                    sectionNum === section.esid &&
                    questionsBySection.length > 0 &&
                    (
                      <div className="mx-5">
                        <p>
                          <strong>Number of questions: </strong>
                          {questionsBySection.length}
                        </p>
                      </div>
                    )
                  }
                </li>
              );
            }
          )
        }
      </ol> 
     
      
      {
        !sessionExpired &&
        sections.map((section, i) => {
          return (
            isSessionRunning &&
            questionsBySection.length > 0 &&
            showAssessment &&
            sectionNum === section.esid && (
              <Assessment
                targetDate={targetDate}
                setSessionExpired={setSessionExpired}
                paused={sessionStatus === "paused" ? true : false}
                questions={questionsBySection}
                sessionExpired={sessionExpired}
                page={page}
                setPage={setPage}
                section={sectionNum}
                setShowAssessment={setShowAssessment}
                key={i}
              />
            )
          );
        })
      } */}

      {
        sections.length === 1 &&
        !sessionExpired ?
        <SectionlessAssessment
          targetDate={targetDate}
          setSessionExpired={setSessionExpired}
          paused={sessionStatus === "paused" ? true : false}
          questions={questions}
          sessionExpired={sessionExpired}
          page={page}
          pages={pages}
          setPage={setPage}
          section={sections[0]?.esid}
          setShowAssessment={true}
          userAnswerArray={userAnswerArray}
          setUserAnswerArray={setUserAnswerArray}
          userFlaggedArray={userFlaggedArray}
        /> : null
      }

    </div>
  );
};
