import { getURL, setHeaders } from "../../../Apis/axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { openModal } from "../../Errors/Slices/errorSlice";

const uploadImg = createAsyncThunk(
  "uploads/uploadImg",
  async (data, thunkAPI) => {
    try {
      const Axios = getURL();
      const image = await Axios.post("/images", data, setHeaders());
      const url = image.data.data.api_url;
      const urlArr = url.split("/");
      const uploadId = urlArr[urlArr.length - 1];
      thunkAPI.dispatch(
        openModal({
          message: "This image has been uploaded successfully",
          severity: "success",
        })
      );
      return uploadId;
    } catch (error) {
      thunkAPI.dispatch(
        openModal({
          message: error.response.data.errormessage,
          severity: "error",
        })
      );
      return thunkAPI.rejectWithValue(error.response.data.errormessage);
    }
  }
);

const fetchImg = createAsyncThunk("uploads/fetchImg", async (id, thunkAPI) => {
  try {
    const Axios = getURL();
    const image = await Axios.get(`/images/${id}/image`, setHeaders());
    const { mimetype, content } = image.data.data;
    const url = `data:${mimetype};base64, ${content}`;
    return { url, id };
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data.errormessage);
  }
});

const uploadVideo = createAsyncThunk(
  "uploads/uploadVideo",
  async (data, thunkAPI) => {
    try {
      // // const params = new URLSearchParams();
      // // params.append("filedesc", data["filedesc"]);
      // // params.append("filename", data["filename"]);
      // // params.append("filecontents", data["filecontents"]);
      // var formData = new FormData();
      // // formData.append(
      // //   "filename",
      // //   "mixkit-strong-woman-training-lifting-weights-44433-medium.mp4"
      // // );
      // formData.append("filecontents", data);
      // formData.append(
      //   "filename",
      //   "mixkit-strong-woman-training-lifting-weights-44433-medium.mp4"
      // );
      // formData.append("filedesc", "test");
      const Axios = getURL();
      const video = await Axios.post("/videos", data, setHeaders());
      const url = video.data.data.api_url;
      const urlArr = url.split("/");
      const uploadId = urlArr[urlArr.length - 1];
      thunkAPI.dispatch(
        openModal({
          message: "This video has been uploaded correctly",
          severity: "success",
        })
      );
      return uploadId;
    } catch (error) {
      thunkAPI.dispatch(
        openModal({
          message: error.response.data.errormessage,
          severity: "error",
        })
      );
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

const fetchVideo = createAsyncThunk(
  "uploads/fetchVideo",
  async (id, thunkAPI) => {
    try {
      const Axios = getURL();
      const video = await Axios.get(`/videos/${id}/video`, setHeaders());
      const { mimetype, content } = video.data.data;
      const url = `data:${mimetype};base64, ${content}`;
      return { url, id };
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.errormessage);
    }
  }
);

const fetchImgs = createAsyncThunk(
  "uploads/fetchImgs",
  async (id, thunkAPI) => {
    try {
      const Axios = getURL();
      const images = await Axios.get("/images", setHeaders());
      // const { mimetype, content } = image.data.data;
      // const url = `data:${mimetype};base64, ${content}`;
      // return { url, id };
      return images.data.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.errormessage);
    }
  }
);

export { uploadImg, fetchImg, uploadVideo, fetchVideo, fetchImgs };
