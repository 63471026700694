/* REACT PACKAGE IMPORTS */
import { useEffect, useState, useRef } from "react";
import { Container } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

/* INTERNAL IMPORTS */
import { LocalDateTime } from "../Components/DateTimeFormatter";
import Header from "../Components/Header";
import SubNavbar from "../Components/SubNavbar";
import Overview from "../Components/Sessions/Overview";
import Users from "../Components/Sessions/Users";
import ListResults from "../Components/Sessions/ListResults";
import Notes from "../Components/Notes";
import FormComponent from "../Components/Form";

import {
  fetchSchedule,
  updateSchedule,
} from "../Features/Sessions/Actions/SessionsActions";
import { fetchAssessments } from "../Features/Assessments/Actions/AssessmentsActions";
import { fetchVenues } from "../Features/Venues/Actions/VenuesActions";

import edit from "../assets/group-edit.svg";
import "../styles/Session.css";


export default function Session({ tabs }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  let { id } = useParams();
  const schId = parseInt(id);

  const { schedule } = useSelector(store => store.sessions);
  const { assessmentsSelect } = useSelector(store => store.assessments);
  const { venuesSelect } = useSelector(state => state.venues);

  const [paragraph, setParagraph] = useState("");
  const [isFormVisible, setFormVisible] = useState(false);
  const [apiCalled, setApiCalled] = useState(false);
  const [radioValue, setRadioValue] = useState("1");
  const mounted = useRef(null);

  

  /* Handler functions */
  const handleChange = (e) => setRadioValue(e.currentTarget.value);

  const handleUpdateSchedule = (scheduleState) => {
    if (mounted.current) {
      dispatch(updateSchedule({ schId, scheduleState }));
      setFormVisible(false);
    };
  };

  /* UseEffect functions */
  useEffect(() => {
    mounted.current = true;

    if (mounted.current) {
      if (isNaN(schId)) navigate("/admin/error", { replace: true });
      else {
        dispatch(fetchSchedule(schId))
          .unwrap()
          .then(response => {
            document.title = response?.title;
            setParagraph(
              `Session created by ${response?.createdby.firstname} ${response?.createdby.lastname},` 
              + LocalDateTime(response?.created)
            );
            setApiCalled(true);
          })
          .catch(() => navigate("/admin/error", { replace: true })); 
      };
    };

    return () => mounted.current = false;
  }, [schId]);

  useEffect(() => {
    mounted.current = true;
    if (assessmentsSelect.length < 0) return;
    if (mounted.current) dispatch(fetchAssessments());
    return () => mounted.current = false;
  }, []);

  useEffect(() => {
    mounted.current = true;
    if (venuesSelect.length < 0) return
    if (mounted.current) dispatch(fetchVenues());
    return () => mounted.current = false;
  }, []);
  

  return (
    apiCalled && (
      <Container className="py-4 position-relative">
        <Header paragraph={paragraph} title={schedule?.title}>
          <SubNavbar
            radioValue={radioValue}
            tabs={tabs}
            handleChange={handleChange}
            className=""
          />
        </Header>
        
        {
          radioValue === "1" &&
          Object.keys(schedule).length > 0 && 
          <Overview data={schedule}>
              {
                assessmentsSelect.length > 0 && 
                venuesSelect.length > 0 && 
                <FormComponent
                  title="Update session"
                  multipleValues={[]}
                  selects={[
                    {
                      label: "Assessment title",
                      type: "text",
                      id: "exid",
                      value: schedule.exam.exid,
                      options: assessmentsSelect,
                      order: "first",
                      required: true,
                    },
                    {
                      label: "Venue",
                      type: "text",
                      id: "vid",
                      value: schedule?.venue?.vid,
                      options: venuesSelect,
                      order: "first",
                      required: false,
                    },
                  ]}
                  inputs={[
                    {
                      label: "Session title",
                      type: "text",
                      id: "title",
                      value: schedule.title,
                      required: true,
                    },
                    {
                      label: "Session description (optional)",
                      type: "textarea",
                      id: "description",
                      value: schedule.description,
                      required: false,
                    },
                    {
                      label: "Exam start time (UTC)",
                      type: "datetime-local",
                      id: "starttime",
                      value: schedule.starttime.split("+")[0],
                      required: true,
                    },
                    {
                      label: "Leeway to start (UTC)",
                      type: "datetime-local",
                      id: "laststarttime",
                      value: schedule.laststarttime.split("+")[0],
                      required: true,
                    },
                  ]}
                  multipleSelects={[]}
                  button="Update session"
                  logo={edit}
                  logoClass="position-absolute top-0 start-100 translate-middle button-edit"
                  action={handleUpdateSchedule}
                  isFormVisible={isFormVisible}
                  setFormVisible={setFormVisible}
                />
              }
          </Overview>
        }
        { radioValue === "2" ? <Users /> : null }
        { radioValue === "3" ? <ListResults /> : null }
        {
          radioValue === "4" ? 
            <Notes 
              id={schId} 
              type="examschedule"
              title={"Session Notes"} 
            /> :
            null
        }
      </Container>
    )
  );
}
