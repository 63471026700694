/* REACT PACKAGE IMPORTS */
import { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import ReactCrop from "react-image-crop";

/* INTERNAL IMPORTS */
import CropArrow from '../assets/image-crop/crop-arrow.png';
import "react-image-crop/dist/ReactCrop.css";


export default function ImageCrop({
  src,
  show,
  handleClose,
  avatar,
  newProfile,
  saveChanges,
  setImageData
}) {
  const [croppedImageUrl, setCroppedImageUrl] = useState();
  const [imageRef, setImageRef] = useState();
  const [fileUrl, setFileUrl] = useState();
  const [crop, setCrop] = useState({
    unit: "px",
    width: 500,
    aspect: 1 / 1,
  });

  const onImageLoaded = (image) => setImageRef(image);
  const onCropComplete = (crop) => makeClientCrop(crop);

  const makeClientCrop = (crop) => {
    if (imageRef && crop.width && crop.height) {
      getCroppedImg(imageRef, crop, "newFile.jpeg")
        .then(response => setCroppedImageUrl(response));
    }
  };

  const getCroppedImg = async (image, crop, fileName) => {
    const canvas = document.createElement("canvas");
    const pixelRatio = window.devicePixelRatio;
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    const ctx = canvas.getContext("2d");

    canvas.width = crop.width * pixelRatio * scaleX;
    canvas.height = crop.height * pixelRatio * scaleY;

    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    ctx.imageSmoothingQuality = "high";

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width * scaleX,
      crop.height * scaleY
    );

    const base64Canvas = canvas.toDataURL("image/jpeg").split(';base64,')[1];
    setImageData(base64Canvas);

    return new Promise((resolve, reject) => {
      canvas.toBlob(
        (blob) => {
          if (!blob) {
            //reject(new Error('Canvas is empty'));
            console.error("Canvas is empty");
            return;
          }
          blob.name = fileName;
          window.URL.revokeObjectURL(fileUrl);
          setFileUrl(resolve(window.URL.createObjectURL(blob)));
          console.log(fileUrl);
        },
        "image/jpeg",
        1
      );
    });
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Upload Profile Photo</Modal.Title>
      </Modal.Header>
      <Modal.Body className="d-flex align-items-center">
        <div className="p-2 text-center">
          <p>Crop Image</p>
          <ReactCrop
            src={src}
            crop={crop}
            onChange={newCrop => setCrop(newCrop)}
            ruleOfThirds
            onImageLoaded={onImageLoaded}
            onComplete={onCropComplete}
          />
        </div>
        <div>
          <img className="mt-2" src={CropArrow} alt="" style={{ height: "48px", width: "48px", margin: "0 10px 0 10px" }}/>
        </div>
        <div className="p-2 text-center" style={{ width: "200px", marginRight: "10px" }}>
        <p>New Profile Photo</p>
          <img
            ref={newProfile}
            alt="Crop"
            style={{ width: "200px", borderRadius: "50%" }}
            src={croppedImageUrl ? croppedImageUrl : avatar}
          />

        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="dark" onClick={saveChanges} className="container">
          Update Profile
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
