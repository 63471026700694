/* REACT PACKAGE IMPORTS */
import React, { useEffect, useState, useRef } from "react";
import { Form, Button, Stack } from "react-bootstrap";
import { SketchPicker } from "react-color";
import { Stage, Layer } from "react-konva";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

/* INTERNAL IMPORTS */
import {
  handleMouseEnter,
  handleMouseLeave,
} from "../../../Features/Questions/Slices/questionSlice";
import { updateQuestion } from "../../../Features/Questions/Actions/QuestionActions";
import { openModal } from "../../../Features/Errors/Slices/errorSlice";
import Area from "../Area";
import LightBox from "../../LightBox";
import "../../../styles/Hotspot.css";


function rgbToStr(rgba) {
  const { r, g, b, a } = rgba;
  var result = `rgba(${r}, ${g}, ${b}, ${a})`;
  return result;
}

const PureCanvas = React.forwardRef(
  (
    {
      onMouseDown,
      onMouseUp,
      onMouseOut,
      onMouseMove,
      width,
      height,
      style,
      className,
    },
    ref
  ) => (
    <canvas
      ref={ref}
      onMouseDown={onMouseDown}
      onMouseUp={onMouseUp}
      onMouseOut={onMouseOut}
      onMouseMove={onMouseMove}
      width={width}
      height={height}
      style={style}
      className={className}
    />
  )
);


export default function HotspotEdit({ question, questionHeader }) {
  const dispatch = useDispatch();

  const { isVisible, answerNum } = useSelector(store => store.question);

  const [idDisplay, setId] = useState(null);
  const [imgWidth, setImgWidth] = useState(1000);
  const [imgHeight, setImgHeight] = useState(1000);
  const [displayColorPicker, setDisplayColorPicker] = useState(false);
  const [url, setUrl] = useState(
    questionHeader.imagecount > 0 ?
      questionHeader.images[questionHeader.imagecount - 1].direct_url :
      ""
  );
  const [areas, setAreas] = useState(
    question.correctanswer.length > 0 ?
      JSON.parse(question.correctanswer) :
      []
  );

  const [width, setWidth] = useState(null);
  const [height, setHeight] = useState(null);
  const [start, setStart] = useState([]);
  const [offset, setOffset] = useState([]);
  const [mouse, setMouse] = useState([]);
  const [polygon, setPolygon] = useState([]);
  const [spot, setSpot] = useState({});
  const [shape, setShape] = useState("rect");
  const [color, setColor] = useState("rgba(0,0,0,0.5)");
  const [title, setTitle] = useState("");
  const [isDown, setIsDown] = useState(false);

  let { id } = useParams();
  const qhid = parseInt(id);
  const qid = question.qid;

  const canvasRef = useRef(null);
  const imgRef1 = useRef(null);
  const imgRef2 = useRef(null);
  const mounted = useRef(null);


  const handleOnCheck = (e, i) => {
    const hotspots = [...areas].map((area) => {
      if (area.id === i) return { ...area, correct: e.target.checked };
      else return { ...area };
    });
    dispatch(
      updateQuestion({
        qid,
        qhid,
        formData: { correctanswer: JSON.stringify(hotspots) },
      })
    );
  };

  const deleteSpot = (e, i) => {
    setAreas((curr) => {
      const newAreas = curr.filter((area, index) => {
        return index !== i;
      });
      return newAreas;
    });
    var hotspots = areas.filter((area, index) => {
      return index !== i;
    });
    dispatch(
      updateQuestion({
        qid,
        qhid,
        formData: { correctanswer: JSON.stringify(hotspots) },
      })
    );
  };

  const saveSpot = () => {
    const hotspots = [...areas];
    dispatch(
      updateQuestion({
        qid,
        qhid,
        formData: { correctanswer: JSON.stringify(hotspots) },
      })
    );
  };

  const addSpot = (e) => {
    var newArea = {};
    if (
      shape === "rect" &&
      Object.keys(start).length > 0 &&
      Object.keys(mouse).length > 0
    ) {
      newArea = {
        id:
          String.fromCharCode(Math.floor(Math.random() * 26) + 97) +
          Math.random().toString(16).slice(2) +
          Date.now().toString(16).slice(4),
        title: title,
        type: shape,
        name: title,
        fillColor: color,
        polygon: [
          [start.startX / width, start.startY / height],
          [mouse.mouseX / width, start.startY / height],
          [mouse.mouseX / width, mouse.mouseY / height],
          [start.startX / width, mouse.mouseY / height],
        ],
        coords: [
          start.startX / width,
          start.startY / height,
          mouse.mouseX / width,
          start.startY / height,
          mouse.mouseX / width,
          mouse.mouseY / height,
          start.startX / width,
          mouse.mouseY / height,
        ],
        correct: false,
      };
    } else if (shape === "poly" && polygon.length > 0) {
      let poly = polygon.map((point) => {
        return [point.pointX / width, point.pointY / height];
      });
      let coords = polygon
        .map(point => [point.pointX / width, point.pointY / height])
        .flat();
      newArea = {
        id:
          String.fromCharCode(Math.floor(Math.random() * 26) + 97) +
          Math.random().toString(16).slice(2) +
          Date.now().toString(16).slice(4),
        title: title,
        type: shape,
        name: title,
        fillColor: color,
        polygon: poly,
        coords: coords,
        correct: false,
      };
      setMouse({});
      setPolygon([]);
    } else if (
      shape === "circle" &&
      Object.keys(start).length > 0 &&
      Object.keys(mouse).length > 0
    ) {
      newArea = {
        id:
          String.fromCharCode(Math.floor(Math.random() * 26) + 97) +
          Math.random().toString(16).slice(2) +
          Date.now().toString(16).slice(4),
        title: title,
        type: shape,
        name: title,
        fillColor: color,
        polygon: [
          start.startX / width,
          start.startY / height,
          (mouse.mouseX - start.startX) / width,
        ],
        coords: [
          start.startX / width,
          start.startY / height,
          (mouse.mouseX - start.startX) / width,
        ],
        correct: false,
      };
    } else if (shape === "spot") {
      const coords = [spot.pointX / width, spot.pointY / height];
      newArea = {
        id:
          String.fromCharCode(Math.floor(Math.random() * 26) + 97) +
          Math.random().toString(16).slice(2) +
          Date.now().toString(16).slice(4),
        title: title,
        type: shape,
        name: title,
        fillColor: color,
        polygon: coords,
        coords: coords,
        correct: false,
      };
    }
    if (Object.keys(newArea).length > 0) {
      setTitle("");
      setAreas(curr => [...curr, newArea]);
      const hotspots = [...areas, newArea];
      dispatch(
        updateQuestion({
          qid,
          qhid,
          formData: { correctanswer: JSON.stringify(hotspots) },
        })
      );
    } else {
      dispatch(
        openModal({
          message:
            "The selected shape does not correspond to the drawing. Please try again.",
          severity: "error",
        })
      );
    }
  };

  const cleanHotposts = (e) => {
    setMouse({});
    setAreas([]);
    setTitle("");
    setPolygon([]);
    setSpot({});
    dispatch(
      updateQuestion({
        qid,
        qhid,
        formData: { correctanswer: "" },
      })
    );
  };

  const handleMouseDown = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setMouse({});

    const canvas = canvasRef.current;

    var canvasOffset = canvas.getBoundingClientRect();
    setOffset({
      offsetX: canvasOffset.left,
      offsetY: canvasOffset.top,
    });

    if (shape === "poly") {
      setPolygon((curr) => {
        const newPoint = {
          pointX: parseInt(e.clientX - canvasOffset.left),
          pointY: parseInt(e.clientY - canvasOffset.top),
        };
        return [...curr, newPoint];
      });
    } else if (shape === "spot") {
      setSpot((curr) => {
        const newPoint = {
          pointX: parseInt(e.clientX - canvasOffset.left),
          pointY: parseInt(e.clientY - canvasOffset.top),
        };
        return newPoint;
      });
    } else {
      setStart({
        startX: parseInt(e.clientX - canvasOffset.left),
        startY: parseInt(e.clientY - canvasOffset.top),
      });
    }
    setIsDown(true);
  };

  const handleMouseUp = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setOffset({});
    setIsDown(false);
  };

  const handleMouseOut = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDown(false);
  };

  const handleMouseMove = (e) => {
    e.preventDefault();
    e.stopPropagation();

    // if we're not dragging, just return
    if (!isDown) return
    // get the current mouse position
    setMouse({
      mouseX: parseInt(e.clientX - offset.offsetX),
      mouseY: parseInt(e.clientY - offset.offsetY),
    });

    drawShape();
  };

  const drawShape = () => {
    const image = imgRef1.current;
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");

    // Put your mousemove stuff here

    // clear the canvas
    ctx.clearRect(0, 0, image.offsetWidth, image.offsetHeight);
    // style the context
    ctx.strokeStyle = "black";
    ctx.setLineDash([5, 5]);
    ctx.lineWidth = 2;
    // calculate the rectangle width/height based
    // on starting vs current mouse position
    var widthShape = mouse.mouseX - start.startX;
    var heightShape = mouse.mouseY - start.startY;
    ctx.beginPath();

    // draw a new rect from the start position
    // to the current mouse position
    if (shape === "rect") {
      ctx.rect(start.startX, start.startY, widthShape, heightShape);
    } else if (shape === "circle") {
      ctx.arc(
        start.startX,
        start.startY,
        widthShape > 0 ? widthShape : -widthShape,
        0,
        2 * Math.PI
      );
    } else if (shape === "poly") {
      polygon.map((point, i) => {
        return i === 0
          ? ctx.moveTo(point.pointX, point.pointY)
          : ctx.lineTo(point.pointX, point.pointY);
      });
    } else if (shape === "spot") {
      ctx.beginPath();
      ctx.arc(spot.pointX, spot.pointY, 5, 0, 2 * Math.PI);
      ctx.fillStyle = "blue";
      ctx.fill();
    }
    ctx.stroke();
  };

  useEffect(() => {
    if (url) {
      const sizeImg1 = imgRef1.current;
      const sizeImg2 = imgRef2.current;
      const ctxArea = canvasRef.current.getContext("2d");
      requestAnimationFrame(() => drawShape());

      const handleResize = (e) => {
        setWidth(sizeImg1.offsetWidth);
        setHeight(sizeImg1.offsetHeight);
        ctxArea.canvas.height = sizeImg1.offsetHeight;
        ctxArea.canvas.width = sizeImg1.offsetWidth;
        setImgWidth(sizeImg2.offsetWidth);
        setImgHeight(sizeImg2.offsetHeight);
      };

      handleResize();
      window.addEventListener("resize", handleResize);
      return () => window.removeEventListener("resize", handleResize);
    }
  }, [url]);

  useEffect(() => {
    mounted.current = true;
    if (mounted.current) {
      if (!isDown && url) drawShape();
    }
    return () => mounted.current = false;
  }, [isDown, url, areas]);

  return (
    <div className="text-center">
      
      <ol className="list-group">
        {areas.map((area, i) => {
          return (
            <li
              className="list-group-item"
              key={i}
              onMouseEnter={() => dispatch(handleMouseEnter(i))}
              onMouseLeave={() => dispatch(handleMouseLeave(i))}
            >
              <div className="my-1 d-flex flex-fill justify-content-between align-items-strech">
                <Form.Label className="text-nowrap me-3 m-auto">
                  {area.type.charAt(0).toUpperCase() + area.type.slice(1)}{" "}
                  {i + 1}
                </Form.Label>
                <Form.Control
                  type="text"
                  aria-describedby="answer"
                  aria-label={`Rename ${area.type} ${i + 1}`}
                  value={area.title}
                  onChange={(e) =>
                    setAreas((curr) => {
                      var newAreas = [...curr];
                      const newValue = e.target.value;
                      newAreas[i].title = newValue;
                      return newAreas;
                    })
                  }
                />
                <div className="position-relative">
                  <div
                    className="swatch"
                    onClick={() => {
                      setId(i);
                      setDisplayColorPicker((curr) => !curr);
                    }}
                  >
                    <div
                      style={{
                        width: "30px",
                        height: "100%",
                        borderRadius: "4px",
                        background: area.fillColor,
                        margin: "auto",
                      }}
                    />
                  </div>
                  {displayColorPicker && i === idDisplay ? (
                    <div className="popover">
                      <div
                        className="cover"
                        onClick={() => {
                          setId(null);
                          setDisplayColorPicker(false);
                        }}
                      />
                      <SketchPicker
                        color={area.fillColor}
                        onChangeComplete={(e) => {
                          setAreas((curr) => {
                            var newAreas = [...curr];
                            const newValue = rgbToStr(e.rgb);
                            newAreas[i].fillColor = newValue;
                            return newAreas;
                          });
                        }}
                      />
                    </div>
                  ) : null}
                </div>
                <Form.Check
                  className="ms-3 m-auto"
                  type="checkbox"
                  name="editAnswer"
                  aria-label="checkbox"
                  onChange={(e) => handleOnCheck(e, area.id)}
                  defaultChecked={
                    area.hasOwnProperty("correct") ?
                      area.correct :
                      false
                  }
                />
              </div>
              <Stack
                direction="horizontal"
                gap={3}
                className={isVisible && answerNum === i ? "shown" : "hidden"}
              >
                <Button
                  variant="dark"
                  size="sm"
                  onClick={(e) => deleteSpot(e, i)}
                >
                  Delete option
                </Button>
              </Stack>
            </li>
          );
        })}
      </ol>

      <div className="my-4 text-start">
        <hr />
        <h3 className="fw-bold mt-4 mb-0 fs-5">Add New Hotspot</h3>
        <p className="text-muted mb-3">Add a new Hotspot area for the question</p>
      </div>

      <div className="d-flex flex-row justify-content-around align-items-center mt-3">
        <Form.Group
          style={{ zIndex: 20 }}
          className="my-2"
        >
          <Form.Label className="m-0 label-small">Hotspot Title</Form.Label>
          <Form.Control
            id="component-outlined"
            value={title}
            onChange={e => setTitle(e.target.value)}
            label="Name"
            aria-label="Hotspot Title"
          />
        </Form.Group>
        <Form.Group
          style={{ zIndex: 20 }}
          className="my-2"
        >
          <Form.Label className="m-0 label-small">Hotspot Shape</Form.Label>
          <Form.Select
            value={shape}
            label="Shape"
            onChange={e => setShape(e.target.value)}
            aria-label="Shape Select"
          >
            <option className="justify-content-center" value="spot">
              Point
            </option>
            <option className="justify-content-center" value="rect">
              Rectangle
            </option>
            <option className="justify-content-center" value="circle">
              Circle
            </option>
            <option className="justify-content-center" value="poly">
              Polygon
            </option>
          </Form.Select>
        </Form.Group>
        <Form.Group
          style={{ zIndex: 20 }}
          className="my-2"
        >
          <Form.Label className="m-0 label-small">Hotspot Colour</Form.Label>
          <div
            className="position-relative"
            style={{
              width: "100%",
              height: "40px",
            }}
          >
            <div
              className="swatch"
              onClick={() => setDisplayColorPicker(curr => !curr)}
            >
              <div
                style={{
                  width: "32px",
                  height: "100%",
                  borderRadius: "4px",
                  background: color,
                  margin: "auto",
                }}
              />
            </div>
            {displayColorPicker && idDisplay === null ? (
              <div className="popover">
                <div
                  className="cover"
                  onClick={() => setDisplayColorPicker(false)}
                />
                <SketchPicker
                  color={color}
                  onChangeComplete={e => setColor(rgbToStr(e.rgb))}
                />
              </div>
            ) : null}
          </div>
        </Form.Group>
      </div>

      {
        url && (
          <div className="d-flex justify-content-center flex-row">
            <div className="m-2 flex-fill justify-content-center">
              <PureCanvas
                ref={canvasRef}
                onMouseDown={handleMouseDown}
                onMouseUp={handleMouseUp}
                onMouseOut={handleMouseOut}
                onMouseMove={handleMouseMove}
                style={{ zIndex: 20, cursor: "crosshair" }}
                className="position-absolute"
              />
              <img
                ref={imgRef1}
                src={url}
                alt="Header"
                className="boxImg responsiveImg"
                onLoad={(e) => {
                  const image = e.target;
                  const { offsetHeight, offsetWidth } = image;
                  setWidth(offsetWidth);
                  setHeight(offsetHeight);
                }}
              />
            </div>
            <div className="m-2 justify-content-center flex-fill position-relative">
              <Stage
                width={imgWidth}
                height={imgHeight}
                className="position-absolute top-0"
              >
                <Layer>
                  {
                    areas.length > 0 &&
                    areas.map((area, i) => {
                      return (
                        <Area
                          key={i}
                          area={area}
                          imgWidth={imgWidth}
                          imgHeight={imgHeight}
                          setAreas={setAreas}
                          editable={true}
                        />
                      );
                    })
                  }
                </Layer>
              </Stage>
              <img
                ref={imgRef2}
                src={url}
                alt="Header"
                className="boxImg responsiveImg"
                onLoad={(e) => {
                  const image = e.target;
                  const { offsetHeight, offsetWidth } = image;
                  setImgWidth(offsetWidth);
                  setImgHeight(offsetHeight);
                }}
              />
            </div>
          </div>
        )
      }


      <div className="mt-3 d-flex justify-content-around">

        <Button className="me-2" variant="dark" onClick={addSpot}>
          Add Spot
        </Button>
        <Button className="me-2" variant="dark" onClick={saveSpot}>
          Save Spots
        </Button>
        <Button variant="dark" onClick={cleanHotposts}>
          Clear Spots
        </Button>
      </div>

      <div className="my-4 text-start">
        <hr />
        <h3 className="fw-bold mt-4 mb-0 fs-5">Upload Hotspot Image</h3>
        <p className="text-muted mb-3">Add a new Image for the question</p>
        <LightBox setUrl={setUrl} qhid={qhid} />
      </div>
    </div>
  );
}
