/* REACT PACKAGE IMPORTS */
import { useState, useEffect, useRef } from "react";
import { ReactSortable } from "react-sortablejs";

/* MUI PACKAGE IMPORTS */
import SortIcon from "@mui/icons-material/Sort";

/* INTERNAL IMPORTS */
import Image from "../../Image";
import { shuffleArray } from "../../../utils/tools.js";


export default function Matching({ question, answer, setUserAnswerArray, setIsComplete }) {
  const [stateLeft, setStateLeft] = useState([]);
  const [stateRight, setStateRight] = useState([]);
  const [userUpdated, setUserUpdated] = useState(false);
  const { options } = question;

  const mounted = useRef(null);

  /* HELPER FUNCTIONS */
 const handleOnChange = (e) => {
    const htmlCollection = e.target.children;
    let optionsElementArray = Array.from(htmlCollection);
   
    const selectedOptionsList = optionsElementArray.map((child) => {
      const selectedOption = options
        .filter(option => option.optanswer === child.textContent)
        .map(option => {
          return { optid: option.qoid, answer: child.textContent };
        });
      return selectedOption[0];
    });
    
    setUserAnswerArray((curr) => {
      const newCurrentAnswer = {
        qid: question.qid,
        answer: { matching: selectedOptionsList },
        qhid: question.qhid,
      };
      if (curr) return [...curr, newCurrentAnswer];
      else return [newCurrentAnswer];
    });
  };

  
  // Question order setup
  useEffect(() => {
    mounted.current = true;
    if ((stateLeft.length > 0 && stateRight.length > 0) || !question.options) return
    if (mounted.current) {
      setStateLeft(() =>
        question.options.map((option, i) => {
          if (option.opttext) return option.opttext;
          else if (option.optimage) return option.optimage.imid;
          else return "";
        })
      );
      
      // Pre-existing answer
      if (answer.length > 0) {
        
        const newAnswer = answer[0].answer.matching;

        setStateRight(() => {
          return newAnswer.map((option, i) => {
            const { options } = question;
            const optionText = options
              .filter(item => item.qoid === option.optid)
              .map(item => item.optanswer);
            return optionText;
          });

        });
      }

      // Initial render
      else {
        if (setIsComplete) setIsComplete(true);
        const shuffledOptions = shuffleArray(question.options || []);
        const newMatchOrder = shuffledOptions.map(option => {
          return { optid: option.qoid, answer: option.optanswer }
        });
        setStateRight(() => newMatchOrder.map(match => match.answer));

        if (userUpdated === false) {
          setUserAnswerArray((curr) => {
            // const newValue = shuffledOptions.map((option, i) => {
            //   return { optid: option.qoid, answer: option.optanswer };
            // });
            const newAnswer = {
              qid: question.qid,
              answer: { matching: newMatchOrder },
              qhid: question.qhid,
            };
            let newCurr = curr.filter(answer => answer.qid !== question.qid);
            if (curr) return [...newCurr, newAnswer];
            else return [newAnswer];
          });
        }
        
      }
    }

    return () => (mounted.current = false);
  }, [stateLeft, stateRight, answer]);

  return (
    <>
      <p className="fs-4 mb-4"><span className="fw-bold">Question</span>: {question.question}</p>
      <p className="fs-6 fst-italic m-1 text-muted">Drag and sort the items into the correct order below</p> 
      <div className="d-flex">
        <ol className="list-group flex-fill">
          {
            stateLeft.map((answer, i) => {
              return (
                <li key={i} className="list-group-item">
                  {
                    Number.isNaN(parseInt(answer)) ? 
                      answer : 
                      <div className="text-center">
                        <Image
                          id={parseInt(answer)}
                          style={{ width: "300px", objectFit: "cover" }}
                        />
                      </div>
                  }
                </li>
              );
            })
          }
        </ol>
        <div className="list-group flex-fill" aria-label="Unordered sortable list">
          <ReactSortable
            list={stateRight}
            setList={setStateRight}
            group="groupName"
            animation={200}
            easing={"cubic-bezier(1, 0, 0, 1)"}
            delayOnTouchStart={true}
            delay={2}
            dragClass="sortable-drag"
            onChange={handleOnChange}
          >
            {
              stateRight.length > 0 ? 
                stateRight.map((item, i) => (
                  <div
                    key={i}
                    className="list-group-item sortable-list-item flex-fill background-secondary"
                  >
                  {
                    Number.isNaN(parseInt(item)) ? 
                      <>
                        <SortIcon
                          fontSize="small"
                          sx={{
                            color: "var(--bkgterciary)",
                            marginRight: "10px",
                          }}
                        />
                        {item}
                      </> : 
                      <div className="d-flex justify-content-center">
                        <SortIcon
                          fontSize="small"
                          sx={{
                            color: "var(--bkgterciary)",
                            marginRight: "10px",
                          }}
                        />
                        <div className="text-end">
                          <Image
                            id={parseInt(item)}
                            style={{ width: "300px", objectFit: "cover" }}
                          />
                        </div>
                      </div>
                  }
                  </div>
                )) : 
                null
            }
          </ReactSortable>
        </div>
      </div>
    </>
  );
}
