import { Spinner } from "react-bootstrap";
import "../styles/Header.css";

export default function Header({ paragraph, subParagraph, title, subTitle, children }) {
  const isSubLoading = subParagraph?.slice(-1) === '~';
  if (isSubLoading) subParagraph = subParagraph.slice(0, -1);
  
  return (
    <div>
      {title ? <h1 className="header-title pt-4">{title}</h1> : ""}
      {paragraph ? (
        <div className="mx-1 d-lg-flex justify-content-between">
          <div className="d-lg-inline-flex flex-fill align-items-center">
            <p className="my-auto d-inline-block header-paragraph">
              <em>{paragraph}</em>
            </p>
          </div>
        </div>
      ) : (
        ""
      )}
      {subParagraph ?
        <div className="pb-4 mx-1 d-lg-flex justify-content-between">
          <div className="d-lg-inline-flex flex-fill align-items-center">
            <em>{subParagraph}</em>
            {isSubLoading ? <Spinner size="sm" className="mx-2"/> : null}
          </div>
        </div> :
        ""
      }
      {subTitle ? <h2 className="mb-4 py-2">{subTitle}</h2> : ""}
      {children ? <div className="pt-4">{children}</div> : ""}
    </div>
  );
}
