import { getURL, setHeaders } from "../../../Apis/axios";
import { createAsyncThunk } from "@reduxjs/toolkit";

const fetchQuestions = createAsyncThunk(
  "questions/fetchQuestions",
  async thunkAPI => {
    try {
      const Axios = getURL();
      const questionHeaders = await Axios.get("/questionheaders", setHeaders());
      return questionHeaders.data.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.errormessage);
    }
  }
);

const fetchQuestionsHeaderByAuthor = createAsyncThunk(
  "questions/fetchQuestionsHeaderByAuthor",
  async thunkAPI => {
    try {
      const userLocal = JSON.parse(sessionStorage.getItem("user"));
      const userId = userLocal?.userid;
      const Axios = getURL();
      const questionHeader = await Axios.get(
        `/questionheaders?createdby=${userId}`,
        setHeaders()
      );
      return questionHeader.data.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.errormessage);
    }
  }
);

const fetchQuestionsType = createAsyncThunk(
  "questions/fetchQuestionsType",
  async thunkAPI => {
    try {
      const Axios = getURL();
      const questiontypes = await Axios.get("/questiontypes", setHeaders());
      return questiontypes.data.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.errormessage);
    }
  }
);

export { fetchQuestions, fetchQuestionsHeaderByAuthor, fetchQuestionsType };
