/* REACT PACKAGE IMPORTS */
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

/* MUI PACKAGE IMPORTS */
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";


export default function ViewAssessment() {
  const [questionsLoading, setQuestionsLoading] = useState(true);

  const { sections, questions } = useSelector(store => store.assessments);
  const isSectionEmpty = questions.length > 0 && questions.length === sections.length


  return (
    <div className="box my-5 p-4">
      {sections.map((section, i) => {
        return (
          <div key={i}>
            <h2 className="fw-bold fs-4 mb-3">{section.sectionname}</h2>
            {
              questions[0]?.questions.length === 0 ? 
                <p><em className="text-muted">Questions haven't been added to this section yet</em></p> : 
                <ol className="list-group flex-fill mx-4">
                  { 
                    isSectionEmpty ?
                    questions
                      .filter(questions => questions.sectionid === section.esid)[0]
                      .questions
                      .map((questionHeader, i) => {
                        return (
                          <li key={i} className="font-secondary my-3 border rounded p-2">
                            <div className="d-flex justify-content-between">
                              <p>{questionHeader.admintitle}</p>
                              <Tooltip title="Open question">
                                <IconButton
                                  aria-label="Open question"
                                  onClick={() => {
                                    window.open(
                                      `/admin/question_bank/${questionHeader.qhid}`
                                    );
                                  }}
                                >
                                  <OpenInNewIcon fontSize="small" />
                                </IconButton>
                              </Tooltip>
                            </div>
                            <ul>
                              {
                                questionHeader.questions &&
                                questionHeader.questions.map((question, i) => {
                                  return <li key={i} >{question.question}</li>;
                                })
                              }
                            </ul>
                          </li>
                        );
                      }) :
                  <p>Empty Question</p>
                }  
                </ol>
            }   
          </div>
        );
      })}
    </div>
  );
}
