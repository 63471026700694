/* REACT PACKAGE IMPORTS */
import { useState, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

/* MUI PACKAGE IMPORTS */
import Button from "@mui/material/Button";
import DownloadIcon from "@mui/icons-material/Download";
import UploadIcon from "@mui/icons-material/Upload";
import Box from "@mui/material/Box";
import { ThemeProvider } from "@mui/material/styles";
import { GridToolbarContainer } from "@mui/x-data-grid";

/* INTERNAL IMPORTS */
import {
  StyledDataGrid,
  StyledGridOverlay,
  theme,
} from "../styles/StyledComponents";
import { openModal } from "../Features/Errors/Slices/errorSlice";


export default function DataTable({
  rows,
  columns,
  type,
  loading,
  getRowId,
  radioValue,
  selected,
  paginationModel,
  setPaginationModel
}) {
  const dispatch = useDispatch();
  const [rowsPerPage, setRowsPerPage] = useState(paginationModel.pageSize);
  const [isAdmin] = useState(window.location.pathname.includes("admin"));
  const dataGridRef = useRef();
  const dataGridRows = rows.length > 0 && typeof rows[0] === 'object' ? rows : [];
  const navigate = useNavigate();

  // Attach aria-busy label to data grid once data and table have loaded
  useEffect(() => {
    const dataGridElement = dataGridRef.current;
    if (dataGridElement) dataGridElement.ariaBusy = "true";
  }, [dataGridRef.current]);

  useEffect(() => {
    setPaginationModel({
      pageSize: 10,
      page: 0,
    });
  }, [radioValue])

  const handleChangeFile = (selectorFiles) => {
    if (selectorFiles.length > 0) {
      var reader = new FileReader();
      reader.onload = function (e) {
        // Use reader.result
        const text = e.target.result;
        const data = csvToArray(text);
        // createData(reader.result);
      };
      reader.readAsText(selectorFiles[0]);
    }
  };

  const handlePageSizeChange = (newPageSize) => {
    setPaginationModel(current => ({ page: current.page, pageSize: newPageSize }));
    setRowsPerPage(newPageSize);
  };

  const handlePageChange = (newPageIndex) => {
    setPaginationModel(current => ({ page: newPageIndex, pageSize: current.pageSize }));
  };

  const handleNavigation = (pathname, id) => {
    navigate(`${pathname}/${id}`);
    //window.location.reload(true);
  };

  const handleDownloadTemplate = () => console.log(`Download ${type} template`);

  const csvToArray = (str, delimiter = ",") => {
    // slice from start of text to the first \n index
    // use split to create an array from string by delimiter
    const headers = str.slice(0, str.indexOf("\n")).split(delimiter);

    // slice from \n index + 1 to the end of the text
    // use split to create an array of each csv value row
    const rows = str.slice(str.indexOf("\n") + 1, -1).split("\n");
    const arr = rows.map(function (row) {
      const values = row.split(delimiter);
      const el = headers.reduce(function (object, header, index) {
        object[header.replace("//", "")] = values[index];
        return object;
      }, {});
      return el;
    });

    // return the array
    return arr;
  };

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <ThemeProvider theme={theme}>
          <Button
            variant="text"
            color="primary"
            size="small"
            component="button"
            className="logo"
          >
            <UploadIcon fontSize="small" />
            Import
            <input
              type="file"
              accept=".csv"
              hidden
              onChange={e => handleChangeFile(e.target.files)}
            />
          </Button>
          <Button
            variant="text"
            color="primary"
            size="small"
            component="button"
            onClick={() => handleDownloadTemplate()}
            className="logo"
          >
            <DownloadIcon fontSize="small" />
            Download template
          </Button>
        </ThemeProvider>
      </GridToolbarContainer>
    );
  };

  function CustomNoRowsOverlay() {
    return (
      <StyledGridOverlay>
        <svg
          width="120"
          height="100"
          viewBox="0 0 184 152"
          focusable="false"
        >
          <g fill="none" fillRule="evenodd">
            <g transform="translate(24 31.67)">
              <ellipse
                className="ant-empty-img-5"
                cx="67.797"
                cy="106.89"
                rx="67.797"
                ry="12.668"
              />
              <path
                className="ant-empty-img-1"
                d="M122.034 69.674L98.109 40.229c-1.148-1.386-2.826-2.225-4.593-2.225h-51.44c-1.766 0-3.444.839-4.592 2.225L13.56 69.674v15.383h108.475V69.674z"
              />
              <path
                className="ant-empty-img-2"
                d="M33.83 0h67.933a4 4 0 0 1 4 4v93.344a4 4 0 0 1-4 4H33.83a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4z"
              />
              <path
                className="ant-empty-img-3"
                d="M42.678 9.953h50.237a2 2 0 0 1 2 2V36.91a2 2 0 0 1-2 2H42.678a2 2 0 0 1-2-2V11.953a2 2 0 0 1 2-2zM42.94 49.767h49.713a2.262 2.262 0 1 1 0 4.524H42.94a2.262 2.262 0 0 1 0-4.524zM42.94 61.53h49.713a2.262 2.262 0 1 1 0 4.525H42.94a2.262 2.262 0 0 1 0-4.525zM121.813 105.032c-.775 3.071-3.497 5.36-6.735 5.36H20.515c-3.238 0-5.96-2.29-6.734-5.36a7.309 7.309 0 0 1-.222-1.79V69.675h26.318c2.907 0 5.25 2.448 5.25 5.42v.04c0 2.971 2.37 5.37 5.277 5.37h34.785c2.907 0 5.277-2.421 5.277-5.393V75.1c0-2.972 2.343-5.426 5.25-5.426h26.318v33.569c0 .617-.077 1.216-.221 1.789z"
              />
            </g>
            <path
              className="ant-empty-img-3"
              d="M149.121 33.292l-6.83 2.65a1 1 0 0 1-1.317-1.23l1.937-6.207c-2.589-2.944-4.109-6.534-4.109-10.408C138.802 8.102 148.92 0 161.402 0 173.881 0 184 8.102 184 18.097c0 9.995-10.118 18.097-22.599 18.097-4.528 0-8.744-1.066-12.28-2.902z"
            />
            <g className="ant-empty-img-4" transform="translate(149.65 15.383)">
              <ellipse cx="20.654" cy="3.167" rx="2.849" ry="2.815" />
              <path d="M5.698 5.63H0L2.898.704zM9.259.704h4.985V5.63H9.259z" />
            </g>
          </g>
        </svg>
        <Box sx={{ mt: 1 }}>No {type}</Box>
      </StyledGridOverlay>
    );
  };

  return (
    <div className="my-4 box background-secondary" style={{ width: "100%" }} aria-busy>
      <StyledDataGrid
        disableSelectionOnClick
        paginationModel={paginationModel}
        onPaginationModelChange={event => console.log(event)}
        ref={dataGridRef}
        rows={dataGridRows}
        columns={columns}
        pageSize={rowsPerPage}
        onPageChange={newPageIndex => handlePageChange(newPageIndex)}
        onPageSizeChange={(newPageSize) => handlePageSizeChange(newPageSize)}
        rowsPerPageOptions={[5, 10, 20, 50, 100]}
        aria-busy
        pagination
        onRowClick={({ id }) => {
          const pathname = window.location.pathname;
          return type === "question_bank" ||
            type === "sessions" ||
            type === "assessments"
            ? handleNavigation(pathname, id)
            : null;
        }}
        autoHeight={true}
        components={
          (
            type === "question_bank" ||
            type === "sessions" ||
            type === "assessments"
          ) &&
            isAdmin ?
            { Toolbar: CustomToolbar, NoRowsOverlay: CustomNoRowsOverlay, } :
            { NoRowsOverlay: CustomNoRowsOverlay }
        }
        loading={loading}
        getRowId={getRowId}
        getRowClassName={params => params.id === selected ? "page_tagged" : ""}
        onCellClick={(event) => {
          if (event.field === 'pin') {
            navigator.clipboard.writeText(event?.value);
            dispatch(
              openModal({
                message: `Copied ${event?.value}`,
                severity: "info"
              })
            )
          };
        }}
      />
    </div>
  );
}
