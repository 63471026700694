import { useEffect, useState, useRef } from "react";
import { Stage, Layer } from "react-konva";
import Area from "../Area";

export default function HotspotView({
  question,
  questionHeader,
  answer,
  setUserAnswerArray,
}) {
  const [imgWidth, setImgWidth] = useState(1000);
  const [imgHeight, setImgHeight] = useState(1000);
  const [url] = useState(
    questionHeader.imagecount > 0
      ? questionHeader.images[questionHeader.imagecount - 1].direct_url
      : ""
  );
  const [areas] = useState(
    question.correctanswer.length > 0 ? JSON.parse(question.correctanswer) : []
  );
  const imgRef = useRef(null);
  const mounted = useRef(null);

  useEffect(() => {
    mounted.current = true;
    const sizeImg = imgRef.current;

    function handleResize() {
      setImgWidth(sizeImg.offsetWidth);
      setImgHeight(sizeImg.offsetHeight);
    }

    if (mounted.current) {
      window.addEventListener("resize", handleResize);
    }
    return () => (mounted.current = false);
  }, []);

  return (
    <div className="row justify-content-center">
      <p>{question.question}</p>
      <div className="position-relative">
        <Stage
          width={imgWidth}
          height={imgHeight}
          className="position-absolute top-0"
        >
          <Layer>
            {areas.length > 0 &&
              areas.map((area, i) => {
                return (
                  <Area
                    key={i}
                    area={area}
                    imgWidth={imgWidth}
                    imgHeight={imgHeight}
                    editable={false}
                  />
                );
              })}
          </Layer>
        </Stage>
        <img
          ref={imgRef}
          src={url}
          alt="Header"
          className="boxImg responsiveImg"
          onLoad={(e) => {
            const image = e.target;
            const { offsetHeight, offsetWidth } = image;
            setImgWidth(offsetWidth);
            setImgHeight(offsetHeight);
          }}
        />
      </div>
    </div>
  );
}
