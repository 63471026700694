import { getURL, getBaseURL, setHeaders, setImageHeaders } from "../../../Apis/axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { openModal } from "../../Errors/Slices/errorSlice";


const fetchUsers = createAsyncThunk(
  "users/fetchUsers",
  async (orgId, thunkAPI) => {
    try {
      // Original code for retrieving orgid using session storage
      // const userLocal = JSON.parse(sessionStorage.getItem("user"));
      // const orgid = userLocal?.orgid;

      const Axios = getURL();
      const users = await Axios.get(
        `/organisations/${orgId}/users?fields=userid,email,firstname,lastname,username`,
        setHeaders()
      );
      return users.data.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.errormessage);
    }
  }
);

const fetchUser = createAsyncThunk(
  "users/fetchUser",
  async (name, thunkAPI) => {
    try {
      const userLocal = JSON.parse(sessionStorage.getItem("user"));
      const userId = userLocal?.userid;
      const Axios = getURL();
      const user = await Axios.get(`/logins/${userId}`, setHeaders());
      return user.data.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.errormessage);
    }
  }
);

const updateUser = createAsyncThunk(
  "users/updateUser",
  async (data, thunkAPI) => {
    try {
      const userLocal = JSON.parse(sessionStorage.getItem("user"));
      const userId = userLocal?.userid;
      const Axios = getURL();
      const user = await Axios.put(`/logins/${userId}`, data, setHeaders());
      thunkAPI.dispatch(
        openModal({
          message: "Your information has been correctly updated",
          severity: "success",
        })
      );
      return thunkAPI.dispatch(fetchUser());
    } catch (error) {
      thunkAPI.dispatch(
        openModal({
          message: error.response.data.errormessage,
          severity: "error",
        })
      );
      return thunkAPI.rejectWithValue(error.response.data.errormessage);
    }
  }
);

const updateProfileImage = createAsyncThunk(
  "users/updateProfileImage",
  async (data, thunkAPI) => {
    try {
      const userLocal = JSON.parse(sessionStorage.getItem("user"));
      const userId = userLocal?.userid;
      const Axios = getBaseURL();
      let requestBody = {
        "image": data,
        "mimetype": "image/jpeg"
      };

      const response = await Axios.post(`/logins/${userId}/profileimagebsf`, requestBody, setImageHeaders());
      thunkAPI.dispatch(
        openModal({
          message: "Your profile image has been updated",
          severity: "success",
        })
      );
      return thunkAPI.dispatch(fetchUser());
    } catch (error) {
      thunkAPI.dispatch(
        openModal({
          message: error.response.data.errormessage,
          severity: "error",
        })
      );
      return thunkAPI.rejectWithValue(error.response.data.errormessage);
    }
  }
);

const deleteProfileImage = createAsyncThunk(
  "users/deleteProfileImage",
  async (thunkAPI) => {
    try {
      const userLocal = JSON.parse(sessionStorage.getItem("user"));
      const userId = userLocal?.userid;
      const Axios = getBaseURL();
      const response = await Axios.delete(`/logins/${userId}/profileimagebsf`, setImageHeaders());
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.errormessage);
    }
  }
);

const addUser = createAsyncThunk("users/addUser", async (name, thunkAPI) => {
  try {
    const Axios = getURL();
    const users = await Axios.post("/users", name, setHeaders());
    return users.data.data;
  } catch (error) {
    thunkAPI.dispatch(
      openModal({
        message: error.response.data.errormessage,
        severity: "error",
      })
    );
    return thunkAPI.rejectWithValue(error.response.data.errormessage);
  }
});

export { fetchUsers, fetchUser, addUser, updateUser, updateProfileImage };