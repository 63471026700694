/* REACT PACKAGE IMPORTS */
import { useEffect, useRef, useState } from "react";
import { Container } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";

/* INTERNAL IMPORTS */
import Header from "../Components/Header";
import SubNavbar from "../Components/SubNavbar";
import Overview from "../Components/Profile/Overview";
import Imagery from "../Components/Imagery";
import FormComponent from "../Components/Form";

import {
  fetchOrganisationsByUser,
  fetchOrganisationByUser,
} from "../Features/Auth/Actions/AuthActions";
import { fetchUser, updateUser } from "../Features/Users/Actions/UsersActions";

import edit from "../assets/group-edit.svg";
import "../styles/Profile.css";


export default function Profile({ paragraph, subParagraph, title, tabs }) {
  

  const dispatch = useDispatch();
  const mounted = useRef(null);

  const { organisation, organisations } = useSelector(store => store.auth);
  const { user } = useSelector(store => store.users);
  const inputFields = [
    {
      label: "Title",
      type: "text",
      id: "title",
      value: user.title,
      required: true,
    },
    {
      label: "First name",
      type: "text",
      id: "firstname",
      value: user.firstname,
      required: true,
    },
    {
      label: "Last name",
      type: "text",
      id: "lastname",
      value: user.lastname,
      required: true,
    },
    {
      label: "Telephone number",
      type: "telephone",
      id: "telephone",
      value: user.telephone,
      required: false,
    },
    {
      label: "Mobile number",
      type: "telephone",
      id: "mobilephone",
      value: user.mpn,
      required: false,
    },
    {
      label: "Country",
      type: "text",
      id: "country",
      value: user.country.name,
      required: false,
    },
    {
      label: "Region",
      type: "text",
      id: "region",
      value: user.region,
      required: false,

    },
    {
      label: "Locality",
      type: "text",
      id: "locality",
      value: user.locality,
      required: false,

    },
    {
      label: "Address line 1",
      type: "text",
      id: "address1",
      value: user.address1,
      required: false,
    },
    {
      label: "Address line 2",
      type: "text",
      id: "address2",
      value: user.address2,
      required: false,
    },
    {
      label: "Postal code",
      type: "text",
      id: "postcode",
      value: user.postcode,
      required: false,
    },
  ];

  const [isFormVisible, setFormVisible] = useState(false);
  const [apiCalled, setApiCalled] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [radioValue, setRadioValue] = useState("1");
  const [data, setData] = useState([]);
  const [imagesData, setImagesData] = useState([]);
  const [isCalled, setIsCalled] = useState(false);
  const [component, setComponent] = useState("");

  const handleChange = e => setRadioValue(e.currentTarget.value);

  const updateProfileHandler = (userState) => {
    if (mounted.current) {
      dispatch(updateUser(userState));
      setFormVisible(false);
    }
  };

  useEffect(() => {
    mounted.current = true;
    if (mounted.current) {
      document.title = "Profile";
      if (!apiCalled) {
        setData({
          // tags: ["VICTVS", "VX", "profile"],
        });
        setApiCalled(true);
      }

      if (radioValue === "3") setComponent(<Imagery data={imagesData} />);
      else if (radioValue === "4") setComponent(<div>Notes</div>);
      else if (radioValue === "5") setComponent(<div>Documents</div>);
      setIsLoading(false);
    }
    return () => (mounted.current = false);
  }, [radioValue, apiCalled]);

  useEffect(() => {
    mounted.current = true;
    if (organisations.length > 0) return;
    if (mounted.current) dispatch(fetchOrganisationsByUser());
    return () => mounted.current = false;
  }, [organisations]);

  useEffect(() => {
    mounted.current = true;
    if (Object.keys(organisation).length > 0) return;
    if (mounted.current) dispatch(fetchOrganisationByUser());
    return () => mounted.current = false;
  }, [organisation]);

  useEffect(() => {
    mounted.current = true;
    if (Object.keys(user).length > 0) {
      setIsCalled(true);
      return;
    };
    if (mounted.current) dispatch(fetchUser());
    return () => mounted.current = false;
  }, [user, dispatch]);

  return isLoading ? (
    <div className="loader-wrapper position-fixed"></div>
  ) : (
    <Container className="py-4 position-relative">
      <Header paragraph={paragraph} title={title} subParagraph={subParagraph}>
        <SubNavbar
          radioValue={radioValue}
          tabs={tabs}
          handleChange={handleChange}
          className=""
        />
      </Header>
      {
        radioValue === "1" ?
          isCalled && (
          <Overview data={data}>
              <FormComponent
                title="Update profile"
                multipleValues={[]}
                selects={[]}
                inputs={inputFields}
                multipleSelects={[]}
                button="Update profile"
                logo={edit}
                logoClass="position-absolute top-0 start-100 translate-middle button-edit"
                action={updateProfileHandler}
                isFormVisible={isFormVisible}
                setFormVisible={setFormVisible}
              />
          </Overview>
          ) : 
          apiCalled ? 
            component
            : ""
      }
    </Container>
  );
}
