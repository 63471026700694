/* REACT PACKAGE IMPORTS */
import { useEffect, useState } from "react";
import { Spinner } from "react-bootstrap";
import { useDispatch } from "react-redux";

/* MUI PACKAGE IMPORTS */
import Chip from "@mui/material/Chip";
import SmartToyIcon from '@mui/icons-material/SmartToy';

/* INTERNAL IMPORTS */
import { openModal } from "../../Features/Errors/Slices/errorSlice";
import { fetchQuestionText } from "../../Features/AI/Actions/AIActions";
import "../../styles/Assistant.css"


function LoadingSpinner() {
  return (
      <Spinner 
        animation="border"
        role="status" 
        size='sm'
        style={{ width: ".75rem", height: ".75rem" }}
      />
  );
};

export default function AssistantButton({ setOutputFieldValue }) {
  const [outputArray, setOutputArray] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();

  const handleClick = (name) => {
    const topic = outputArray.filter(topic => topic.topic_name === name)[0];
    navigator.clipboard.writeText(topic.answer);
    setOutputFieldValue(topic.example_question);
    dispatch(
    openModal({
        message: `Copied Related Answer to Clipboard`,
        severity: "info"
      })
    );
  };

  const topics = 
  <div className="d-flex flex-wrap justify-content-end">
    {
      outputArray
        .map(topic => {
          return <Chip 
            label={topic.topic_name} 
            size="small" 
            className="m-1" 
            clickable
            onClick={() => handleClick(topic.topic_name)}
          />
        })
    }
  </div>

  const handleAIcall = () => {
    setIsLoading(true);
    fetchQuestionText()
      .then(response => {
        const responseObject = JSON.parse(response);
        setOutputArray(responseObject.topic_suggestions);
        setIsLoading(false);
      });
  };


  return (
    <>
      <div
        className="my-2 d-flex flex-row justify-content-end align-items-center"
        onClick={handleAIcall}
      >
        <SmartToyIcon fontSize="sm"/>
        <p className="underlined-link-medium m-0 mx-1">
          Want an AI Suggestion?
        </p>
        { isLoading ? <LoadingSpinner /> : null }
      </div>
      { 
        outputArray.length > 0 &&
        topics
      }
    </>
  );

};