/* REACT PACKAGE IMPORTS */
import { useEffect, useState, useRef } from "react";
import { Container, Form, Button } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";

/* INTERNAL IMPORTS */
import { fetchNotes, addNotes } from "../Features/Notes/Actions/NotesActions";
import { openModal } from "../Features/Errors/Slices/errorSlice";
import Note from "./Note";


export default function Notes({ type, id, title }) {
  const dispatch = useDispatch();

  const { notes } = useSelector(store => store.notes);

  const [isCalled, setIsCalled] = useState(false);
  const [note, setNote] = useState("");

  const mounted = useRef(null);
  useEffect(() => {
    mounted.current = true;
    if (isCalled) return;
    if (mounted.current) {
      dispatch(fetchNotes({ type, id }))
        .then(() => setIsCalled(true));
    }
    return () => mounted.current = false;
  }, [id, type, notes, dispatch, isCalled]);


  const handleSaveNote = () => {
    const data = { notetext: note };
    if (note === "") {
      dispatch(
        openModal({
          message: `Note cannot be left blank`,
          severity: "warning"
        })
      );
    } else {
      dispatch(addNotes({ type, id, data }));
      setNote("");
    }
  };


  return (
    <Container className="position-relative p-0">

      <Form.Group
        className="Box background-secondary my-3 box"
        controlId="adminnotes"
      >
        <h2 className="fw-bold">{title}</h2>
        {
          notes.length ?
            <ul className="note-list mb-5">
              {notes.map((note, i) => <Note key={i} note={note} id={id} type={type} />)}
            </ul> :
            <p>
              <em className="text-muted mb-3">There are no notes to display</em>
              <hr />
            </p>
        }
        <h3 className="fw-bold fs-5 mb-3">Add New Note</h3>
        <Form.Control
          as="textarea"
          rows={3}
          value={note}
          className="mb-3"
          onChange={e => setNote(e.target.value)}
          aria-label="Notes Text Area"
        />
        <Button variant="dark" onClick={handleSaveNote}>
          Add note
        </Button>
      </Form.Group>
    </Container>
  );
}
