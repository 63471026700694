import { createAsyncThunk } from "@reduxjs/toolkit";
import { openModal } from "../../Errors/Slices/errorSlice";


const startWalkthrough = createAsyncThunk(
  "walkthroughs/startWalkthrough",
  async (steps) => {
    
    const walkthroughObject = {
      steps: steps,
      run: true
    };

    return walkthroughObject;
  }
);

const endWalkthrough = createAsyncThunk(
  "walkthroughs/endWalkthrough",
  async () => {
    const resetObject = {
      run: false,
      stepIndex: 0
    }

    return resetObject;
  }
);

export { startWalkthrough, endWalkthrough };