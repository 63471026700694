import { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { uploadImg } from "../Features/Uploads/Actions/UploadsActions";
import { addQuestionHeaderImage } from "../Features/Questions/Actions/QuestionActions";
import { fetchImgs } from "../Features/Uploads/Actions/UploadsActions";
import { Form, Button, InputGroup, Modal } from "react-bootstrap";
import { openModal } from "../Features/Errors/Slices/errorSlice";
import Lightbox from "yet-another-react-lightbox";
import Thumbnails from "yet-another-react-lightbox/plugins/thumbnails";
import Captions from "yet-another-react-lightbox/plugins/captions";
import "yet-another-react-lightbox/plugins/captions.css";
import "yet-another-react-lightbox/styles.css";
import "yet-another-react-lightbox/plugins/thumbnails.css";

export default function LightBox({ setUrl, qhid }) {
  // To open the lightbox change the value of the "toggler" prop.
  const [toggler, setToggler] = useState(false);
  const [show, setShow] = useState(false);
  const [src, setSrc] = useState(null);
  const [imid, setImid] = useState(null);
  const [newFile, setNewFile] = useState({});
  const [sources, setSources] = useState([]);
  const mounted = useRef(null);
  const dispatch = useDispatch();

  /* useEffect functions */
  useEffect(() => {
    mounted.current = true;
    if (mounted.current) {
      dispatch(fetchImgs()).then((response) => {
        setSources(() => {
          const images = response.payload;
          return images.map((img) => {
            const { direct_url, filename, filedesc, imid } = img;
            return {
              src: direct_url,
              imid: imid,
              alt: filename,
              title: filename,
              description: filedesc,
            };
          });
        });
      });
    }
    return () => (mounted.current = false);
  }, []);

  /* Handler functions */
  const handleClickGallery = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setToggler(true);
  };

  const handleFileChange = (e) => {
    // Allowing file type
    const fileObj = e.target.files && e.target.files[0];
    if (!fileObj) {
      return;
    }

    var allowedImgExtensions = /(\.jpg|\.jpeg|\.png|\.gif)$/i;
    const fileSize = fileObj.size / 1024 / 1024; // in MiB
    if (fileSize > 120) {
      dispatch(
        openModal({
          message: "File size exceeds 120 MiB",
          severity: "error",
        })
      );
      setUrl("");
      // 👇️ reset file input
      e.target.value = null;
    } else {
      const newFile = URL.createObjectURL(fileObj);
      let reader = new FileReader();
      reader.onload = function () {
        var base64data = reader.result;
        setNewFile(() => {
          return {
            url: newFile,
            filedesc: fileObj.name,
            filename: fileObj.name,
            filecontents: base64data,
          };
        });
        if (allowedImgExtensions.exec(fileObj.name)) {
          setUrl(newFile);
        }
      };
      reader.readAsDataURL(fileObj);
      reader.onerror = function (error) {
        dispatch(
          openModal({
            message: error,
            severity: "error",
          })
        );
      };
    }
  };

  const handleUpload = () => {
    // Allowing file type
    var allowedImgExtensions = /(\.jpg|\.jpeg|\.png|\.gif)$/i;

    if (allowedImgExtensions.exec(newFile.filename)) {
      dispatch(uploadImg(newFile))
        .then((response) => {
          const imid = Number(response.payload);
          if (imid) {
            dispatch(addQuestionHeaderImage({ qhid, imid }));
          }
        });
    }
  };

  

  return (
    <>
      <Form.Group
        className="mb-3 text-start d-flex flex-column"
        controlId="exampleForm.ControlInputFile"
      >
        <Form.Label>Select an image from the gallery</Form.Label>
        <Button variant="dark" onClick={handleClickGallery}>
          Gallery images
        </Button>
      </Form.Group>
      <div className="my-2 fs-6 text-center">OR</div>
      <Form.Group
        className="mb-3 text-start"
        controlId="exampleForm.ControlInputFile"
      >
        <Form.Label>Upload your own image</Form.Label>
        <InputGroup>
          <Form.Control type="file" multiple onChange={handleFileChange} />
          <Button
            variant="dark"
            onClick={handleUpload}
          >
            Upload Image
          </Button>
        </InputGroup>
        <Modal show={show} onHide={() => setShow(false)}>
          <Modal.Header>
            <Modal.Title>Hotspot Image Selection</Modal.Title>
          </Modal.Header>
          <Modal.Body>Are you sure you want to use this image?</Modal.Body>
          <Modal.Footer className="d-flex justify-content-between">
            <Button
              variant="secondary"
              onClick={() => {
                setSrc(null);
                setImid(null);
                setShow(false);
              }}
            >
              Cancel
            </Button>
            <Button
              variant="primary"
              onClick={() => {
                setUrl(src);
                dispatch(addQuestionHeaderImage({ qhid, imid }))
                  .then(() => {
                    setSrc(null);
                    setImid(null);
                    setShow(false);
                  });
              }}
            >
              Select
            </Button>
          </Modal.Footer>
        </Modal>
        <Lightbox
          open={toggler}
          close={() => setToggler(false)}
          slides={sources}
          plugins={[Thumbnails, Captions]}
          on={{
            click: (i) => {
              const { src, imid } = sources[i];
              setSrc(src);
              setImid(imid);
              setShow(true);
              setToggler(false);
            },
          }}
        />
      </Form.Group>
    </>
  );
}
