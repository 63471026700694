/* REACT PACKAGE IMPORTS */
import { useEffect, useState, useRef } from "react";
import { Container } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";

/* INTERNAL IMPORTS */
import DataTable from "../Components/DataTable";
import SubNavbar from "../Components/SubNavbar";
import Header from "../Components/Header";
import FormBuilder from "../Components/FormComponents/FormBuilder";
import { LocalDateTime } from "../Components/DateTimeFormatter";

import { setIsLoading } from "../Features/Auth/Slices/authSlice";
import {
  fetchSchedules,
  fetchTodaySchedules,
  fetchOwnerSchedules,
  createSchedule,
} from "../Features/Sessions/Actions/SessionsActions";
import { fetchAssessments } from "../Features/Assessments/Actions/AssessmentsActions";
import { fetchVenues } from "../Features/Venues/Actions/VenuesActions";

import plus from "../assets/plus.svg";


export default function Sessions({ paragraph, subParagraph, title, tabs }) {
  const dispatch = useDispatch();
  const initialPagination = {
    pageSize: 20,
    page: 0,
  };

  const { assessmentsSelect } = useSelector(state => state.assessments);
  const { venuesSelect } = useSelector(state => state.venues);
  const { isLoading } = useSelector(store => store.auth);

  const [paginationModel, setPaginationModel] = useState(initialPagination);
  const [displaySessions, setDisplaySessions] = useState([]);
  const [formVisible, setFormVisible] = useState(false);

  /* NEW FORM STATE */
  const [isFormVisible, setIsFormVisible] = useState(false);
  const venuesOptions = [{ value: "", label: "VICTVS (Remote)" }, ...venuesSelect];

  const formFieldsArray = [
    {
      fieldCategory: "input",
      id: "session-title-field",
      label: "Title",
      message: "",
      value: "",
      type: "text",
      isRequired: true,
      validFeedback: "Great"
    },
    {
      fieldCategory: "textArea",
      id: "session-description-field",
      label: "Description (Optional)",
      message: "",
      value: "",
      rows: 3,
      type: "text",
      isRequired: false,
      validFeedback: "Great"
    },
    {
      fieldCategory: "dropdown",
      id: "assessment-title-field",
      label: "Assessment Title",
      message: "",
      value: "",
      options: assessmentsSelect,
      type: "text",
      isRequired: true,
      invalidFeedback: "This is not Great"
    },
    {
      fieldCategory: "dropdown",
      id: "venue-field",
      label: "Venue",
      message: "",
      value: "",
      options: venuesOptions,
      type: "text",
      isRequired: true,
      invalidFeedback: "This is not Great"
    },
    {
      fieldCategory: "input",
      id: "session-start-time-field",
      label: "Start Time (UTC)",
      message: "",
      value: "",
      type: "datetime-local",
      isRequired: true,
      validFeedback: "Great"
    },
    {
      fieldCategory: "input",
      id: "session-leeway-time-field",
      label: "Leeway Time (UTC)",
      message: "",
      value: "",
      type: "datetime-local",
      isRequired: true,
      validFeedback: "Great"
    },
    {
      fieldCategory: "switch",
      id: "show-answer-field",
      label: "Show Results",
      message: "",
      value: false,
      type: "switch",
      isRequired: false,
      invalidFeedback: "This is not Great"
    },
  ];

  const [sessionsLoaded, setSessionsLoaded] = useState(false);
  const [showAssessments, setShowAssessments] = useState(false);
  const [showVenues, setShowVenues] = useState(false);
  const [radioValue, setRadioValue] = useState("1");
  const [isTabChange, setIsTabChange] = useState(false);

  const mounted = useRef(null);

  const handleSubnavChange = (e) => {
    let value = e.currentTarget.value;
    setIsTabChange(!isTabChange);
    setRadioValue(value);
    setPaginationModel({
      pageSize: 10,
      page: 0,
    });
    dispatch(setIsLoading(true));
  };

  const columns = [
    {
      field: "title",
      headerName: "Title",
      flex: 2,
    },
    {
      field: "description",
      headerName: "Description",
      flex: 2,
    },
    {
      field: "pass_mark",
      headerName: "Pass mark",
      headerAlign: "center",
      align: "center",
      width: 90,
      valueGetter: (params) => params?.row?.exam?.score,
    },
    {
      field: "starttime",
      headerName: "Start time",
      type: "date",
      flex: 2,
      valueFormatter: ({ value }) => LocalDateTime(value),
    },
    {
      field: "time_limit",
      headerName: "Time limit",
      headerAlign: "center",
      align: "center",
      width: 80,
      valueGetter: (params) => params?.row?.exam?.duration?.stringshort,
    },
    {
      field: "created",
      headerName: "Created",
      flex: 2,
      valueFormatter: ({ value }) => LocalDateTime(value),
    },
    {
      field: "createdby",
      headerName: "Owner",
      flex: 2,
      valueGetter: ({ value }) => {
        return value.firstname + " " + value.lastname;
      },
    },
    {
      field: "status",
      headerName: "Status",

      width: 90,
      valueFormatter: ({ value }) =>
        value.charAt(0).toUpperCase() + value.slice(1),
    },
  ];

  const createScheduleHandler = (data) => {
    if (mounted.current) {
      dispatch(createSchedule(data)).unwrap();
      setFormVisible(false);
      setIsFormVisible(false);
    }
  };

  const renderSessionsHelper = (response) => {
    setSessionsLoaded(true);
    setDisplaySessions(response.payload);
    dispatch(setIsLoading(false));
  };

  useEffect(() => {
    mounted.current = true;
    if (mounted.current) dispatch(setIsLoading(true));
    return () => mounted.current = false;
  }, []);

  useEffect(() => {
    mounted.current = true;
    if (!isLoading) return;
    if (mounted.current) {
      // const signal = abortControllerRef.current.signal;
      const requestBody = {
        paginationModel: paginationModel,
        // signal: signal
      }
      document.title = "Sessions";
      dispatch(setIsLoading(true));
      setSessionsLoaded(false);
      if (radioValue === "1") {
        dispatch(fetchTodaySchedules(requestBody))
          .then(response => renderSessionsHelper(response));
      } else if (radioValue === "2") {
        dispatch(fetchSchedules(requestBody))
          .then(response => renderSessionsHelper(response));
      } else {
        dispatch(fetchOwnerSchedules(requestBody))
          .then(response => renderSessionsHelper(response));
      }
    }
    return () => mounted.current = false;
  }, [dispatch, isLoading, paginationModel]);

  useEffect(() => {
    mounted.current = true;
    if (showAssessments) return;
    if (mounted.current) dispatch(fetchAssessments()).then(() => setShowAssessments(true));
    return () => mounted.current = false;
  }, [showAssessments, dispatch]);

  useEffect(() => {
    mounted.current = true;
    if (showVenues) return
    if (mounted.current) dispatch(fetchVenues()).then(() => setShowVenues(true));
    return () => (mounted.current = false);
  }, [showVenues, dispatch]);

  return (
    <Container className="py-4 position-relative">
      <Header subParagraph={subParagraph} paragraph={paragraph} title={title}>
        <SubNavbar
          radioValue={radioValue}
          tabs={tabs}
          handleChange={handleSubnavChange}
        />
      </Header>
      <DataTable
        columns={columns}
        rows={displaySessions}
        type={"sessions"}
        loading={isLoading}
        radioValue={radioValue}
        getRowId={displaySessions.length <= 0 ? null : row => row.esid}
        setPaginationModel={setPaginationModel}
        paginationModel={paginationModel}
      />
      <Container className="text-end">
        <img
          className="plus position-fixed"
          src={plus}
          alt="Icon"
          onClick={() => setIsFormVisible(true)}
          style={{
            opacity: isLoading ? 0.5 : 1,
            cursor: isLoading ? "wait" : "pointer",
            pointerEvents: "all",
          }}
        />
      </Container>
      <FormBuilder
        isFormVisible={isFormVisible}
        setIsFormVisible={setIsFormVisible}
        formTitle={"Create Session"}
        formAction={createScheduleHandler}
        fieldData={formFieldsArray}
      />
    </Container>
  );
}
