import { createSlice } from "@reduxjs/toolkit";
import {
  uploadImg,
  fetchImg,
  uploadVideo,
  fetchVideo,
  fetchImgs,
} from "../Actions/UploadsActions";
const initialState = {
  uploads: [],
  downloads: [],
  uploadId: null,
  images: [],
  status: "idle", //'idle' | 'loading' | 'succeeded' | 'failed'
  error: null,
};

const uploadsSlice = createSlice({
  name: "uploads",
  initialState,
  reducers: {
    hideError(state, action) {
      return {
        ...state,
        status: "idle",
        error: null,
      };
    },
  },
  extraReducers(builder) {
    builder
      .addCase(uploadImg.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(uploadImg.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.uploadId = action.payload;
      })
      .addCase(uploadImg.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })
      .addCase(fetchImg.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(fetchImg.fulfilled, (state, action) => {
        state.status = "succeeded";
        const ids = state.downloads.map((download) => download.id);
        if (!ids.includes(action.payload.id)) {
          state.downloads.push(action.payload);
        }
      })
      .addCase(fetchImg.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })
      .addCase(uploadVideo.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(uploadVideo.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.uploadId = action.payload;
      })
      .addCase(uploadVideo.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })
      .addCase(fetchVideo.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(fetchVideo.fulfilled, (state, action) => {
        state.status = "succeeded";
        const ids = state.downloads.map((download) => download.id);
        if (!ids.includes(action.payload.id)) {
          state.downloads.push(action.payload);
        }
      })
      .addCase(fetchVideo.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })
      .addCase(fetchImgs.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(fetchImgs.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.images = action.payload;
      })
      .addCase(fetchImgs.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      });
  },
});

export const { hideError } = uploadsSlice.actions;

export default uploadsSlice.reducer;
