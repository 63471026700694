import { createSlice } from "@reduxjs/toolkit";
import { dynamicSort } from "../../../utils/tools.js";
import {
  fetchUsers,
  addUser,
  updateUser,
  fetchUser,
} from "../Actions/UsersActions";
const initialState = {
  users: [],
  user: {},
  usersSelect: [],
  status: "idle", //'idle' | 'loading' | 'succeeded' | 'failed'
  error: null,
};

const usersSlice = createSlice({
  name: "users",
  initialState,
  reducers: {
    hideError(state, action) {
      return {
        ...state,
        status: "idle",
        error: null,
      };
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchUsers.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(fetchUsers.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.users = action.payload;
        state.usersSelect = action.payload
          .sort(dynamicSort("firstname"))
          .map((user) => {
            return {
              value: user.userid,
              label: `${user.lastname}, ${user.firstname} :: ${user.email} :: ${user.username}`,
            };
          });
      })
      .addCase(fetchUsers.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })
      .addCase(fetchUser.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(fetchUser.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.user = action.payload;
      })
      .addCase(fetchUser.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
        state.user = {};
      })
      .addCase(updateUser.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(updateUser.fulfilled, (state, action) => {
        state.status = "succeeded";
        // state.user = action.payload;
      })
      .addCase(updateUser.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })
      .addCase(addUser.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(addUser.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.users = action.payload;
      })
      .addCase(addUser.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      });
  },
});

export const { hideError } = usersSlice.actions;
export default usersSlice.reducer;
