import React from "react";
import { Form } from "react-bootstrap";
import { Link } from "react-router-dom";

import ErrorLogo from "../assets/error/error.png";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    console.log(error);
    console.log(errorInfo);
    // logErrorToMyService(error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return (
        <div className="box position-absolute top-50 start-50 translate-middle d-flex align-items-center flex-column p-5">
          <h1 className="fs-2">Unexpected item in the bagging area</h1>
          <img 
            src={ErrorLogo} 
            style={{ width: '128px' }} 
            alt="Web error icon"
            className="my-3"
          />
          <h2 className="fs-4">Something went wrong...</h2>
          <div className="d-flex flex-column">
            <Form.Text>
              Back to the sign in page?
              <Link
                className="mx-2 text-decoration-none link-font-size"
                to="/login"
              >
                Candidate Sign In
              </Link>
            </Form.Text>
          </div>
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
