/* REACT PACKAGE IMPORTS */
import { useEffect } from "react";
import { Form } from "react-bootstrap";


export default function MultipleChoice({ question, answer, setUserAnswerArray }) {

  const optionsIdArray = [];


  const handleOnChange = (e) => {
    const value = parseInt(e.target.value);
    const isChecked = e.target.checked;

    if (isChecked && !optionsIdArray.includes(value)) optionsIdArray.push(value);
    else if (!isChecked && optionsIdArray.includes(value)) {
      const valueIndex = optionsIdArray.indexOf(value);
      optionsIdArray.splice(valueIndex, 1);
    }

    const newCurrentAnswer = {
      qid: question.qid,
      answer: { multichoice: optionsIdArray },
      qhid: question.qhid,
    };

    setUserAnswerArray((current) => {
      let previousAnswer = current.filter(answer => answer.qid === question.qid);
      if (previousAnswer.length > 0) {
       
        const newChecked = [...previousAnswer[0]?.answer?.multichoice, value];
        if (isChecked) {
          newCurrentAnswer.answer = { multichoice: newChecked };
        } else {
          const index = previousAnswer[0]?.answer?.multichoice.indexOf(value);
          newCurrentAnswer.answer = { multichoice : [...previousAnswer[0]?.answer?.multichoice] };
          newCurrentAnswer.answer?.multichoice.splice(index, 1);
        }
      } else {
        if (isChecked) newCurrentAnswer.answer = { multichoice: [value] };
      }

      let currentAnswers = current.filter(answer => answer.qid !== question.qid);
      return [...currentAnswers, newCurrentAnswer];
    });
  };

  return (
    <div>
      <p className="fs-4 mb-4"><span className="fw-bold">Question</span>: {question.question}</p>
      <p className="fs-6 fst-italic m-1 text-muted">Select the correct option(s) from below</p>
      {
        <ol className={"list-group d-flex flex-row flex-wrap row px-3"}>
          {
            question.options &&
            question.options.map((option, i) => {
              let defaultChecked = answer.length > 0 ?
                answer[0].answer?.multichoice?.includes(option.qoid) :
                false;

              return (
                <li
                  key={i}
                  className={
                    option.optimage ?
                      "list-group-item flex-fill col-3" :
                      "list-group-item"
                  }
                >
                  <Form.Check
                    type={"checkbox"}
                    label={option.opttext ? option.opttext : ""}
                    aria-label={option.opttext || "Checkbox"}
                    name="singleAnswer"
                    value={option.qoid}
                    defaultChecked={defaultChecked}
                    onChange={e => handleOnChange(e)}
                  />
                  {
                    option.optimage &&
                    <img
                      key={parseInt(option.optimage.imid)}
                      style={{ width: "100%", objectFit: "cover" }}
                      src={option.optimage.direct_url}
                      alt="Question"
                    />
                  }
                </li>
              );
            })}
        </ol>
      }
    </div>
  );
}