/* REACT PACKAGE IMPORTS */
import { useState } from "react";

/* MUI PACKAGE IMPORTS */
import { IconButton } from "@mui/material";
import CalculateIcon from "@mui/icons-material/Calculate";
import NoteAddIcon from "@mui/icons-material/NoteAdd";

/* INTERNAL IMPORTS */
import '../../styles/Assessment.css';
import TextEditor from "../TextEditor";
import Calculator from "../Calculator/Calculator";


export default function AssessmentUtilities() {
  const [showCalculator, setShowCalculator] = useState(false);
  const [showEditor, setShowEditor] = useState(false);

  const iconButtonClass = "d-flex flex-column bg-dark-blue hover-pointer rounded p-2";

  return (
    <>
      <hr />
      <div className="d-flex flex-column justify-content-center align-items-center my-4">
        <p className="mb-2 fs-6 bg-dark rounded-pill p-2 px-3 text-white">Assessment Utilities</p>
        <div className="d-flex w-25 justify-content-around mt-3">
          <div
            className={iconButtonClass}
            onClick={() => setShowCalculator(!showCalculator)}
          >
            <IconButton
              aria-label="calculator"
              sx={{ color: 'white' }}
            >
              <CalculateIcon />
            </IconButton>
            <span className="text-white sm-icon-font">Calculator</span>
          </div>
          <div
            className={iconButtonClass}
            onClick={() => setShowEditor(!showEditor)}
          >
            <IconButton
              aria-label="notebook"
              sx={{ color: 'white' }}
            >
              <NoteAddIcon />
            </IconButton>
            <span className="text-white sm-icon-font">Notebook</span>
          </div>
        </div>
      </div>
      <Calculator show={showCalculator} setShow={setShowCalculator} />
      <TextEditor show={showEditor} setShow={setShowEditor} />
    </>
  );
};