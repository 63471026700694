import { useDroppable } from "@dnd-kit/core";
import { useState, useEffect } from 'react';

const Droppable = ({ id, value, setDroppablesState, children }) => {
  const { setNodeRef } = useDroppable({ id, data: children.props.value });
  // setDroppablesState((curr) => {
  //   if (!curr) return { [id]: children.props.value } ;
  //   else {
  //     console.log([curr, children.props.value]);
      
  //     return "";
  //   }
  // })
  // console.log(children.props.value);

  return <div ref={setNodeRef}>{children}</div>;
};

export default Droppable;
