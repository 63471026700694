import { useDraggable } from "@dnd-kit/core";
import { CSS } from "@dnd-kit/utilities";

const Draggable = ({ id, value, isHovering }) => {
  const { attributes, isDragging, transform, setNodeRef, listeners } =
    useDraggable({
      id,
      data: value,
    });

  const styleBox = {
    cursor: "grab",
    width: "auto",
    height: "auto",
    minWidth: "90px",
    padding: "0.5rem 1rem",
    backgroundColor: "white",
    marginRight: "0.5rem",
    marginLeft: "0.5rem",
    float: "left",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    border: isHovering ? "1px solid #4c9ffe" : "1px dashed gray",
    margin: 20,
    transform: CSS.Translate.toString(transform),
    borderRadius: "0.25rem",
    boxShadow: isDragging
      ? "-1px 0 15px 0 rgba(34, 33, 81, 0.01), 0px 15px 15px 0 rgba(34, 33, 81, 0.25)"
      : undefined,
  };

  return (
    <div ref={setNodeRef} style={styleBox} {...attributes} {...listeners}>
      {value}
    </div>
  );
};

export default Draggable;
