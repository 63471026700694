import { useRef, useEffect, useState } from "react";
import { Routes, Route } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
// Pages
import SessionsCandidate from "./SessionsCandidate";
import SessionCandidate from "./SessionCandidate";
import SessionCandidateCopy from "./SessionCandidateCopy";
import NoMatch from "./NoMatch";
import Profile from "./Profile";
import { timeAgo } from "../Components/DateTimeFormatter";
import { fetchUser } from "../Features/Users/Actions/UsersActions";

export default function Candidate() {
  const [paragraph, setParagraph] = useState("");
  const [title, setTitle] = useState("");

  const { user } = useSelector(store => store.users);

  const mounted = useRef(null);
  const dispatch = useDispatch();

  useEffect(() => {
    mounted.current = true;
    if (Object.keys(user).length === 0) {
      dispatch(fetchUser());
      return;
    }
    if (mounted.current) {
      if (user.lastlogin === null || user.lastlogin === "") {
        setTitle(`Welcome, ${user.firstname}`);
        setParagraph(`Select your assessement from the list below`);
      } else {
        setTitle(`Welcome back, ${user.firstname}`);
        setParagraph(`Last logged in ${timeAgo(user.lastlogin)}.`);
      }
    }
    return () => (mounted.current = false);
  }, [user]);

  return (
    <Routes>
      <Route path="/">
        <Route
          path="assessments"
          element={
            <SessionsCandidate
              tabs={[
                { name: "Today's assessments", value: "1" },
                { name: "My assessments", value: "2" },
              ]}
              paragraph={paragraph}
              title={title}
            />
          }
        />
        <Route path="assessments/:id" element={
          <SessionCandidate paragraph={paragraph} title={title} />} 
        />
        <Route
          path="profile"
          element={
            <Profile
              paragraph={paragraph}
              title={title}
              tabs={[{ name: "My profile", value: "1" }]}
            />
          }
        />
        <Route path="*" element={<NoMatch />} />
        {
          /* 
            Using path="*"" means "match anything", so this route
            acts like a catch-all for URLs that we don't have explicit
            routes for.
          */
        }
        <Route path="*" element={<NoMatch />} />
      </Route>
    </Routes>
  );
}
