/* REACT PACKAGE IMPORTS */
import { useEffect, useState, useRef } from "react";
import { Container } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

/* MUI PACKAGE IMPORTS */
import DeleteIcon from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";
import MoreTimeIcon from '@mui/icons-material/MoreTime';
import SpeakerNotesIcon from '@mui/icons-material/SpeakerNotes';
import Tooltip from "@mui/material/Tooltip";
import CancelIcon from "@mui/icons-material/Cancel";

/* INTERNAL IMPORTS */
import Modal from "../Modal";
import CenteredInputModal from "../CenteredInputModal";
import DataTable from "../DataTable";
import FormComponent from "../Form";
import Notes from "../Notes";
import UsersForm from "./UsersForm";
import plus from "../../assets/plus.svg";

import {
  fetchSessionsBySchedule,
  addNewSession,
  deleteSession,
  cancelSession,
  updateSession
} from "../../Features/Sessions/Actions/SessionsActions";
import { fetchUsers } from "../../Features/Users/Actions/UsersActions";



export default function Users() {
  const initialColumns = [
    {
      field: "esuid",
      headerName: "ID",
      width: 90,
    },
    {
      field: "firstname",
      headerName: "Full name",
      flex: 1,
      valueGetter: (params) =>
        params?.row?.user?.firstname + " " + params?.row?.user?.lastname,
    },
    {
      field: "country",
      headerName: "Country",
      flex: 1,
      valueGetter: (params) => params?.row?.user?.country?.shortname,
    },
    {
      field: "emailverified",
      headerName: "Verified",
      headerAlign: "center",
      align: "center",
      width: 90,
      valueGetter: (params) => params?.row?.user?.emailverified,
      renderCell: ({ value }) => (value ? "True" : "False"),
    },
    {
      field: "pin",
      headerName: "PIN",
      headerAlign: "center",
      align: "center",
      width: 90,
      renderCell: (params) => (
        <Tooltip title={'Click to copy'}>
          <span>{params.value}</span>
        </Tooltip>
      )
    },
    {
      field: "extratime",
      headerName: "Extra time",
      headerAlign: "center",
      align: "center",
      valueGetter: (params) =>
        params?.row?.extratime ? params?.row?.extratime?.string : "-",
      width: 90,
    },
    {
      field: "pausetime",
      headerName: "Pause time",
      headerAlign: "center",
      align: "center",
      valueGetter: (params) =>
        params?.row?.pausetime ? params?.row?.pausetime?.string : "-",
      width: 90,
    },
    {
      field: "status",
      headerName: "Status",
      headerAlign: "center",
      align: "center",
      valueGetter: ({ row, value }) => row.cancelled ? "Cancelled" : value[0].toUpperCase() + value.substring(1),
      width: 90,
    },
    {
      field: "ID",
      headerName: "",
      sortable: false,
      align: "center",
      width: 50,
      valueGetter: (params) => params?.id,
      renderCell: (params) => {
        const id = params?.id;
        const onClick = e => {
          e.stopPropagation();
          setCandidateName(params?.row?.user?.firstname + " " + params?.row?.user?.lastname);
          handleAdditionalTime(e, id)
        };
        
        return (
          <Tooltip title="Change allotted time">
            <IconButton
              aria-label="Change allotted time"
              onClick={onClick}
            >
              <MoreTimeIcon fontSize="small" />
            </IconButton>
          </Tooltip>
        );
      },
    },

    {
      field: "notes",
      headerName: "",
      sortable: false,
      align: "center",
      width: 50,
      valueGetter: (params) => params?.id,
      renderCell: ({ id }) => {
        const onClick = (e) => {
          e.stopPropagation(); // don't select this row after clicking
          setShowNotes((currShow) => {
            if (currShow) setSessionId(null);
            else setSessionId(id);
            return !currShow;
          });
        };
        return (
          <Tooltip title="Show notes">
            <IconButton aria-label="Show notes" onClick={onClick}>
              <SpeakerNotesIcon fontSize="small" />
            </IconButton>
          </Tooltip>
        );
      },
    },
    {
      field: "action",
      headerName: "",
      sortable: false,
      align: "center",
      width: 50,
      valueGetter: (params) => params?.row?.user.userid,
      renderCell: (params) => {
        const onClick = (e) => {
          e.stopPropagation(); // don't select this row after clicking
          setShowModal(true);
          setUserId(params?.row?.user?.userid);
          setMsg(
            `Are you sure you want remove "${params?.row?.user?.firstname} ${params?.row?.user?.lastname}" from the session?`
          );
          setButton("Delete");
        };
        return (
          <Tooltip title="Delete session">
            <IconButton aria-label="Delete session" onClick={onClick}>
              <DeleteIcon fontSize="small" />
            </IconButton>
          </Tooltip>
        );
      },
    },
    {
      field: "cancel",
      headerName: "",
      sortable: false,
      align: "center",
      width: 50,
      renderCell: ({ id }) => {
        const onClick = (e) => {
          e.stopPropagation(); // don't select this row after clicking
          setShowModal(true);
          setMsg("Are you sure you want to cancel this session?");
          setEsuid(id);
          setButton("Cancel");
        };
        return (
          <Tooltip title="Cancel session">
            <IconButton aria-label="Delete session" onClick={onClick}>
              <CancelIcon variant="dark" fontSize="small" />
            </IconButton>
          </Tooltip>
        );
      },
    },
  ];

  
  const { sessions } = useSelector(store => store.sessions);
  const { usersSelect } = useSelector(store => store.users);
  const orgid = useSelector(store => store?.sessions?.schedule?.exam.orgid);
  
  const [paginationModel, setPaginationModel] = useState({
    pageSize: 10,
    page: 0,
  });
  const [sessionId, setSessionId] = useState(null);
  const [candidateName, setCandidateName] = useState("default candidate")
  
  
  const [showNotes, setShowNotes] = useState(false);
  const [showTimerModal, setShowTimerModal] = useState(false)
  const [showModal, setShowModal] = useState(false);
  const [button, setButton] = useState("");
  const [msg, setMsg] = useState("");

  const [userId, setUserId] = useState(null);
  const [esuid, setEsuid] = useState(null);
  const [usersSelected, setUsersSelected] = useState([]);

  const [isFormVisible, setFormVisible] = useState(false);
  const [columns] = useState(initialColumns);

  const [rows, setRows] = useState([]);
  const [type] = useState("users");
  const [ID] = useState("esuid");
  const mounted = useRef(null);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  let { id } = useParams();
  const schId = parseInt(id);


  const INITIAL_PROVISIONAL_TIME = "00:00"

  const [extratime, setExtraTime] = useState(INITIAL_PROVISIONAL_TIME);
  const [pausetime, setPauseTime] = useState(INITIAL_PROVISIONAL_TIME);

  const handleExtraTime = value => setExtraTime(value);

  const timerInputFields = [
    {
      label: 'Extra Time',
      handleChange: handleExtraTime,
      value: extratime,
      type: 'Time',
      controlId: 'extratime'
    },
    {
      label: 'Pause Time Allowed',
      handleChange: newTime => setPauseTime(newTime),
      value: pausetime,
      type: 'Time',
      controlId: 'pausetime'
    },
  ];


  /* Handler Functions */
  const handleCancelSession = () => {
    dispatch(cancelSession({ esuid }));
    setShowModal(false);
  };

  const handleAddUserSession = (data) => {
    const users = data.session_users;
    const candidateIdArray = users.map(user => user.value);
    const requestData = { schId, candidateIdArray };
    dispatch(addNewSession(requestData)).unwrap();
    setFormVisible(false);
  };

  const handleDeleteSession = () => {
    if (mounted.current) {
      dispatch(deleteSession({ schId, userId }));
      setShowModal(false);
    }
  };

  const handleTimeFieldsReset = () => {
    setExtraTime(INITIAL_PROVISIONAL_TIME);
    setPauseTime(INITIAL_PROVISIONAL_TIME);
  };

  const handleAdditionalTime = (e, id) => {
    if (mounted.current) {
      e.stopPropagation();

      if (id !== sessionId) {
        setSessionId(id);
        setShowTimerModal(true);
      };
    };
  };

  const handleSubmit = () => {

  };

  const handleTimeSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();
    const sessionState = { pausetime, extratime };
    dispatch(updateSession({ sessionId, schId, sessionState }));
    //handleTimeFieldsReset();
  };


  /* useEffect functions */
  useEffect(() => {
    mounted.current = true;
    if (mounted.current) {
      if (isNaN(schId)) navigate("/admin/error", { replace: true });
      else {
        dispatch(fetchSessionsBySchedule(schId))
          .then(({ payload }) => {
            const sessions = payload;
            // const users = sessions.map(({ user }) => {
            //   if (!user) return null;
            //   return {
            //       value: user.userid,
            //       label: user.firstname + " " + user.lastname,
            //   };
            // });
            setRows(sessions);
            // setUsersSelected(users);
          });
      };
    };

    return () => mounted.current = false;
  }, []);


  // Refetch the correct organisation users for add candidates feature.
  useEffect(() => {
    mounted.current = true;
    if (usersSelect.length > 0) return;
    if (mounted.current) dispatch(fetchUsers(orgid));
    return () => mounted.current = false;
  }, [usersSelect, dispatch]);

  useEffect(() => {
    mounted.current = true;
    if (mounted.current) {
      setRows(sessions);
      setUsersSelected(() =>
        sessions.map(session => {
          return {
            value: session.user.userid,
            label: session.user.firstname + " " + session.user.lastname,
          };
        })
      );
    }
  }, [sessions]);


  return (
    <div>
      <DataTable
        columns={columns}
        rows={rows}
        type={type}
        getRowId={row => row[ID]}
        selected={sessionId}
        setPaginationModel={setPaginationModel}
        paginationModel={paginationModel}
      />
      {
        sessions.length > 0 &&
        sessions.map((session, i) => {
          return (
            session.esuid === sessionId &&
            showNotes &&
            <Notes key={i} id={session.esuid} type="examsessions" />
          );
        })
      }
      <FormComponent
        title="Add candidates"
        selects={[]}
        inputs={[]}
        multipleSelects={[
          {
            label: "Candidates",
            type: "multipleSelect",
            id: "session_users",
            value: usersSelected,
            options: usersSelect,
            setValue: setUsersSelected,
          },
        ]}
        multipleValues={{
          label: "New candidates",
          type: "multipleValues",
          id: "invitation",
          state: {
            inputValue: "",
            value: [],
          },
          order: 2,
        }}
        button="Add candidate"
        logo={plus}
        logoClass="plus position-fixed"
        action={handleAddUserSession}
        isFormVisible={isFormVisible}
        setFormVisible={setFormVisible}
        isLoading={usersSelect.length === 0}
      />
      {/* <UsersForm
        onSubmit={handleSubmit}
        users={usersSelect}
      /> */}
      <Modal
        setShowModal={setShowModal}
        show={showModal}
        handleSubmit={button === "Delete" ? handleDeleteSession : handleCancelSession}
        msg={msg}
        button={button}
      />
      <CenteredInputModal
        setShowModal={setShowTimerModal}
        showModal={showTimerModal}
        modalTitle={"Add session time"}
        modalHeading={`${candidateName} session`}
        message={msg}
        handleSubmit={handleTimeSubmit}
        buttonText={"Apply Changes"}
        inputFields={timerInputFields}
      />
    </div>
  );
}
