const Item = ({ children, isHovering, id }) => {
  const styleBox = {
    width: "auto",
    height: "auto",
    minWidth: "90px",
    padding: "0.5rem 1rem",
    marginRight: "0.5rem",
    marginLeft: "0.5rem",
    fontSize: ".9rem",
    fontWeight: "bold",
    backgroundColor: "#fff",
    border: "1px solid #ced4da",
    borderRadius: "0.25rem",
    margin: 20,
    borderColor: isHovering ? "#4c9ffe" : "#EEE",
  };

  return <div style={styleBox}>{children}</div>;
};

export default Item;
