/* REACT PACKAGE IMPORTS */
import { useEffect } from "react";
import { Form } from "react-bootstrap";


export default function SingleChoice({ question, answer, setUserAnswerArray }) {
  
  const handleOnChange = (e) => {
    const value = parseInt(e.target.value);
    if (!question.qid) console.log(question.qid);

    const newCurrentAnswer = {
      qid: question.qid,
      answer: { singlechoice: value },
      qhid: question.qhid,
    };
 
    setUserAnswerArray((current) => {
      let currentAnswers = current.filter(answer => answer.qid !== question.qid);
      
      return [...currentAnswers, newCurrentAnswer];
    });

  };

  return (
    <div>
      <p className="fs-4 mb-4"><span className="fw-bold">Question</span>: {question.question}</p>
      <p className="fs-6 fst-italic m-1 text-muted">Select a single option from below</p>
      {
        <ol className={"list-group d-flex flex-row flex-wrap row px-3"}>
          {
            question.options &&
            question.options.map((option, i) => {       
              let defaultChecked = answer.length > 0 ?
                answer[0]?.answer?.singlechoice === option.qoid :
                false;
              return (
                <li
                  key={i}
                  className={
                    option.optimage ?
                      "list-group-item flex-fill col-3" :
                      "list-group-item"
                  }
                >
                  <Form.Check
                    type={"radio"}
                    label={option.opttext ? option.opttext : ""}
                    aria-label={option.opttext || "Checkbox"}
                    name="singleAnswer"
                    value={option.qoid}
                    defaultChecked={defaultChecked}
                    onChange={event => handleOnChange(event)}
                  />
                  {
                    option.optimage &&
                    <img
                      key={parseInt(option.optimage.imid)}
                      style={{ width: "100%", objectFit: "cover" }}
                      src={option.optimage.direct_url}
                      alt="Question"
                    />
                  }
                </li>
              );
            })
          }
        </ol>
      }
    </div>
  );
}