import { useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";
import {
  ZoomableGroup,
  ComposableMap,
  Geographies,
  Geography,
  Graticule,
} from "react-simple-maps";

export default function HotspotMap({ setAnswer, question }) {
  const { options } = useSelector((store) => store.question);
  const [isActive, setActive] = useState(false);
  const [selected, setSelected] = useState([]);
  const geoUrl =
    "https://raw.githubusercontent.com/zcreativelabs/react-simple-maps/master/topojson-maps/world-110m.json";

  const rounded = (num) => {
    if (num > 1000000000) {
      return Math.round(num / 100000000) / 10 + "Bn";
    } else if (num > 1000000) {
      return Math.round(num / 100000) / 10 + "M";
    } else {
      return Math.round(num / 100) / 10 + "K";
    }
  };
  useEffect(() => {}, []);

  return (
    // <Graticule stroke="#EAEAEC" />
    <div className="Box background-secondary box">
      <ComposableMap data-tip="" projectionConfig={{ scale: 200 }}>
        <ZoomableGroup>
          <Geographies geography={geoUrl}>
            {({ geographies }) =>
              geographies.map((geo, i) => (
                <Geography
                  className={isActive && selected.includes(i) ? "active" : ""}
                  key={geo.rsmKey}
                  geography={geo}
                  onClick={(e) => {
                    setActive(true);
                    setSelected((curr) => {
                      if (curr.includes(i)) {
                        return curr.filter((num) => {
                          return num !== i;
                        });
                      } else {
                        return [...curr, i];
                      }
                    });
                    const { NAME } = geo.properties;
                    setAnswer((curr) => {
                      if (curr.includes(NAME)) {
                        return curr.filter((res) => {
                          return res !== NAME;
                        });
                      } else {
                        return [...curr, NAME];
                      }
                    });
                  }}
                  style={{
                    default: {
                      fill: "#9998A3",
                      stroke: "#EAEAEC",
                      outline: "none",
                    },
                    hover: {
                      fill: "#F53",
                      outline: "none",
                    },
                    pressed: {
                      fill: "#E42",
                      stroke: "black",
                      outline: "none",
                    },
                    clicked: {
                      fill: "#F53",
                      outline: "none",
                    },
                  }}
                />
              ))
            }
          </Geographies>
        </ZoomableGroup>
      </ComposableMap>
    </div>
  );
}
