/* BOOTSTRAP PACKAGE IMPORTS */
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";


function InputField({ inputField }) {
  const { label, type, value, handleChange, controlId } = inputField;

  return (
    <Form.Group className="my-3" controlId={controlId}>
      <Form.Label>{label}</Form.Label>
      <Form.Control
        type={type}
        value={value}
        onChange={e => handleChange(e.target.value)}
      />
    </Form.Group>
  );
};


export default function CenteredInputModal({
  message,
  modalTitle,
  modalHeading,
  showModal,
  setShowModal,
  handleSubmit,
  buttonText,
  inputFields
}) {


  return (
    <Modal
      show={showModal}
      onHide={() => setShowModal(false)}
      size="lg"
      centered
      aria-label={`Modal with message: ${message}`}
    >
      <Modal.Header className="p-4" closeButton>
        <Modal.Title className="fw-bold">{modalTitle}</Modal.Title>
      </Modal.Header>
      <Modal.Body className="p-4">
        <h5>{modalHeading}</h5>
        <Form
          id="form"
          onSubmit={handleSubmit}
        >
          {
            inputFields.length > 0 &&
            inputFields.map(inputField => <InputField inputField={inputField} key={inputField.label}/>)
          }
        </Form>
      </Modal.Body>
      <Modal.Footer className="border-0 p-4">
        <Button
          variant="dark"
          onClick={handleSubmit}
          className={"container"}
        >
          {buttonText}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};