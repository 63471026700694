/* REACT PACKAGE IMPORTS */
import { useState, useEffect, useRef } from "react";

/* DND KIT IMPORTS */
import {
  DndContext,
  KeyboardSensor,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import { sortableKeyboardCoordinates } from "@dnd-kit/sortable";

/* INTERNAL IMPORTS */
import Droppable from "./DragDrop/Droppable";
import Draggable from "./DragDrop/Draggable";
import Item from "./DragDrop/Item";
import { shuffleArray } from "../../../utils/tools.js";
const reactStringReplace = require("react-string-replace");


export default function DragDropChoice({ question, answer, setUserAnswerArray }) {
  const [isLoading, setIsLoading] = useState(true);
  const [draggableItems, setDraggableItems] = useState({});
  const [parents, setParents] = useState([]);

  const [isHovering, setIsHovering] = useState(false);
  const [activeId, setActiveId] = useState(null);

  const blanksTable = {};

  const mounted = useRef(null);
  const sensors = useSensors(
    useSensor(MouseSensor),
    useSensor(TouchSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const handleDragOver = ({ active, over }) => {
    const overId = over?.id;
    const item = active.data.current;
    const activeIndex = active.id;
    if (!overId) {
      setActiveId(null);
      setIsHovering(false);
      return;
    } else {
      setActiveId(overId);
      setIsHovering(true);
    }

    setDraggableItems(currItems => {
      let newItems = { ...currItems };
      newItems[activeIndex] = item;
      return newItems;
    });

    setParents(currParent => {
      let newParents = currParent.filter(par => par.child.id !== activeIndex);
      return over ? newParents : currParent;
    });

    // setUserAnswerArray((curr) => {
    //   const newCurr = curr.filter(answer => answer.qid !== question.qid);
    //   const newAnswer = {
    //     qid: question.qid,
    //     answer: { draganddropchoice: [] },
    //     qhid: question.qhid,
    //   };
    //   if (newCurr) return [...newCurr, newAnswer];
    //   else return [newAnswer];
    // });

  };

  const handleDragEnd = ({ active, over }) => {
    if (!over) return;
    setActiveId(null);
    setIsHovering(false);

    if (active.id !== over.id) {
      const item = active.data.current;
      const overIndex = over.id;
      const activeIndex = active.id;

      setDraggableItems(currItems => {
        let newItems = { ...currItems };
        delete newItems[activeIndex];
        const newParents = parents.filter(par => par.parent === overIndex);
        if (newParents.length > 0) newItems[newParents[0].child.id] = newParents[0].child.value;
        return newItems;
      });

      setParents(currParents => {
        let newParents = currParents.filter(par => par.parent !== overIndex);
        const newParent = {
          parent: overIndex,
          child: { id: activeIndex, value: item },
        };
        return over ? [...newParents, newParent] : currParents;
      });

      setUserAnswerArray(curr => {
        const newCurr = curr.filter(answer => answer.qid !== question.qid);
        const newAnswer = {
          qid: question.qid,
          answer: { draganddropchoice: parseInt(activeIndex) },
          qhid: question.qhid,
        };
        if (newCurr) return [...newCurr, newAnswer];
        else return [newAnswer];
      });

    }
  };

  useEffect(() => {
    mounted.current = true;
    if (!isLoading) return;
    if (mounted.current) {

      if (answer.length > 0) {
        const optionId = answer[0].answer.draganddropchoice;
        const { options } = question;

        setParents(curr => {
          let dropId = question.question.indexOf("{");
          const optionText = options
            .filter(item => item.qoid === optionId)
            .map(item => item.opttext)[0];

          if (optionText)
            return [
              {
                parent: `drop_${dropId}`,
                child: { id: optionId, value: optionText },
              },
            ];
          else return curr;
        });

        setDraggableItems(() => {
          const newItems = {};
          options
            .filter(item => item.qoid !== optionId)
            .forEach(item => newItems[item.qoid] = item.opttext);
          return newItems;
        });
      }
      else {
        setDraggableItems(() => {
          const newItems = {};
          const shuffledOptions = question.options ?
            shuffleArray(question.options)
            : [];
          shuffledOptions.forEach(item => newItems[item.qoid] = item.opttext);
          return newItems;
        });
      }

      setIsLoading(false);
    }
    return () => (mounted.current = false);
  }, [draggableItems]);

  const createBlanksTable = (match, id, offset) => {
    const dropOffset = `drop_${offset}`;
    blanksTable[dropOffset] = "";

    return (
      <Droppable key={`drop_${id}`} id={`drop_${offset}`}>
        {
          parents.filter(({ parent }) => parent === `drop_${offset}`).length > 0 ?
            <Draggable
              id={
                parents.filter(par => par.parent === `drop_${offset}`)[0].child.id
              }
              value={
                parents.filter(par => par.parent === `drop_${offset}`)[0].child.value
              }
              isHovering={isHovering && activeId === `drop_${offset}`}
            /> :
            <Item
              id={id}
              isHovering={isHovering && activeId === `drop_${offset}`}
            >
              Drop here
            </Item>
        }
      </Droppable>
    );
  };

  return (
    <div>
      <p className="fs-4 mb-4"><span className="fw-bold">Drop in the Blanks (Multiple Choice)</span></p>
      <p className="fs-6 fst-italic text-muted">Drag and drop your answer into each of the blanks below</p>
      <DndContext
        sensors={sensors}
        onDragOver={handleDragOver}
        onDragEnd={handleDragEnd}
      >
        <div className="" style={{ overflow: "hidden", clear: "both" }}>
          {/* Question with droppable inputs */}
          <div className="d-flex align-items-center">
            {
              reactStringReplace(
                question.question,
                /\{(.*?)\}/,
                (match, id, offset) => createBlanksTable(match, id, offset)
              )
            }
          </div>

          {/* Container for unset/initial droppable items */}
          <div className="d-flex justify-content-center align-items-center">
            {
              Object
                .keys(draggableItems)
                .map((key, id) => <Draggable key={`drag_${id}`} id={key} value={draggableItems[key]} />)
            }
          </div>
        </div>
      </DndContext>
    </div>

  );
}
