import { forwardRef } from "react";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import IconButton from "@mui/material/IconButton";
import RefreshIcon from "@mui/icons-material/Refresh";
import { useSelector, useDispatch } from "react-redux";
import { closeModal } from "../Features/Errors/Slices/errorSlice";

const Alert = forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function SimpleSnackbar() {
  const dispatch = useDispatch();
  const { isOpen, severity, message } = useSelector((store) => store.error);

  const handleClose = () => {
    dispatch(closeModal());
  };

  function refreshPage() {
    window.location.reload(false);
  }

  return (
    <Snackbar
      open={isOpen}
      autoHideDuration={6000}
      onClose={handleClose}
      anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
    >
      <Alert
        onClose={handleClose}
        severity={severity}
        sx={{ width: "100%" }}
        className="align-items-center"
      >
        {message}
        {severity === "error" ? (
          <IconButton
            onClick={refreshPage}
            aria-label="delete"
            className="text-white"
          >
            <RefreshIcon />
          </IconButton>
        ) : (
          ""
        )}
      </Alert>
    </Snackbar>
  );
}
