/* REACT PACKAGE IMPORTS */
import { useEffect, useState, useRef } from "react";
import { Form, InputGroup, Button } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { createFilter } from "react-select";
import makeAnimated from "react-select/animated";
import CreatableSelect from "react-select/creatable";

/* MUI PACKAGE IMPORTS */
import IconButton from "@mui/material/IconButton";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import Tooltip from "@mui/material/Tooltip";

/* INTERNAL IMPORTS */
import { StyledPagination, customStyles } from "../../styles/StyledComponents";
import {
  addSection,
  updateSection,
  addQuestionBySection,
  deleteQuestionBySection,
  deleteSection,
} from "../../Features/Assessments/Actions/AssessmentsActions";
import { fetchQuestions } from "../../Features/QuestionBank/Actions/QuestionBankActions";
import {
  fetchQuestionHeader,
  createQuestionHeader,
} from "../../Features/Questions/Actions/QuestionActions";

import Modal from "../Modal";
import QuestionsList from "../Assessments/QuestionsList";
import Notes from "../Notes";

const animatedComponents = makeAnimated();

const filterConfig = {
  stringify: (option) => {
    const cats = JSON.stringify(option.data.categories);
    return `${option.label} ${cats}`;
  },
};


export default function EditAssessment() {
  const dispatch = useDispatch();
  let { id } = useParams();
  const exid = parseInt(id);

  const { sections, questions } = useSelector(store => store.assessments);
  const { questionHeaders } = useSelector(store => store.questions);

  const [showModal, setShowModal] = useState(false);
  const [showModalNewQuestion, setShowModalNewQuestion] = useState(false);
  const [button, setButton] = useState("");
  const [msg, setMsg] = useState("");
  const [action, setAction] = useState(null);
  const [showQuestion, setShowQuestion] = useState(false);
  const [page, setPage] = useState(1);
  const [sectionState, setSectionState] = useState({});
  const [sectionNum, setSectionNum] = useState(null);
  const [questionHeaderId, setQuestionHeaderId] = useState("");
  const [questionHeaderIdShow, setQuestionHeaderIdShow] = useState("");
  const [options, setOptions] = useState([]);

  const mounted = useRef(true);

  const handleChange = (e, value) => setPage(value);

  const goToQuestion = () => {
    window.open(`/admin/question_bank/${questionHeaderId}`);
    setShowModalNewQuestion(false);
  };

  const handleChangeSelect = (newValue, actionMeta) => {
    const { action } = actionMeta;
    const { value } = newValue;
    if (action === "create-option") {
      const data = { admintitle: value };

      dispatch(createQuestionHeader(data))
        .then((response) => {
          const qhid = response.payload;
          setQuestionHeaderId(qhid);
          dispatch(fetchQuestionHeader(qhid));
          setQuestionHeaderIdShow(qhid);
          setShowQuestion(true);
          dispatch(fetchQuestions());
        });
    } else if (action === "select-option") {
      if (newValue !== null) {
        const qhid = parseInt(value);
        setQuestionHeaderId(qhid);
        dispatch(fetchQuestionHeader(qhid));
        setQuestionHeaderIdShow(qhid);
        setShowQuestion(true);
      }
    }
  };

  const addSectionHandler = () => {
    const data = {
      sectionname: "New section",
      sectionheader: "",
      duration: "00:00",
      exid: exid,
    };
    dispatch(addSection(data));
    setShowQuestion(false);
    setPage(sections.length + 1);
  };

  const updateSectionHandler = (esid, sectionState) => dispatch(updateSection({ esid, sectionState }));

  const addQuestionBySectionHandler = (esid, qhid) => {
    dispatch(addQuestionBySection({ esid, qhid }))
      .then(response => {
        const hasQuestions = response.payload.question.questions;
        if (!hasQuestions) setShowModalNewQuestion(true);
      });
  };

  const deleteQuestionBySectionHandler = () => {
    let esid = sectionNum;
    let qhid = questionHeaderId;
    dispatch(deleteQuestionBySection({ esid, qhid }));
    setShowModal(false);
    setQuestionHeaderId("");
  };

  const confirmSectionDelete = () => {
    const esid = sectionNum;
    setShowModal(false);
    dispatch(deleteSection({ esid }));
    setPage(1);
  };

  const handleSectionDelete = (section) => {
    setSectionNum(section.esid);
    setShowModal(true);
    setMsg(
      `Are you sure you want remove the section "${section.sectionname}" from the assessment? This is an irreversible action.`
    );
    setButton("Delete");
    setAction(2);
  }

  useEffect(() => {
    mounted.current = true;
    if (questionHeaders.length > 0) {
      setOptions(questionHeaders);
      return;
    }
    if (mounted.current) dispatch(fetchQuestions());
    return () => mounted.current = false;
  }, [questionHeaders, dispatch]);


  return (
    <div className="my-4">
      <div className="mb-4 d-flex justify-content-center align-items-center">
        <StyledPagination
          count={sections.length}
          page={page}
          onChange={handleChange}
        />
        <Tooltip title="Add section">
          <IconButton aria-label="add section" onClick={addSectionHandler}>
            <AddCircleIcon />
          </IconButton>
        </Tooltip>
      </div>
      {sections.map((section, i) => {
        const questionTotal = questions.length > 0 ?
          questions
            .filter(questions => questions.sectionid === section.esid)[0]
            .questions.length : 0;

        return (
          page === i + 1 && (
            <div key={i} className="my-3">
              {/* ADD NEW QUESTION TO SECTION */}
              <Form
                className="Box background-secondary my-3 box"
                id="formSection"
              >
                <Form.Group controlId="controlInput" className="my-3">
                  <h2 className="fw-bold">Section Details</h2>
                  <p className="mb-3 text-muted">Information about this section</p>
                  <Form.Label>Section title</Form.Label>
                  <Form.Control
                    type="text"
                    defaultValue={section.sectionname}
                    onChange={e => setSectionState(curr => ({ ...curr, sectionname: e.target.value }))}
                  />
                </Form.Group>
                <Form.Group controlId="controlInput" className="my-3">
                  <Form.Label>Section description</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    defaultValue={section.sectionheader}
                    onChange={e => setSectionState(curr => ({ ...curr, sectionheader: e.target.value }))}
                  />
                </Form.Group>
                <Form.Group controlId="controlInput" className="my-3">
                  <Form.Label>Pass mark</Form.Label>
                  <Form.Control
                    type="number"
                    defaultValue={section.passmark}
                    onChange={e => setSectionState(curr => ({ ...curr, passmark: e.target.value }))}
                  />
                </Form.Group>
                <Form.Group controlId="controlInput" className="my-3">
                  <Form.Label>Pass percentage (%)</Form.Label>
                  <InputGroup>
                    <Form.Control
                      type="number"
                      defaultValue={section.passpercent}
                      onChange={e => setSectionState(curr => ({ ...curr, passpercent: e.target.value }))}
                    />
                    <InputGroup.Text>%</InputGroup.Text>
                  </InputGroup>
                </Form.Group>
                <div className="text-end d-flex justify-content-between mt-3">
                  <Tooltip className="me-2" title="Update section">
                    <Button
                      variant="dark"
                      aria-label="Update section"
                      onClick={() => updateSectionHandler(section.esid, sectionState)}
                    >
                      Update Section
                    </Button>
                  </Tooltip>
                  <Tooltip title="Delete section">
                    <Button
                      variant="dark"
                      aria-label="Update section name"
                      onClick={section => handleSectionDelete(section)}
                    >
                      Delete Section
                    </Button>
                  </Tooltip>
                </div>
              </Form>

              <Form
                className="Box background-secondary my-4 box"
                id="formAddQuestionSection"
              >
                <QuestionsList
                  section={section}
                  questionTotal={questionTotal}
                  setShowQuestion={setShowQuestion}
                  showQuestion={showQuestion}
                  setQuestionHeaderIdShow={setQuestionHeaderIdShow}
                  questionHeaderIdShow={questionHeaderIdShow}
                  setQuestionHeaderId={setQuestionHeaderId}
                  setSectionNum={setSectionNum}
                  setShowModal={setShowModal}
                  setMsg={setMsg}
                  setButton={setButton}
                  setAction={setAction}
                />
                <hr />
                {/* ADD NEW QUESTION TO SECTION */}

                <h2 className="fw-bold fs-5">Add New Question</h2>
                <p className="mb-3 text-muted">Enter the title of an existing question header, filter them
                    by category or create a new one.</p>
                <Form.Group controlId="controlInput">
                  <CreatableSelect
                    styles={customStyles}
                    closeMenuOnSelect={true}
                    components={animatedComponents}
                    isClearable={true}
                    options={options.map((question) => {
                      return {
                        label: question.admintitle,
                        value: question.qhid,
                        categories: question.categories?.map(cat => cat.catname) || [],
                      };
                    })}
                    formatOptionLabel={(option) => {
                      const cats = option.categories || [];
                      return (
                        <p className="m-0">
                          {option.label}
                          {cats.length > 0 &&
                            cats.map((cat, i) => (
                              <span
                                key={i}
                                className="btn btn-sm btn-outline-primary ms-4 px-1 py-0"
                              >
                                {cat}
                              </span>
                            ))}
                        </p>
                      );
                    }}
                    filterOption={createFilter(filterConfig)}
                    placeholder="Select a question header..."
                    onChange={handleChangeSelect}
                  />
                  <div className="mt-3 w-100">
                    <Button
                      variant="dark"
                      aria-label="add question"
                      onClick={() => addQuestionBySectionHandler(section.esid, questionHeaderId)}
                    >
                      Add Question
                    </Button>
                  </div>
                </Form.Group>
              </Form>

              <Notes
                id={section.esid}
                type="examsections"
                title={"Section Notes"}
              />
            </div>
          )
        );
      })
      }
      <Modal
        setShowModal={setShowModal}
        show={showModal}
        handleSubmit={
          action === 1 ? deleteQuestionBySectionHandler : confirmSectionDelete
        }
        msg={msg}
        button={button}
      />
      <Modal
        setShowModal={setShowModalNewQuestion}
        show={showModalNewQuestion}
        handleSubmit={goToQuestion}
        msg="This question is empty. You need to add some questions to this header. Click on
    the button below to edit it properly."
        button={"GO"}
      />
    </div>
  );
}
