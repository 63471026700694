import { Modal, Button } from "react-bootstrap";

export default function PinModal({
  children,
  show,
  setShowModal,
  handleSubmit,
  motives,
  msg,
  button,
}) {
  const handleClose = () => setShowModal(false);

  return (
    <Modal 
      show={show} 
      onHide={() => setShowModal(false)}
      aria-label={`Modal with message: ${msg}`}
    >
      <Modal.Header closeButton>
        <Modal.Title></Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p style={{ whiteSpace: "pre-wrap" }}>{msg}</p>
        <ul>
          { 
            motives &&
            motives.map((motive, i) => <li key={i}>{motive}</li>)
          }
        </ul>
        {children}
      </Modal.Body>
      <Modal.Footer>
        <Button 
          variant="secondary"
          onClick={handleClose}
        >
          Close
        </Button>
        <Button
          variant="primary"
          onClick={handleSubmit}
        >
          {button}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
