// function to shuffle the array of options (the order of which is given as the solution to the question)
export const shuffleArray = (array) => {
  let newArr = [...array];
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    const temp = newArr[i];
    newArr[i] = newArr[j];
    newArr[j] = temp;
  }
  return newArr;
};

export const dynamicSort = (property) => {
  var sortOrder = 1;
  if (property[0] === "-") {
    sortOrder = -1;
    property = property.substr(1);
  }
  return function (a, b) {
    /* next line works with strings and numbers,
     * and you may want to customize it to your needs
     */
    var result =
      a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0;
    return result * sortOrder;
  };
};

export const format = (seconds) => {
  if (Number.isNaN(parseInt(seconds))) {
    return "00:00";
  }
  const date = new Date(seconds * 1000);
  const hh = date.getUTCHours();
  const mm = date.getUTCMinutes();
  const ss = date.getUTCSeconds().toString().padStart(2, "0");
  if (hh) {
    return `${hh}:${mm.toString().padStart(2, "0")}:${ss}`;
  }
  return `${mm}:${ss}`;
};

export const formatterDropInTheBlanks = (str, arr) => {
  let newStr = str;
  [...arr].forEach((item) => {
    newStr = newStr.replace(/\{-\}/, item);
  });
  return newStr;
};

export const flattenArr = (arr) => {
  return [].concat.apply([], arr);
};
