/* REACT PACKAGE IMPORTS */
import { useEffect, useState, useRef } from "react";
import { Container } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

/* INTERNAL IMPORTS */
import ConfirmBrowserExit from "../Components/ConfirmBrowserExit";
import Header from "../Components/Header";
import CountdownTimer from "../Components/Timer";
import SessionInfo from "../Components/Sessions/SessionInfo";
import SessionStart from "../Components/Sessions/SessionStart";
import SessionFinish from "../Components/Sessions/SessionFinish";
import { DateTimeFormatter } from "../Components/DateTimeFormatter";

import { fetchAssessment } from "../Features/Assessments/Actions/AssessmentsActions";
import { fetchSession } from "../Features/Sessions/Actions/SessionsActions";
import { markPagesAsCompleted } from "../Features/Sessions/Slices/sessionsSlice";
import { countPages } from "../Features/Assessments/Slices/assessmentsSlice";
import AssessmentBuilder from "../Components/Assessment/AssessmentBuilder";
import AssessmentUtilityBar from "../Components/AssessmentUtilityBar";

import OffcanvasBuilder from "../Components/OffcanvasComponents/OffcanvasBuilder";
import UtilityBar from "../Components/Assessment/UtilityBar";


export default function SessionCandidate() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { id } = useParams();
  const sessionId = parseInt(id);

  const {
    assessment,
    sections,
    questions
  } = useSelector(store => store.assessments);

  const {
    session,
    sessionStatus,
    scheduleStatus
  } = useSelector(store => store.sessions);

  const [sessionExpired, setSessionExpired] = useState(false);
  const [startTimer, setStartTimer] = useState(false);
  const [timeToStartSession, setTimeToStartSession] = useState(0);
  const [timeSession, setTimeSession] = useState(null);

  const [isSessionCalled, setIsSessionCalled] = useState(false);
  const [isScheduleStarted, setIsScheduleStarted] = useState(false);
  const [isConducted, setConducted] = useState(false);
  const [isSessionRunning, setIsSessionRunning] = useState(null);

  const [showPause, setShowPause] = useState(false);
  const [exId, setExID] = useState(null);
  const [title, setTitle] = useState("");
  const [subtitle, setSubtitle] = useState("");

  const [instructions, setInstructions] = useState(null);
  const [passmark, setPassmark] = useState(0);
  const [description, setDescription] = useState("");
  const [duration, setDuration] = useState("");

  const mounted = useRef(null);
  const resultsRef = useRef(null);


  /* UseEffect functions */
  // 1. fetch session
  useEffect(() => {
    mounted.current = true;
    if (isSessionCalled) return

    if (mounted.current) {
      if (isNaN(sessionId)) {
        navigate("/candidate/error", { replace: true });
      } else {
        dispatch(fetchSession(sessionId))
          .unwrap()
          .then((response) => {
            
            const { pausetime, schedule, status } = response;
            const { starttime, exam, description, title } = schedule;
            const { duration, passmark, instructions, exid } = exam;
            const { stringshort } = duration;
            document.title = title;
            setExID(parseInt(exid));
            setTitle(title);
            setSubtitle(DateTimeFormatter(starttime));
            setShowPause(pausetime);
            setTimeToStartSession(starttime);
            setDescription(description);
            setInstructions(instructions);
            setIsSessionRunning(status === "started");
            setDuration(stringshort);
            setPassmark(passmark);
            setIsSessionCalled(true);
          })
          .catch(() => navigate("/admin/error", { replace: true }));
      }
    };

    return () => mounted.current = false;
  }, [isSessionCalled, sessionId]);

  // 2. when session is called set all the information to complete the page
  useEffect(() => {
    mounted.current = true;
    if (!isSessionCalled) return
    if (mounted.current) {
      if (Object.keys(session).length > 0) {
        // check if session has started
        if (Object.keys(session).includes("timeleft")) {
          const { timeleft } = session;
          const { hours, minutes, seconds } = timeleft;
          const nowInMs = new Date().getTime();
          const timeAfterSession =
            (hours * 60 * 60 + minutes * 60 + seconds) * 1000;

          setTimeSession(timeAfterSession + nowInMs);
          setStartTimer(true);
          // setIsSessionRunning(true);
        } else setTimeSession(0);
      }
    }

    return () => mounted.current = false;
  }, [isSessionCalled, session]);

  // 3. check if session has started to fetch assessment
  useEffect(() => {
    mounted.current = true;
    if (mounted.current) {
      if (Object.keys(assessment).length > 0) {
        setIsScheduleStarted(true);
        return;
      }
      if (
        Object.keys(assessment).length === 0 &&
        exId !== null &&
        (
          sessionStatus === "started" ||
          sessionStatus === "finished" ||
          sessionStatus === "paused"
        )
      ) {
        dispatch(fetchAssessment(exId))
          .then((res) => setIsScheduleStarted(true));
      }
      dispatch(markPagesAsCompleted());
    }
    return () => (mounted.current = false);
  }, [assessment, sessionStatus, exId]);

  // 4. check if assessment is fetch to count pages
  useEffect(() => {
    mounted.current = true;
    if (questions.length !== sections.length) return;
    if (mounted.current) {
      if (questions.length === sections.length) dispatch(countPages());
    }
    return () => mounted.current = false;
  }, [sections, questions]);

  useEffect(() => {
    mounted.current = true;

    if (sessionStatus === null && scheduleStatus === null) return;
    if (scheduleStatus === "pending" || sessionStatus === "pending") return;
    if (mounted.current) {
      if (scheduleStatus === "live") setIsScheduleStarted(true);
      if (scheduleStatus === "live" && sessionStatus === "started") setIsSessionRunning(true);
      if (sessionStatus === "paused") setIsSessionRunning(false);
      if (sessionStatus === "finished" || scheduleStatus === "finished") setConducted(true);
    }
    return () => mounted.current = false;
  }, [sessionStatus, scheduleStatus]);

  if (false) {
    return (
      <>
        {/* <OffcanvasBuilder OffcanvasTitle={"Assessment Details"}/> */}
        <AssessmentBuilder />
        <UtilityBar />
      </>
    );
  };


  return (
    isSessionCalled && (
      <Container ref={resultsRef} className="py-4 position-relative">
        <ConfirmBrowserExit />
        <Header title={title} subTitle={subtitle} />
        {isConducted && <SessionFinish />}
        <SessionInfo
          description={description}
          duration={duration}
          passmark={passmark}
          instructions={instructions}
          sessionId={sessionId}
          exId={exId}
          timeToStartSession={timeToStartSession}
          showPause={showPause}
          isSessionRunning={isSessionRunning}
          sessionExpired={sessionExpired}
          setConducted={setConducted}
          isConducted={isConducted}
          setIsSessionRunning={setIsSessionRunning}
          setStartTimer={setStartTimer}
          setIsSessionCalled={setIsSessionCalled}
        />
        {/* {
          !isConducted &&
          startTimer &&
          <CountdownTimer
            targetDate={timeSession}
            sessionExpired={sessionExpired}
            setSessionExpired={setSessionExpired}
            paused={sessionStatus === "paused" ? true : false}
          />
        } */}
        {/* {
          !isConducted &&
          startTimer &&
          <AssessmentUtilityBar
            targetDate={timeSession}
            sessionExpired={sessionExpired}
            setSessionExpired={setSessionExpired}
            paused={sessionStatus === "paused" ? true : false} />
        } */}
        {
          !isConducted &&
          isScheduleStarted &&
          <SessionStart
            isSessionRunning={isSessionRunning}
            isConducted={isConducted}
            sessionExpired={sessionExpired}
            targetDate={timeSession}
            setSessionExpired={setSessionExpired}
            paused={sessionStatus === "paused" ? true : false}
          />
        }
      </Container>
    )
  );
}
