import { createSlice } from "@reduxjs/toolkit";
import {
  fetchQuestionHeader,
  fetchQuestionHeaderImage,
  addQuestionHeaderImage,
  fetchQuestionHeaderAudit,
  addQuestionHeaderVideo,
  fetchQuestionHeaderVideo,
  createQuestionHeader,
  updateQuestionHeader,
  cloneQuestionHeader,
  deleteQuestionHeader,
  fetchCategoriesByQuestionHeader,
  addCategoryToQuestion,
  removeCategoryFromQuestion,
  fetchAllCategories,
  createCategory,
  deleteCategory,
  createQuestion,
  updateQuestion,
  deleteQuestion,
  createOption,
  updateOption,
  deleteOption,
} from "../Actions/QuestionActions";
const initialState = {
  questionHeader: {},
  qHImages: [],
  qHVideos: [],
  questionHeaderAudit: [],
  questions: [],
  cats: [],
  catsByQuestion: [],
  status: "idle", //'idle' | 'loading' | 'succeeded' | 'failed'
  isVisible: null,
  answerNum: null,
  error: null,
};

const questionSlice = createSlice({
  name: "question",
  initialState,
  reducers: {
    hideError(state, action) {
      return {
        ...state,
        status: "idle",
        error: null,
      };
    },
    handleMouseEnter(state, action) {
      return {
        ...state,
        isVisible: true,
        answerNum: action.payload,
        error: null,
      };
    },
    handleMouseLeave(state, action) {
      return {
        ...state,
        isVisible: false,
        answerNum: null,
        error: null,
      };
    },
    addBlank(state, action) {
      return {
        ...state,
        question: {
          question: state.question.question + " [Blank]",
        },
        answers: [...state.answers, action.payload],
      };
    },
    addOption(state, action) {
      return {
        ...state,
        options: [...state.options, action.payload],
      };
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchQuestionHeader.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(fetchQuestionHeader.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.questionHeader = action.payload;
        state.questions = action.payload.questions || [];
      })
      .addCase(fetchQuestionHeader.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })
      .addCase(fetchQuestionHeaderImage.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(fetchQuestionHeaderImage.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.qHImages = action.payload;
      })
      .addCase(fetchQuestionHeaderImage.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })
      .addCase(addQuestionHeaderImage.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(addQuestionHeaderImage.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.qHImages.push(action.payload);
      })
      .addCase(addQuestionHeaderImage.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })
      .addCase(fetchQuestionHeaderVideo.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(fetchQuestionHeaderVideo.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.qHVideos = action.payload;
      })
      .addCase(fetchQuestionHeaderVideo.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })
      .addCase(addQuestionHeaderVideo.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(addQuestionHeaderVideo.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.qHVideos.push(action.payload);
      })
      .addCase(addQuestionHeaderVideo.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })
      .addCase(fetchAllCategories.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(fetchAllCategories.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.cats = action.payload.map((cat) => {
          return { value: cat.qcid, label: cat.catname };
        });
      })
      .addCase(fetchAllCategories.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })
      .addCase(createCategory.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(createCategory.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.cats.push(action.payload);
      })
      .addCase(createCategory.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })
      .addCase(fetchCategoriesByQuestionHeader.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(fetchCategoriesByQuestionHeader.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.catsByQuestion = action.payload.map((cat) => {
          return { value: cat.qcid, label: cat.catname };
        });
      })
      .addCase(fetchCategoriesByQuestionHeader.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })
      .addCase(addCategoryToQuestion.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(addCategoryToQuestion.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.catsByQuestion.push(action.payload);
      })
      .addCase(addCategoryToQuestion.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })
      .addCase(removeCategoryFromQuestion.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(removeCategoryFromQuestion.fulfilled, (state, action) => {
        state.status = "succeeded";
      })
      .addCase(removeCategoryFromQuestion.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })
      .addCase(fetchQuestionHeaderAudit.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(fetchQuestionHeaderAudit.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.questionHeaderAudit = action.payload;
      })
      .addCase(fetchQuestionHeaderAudit.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })
      .addCase(deleteQuestionHeader.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(deleteQuestionHeader.fulfilled, (state, action) => {
        state.status = "succeeded";
      })
      .addCase(deleteQuestionHeader.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })
      .addCase(createQuestion.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(createQuestion.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.questions.push(action.payload);
      })
      .addCase(createQuestion.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })
      .addCase(deleteQuestion.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(deleteQuestion.fulfilled, (state, action) => {
        state.status = "succeeded";
      })
      .addCase(deleteQuestion.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })
      .addCase(cloneQuestionHeader.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(cloneQuestionHeader.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.questionHeader = {};
        state.questions = [];
      })
      .addCase(cloneQuestionHeader.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      });
  },
});

export const { hideError, handleMouseEnter, handleMouseLeave } =
  questionSlice.actions;

export default questionSlice.reducer;
