/* REACT PACKAGE IMPORTS */
import { useState } from "react";
import { Form, Button, Stack } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

/* INTERNAL IMPORTS */
import {
  handleMouseEnter,
  handleMouseLeave,
} from "../../../Features/Questions/Slices/questionSlice";
import {
  createOption,
  updateOption,
  deleteOption,
  updateQuestion,
} from "../../../Features/Questions/Actions/QuestionActions";

const reactStringReplace = require("react-string-replace");


export default function InlineChoice({ question }) {
  const dispatch = useDispatch();

  const { isVisible, answerNum } = useSelector(store => store.question);

  const [options, setOptions] = useState(question.options || []);
  const [option, setOption] = useState("");

  let { id } = useParams();
  const qhid = parseInt(id);
  const qid = question.qid;


  /* Handler functions */
  const handleOnRadio = (e, i) => {
    const optionid = parseInt(e.target.value);

    let correct_options = [optionid];
    dispatch(
      updateQuestion({
        qid,
        qhid,
        formData: { correct_options },
      })
    );
  };

  const handleAddOption = () => {
    if (option.length > 0) {
      let newOption = {
        qid: question.qid,
        opttext: option
      };
      setOption("");
      dispatch(createOption({ qhid, newOption }))
        .then(response => {
          setOptions(
            response.payload.payload.questions
              .filter(item => item.qid === qid)[0].options
          );
        })
        .catch(error => console.log(error));
    }
  };

  const handleDeleteOption = (option) => {
    setOptions(options => options.filter(currOpt => currOpt.qoid !== option.qoid));
    dispatch(
      deleteOption({
        qhid,
        oid: option.qoid,
      })
    );
  };

  const handleSaveOption = (option) => {
    dispatch(
      updateOption({
        qhid,
        oid: option.qoid,
        option: { opttext: option.opttext },
      })
    );
  };


  return (
    <div>
      {/* 
        <p>
          {
            reactStringReplace(question.question, "{}", (match, i) => (
              <span key={i} className="text-primary">
                {match.split("{").pop().split("}")[0]}
              </span>
            ))
          }
        </p> 
      */}
      {
        options.length === 0 ?
          <p className="text-muted fst-italic">There are no options to preview yet...</p> :
          null
      }
      <ol className="list-group">
        {
          options &&
          options.map((option, i) => {
            return (
              <li
                className="list-group-item"
                key={i}
                onMouseEnter={() => dispatch(handleMouseEnter(i))}
                onMouseLeave={() => dispatch(handleMouseLeave(i))}
              >
                <Form.Group>
                  <Form.Label>Option {i + 1}</Form.Label>
                  <div className="my-1 d-flex align-items-center">
                    <Form.Control
                      type="text"
                      aria-describedby="answer"
                      value={option.opttext}
                      aria-label={option.opttext}
                      onChange={(e) => {
                        setOptions((options) => {
                          let newOptions = [...options];
                          let newOption = {
                            ...newOptions[i],
                            opttext: e.target.value,
                          };
                          newOptions[i] = newOption;
                          return [...newOptions];
                        });
                      }}
                    />
                    <Form.Check
                      className="ms-3"
                      type={"radio"}
                      name="singleAnswer"
                      value={option.qoid}
                      onChange={handleOnRadio}
                      defaultChecked={option?.iscorrect}
                      aria-label={`${option.opttext} checkbox`}
                    />
                  </div>
                </Form.Group>
                <Stack
                  direction="horizontal"
                  gap={3}
                  className={isVisible && answerNum === i ? "shown" : "hidden"}
                >
                  <Button
                    variant="dark"
                    size="sm"
                    onClick={() => handleSaveOption(option)}
                  >
                    Save option
                  </Button>
                  <Button
                    variant="dark"
                    size="sm"
                    onClick={() => handleDeleteOption(option)}
                  >
                    Delete option
                  </Button>
                </Stack>
              </li>
            );
          })
        }
      </ol>
      <hr />
      <Form.Group className="my-3" controlId="controlInput">
        <h3 className="fw-bold mt-4 mb-0 fs-5">Add New Option</h3>
        <p className="text-muted mb-3">Add a new option (Text or Image) for the question</p>
        <Form.Control
          as="textarea"
          rows={3}
          onChange={e => setOption(e.target.value)}
          value={option}
          aria-label="Add Option Text area"
        />
      </Form.Group>
      <Button
        variant="dark"
        onClick={handleAddOption}
      >
        Add option
      </Button>
    </div>
  );
}
