/* REACT PACKAGE IMPORTS */
import { useEffect, useState, useRef } from "react";
import { useDispatch } from "react-redux";

/* INTERNAL IMPORTS */
import { openModal } from "../Features/Errors/Slices/errorSlice";
import "../styles/Assessment.css";
import "../styles/Session.css";


const useCountdown = (targetDate, paused) => {
  const countDownDate = new Date(targetDate).getTime();
  const [countDown, setCountDown] = useState(countDownDate - new Date().getTime());

  useEffect(() => {
    const interval = setInterval(() => {
      if (!paused) setCountDown(countDownDate - new Date().getTime());
    }, 1000);

    return () => clearInterval(interval);
  }, [countDownDate, paused]);

  return getReturnValues(countDown);
};

const getReturnValues = (countDown) => {
  // calculate time left
  const hours = Math.floor(
    (countDown % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
  );
  const minutes = Math.floor((countDown % (1000 * 60 * 60)) / (1000 * 60));
  const seconds = Math.floor((countDown % (1000 * 60)) / 1000);

  return [hours, minutes, seconds];
};

export default function CountdownTimer({
  targetDate,
  sessionExpired,
  setSessionExpired,
  paused,
}) {
  const [hours, minutes, seconds] = useCountdown(targetDate, paused);
  const mounted = useRef(null);
  const dispatch = useDispatch();

  useEffect(() => {
    mounted.current = true;
    if (mounted.current) {
      // Toast for 30 minutes of assessment time remaining
      if(hours === 0 && minutes === 30 && seconds === 0) {
        dispatch(openModal({
          message: "30 Minutes remaining",
          severity: "info",
        }));
      };
      // Toast for 5 minutes of assessment time remaining
      if(hours === 0 && minutes === 5 && seconds === 0) {
        dispatch(openModal({
          message: "5 Minutes remaining",
          severity: "info",
        }));
      };
      if (hours + minutes + seconds <= 0) setSessionExpired(true);
    }
    return () => mounted.current = false;
  }, [hours, minutes, seconds]);

  if (sessionExpired) return <ExpiredNotice />;
  else return <ShowCounter hours={hours} minutes={minutes} seconds={seconds} />;
};

const ExpiredNotice = () => {
  return (
    <div className="expired-notice">
      <span>Assessment Expired!</span>
      <p>Please click the 'Finish Assessment' button to proceed.</p>
    </div>
  );
};

const ShowCounter = ({ hours, minutes, seconds }) => {
  const [countdownState, setCountdownState] = useState([]);

  const isDanger = countdownState === "danger";
  const dangerClass = "danger show-counter rounded-pill d-flex flex-column align-items-center px-4";
  const typicalClass = "bg-dark show-counter rounded-pill d-flex flex-column align-items-center px-4";

  return (
    <div className="container timer-container rounded-top p-2 d-flex justify-content-center" elevation={6}>
      <div className={isDanger ? dangerClass : typicalClass}>
        <div className="countdown-link">
          <DateTimeDisplay
            value={hours}
            type={"Hours"}
          />
          <p className="m-0">:</p>
          <DateTimeDisplay
            value={minutes}
            type={"Minutes"}
          />
          <p className="m-0">:</p>
          <DateTimeDisplay
            value={seconds}
            type={"Seconds"}
          />
        </div>
      </div>
    </div>

  );
};

const DateTimeDisplay = ({ value, type }) => {
  return (
    <div className={"countdown"}>
      <p>{value}</p>
      <span>{type}</span>
    </div>
  );
};
