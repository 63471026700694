import { getURL, setHeaders, setHeadersAndParameters } from "../../../Apis/axios";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { openModal } from "../../Errors/Slices/errorSlice";
import { fetchAssessment } from "../../Assessments/Actions/AssessmentsActions";

const fetchOrganisation = createAsyncThunk(
  "sessions/fetchOrganisation",
  async (orgId, thunkAPI) => {
    try {
      const Axios = getURL();
      const organisation = await Axios.get(`/organisations/${orgId}`, setHeaders());
      return organisation.data.data.orgname;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.errormessage);
    }
  }
);

const fetchSchedules = createAsyncThunk(
  "sessions/fetchSchedules",
  async ({ paginationModel, signal }, thunkAPI) => {
    try {
      const params = { 
        params: "" 
      };
      console.log(signal, "this is the signal inside fetchSchedules");
      const Axios = getURL();
      const sessions = await Axios.get("/examschedule", setHeadersAndParameters(params, signal));
      return sessions.data.data;
    } catch (error) {
      console.log(error)
      if (error.message === "canceled") {
        return [];
      }
      return thunkAPI.rejectWithValue(error.response.data.errormessage);
    }
  }
);

const fetchTodaySchedules = createAsyncThunk(
  "sessions/fetchTodaySchedules",
  async ({paginationModel, signal}, thunkAPI) => {
    try {
      const todayDate = new Date();
      let tomorrowDate = new Date().setDate(todayDate.getDate() + 1);
      const params = { 
        params: {
          /* HOLD OFF Until meeting */
          // api_limit: pageSize,
          // api_offset: page * pageSize,
          startdate: todayDate.toISOString().split('T')[0], 
          enddate: new Date(tomorrowDate).toISOString().split('T')[0] 
        } 
      };
      const Axios = getURL();
      const sessions = await Axios.get("/examschedule", setHeadersAndParameters(params, signal));
      return sessions.data.data;
    } catch (error) {
      if (error.message === "canceled") return [];
      return thunkAPI.rejectWithValue(error.response.data.errormessage);
    }
  }
);

const fetchOwnerSchedules = createAsyncThunk(
  "sessions/fetchOwnerSchedules",
  async ({paginationModel, signal}, thunkAPI) => {
    try {
      const user = JSON.parse(sessionStorage.getItem("user"));
      const { userid } = user;
      const params = { 
        params: { 
          /* HOLD OFF Until meeting */
          // api_limit: pageSize,
          // api_offset: page * pageSize,
          createdby: userid,
        } 
      };
      const Axios = getURL();
      const sessions = await Axios.get("/examschedule", setHeadersAndParameters(params, signal));
      return sessions.data.data;
    } catch (error) {
      if (error.message === "canceled") return [];
      return thunkAPI.rejectWithValue(error.response.data.errormessage);
    }
  }
);

const fetchSchedule = createAsyncThunk(
  "sessions/fetchSchedule",
  async (requestData, thunkAPI) => {
    try {
      const Axios = getURL();
      const schedule = await Axios.get(`/examschedule/${requestData}`, setHeaders());
      return schedule.data.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.errormessage);
    }
  }
);

const updateSchedule = createAsyncThunk(
  "sessions/updateSchedule",
  async ({ schId, scheduleState }, thunkAPI) => {
    try {
      const Axios = getURL();
      await Axios.put(`/examschedule/${schId}`, scheduleState, setHeaders());
      thunkAPI.dispatch(
        openModal({
          message: "This schedule has been correctly updated",
          severity: "success",
        })
      );
      return thunkAPI.dispatch(fetchSchedule(schId));
    } catch (error) {
      thunkAPI.dispatch(
        openModal({
          message: error.response.data.errormessage,
          severity: "error",
        })
      );
      return thunkAPI.rejectWithValue(error.response.data.errormessage);
    }
  }
);

const deleteSchedule = createAsyncThunk(
  "sessions/deleteSchedule",
  async (schId, thunkAPI) => {
    try {
      const Axios = getURL();
      await Axios.delete(`/examschedule/${schId}`, setHeaders());
      return;
    } catch (error) {
      thunkAPI.dispatch(
        openModal({
          message: "This schedule cannot be deleted, it may still be in use.",
          severity: "error",
        })
      );
      return thunkAPI.rejectWithValue(error.response.data.errormessage);
    }
  }
);

const cancelSession = createAsyncThunk(
  "sessions/cancelSession",
  async ({ esuid }, thunkAPI) => {
    try {
      const Axios = getURL();
      await Axios.put(`/examsessions/${esuid}`, { cancelled: 1 }, setHeaders());
      return thunkAPI.dispatch(
        openModal({
          message: "This session has been cancelled and the candidate has been notified.",
          severity: "success",
        })
      );
    } catch (error) {
      thunkAPI.dispatch(
        openModal({
          message: error.response.data.errormessage,
          severity: "error",
        })
      );
      return thunkAPI.rejectWithValue(error.response.data.errormessage);
    }
  }
);

const fetchAuditSchedule = createAsyncThunk(
  "sessions/fetchAuditSchedule",
  async (name, thunkAPI) => {
    try {
      const Axios = getURL();
      const audit = await Axios.get(
        `/examschedule/${name}/changes`,
        setHeaders()
      );
      return audit.data.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.errormessage);
    }
  }
);

const fetchSessionsBySchedule = createAsyncThunk(
  "sessions/fetchSessionsBySchedule",
  async (schid, thunkAPI) => {
    try {
      const Axios = getURL();
      const sessions = await Axios.get(
        `/examschedule/${schid}/sessions`,
        setHeaders()
      );
      return sessions.data.data;
    } catch (error) {
      thunkAPI.dispatch(
        openModal({
          message:
            "Please assign candidates to the session",
          severity: "info",
        })
      );
      return thunkAPI.rejectWithValue(error.response.data.errormessage);
    }
  }
);

const fetchSession = createAsyncThunk(
  "sessions/fetchSession",
  async (name, thunkAPI) => {
    try {
      const Axios = getURL();
      const session = await Axios.get(`/examsessions/${name}`, setHeaders());
      sessionStorage.setItem(
        "scheduleStatus",
        session.data.data.schedule.status
      );
      sessionStorage.setItem("sessionStatus", session.data.data.status);
      // sessionStorage.setItem("paused", session.data.data.status);
      return session.data.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.errormessage);
    }
  }
);

const updateSession = createAsyncThunk(
  "sessions/updateSession",
  async ({ sessionId, schId, sessionState }, thunkAPI) => {
    try {
      const Axios = getURL();
      const session = await Axios.put(
        `/examsessions/${sessionId}`,
        sessionState,
        setHeaders()
      );
      thunkAPI.dispatch(
        openModal({
          message: "This session has been successfully updated",
          severity: "success",
        })
      );
      thunkAPI.dispatch(fetchSession(sessionId));
      return thunkAPI.dispatch(fetchSessionsBySchedule(schId));
    } catch (error) {
      thunkAPI.dispatch(
        openModal({
          message: error.response.data.errormessage,
          severity: "error",
        })
      );
      return thunkAPI.rejectWithValue(error.response.data.errormessage);
    }
  }
);

const startSession = createAsyncThunk(
  "sessions/startSession",
  async ({ esId, exId, pin }, thunkAPI) => {
    try {
      const Axios = getURL();
      const session = await Axios.post(
        `/examsessions/${esId}/start`,
        { pin },
        setHeaders()
      );
      thunkAPI.dispatch(fetchAssessment(exId));
      return thunkAPI.dispatch(fetchSession(esId));
    } catch (error) {
      sessionStorage.setItem("session", "rejected");
      thunkAPI.dispatch(
        openModal({
          message: "There was a problem starting the session, please try again.",
          severity: "error",
        })
      );
      return thunkAPI.rejectWithValue(error.response.data.errormessage);
    }
  }
);

const pauseSession = createAsyncThunk(
  "sessions/pauseSession",
  async (esId, thunkAPI) => {
    try {
      const Axios = getURL();
      const session = await Axios.delete(
        `/examsessions/${esId}/start`,
        setHeaders()
      );
      return thunkAPI.dispatch(fetchSession(esId));
    } catch (error) {
      thunkAPI.dispatch(
        openModal({
          message: "This session cannot be paused",
          severity: "error",
        })
      );
      return thunkAPI.rejectWithValue(error.response.data.errormessage);
    }
  }
);

const finishSession = createAsyncThunk(
  "sessions/finishSession",
  async (esId, thunkAPI) => {
    try {
      const Axios = getURL();
      const session = await Axios.post(
        `/examsessions/${esId}/finish`,
        {},
        setHeaders()
      );
      thunkAPI.dispatch(fetchSession(esId));
      return thunkAPI.dispatch(fetchAnswersBySession(esId));
    } catch (error) {
      // thunkAPI.dispatch(
      //   openModal({
      //     message: "This session cannot be finished",
      //     severity: "error",
      //   })
      // );
      return thunkAPI.rejectWithValue(error.response.data.errormessage);
    }
  }
);

const createSchedule = createAsyncThunk(
  "sessions/createSchedule",
  async (data, thunkAPI) => {
    try {
      const requestBody = {
        exid: data["assessment-title-field"],
        vid: data["venue-field"],
        starttime: data["session-start-time-field"],
        laststarttime: data["session-leeway-time-field"],
        showanswer: (data["show-answer-field"]) ? true : false,
        title: data["session-title-field"],
        description: data["session-description-field"]
      };

      // console.log("this is the data inside of the create schedule function call>>", data);
      // console.log("this is the requestBody to be sent to the backend", requestBody);

      const Axios = getURL();
      const response = await Axios.post("/examschedule", requestBody, setHeaders());
      
      const url = response.data.data.api_url;
      const urlArr = url.split("/");
      const newExamScheduleID = urlArr[urlArr.length - 1];
      const pathname = window.location.pathname;
      window.location.href = `${pathname}/${newExamScheduleID}`;
      return newExamScheduleID;
    } catch (error) {
      thunkAPI.dispatch(
        openModal({
          message: error.response.data.errormessage,
          severity: "error",
        })
      );
      return thunkAPI.rejectWithValue(error.response.data.errormessage);
    }
  }
);

const addNewSession = createAsyncThunk(
  "sessions/addNewSession",
  async ({ schId, candidateIdArray }, thunkAPI) => {
    try {
      const Axios = getURL();
      const response = await Axios.post(
        `/examschedule/${schId}/sessions`,
        { userids: candidateIdArray },
        setHeaders()
      );

      return thunkAPI.dispatch(fetchSessionsBySchedule(schId));
    } catch (error) {
      thunkAPI.dispatch(
        openModal({
          message: error.response.data.errormessage,
          severity: "error",
        })
      );
      return thunkAPI.rejectWithValue(error.response.data.errormessage);
    }
  }
);

const deleteSession = createAsyncThunk(
  "sessions/deleteSession",
  async ({ schId, userId }, thunkAPI) => {
    try {
      const Axios = getURL();
      await Axios.delete(
        `/examschedule/${schId}/sessions/${userId}`,
        setHeaders()
      );
      thunkAPI.dispatch(fetchSessionsBySchedule(schId));
      return userId;
    } catch (error) {
      thunkAPI.dispatch(
        openModal({
          message: error.response.data.errormessage,
          severity: "error",
        })
      );
      return thunkAPI.rejectWithValue(error.response.data.errormessage);
    }
  }
);


const fetchCandidateSessions = createAsyncThunk(
  "sessions/fetchCandidateSessions",
  async(requestData, thunkAPI) => {
    const { userId } = requestData;

    try {
      const Axios = getURL();
      const response = await Axios.get(
        `/logins/${userId}/sessions`,
        setHeaders()
      );
      return response.data.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.errormessage);
    }
  }
);

const fetchCandidateTodaySessions = createAsyncThunk(
  "sessions/fetchCandidateTodaySessions",
  async(requestData, thunkAPI) => {
    const { userId, startDate } = requestData;

    try {
      const Axios = getURL();
      const response = await Axios.get(
        `/logins/${userId}/sessions?startdate=${startDate}`,
        setHeaders()
      );
      return response.data.data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data.errormessage);
    }
  }
);

const fetchAnswersBySession = createAsyncThunk(
  "sessions/fetchAnswersBySession",
  async (esId, thunkAPI) => {
    try {
      const Axios = getURL();
      const answers = await Axios.get(
        `/examsessions/${esId}/answers`,
        setHeaders()
      );
      return answers.data.data;
    } catch (error) {
      // thunkAPI.dispatch(
      //   openModal({
      //     message: "This session is not over yet.",
      //     severity: "error",
      //   })
      // );
      return thunkAPI.rejectWithValue(error.response.data.errormessage);
    }
  }
);

const postAnswer = createAsyncThunk(
  "sessions/postAnswer",
  async ({ esId, answer }, thunkAPI) => {
    try {
      const requestBody = {
        qid: answer.qid,
        answercontent: answer.answer
      };
      if (Object.keys(requestBody).length === 0 || requestBody.qid === undefined) return;
      const Axios = getURL();
      const response = await Axios.post(
        `/examsessions/${esId}/answers`,
        requestBody,
        setHeaders()
      );
      
      return response.data.data;
    } catch (error) {
      // thunkAPI.dispatch(
      //   openModal({
      //     message: error.response.data.errormessage,
      //     severity: "error",
      //   })
      // );
      return thunkAPI.rejectWithValue(error.response.data.errormessage);
    }
  }
);

const fetchFlaggedQuestions = createAsyncThunk(
  "sessions/getFlaggedQuestions",
  async ({ esId }, thunkAPI) => {
    try {
      const Axios = getURL();
      const response = await Axios.get(`/examsessions/${esId}/flagged`, setHeaders());
      return response.data;
    } catch (error) {
      thunkAPI.dispatch(
        openModal({
          message: error.response.data.errormessage,
          severity: "error",
        })
      );
      return thunkAPI.rejectWithValue(error.response.data.errormessage);
    }
  }
);

const postQuestionFlag = createAsyncThunk(
  "sessions/postQuestionFlag",
  async ({ esId, qid }, thunkAPI) => {
    try {
      const Axios = getURL();
      const response = await Axios.post(`/examsessions/${esId}/flagged/${qid}`, {}, setHeaders());
      return response.data.data;
    } catch (error) {
      thunkAPI.dispatch(
        openModal({
          message: error.response.data.errormessage,
          severity: "error",
        })
      );
      return thunkAPI.rejectWithValue(error.response.data.errormessage);
    }
  }
);

const deleteQuestionFlag = createAsyncThunk(
  "sessions/postQuestionFlag",
  async ({ esId, qid }, thunkAPI) => {
    try {
      const Axios = getURL();
      const response = await Axios.delete(
        `/examsessions/${esId}/flagged/${qid}`,
        setHeaders()
      ); 
      return response.data.data;
    } catch (error) {
      thunkAPI.dispatch(
        openModal({
          message: error.response.data.errormessage,
          severity: "error",
        })
      );
      return thunkAPI.rejectWithValue(error.response.data.errormessage);
    }
  }
);

const endSessionHandle = createAsyncThunk(
  "sessions/endSessionHandle",
  async (esId, thunkAPI) => {
    try {
      const answers = JSON.parse(sessionStorage.getItem("answers")) || [];
      if (answers.length === 0) {
        return thunkAPI.dispatch(
          openModal({
            message: "You have not answered any questions",
            severity: "error",
          })
        );
      } else {
        const promises = answers.map(answer => thunkAPI.dispatch(postAnswer({ esId, answer })));
        Promise
          .all(promises)
          .then(() => {
            sessionStorage.removeItem("answers");
            sessionStorage.removeItem("pages");
            thunkAPI.dispatch(finishSession(esId));
          });

        return "finished";
      }
    } catch (error) {
      thunkAPI.dispatch(
        openModal({
          message: "You have not answered any questions",
          severity: "error",
        })
      );
      return thunkAPI.rejectWithValue(error.response.data.errormessage);
    }
  }
);

const saveMark = createAsyncThunk(
  "sessions/saveMark",
  async ({ esId, data }, thunkAPI) => {
    try {
      const Axios = getURL();
      await Axios.post(`/examsessions/${esId}/marks`, data, setHeaders());
      thunkAPI.dispatch(fetchMarks(esId));
      return thunkAPI.dispatch(fetchAnswersBySession(esId));
    } catch (error) {
      thunkAPI.dispatch(
        openModal({
          message: error.response.data.errormessage,
          severity: "error",
        })
      );
      return thunkAPI.rejectWithValue(error.response.data.errormessage);
    }
  }
);

const fetchMarks = createAsyncThunk(
  "sessions/fetchMarks",
  async (esId, thunkAPI) => {
    try {
      const Axios = getURL();
      const marks = await Axios.get(
        `/examsessions/${esId}/marks`,
        setHeaders()
      );
      return marks.data.data;
    } catch (error) {
      thunkAPI.dispatch(
        openModal({
          message: error.response.data.errormessage,
          severity: "error",
        })
      );
      return thunkAPI.rejectWithValue(error.response.data.errormessage);
    }
  }
);

export {
  fetchOrganisation,
  fetchTodaySchedules,
  fetchOwnerSchedules,
  fetchSchedules,
  fetchSchedule,
  updateSchedule,
  deleteSchedule,
  fetchAuditSchedule,
  fetchSessionsBySchedule,
  fetchSession,
  updateSession,
  startSession,
  pauseSession,
  finishSession,
  createSchedule,
  addNewSession,
  deleteSession,
  cancelSession,
  fetchCandidateSessions,
  fetchCandidateTodaySessions,
  fetchAnswersBySession,
  postAnswer,
  postQuestionFlag,
  deleteQuestionFlag,
  fetchFlaggedQuestions,
  endSessionHandle,
  saveMark,
  fetchMarks,
};
