/* REACT PACKAGE IMPORTS */
import { useState } from "react";
import { Form } from "react-bootstrap";

export default function SelectField({ select, handleChange }) {
  const [validationStatus, setValidationStatus] = useState(null); // invalid, valid, null
  // CSS classes for valid, invalid and null states.


  const fieldLabel = (select.isRequired) ?
    <Form.Label className="fw-bold m-0">{select.label}
      <span className="asterisk-red"> *</span>
    </Form.Label> :
    <Form.Label className="fw-bold m-0">{select.label}</Form.Label>;


  return (
    <Form.Group
      className={"mb-4"}
      key={select.id}
      controlId={select.id}
    >
      <div className="d-flex flex-column">
        {fieldLabel}
        {
          select.message &&
          <Form.Text className="text-muted mt-0 mb-1">{select.message}</Form.Text>
        }
      </div>
      <Form.Select
        required={select.isRequired}
        aria-label={`${select.label} select`}
        defaultValue={select.value}
        onChange={handleChange}
      >
        <option hidden selected>Select an option</option>
        {
          select.options.map((option, index) => {
            return (
              <option key={index} value={option.value}>
                {option.label}
              </option>
            );
          })
        }
      </Form.Select>
      {
        select.invalidFeedback &&
        <Form.Control.Feedback type="invalid">
          {select.invalidFeedback}
        </Form.Control.Feedback>
      }
      {
        select.validFeedback &&
        <Form.Control.Feedback type="valid">
          {select.validFeeback}
        </Form.Control.Feedback>
      }
    </Form.Group>
  );
};