import { createSlice } from "@reduxjs/toolkit";
import { dynamicSort } from "../../../utils/tools.js";
import {
  fetchAssessments,
  fetchAssessmentsByAuthor,
  fetchAssessmentsToday,
  fetchAssessment,
  fetchAssessmentAudit,
  createAssessment,
  updateAssessment,
  fetchSections,
  fetchSection,
  addSection,
  updateSection,
  deleteSection,
  fetchQuestionsBySection,
  addQuestionBySection,
  deleteQuestionBySection,
} from "../Actions/AssessmentsActions";
const initialState = {
  assessments: [],
  assessmentsByAuthor: [],
  assessmentsToday: [],
  assessmentsSelect: [],
  assessment: {},
  assessmentAudit: [],
  sections: [],
  section: {},
  questions: [],
  countedPages: JSON.parse(sessionStorage.getItem("pages")) || [],
  status: "idle", //'idle' | 'loading' | 'succeeded' | 'failed'
  error: null,
};

const assessmentsSlice = createSlice({
  name: "assessments",
  initialState,
  reducers: {
    hideError(state, action) {
      return {
        ...state,
        status: "idle",
        error: null,
      };
    },

    countPages(state, action) {
      let countedPages = JSON.parse(sessionStorage.getItem("pages")) || [];
      if (countedPages.length > 0) return;

      let totalPages = [];

      state.questions.forEach((question) => {
        let newQuestions = question.questions;
        let newSection = question.sectionid;

        let newPages = newQuestions.map((question, i) => {
          return {
            section: newSection,
            page: i + 1,
            qhid: question.qhid,
            completed: false,
            tagged: false,
          };
        });
        totalPages = [...totalPages, ...newPages];
      });
      sessionStorage.setItem("pages", JSON.stringify(totalPages));

      return {
        ...state,
        status: "succeeded",
        error: null,
      };
    },
  },

  extraReducers(builder) {
    builder
      .addCase(fetchAssessments.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(fetchAssessments.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.assessments = action.payload;
        state.assessmentsSelect = action.payload
          .sort(dynamicSort("examtitle"))
          .map((exam) => {
            return { value: exam.exid, label: exam.examtitle };
          });
      })
      .addCase(fetchAssessments.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })
      .addCase(fetchAssessmentsByAuthor.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(fetchAssessmentsByAuthor.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.assessmentsByAuthor = action.payload;
      })
      .addCase(fetchAssessmentsByAuthor.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })
      .addCase(fetchAssessmentsToday.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(fetchAssessmentsToday.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.assessmentsToday = action.payload;
      })
      .addCase(fetchAssessmentsToday.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })
      .addCase(createAssessment.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(createAssessment.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.assessments = action.payload;
      })
      .addCase(createAssessment.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })
      .addCase(fetchAssessment.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(fetchAssessment.fulfilled, (state, action) => {
        state.assessment = action.payload;
        state.questions = [];
        state.sections = [];
      })
      .addCase(fetchAssessment.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
        state.questions = [];
      })
      .addCase(fetchSections.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(fetchSections.fulfilled, (state, action) => {
        state.sections = action.payload;
      })
      .addCase(fetchSections.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })
      .addCase(fetchSection.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(fetchSection.fulfilled, (state, action) => {
        state.section = action.payload;
        const { esid } = action.payload;
        const sections = state.sections;
        const index = sections
          .map(section =>  section.esid)
          .indexOf(esid);
        state.sections[index] = action.payload;
      })
      .addCase(fetchSection.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })
      .addCase(addSection.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(addSection.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.sections.push({
          esid: action.payload,
          sectionname: "New section",
          sectionheader: "",
          duration: "00:00",
        });
        state.questions.push({ sectionid: action.payload, questions: [] });
      })
      .addCase(addSection.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })
      .addCase(fetchAssessmentAudit.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(fetchAssessmentAudit.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.assessmentAudit = action.payload;
      })
      .addCase(fetchAssessmentAudit.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })
      .addCase(fetchQuestionsBySection.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(fetchQuestionsBySection.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.questions.push(action.payload);
      })
      .addCase(fetchQuestionsBySection.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })
      .addCase(updateAssessment.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(updateAssessment.fulfilled, (state, action) => {
        state.status = "succeeded";
      })
      .addCase(updateAssessment.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })
      .addCase(updateSection.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(updateSection.fulfilled, (state, action) => {
        state.status = "succeeded";
      })
      .addCase(updateSection.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })
      .addCase(addQuestionBySection.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(addQuestionBySection.fulfilled, (state, action) => {
        state.status = "succeeded";
        const { sectionid, question } = action.payload;
        state.questions
          .filter((section) => section.sectionid === sectionid)[0]
          .questions.push(question);
      })
      .addCase(addQuestionBySection.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })
      .addCase(deleteQuestionBySection.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(deleteQuestionBySection.fulfilled, (state, action) => {
        state.status = "succeeded";
        const { sectionid, qhid } = action.payload;
        const questions = state.questions.filter(
          (section) => section.sectionid === sectionid
        )[0].questions;
        const index = questions.map((question) => question.qhid).indexOf(qhid);
        questions.splice(index, 1);
      })
      .addCase(deleteQuestionBySection.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })
      .addCase(deleteSection.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(deleteSection.fulfilled, (state, action) => {
        state.status = "succeeded";
        const esid = action.payload;
        state.questions = state.questions.filter(
          (section) => section.sectionid !== esid
        );
        state.sections = state.sections.filter(
          (section) => section.esid !== esid
        );
      })
      .addCase(deleteSection.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      });
  },
});

export const { hideError, countPages } = assessmentsSlice.actions;

export default assessmentsSlice.reducer;
