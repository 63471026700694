import { configureStore } from "@reduxjs/toolkit";
import authReducer from "../Features/Auth/Slices/authSlice";
import sessionsReducer from "../Features/Sessions/Slices/sessionsSlice";
import assessmentsReducer from "../Features/Assessments/Slices/assessmentsSlice";
import questionReducer from "../Features/Questions/Slices/questionSlice";
import errorReducer from "../Features/Errors/Slices/errorSlice";
import questionsBankReducer from "../Features/QuestionBank/Slices/questionsBankSlice";
import usersReducer from "../Features/Users/Slices/usersSlice";
import uploadsReducer from "../Features/Uploads/Slices/uploadsSlice";
import venuesReducer from "../Features/Venues/Slices/venuesSlice";
import notesReducer from "../Features/Notes/Slices/notesSlice";
import walkthroughsReducer from "../Features/Walkthroughs/Slices/WalkthroughsSlice";

export const store = configureStore({
  reducer: {
    auth: authReducer,
    sessions: sessionsReducer,
    assessments: assessmentsReducer,
    question: questionReducer,
    questions: questionsBankReducer,
    users: usersReducer,
    error: errorReducer,
    uploads: uploadsReducer,
    venues: venuesReducer,
    notes: notesReducer,
    walkthroughs: walkthroughsReducer
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        // Ignore these action types
        ignoredActions: ['auth/verifyemail/fulfilled'],
        // Ignore these field paths in all actions
        // ignoredActionPaths: ['meta.arg', 'payload.timestamp'],
        // Ignore these paths in the state
        // ignoredPaths: ['items.dates'],
      },
    }),
});
