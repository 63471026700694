import { Pagination } from "react-bootstrap";

import "../styles/Assessment.css";

export default function SimplePagination({ count, page, onChange, pages }) {
  let items = [];

  for (let number = 1; number <= count; number++) {
    items.push(
      <Pagination.Item
        key={number}
        active={number === page}
        onClick={() => onChange(number)}
        className={`page_${pages[number - 1]} default-shadow`}
      >
        {number}
      </Pagination.Item>
    );
  }

  return (
    <div className="d-flex flex-column align-items-center flex-wrap mt-5 mb-4 mx-2">
      <Pagination className="mx-2 d-flex flex-wrap justify-content-center">
        {items}
      </Pagination>
    </div>
  );
};
