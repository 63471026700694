/* REACT PACKAGE IMPORTS */
import { useEffect, useState, useRef } from "react";
import { Form } from "react-bootstrap";
import { useSelector } from "react-redux";

/* INTERNAL IMPORTS */
import QuestionHeader from "./QuestionHeader";
import QuestionComponent from "./QuestionComponent";


export default function ViewQuestion({
  userAnswerArray,
  setUserAnswerArray,
  questions,
  questionHeader,
  setIsComplete
}) {
  const [isCalled, setIsCalled] = useState(false);
  const { answersSaved } = useSelector(store => store.sessions);
  const mounted = useRef(null);

  useEffect(() => {
    mounted.current = true;
    if (mounted.current) {
      setUserAnswerArray(answersSaved);
      setIsCalled(true);
    };
    return () => (mounted.current = false);
  }, [answersSaved, isCalled]);

  return (
    <div>
      <QuestionHeader questionHeader={questionHeader} />
      {
        questions.length > 0 &&
        questions.map((question, i) => {
          return (
            <div key={i}>
              {
                isCalled && 
                <Form className="background-secondary mb-3 p-3">
                  <QuestionComponent
                    questionType={question?.qtype?.typename}
                    questionHeader={questionHeader}
                    question={question}
                    answers={userAnswerArray}
                    setUserAnswerArray={setUserAnswerArray}
                    setIsComplete={setIsComplete}
                  />
                </Form>
              }
            </div>
          );
        })
      }
    </div>
  );
}
