import { ButtonGroup, ToggleButton, Navbar } from "react-bootstrap";
import "../styles/Navbar.css";

export default function SubNavbar({
  tabs,
  radioValue,
  handleChange,
  className,
}) {
  return (
    <Navbar
      expand="lg"
      className={className}
      style={{ width: "100%" }}
    >
      <Navbar.Toggle aria-controls="basic-subnavbar-nav" />

      <Navbar.Collapse id="basic-subnavbar-nav">
        <ButtonGroup className="d-flex flex-column d-lg-block SubNavbar">
          {tabs.map((radio, idx) => (
            <ToggleButton
              key={idx}
              id={`radio-${idx}`}
              type="radio"
              variant="text"
              name="radio"
              value={radio.value}
              checked={radioValue === radio.value}
              onChange={(e) => handleChange(e)}
              className="text-start text-theme SubNavbar"
              bsPrefix="btn-check"
            >
              {radio.name}
            </ToggleButton>
          ))}
        </ButtonGroup>
      </Navbar.Collapse>
    </Navbar>
  );
}
