import { createSlice } from "@reduxjs/toolkit";
import { fetchVenues } from "../Actions/VenuesActions";
import { dynamicSort } from "../../../utils/tools.js";

const initialState = {
  venues: [],
  venuesSelect: [],
  status: "idle", //'idle' | 'loading' | 'succeeded' | 'failed'
  error: null,
};

const venuesSlice = createSlice({
  name: "venues",
  initialState,
  reducers: {
    hideError(state, action) {
      return {
        ...state,
        status: "idle",
        error: null,
      };
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchVenues.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(fetchVenues.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.venues = action.payload;
        state.venuesSelect = action.payload
          .sort(dynamicSort("vname"))
          .map(exam => ({ value: exam.vid, label: exam.vname }));
      })
      .addCase(fetchVenues.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      });
  },
});

export const { hideError, countPages } = venuesSlice.actions;

export default venuesSlice.reducer;
