import { NavLink } from "react-router-dom";

export default function TabLink({ tab }) {
  const reg = /\s/g;
  let param =
    tab === "Home"
      ? "/candidate/assessments"
      : tab.toLowerCase().replace(reg, "_");

  return (
    <NavLink
      to={param}
      className={({ isActive }) =>
        !isActive ? "nav-link" : "nav-link nav-link-active"
      }
    >
      {tab}
    </NavLink>
  );
}
