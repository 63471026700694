import { createSlice } from "@reduxjs/toolkit";
import { startWalkthrough, endWalkthrough } from "../Actions/WalkthroughsActions";

const initialState = {
  run: false,
  steps: [],
  stepIndex: 0,
  status: 'idle'
};

const walkthroughSlice = createSlice({
  name: "walkthroughs",
  initialState,
  reducers: {
    hideError(state) {
      return {
        ...state,
        status: "idle",
        error: null,
      };
    },
  },
  extraReducers(builder) {
    builder
      .addCase(startWalkthrough.pending, state => {
        return {
          ...state,
          status: "loading"
        }
      })
      .addCase(startWalkthrough.fulfilled, (state, action) => {
        return {
          ...state,
          run: action.payload.run,
          status: "succeeded",
          steps: action.payload.steps
        }
      })
      .addCase(startWalkthrough.rejected, state => {
        return {
          ...state,
          status: "failed"
        }
      })
      .addCase(endWalkthrough.pending, state => {
        return {
          ...state,
          status: "loading"
        }
      })
      .addCase(endWalkthrough.fulfilled, (state, action) => {
        return {
          ...state,
          run: false,
          status: "succeeded",
        }
      })
      .addCase(endWalkthrough.rejected, state => {
        return {
          ...state,
          status: "failed"
        }
      });
  },
});

export const { hideError } = walkthroughSlice.actions;

export default walkthroughSlice.reducer;
