import { createTheme, styled } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";
import { DataGrid } from "@mui/x-data-grid";
import { Slider, Pagination, ToggleButton, Button } from "@mui/material";

export const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
  border: 0,
  WebkitFontSmoothing: "auto",
  backgroundColor: "var(--bkgsecondary)",
  color: "var(--text-color)",
  letterSpacing: "normal",
  padding: "1%",
  "& .MuiDataGrid-columnHeaders": {
    margin: "2px 0 4px 0",
    padding: "0.5rem"
  },
  "& .MuiDataGrid-columnsContainer": {
    backgroundColor: theme.palette.mode === "light" ? "#fafafa" : "#1d1d1d",
  },
  "& .MuiDataGrid-iconSeparator": {
    display: "none",
  },
  "& .MuiDataGrid-columnHeader, .MuiDataGrid-cell": {
    border: 0,
    whiteSpace: "normal",
    "&:focus-within": {
      outline: "none",
    },
  },
  "& .MuiDataGrid-columnsContainer, .MuiDataGrid-cell": {
    borderBottom: 0,
  },
  "& .MuiDataGrid-cell": {
    color: "var(--textsecondary-color)",
    minHeight: "auto !important",
    height: "auto !important",
    maxHeight: "none !important",
  },
  "& .MuiPaginationItem-root": {
    borderRadius: 0,
    color: "var(--textsecondary-color)",
  },

  "& .MuiDataGrid-row": {
    cursor: "pointer",
    padding: "0.5rem",
    height: "auto !important",
    maxHeight: "none !important",
    pointerEvents: "auto",
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
  "& .MuiDataGrid-footerContainer": {
    justifyContent: "center",
    margin: "2px 0",
    border: 0,
    "& p": {
      margin: "auto",
      color: "var(--textsecondary-color)",
    },
    "& div": {
      color: "var(--textsecondary-color)",
      filter: "invert(100) !important",
    },
  },
}));

export const StyledPagination = styled(Pagination)(({ theme }) => ({
  color: "var(--text-color)",
  "& .MuiPagination-root.MuiPagination-text": {
    color: "var(--text-color)",
  },
  "& .MuiPagination-ul": {
    "& .MuiPagination-li": {
      "& .MuiPaginationItem-text": {
        color: "var(--text-color)",
      },
    },
  },
}));

export const StyledGridOverlay = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  height: "100%",
  "& .ant-empty-img-1": {
    fill: theme.palette.mode === "light" ? "#aeb8c2" : "#262626",
  },
  "& .ant-empty-img-2": {
    fill: theme.palette.mode === "light" ? "#f5f5f7" : "#595959",
  },
  "& .ant-empty-img-3": {
    fill: theme.palette.mode === "light" ? "#dce0e6" : "#434343",
  },
  "& .ant-empty-img-4": {
    fill: theme.palette.mode === "light" ? "#fff" : "#1c1c1c",
  },
  "& .ant-empty-img-5": {
    fillOpacity: theme.palette.mode === "light" ? "0.8" : "0.08",
    fill: theme.palette.mode === "light" ? "#f5f5f5" : "#fff",
  },
}));

export const customStyles = {
  control: (styles, state) => ({
    ...styles,
    color: "var(--text-color)",
    backgroundColor: "var(--bkgsecondary)",
  }),
  menu: (styles, state) => ({
    ...styles,
    backgroundColor: "var(--bkgsecondary)",
    color: "var(--text-color)",
  }),
  option: (styles, state) => ({
    ...styles,
    backgroundColor: "var(--bkgsecondary)",
    color: "var(--text-color)",
    ":hover": {
      backgroundColor: "var(--bkgterciary)",
      color: "white",
    },
  }),
  input: (styles) => ({
    ...styles,
    color: "var(--text-color)",
  }),
  multiValueLabel: (styles) => ({
    ...styles,
    backgroundColor: "var(--bkgterciary)",
  }),
  multiValueRemove: (styles) => ({
    ...styles,
    backgroundColor: "var(--bkgterciary)",
  }),
};

export const useStyles = makeStyles({
  playerWrapper: {
    width: "100%",
    position: "relative",
    height: "500px",
  },
  controlsWrapper: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    background: "rgba(0,0,0,0.6)",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    zIndex: 1,
  },
  playerNone: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    background: "rgba(0,0,0,0.6)",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    zIndex: 1,
  },
  controlIcons: {
    color: "#777",
    fontSize: 50,
    transform: "scale(0.9)",
    "&:hover": {
      color: "#fff",
      transform: "scale(1)",
    },
  },
  bottomIcons: {
    color: "#777",
    padding: 0,
  },
});

export const PrettoSlider = styled(Slider)({
  height: 8,
  margin: 0,
  "& .MuiSlider-root": {
    marginBottom: 0,
  },
  "& .MuiSlider-track": {
    height: 8,
    border: "none",
  },
  "& .MuiSlider-thumb": {
    display: "none",
  },
  "& .MuiSlider-markLabel": {
    top: "-10px",
    transform: "translate(-25%)",
  },
});

export const theme = createTheme({
  palette: {
    primary: {
      main: "#020208",
    },
    secondary: {
      main: "#5d5050",
    },
    flag: {
      main: "#FFBE45"
    }
  },
});

export const ColorButton = styled(Button)(() => ({
  "&:hover": {
    backgroundColor: "#02020852",
  },
}));

export const BootstrapButton = styled(ToggleButton)({
  boxShadow: "none",
  backgroundColor: "#0d6efd",
  color: "white",
  "&:hover": {
    backgroundColor: "#0069d9",
    boxShadow: "none",
  },
  "&:active": {
    boxShadow: "none",
    backgroundColor: "#0d6efd",
    borderColor: "#005cbf",
  },
  "&:focus": {
    boxShadow: "none",
  },
});
