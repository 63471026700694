import { createSlice } from "@reduxjs/toolkit";
import {
  fetchQuestions,
  fetchQuestionsType,
  fetchQuestionsHeaderByAuthor,
} from "../Actions/QuestionBankActions";
const initialState = {
  questionHeaders: [],
  questionHeadersByAuthor: [],
  questions: [],
  questionsByAuthor: [],
  questionTypes: [],
  status: "idle", //'idle' | 'loading' | 'succeeded' | 'failed'
  error: null,
};

const questionsBankSlice = createSlice({
  name: "question",
  initialState,
  reducers: {
    hideError(state, action) {
      return {
        ...state,
        status: "idle",
        error: null,
      };
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchQuestions.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(fetchQuestions.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.questionHeaders = action.payload;
        state.questions = [];
        action.payload.forEach((questionHeader) => {
          if (questionHeader.questions) {
            questionHeader.questions.forEach((question) => {
              state.questions.push(question);
            });
          } else {
            state.questions.push({
              qhid: questionHeader.qhid,
              qid: 0,
              qtype: {
                typedesc: "None",
              },
              question: "None",
            });
          }
        });
      })
      .addCase(fetchQuestions.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })
      .addCase(fetchQuestionsHeaderByAuthor.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(fetchQuestionsHeaderByAuthor.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.questionHeadersByAuthor = action.payload;
        state.questionsByAuthor = [];
        action.payload.forEach((questionHeader) => {
          if (questionHeader.questions) {
            questionHeader.questions.forEach((question) => {
              state.questionsByAuthor.push(question);
            });
          } else {
            state.questionsByAuthor.push({
              qhid: questionHeader.qhid,
              qid: 0,
              qtype: {
                typedesc: "None",
              },
              question: "None",
            });
          }
        });
      })
      .addCase(fetchQuestionsHeaderByAuthor.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })
      .addCase(fetchQuestionsType.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(fetchQuestionsType.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.questionTypes = action.payload.map((questionType) => {
          return { value: questionType.tid, label: questionType.typedesc };
        });
      })
      .addCase(fetchQuestionsType.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      });
  },
});

export const { hideError } = questionsBankSlice.actions;

export default questionsBankSlice.reducer;
