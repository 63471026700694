/* REACT PACKAGE IMPORTS */
import { useEffect, useState, useRef } from "react";
import { Container } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

/* MUI PACKAGE IMPORTS */
import { IconButton } from "@mui/material";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import LaunchIcon from "@mui/icons-material/Launch";

/* INTERNAL PACKAGE IMPORTS */
import DataTableGrouping from "../Components/DataTableGrouping";
import Modal from "../Components/Modal";
import SubNavbar from "../Components/SubNavbar";
import FormComponent from "../Components/Form";
import Header from "../Components/Header";
import plus from "../assets/plus.svg";

import {
  cloneQuestionHeader,
  createQuestionHeader,
} from "../Features/Questions/Actions/QuestionActions";
import { setIsLoading } from "../Features/Auth/Slices/authSlice";
import { fetchQuestions, fetchQuestionsHeaderByAuthor } from "../Features/QuestionBank/Actions/QuestionBankActions";

import { flattenArr } from "../utils/tools.js";
import { LocalDateTime } from "../Components/DateTimeFormatter";


export default function QuestionBank({ paragraph, subParagraph, title, tabs }) {
  const initialColumn = [
    {
      field: "qid",
      title: "Reference",
      width: 90,
    },
    {
      field: "qhid",
      title: "View",

    },
    {
      field: "question",
      title: "Question",
      flex: 2,
    },
    {
      field: "question_type",
      title: "Question type",
      flex: 1,
    },
    {
      field: "qhid",
      title: "Categories",
      flex: 1,
    },
    {
      field: "qhid",
      title: "Created",
      type: "date",
    },
  ];

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { isLoading } = useSelector(store => store.auth);

  const [displayQuestions, setDisplayQuestions] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [button, setButton] = useState("");
  const [msg, setMsg] = useState("");
  const [questionId, setQuestionId] = useState(null);
  const [isFormVisible, setFormVisible] = useState(false);
  const [radioValue, setRadioValue] = useState("1");
  const [columns, setColumns] = useState(initialColumn);

  const mounted = useRef(null);

  const displayAuthorQuestionHeaders = (response, userId) => {
    const newArr = response.payload
      .filter(question => question?.createdby.userid === userId)
      .map((question) => {
        if (question.questions) return question.questions;
        else
          return {
            qhid: question.qhid,
            qid: 0,
            qtype: {
              typedesc: "None",
            },
            question: "None",
          };
      });
    return flattenArr(newArr);
  };

  const displayAllQuestionHeaders = (response) => {
    const newArr = response.payload.map(question => {
      if (question.questions) return question.questions;
      else
        return {
          qhid: question.qhid,
          qid: 0,
          qtype: {
            typedesc: "None",
          },
          question: "None",
        };
    });
    return flattenArr(newArr);
  };


  const handleChange = (e) => {
    let value = e.currentTarget.value;
    setRadioValue(value);
    dispatch(setIsLoading(true));
  };

  const handleCloneQuestion = () => dispatch(cloneQuestionHeader({ questionId, navigate }))

  const handleCreateQuestionHeader = data => {
    if (mounted.current) dispatch(createQuestionHeader(data));
  };

  useEffect(() => {
    mounted.current = true;
    if (mounted.current) dispatch(setIsLoading(true));
    return () => mounted.current = false;
  }, []);

  useEffect(() => {
    mounted.current = true;
    if (!isLoading) return;
    if (mounted.current) {
      document.title = "Question Bank";
      if (radioValue === "1") {
        dispatch(fetchQuestionsHeaderByAuthor())
          .then(response => {
            const newColumns = [
              {
                field: "qid",
                title: "Reference",
                width: 90,
              },
              {
                field: "qhid",
                title: "View",
                defaultGroupOrder: 0,
                cellStyle: {
                  fontWeight: "bold",
                  fontSize: "16px",
                  fontFamily: "Inter",
                },
                render: (rowData) => {
                  const header = response.payload.filter(header => header.qhid === rowData);
                  const fullName = `${header[0]?.createdby?.firstname} ${header[0]?.createdby?.lastname}`;
                  return (
                    header.length > 0 && (
                      <div className="d-flex justify-content-between align-items-center">
                        <span className="mx-5">{header[0].admintitle}</span>
                        <div className="d-flex justify-content-between align-items-center">
                          <span>
                            {fullName}
                          </span>
                          <span className="mx-5">
                            <IconButton
                              onClick={() => {
                                setShowModal(true);
                                setMsg(
                                  `Are you sure you want clone the question header "${header[0].admintitle}"?`
                                );
                                setButton("Clone");
                                setQuestionId(rowData);
                              }}
                              className="logo"
                              aria-label={`Copy question header ${header[0].admintitle} to clipboard`}
                            >
                              <FileCopyIcon fontSize="small" />
                            </IconButton>
                            <IconButton
                              onClick={() => {
                                const pathname = window.location.pathname;
                                return window.open(`${pathname}/${rowData}`);
                              }}
                              className="logo"
                              aria-label={`Edit Question ${header[0].admintitle}`}
                            >
                              <LaunchIcon fontSize="small" />
                            </IconButton>
                          </span>
                        </div>
                      </div>
                    )
                  );
                },
              },
              {
                field: "question",
                title: "Question",
                flex: 2,
              },
              {
                field: "question_type",
                title: "Question type",
                flex: 1,
                render: rowData => rowData?.qtype?.typedesc,
              },
              {
                field: "qhid",
                title: "Categories",
                flex: 1,
                render: (rowData) => {
                  const header = response?.payload?.filter(header => header.qhid === rowData.qhid);
                  return (
                    <div>
                      {header[0].categories?.map((cat, i) => {
                        return (
                          <button
                            key={i}
                            className="btn btn-outline-dark btn-sm m-1"
                          >
                            {cat.catname}
                          </button>
                        );
                      })}
                    </div>
                  );
                },
              },
              {
                field: "qhid",
                title: "Created",
                type: "date",
                render: (rowData) => {
                  const header = response.payload.filter(header => header.qhid === rowData.qhid);
                  return (
                    header.length > 0 &&
                    <span>{LocalDateTime(header[0].created)}</span>
                  );
                },
              },
            ];
            setColumns(newColumns);
            if (
              response.payload &&
              typeof response.payload[0] === "object"
            ) {
              const userLocal = JSON.parse(sessionStorage.getItem("user"));
              const userId = userLocal?.userid;
              setDisplayQuestions(() => displayAllQuestionHeaders(response));
              dispatch(setIsLoading(false));
            };
            
          });
      } else if (radioValue === "2") {
        dispatch(fetchQuestions())
          .then(response => {
            const newColumns = [
              {
                field: "qid",
                title: "Reference",
                width: 90,
              },
              {
                field: "qhid",
                title: "View",
                defaultGroupOrder: 0,
                cellStyle: {
                  fontWeight: "bold",
                  fontSize: "16px",
                  fontFamily: "Inter",
                },
                render: (rowData) => {
                  const header = response.payload.filter(header => header.qhid === rowData);
                  const fullName = `${header[0]?.createdby?.firstname} ${header[0]?.createdby?.lastname}`;
                  return (
                    header.length > 0 && (
                      <div className="d-flex justify-content-between align-items-center">
                        <span className="mx-5">{header[0].admintitle}</span>
                        <div className="d-flex justify-content-between align-items-center">
                          <span>
                            {fullName}
                          </span>
                          <span className="mx-5">
                            <IconButton
                              onClick={() => {
                                setShowModal(true);
                                setMsg(
                                  `Are you sure you want clone the question header "${header[0].admintitle}"?`
                                );
                                setButton("Clone");
                                setQuestionId(rowData);
                              }}
                              className="logo"
                              aria-label={`Copy question header ${header[0].admintitle} to clipboard`}
                            >
                              <FileCopyIcon fontSize="small" />
                            </IconButton>
                            <IconButton
                              onClick={() => {
                                const pathname = window.location.pathname;
                                return window.open(`${pathname}/${rowData}`);
                              }}
                              className="logo"
                              aria-label={`Edit Question ${header[0].admintitle}`}
                            >
                              <LaunchIcon fontSize="small" />
                            </IconButton>
                          </span>
                        </div>
                      </div>
                    )
                  );
                },
              },
              {
                field: "question",
                title: "Question",
                flex: 2,
              },
              {
                field: "question_type",
                title: "Question type",
                flex: 1,
                render: rowData => rowData?.qtype?.typedesc,
              },
              {
                field: "qhid",
                title: "Categories",
                flex: 1,
                render: (rowData) => {
                  const header = response.payload.filter(header => header.qhid === rowData.qhid);
                  return (
                    <div>
                      {header[0].categories?.map((cat, i) => {
                        return (
                          <button
                            key={i}
                            className="btn btn-outline-dark btn-sm m-1"
                          >
                            {cat.catname}
                          </button>
                        );
                      })}
                    </div>
                  );
                },
              },
              {
                field: "qhid",
                title: "Created",
                type: "date",
                render: (rowData) => {
                  const header = response.payload.filter(header => header.qhid === rowData.qhid);
                  return (
                    header.length > 0 &&
                    <span>{LocalDateTime(header[0].created)}</span>
                  );
                },
              },
            ];
            setColumns(newColumns);
            if (
              response.payload.length > 0 &&
              typeof response.payload[0] === "object"
            ) {
              setDisplayQuestions(() => displayAllQuestionHeaders(response));
              dispatch(setIsLoading(false));
            };
          });
      } else setDisplayQuestions([]);
      
    }

    return () => (mounted.current = false);
  }, [dispatch, isLoading, radioValue]);

  return (
    <Container className="py-4 position-relative">
      <Header paragraph={paragraph} title={title} subParagraph={subParagraph}>
        <SubNavbar
          radioValue={radioValue}
          tabs={tabs}
          handleChange={handleChange}
        />
      </Header>
      <DataTableGrouping
        columns={columns}
        rows={displayQuestions}
        loading={isLoading}
        type={"question_bank"}
        getRowId={ displayQuestions.length <= 0 ? null : row => row.qid }
      />
      {/* Question headers are displayed as Question references to remove ambiguity for the admin user */}
      <FormComponent
        title="Create question reference"
        inputs={[
          {
            label: "Question reference id",
            type: "text",
            id: "admintitle",
            value: "",
            required: true,
          },
          {
            label: "Question reference text (optional)",
            type: "textarea",
            id: "qtext",
            value: "",
            required: false,
          },
        ]}
        multipleValues={[]}
        multipleSelects={[]}
        selects={[]}
        button="Add question reference"
        logo={plus}
        logoClass="plus position-fixed"
        action={handleCreateQuestionHeader}
        isFormVisible={isFormVisible}
        setFormVisible={setFormVisible}
        isLoading={false}
      />
      <Modal
        setShowModal={setShowModal}
        show={showModal}
        handleSubmit={handleCloneQuestion}
        msg={msg}
        button={button}
      />
    </Container>
  );
}
