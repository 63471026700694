const reactStringReplace = require("react-string-replace");

export default function InlineChoice({ question, answer, setUserAnswerArray }) {
  const regEx = /\{(.*?)\}/;
  const handleOnChange = (e) => {
    const value = parseInt(e.target.value);

    setUserAnswerArray((curr) => {
      let newAnswer = {
        qid: question.qid,
        answer: { dropdownchoice: value },
        qhid: question.qhid,
      };

      let newCurr = curr.filter(answer => answer.qid !== question.qid);
      return [...newCurr, newAnswer];
    });
  };

  const createDropdown = (match, index) => {
    return (
      <select
        aria-label="Default select example"
        className="select mx-2 col"
        key={index}
        onChange={handleOnChange}
        multiple={false}
        defaultValue={answer.length > 0 ? answer[0]?.answer?.dropdownchoice : ""}
      >
        <option key={"select"} className="" />
        {
          question.options &&
          question.options.map((option, index) => {
            return (
              <option key={index} className="" value={option.qoid}>
                {option.opttext}
              </option>
            );
          })
        }
      </select>)
  }

  return (
    <div className="row">
      <p className="fs-4 mb-4"><span className="fw-bold">Dropdown Choice</span></p>
      <p className="fs-6 fst-italic mb-3 text-muted">Select an option from the dropdown below</p>
      <p className="mb-2">
        {reactStringReplace(question.question, regEx, (match, index) => createDropdown(match, index))}
      </p>
    </div>
  );
}
