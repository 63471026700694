import { useEffect, useState, useRef } from "react";
import { useLocation, Navigate, Outlet } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { userLoaded, userLogged } from "../Features/Auth/Slices/authSlice";
import { getUser } from "../Features/Auth/Actions/AuthActions";
import { Modal, Button } from "react-bootstrap";

const RequireAuth = ({ allowedRoles }) => {
  const isLoaded = useSelector(userLoaded);
  const user = useSelector(userLogged);

  const location = useLocation();
  const dispatch = useDispatch();
  const mounted = useRef(null);

  useEffect(() => {
    mounted.current = true;
    if (isLoaded) return;
    if (mounted.current) dispatch(getUser())
    return () => mounted.current = false;
  }, [isLoaded, dispatch]);

  return user?.roles?.find((role) => allowedRoles?.includes(role)) ? 
    <Outlet /> : 
      isLoaded ? 
        <Navigate to="/unauthorized" state={{ from: location }} replace /> : 
        <Navigate to="/login" state={{ from: location }} replace />;
};

export default RequireAuth;
