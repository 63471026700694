import { useEffect, useState, useRef } from "react";
import { Button, Form } from "react-bootstrap";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import {
  updateNotes,
  deleteNotes,
} from "../Features/Notes/Actions/NotesActions";
import { DateTimeFormatter } from "./DateTimeFormatter";
import { useDispatch } from "react-redux";

export default function Note({ note, id, type }) {
  const [show, setShow] = useState(false);
  const [newNote, setNewNote] = useState(note.notetext);

  const dispatch = useDispatch();
  const mounted = useRef(null);

  const handleMouseEnter = () => {
    setShow(true);
  };

  const handleMouseLeave = () => {
    setShow(false);
  };

  const handleOnChange = (e) => {
    setNewNote(e.target.value);
  };

  const handleUpdateNote = (e) => {
    const onid = e.target.dataset.id;
    const data = { notetext: newNote };
    dispatch(updateNotes({ type, id, onid, data }));
  };

  const handleDeleteNote = (e) => {
    const onid = e.target.dataset.id;
    dispatch(deleteNotes({ type, id, onid }));
  };

  useEffect(() => {
    mounted.current = true;

    if (mounted.current) {
      setNewNote(note.notetext);
    }
    return () => (mounted.current = false);
  }, [note]);

  return (
    <li
      id={`noteList_${note.onid}`}
      className="note-list-item border-0 position-relative my-3"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div className="p-2 position-relative">
        <Form.Control
          type="text"
          className="font-secondary"
          value={newNote}
          onChange={handleOnChange}
        />
        <div
          id={`editNoteContainer_${note.onid}`}
          // className="align-items-center p-2 item"
          className={show ? "shown" : "hidden"}
        >
          <Button
            variant="dark"
            className="me-2"
            id="saveNote"
            data-id={note.onid}
            onClick={handleUpdateNote}
          >
            Save
          </Button>
          <Button variant="dark" data-id={note.onid} onClick={handleDeleteNote}>
            Delete
          </Button>
        </div>
        <Form.Text className="font-secondary d-flex align-items-center">
          <AccessTimeIcon fontSize="small" />
          {DateTimeFormatter(note.notetime)} | Posted by {note.user.displayname}
        </Form.Text>
      </div>
    </li>
  );
}
