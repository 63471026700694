/* REACT PACKAGE IMPORTS */
import { useRef, useState, useEffect, useMemo } from "react";
import { Form, Button, InputGroup } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useLocation } from "react-router-dom";

/* MUI PACKAGE IMPORTS */
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";

/* INTERNAL IMPORTS */
import { LoginAuthAction } from "../Features/Auth/Actions/AuthActions";
import {
  userLoaded,
  userLogged,
  getAuthStatus,
  authError,
  hideError,
} from "../Features/Auth/Slices/authSlice";
import "../styles/Login.css";


export default function ResetPassword({ logo }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { status } = useSelector(state => state.auth);

  const mounted = useRef(null);
  const userRef = useRef();
  const errRef = useRef();

  const [validated, setValidated] = useState(false);
  const [isShowed1, setIsShowed1] = useState(false);
  const [isShowed2, setIsShowed2] = useState(false);
  const [pwd1, setPwd1] = useState("");
  const [pwd2, setPwd2] = useState("");
  const [msg, setMsg] = useState("");
  const [isError, setIsError] = useState(
    authStatus === "failed" ? true : false
  );

  const authStatus = useSelector(getAuthStatus);

  const message = useSelector(authError);
  const isLoaded = useSelector(userLoaded);
  const user = useSelector(userLogged);

  const { search } = useLocation();


  useEffect(() => userRef.current.focus(), []);

  useEffect(() => {
    dispatch(hideError());
    setMsg("");
    setIsError(false);
    setValidated(false);
  }, [pwd1, pwd2, dispatch]);

  useEffect(() => {
    mounted.current = true;
    if (mounted.current && status === "failed") setMsg(message);
  }, [status, dispatch]);

  useEffect(() => {
    mounted.current = true;
    if (!isLoaded) return;
    if (mounted.current && status === "succeeded") {
      navigate(user?.roles.includes(1) ? "/admin" : "/candidate", {
        replace: true,
      });
    }
    return () => mounted.current = false;
  }, [isLoaded, user, navigate, status]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    setValidated(true);

    if (pwd1 !== pwd2) {
      setMsg("The passwords do not match. Please try again.");
      setIsError(true);
    } else {
      const credentials = {
        password1: pwd1,
        password2: pwd2,
      };
      dispatch(LoginAuthAction(credentials));
    }
    errRef.current.focus();
  };

  return (
    <main className="Login position-relative">
      <div className="Login-container box position-absolute top-50 start-50 translate-middle p-5">
        {/* Invisible h1 added for accessibility requirements */}
        <h1 className="visually-hidden">victvs vx reset password</h1> 
        <div className="m-5">
          <img src={logo} alt="Logo" height="72" />
        </div>
        <Form
          id="form"
          className="m-5"
          noValidate
          validated={validated}
          onSubmit={handleSubmit}
        >
          <p
            ref={errRef}
            className={isError ? "error" : "offscreen"}
            aria-live="assertive"
          >
            {msg}
          </p>
          <Form.Group className="mb-4" controlId="password1">
            <Form.Label>Password</Form.Label>
            <InputGroup>
              <Form.Control
                ref={userRef}
                type={!isShowed1 ? "password" : "text"}
                value={pwd1}
                onChange={(event) => {
                  const password = event.target.value;
                  setPwd1(password);
                }}
                required
              />
              <InputGroup.Text>
                <i onClick={() => setIsShowed1(!isShowed1)}>
                  {
                    !isShowed1 ? 
                      <VisibilityIcon fontSize="small" /> :
                      <VisibilityOffIcon fontSize="small" />
                  }
                </i>
              </InputGroup.Text>
            </InputGroup>
          </Form.Group>
          <Form.Group className="mb-4" controlId="password2">
            <Form.Label>Repeat password</Form.Label>
            <InputGroup>
              <Form.Control
                type={!isShowed2 ? "password" : "text"}
                value={pwd2}
                onChange={(event) => {
                  const password = event.target.value;
                  setPwd2(password);
                }}
                required
              />
              <InputGroup.Text>
                <i onClick={() => setIsShowed2(!isShowed2)}>
                  {
                    !isShowed2 ? 
                      <VisibilityIcon fontSize="small" /> : 
                      <VisibilityOffIcon fontSize="small" />
                  }
                </i>
              </InputGroup.Text>
            </InputGroup>
          </Form.Group>
          <div className="my-4">
            <div className="mb-3"></div>
            <Button variant="dark" type="submit">
              Reset
            </Button>
          </div>
        </Form>
      </div>
    </main>
  );
}
