import { useEffect, useState, useRef } from "react";
import { Button } from "react-bootstrap";
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { easeLinear } from "d3-ease";

import PrintResults from "./PrintResults";
import AnimatedProgressProvider from '../AnimatedProgressProvider';
import 'react-circular-progressbar/dist/styles.css';


function SessionResult({ fullMarked, passed, marks, score }) {
  const [percentage, setPercentage] = useState(0);

  return (
    <div className="d-flex flex-column align-items-center justify-content-center">
      {
        fullMarked &&
        <>
          <div style={{ width: 96, height: 96 }}>
            <AnimatedProgressProvider
              valueStart={0}
              valueEnd={percentage}
              duration={0.5}
              easingFunction={easeLinear}
            >
              {
                value => {
                  const roundedValue = value.toFixed(2);
                  return (
                    <CircularProgressbar
                      value={value}
                      text={`${roundedValue}%`}
                      /* This is important to include, because if you're fully managing the
                        animation yourself, you'll want to disable the CSS animation. */
                      styles={buildStyles({
                        pathTransition: "none",
                        pathColor: '#020208',
                        textColor: '#020208',
                        strokeLinecap: "butt"
                      })}
                    />
                  );
                }
              }
            </AnimatedProgressProvider>
          </div>
          <p className="mt-2">You have scored {marks} out of {score}.</p>
        </>
      }
      <div className="my-2">
        {
          fullMarked ?
            passed ?
              <p>Congratulations! You have passed this assessment.</p> :
              <p>Unfortunately, you have not passed the assessment this time.</p>
            :
            <p>There are some answers that are pending marking.</p>
        }
      </div>
    </div>
  );
};

export default function SessionFinish() {
  const [score, setScore] = useState(0);
  const [marks, setMarks] = useState(0);
  const [passed, setPassed] = useState(0);
  const [fullMarked, setFullMarked] = useState(false);
  const [showAnswer, setShowAnswer] = useState(false);
  const mounted = useRef(null);
  const { session } = useSelector(store => store.sessions);
  const navigate = useNavigate();

  const handleNavigate = () => {
    navigate("/candidate/assessments", { replace: true });
  };


  useEffect(() => {
    mounted.current = true;
    if (Object.keys(session).length <= 0) return;

    if (mounted.current) {
      if (Object.keys(session).length > 0) {
        // check if session has finished
        if (Object.keys(session).includes("result")) {
          const { result, schedule } = session;
          const { showanswer } = schedule;
          const { marks, score } = result;
          const newPercentage = (marks / score * 100).toFixed(2);
          setScore(score);
          setMarks(marks);
          setShowAnswer(showanswer);
          //setPercentage(newPercentage);
          if (Object.keys(result).includes("passed")) {
            const { passed, marked } = result;
            setPassed(passed);
            setFullMarked(marked);
          }
        }
      }
    }

    return () => (mounted.current = false);
  }, [session]);

  /*
    CHANGED to generic assessment completion message for NALP demo changes
  */
  return (
    <div className="box d-flex flex-column align-items-center justify-content-center p-3 mb-2">
      <p className="fw-bold fs-3 text-center mb-1">Assessment Complete</p>
      <p className="text-muted">
        Congratulations! You have completed the test.
        {showAnswer && "You can see your results or download them below."}
      </p>
      <Button
        variant="dark"
        onClick={handleNavigate}
      >
        Back to Assessment List
      </Button>

      {showAnswer && <PrintResults />}
    </div>
  );
}
