import { Container, Button } from "react-bootstrap";

export default function OurFallbackComponent({
  error,
  componentStack,
  resetErrorBoundary,
}) {
  return (
    <Container style={{ height: "100vh" }}>
      <div
        className="h-100 d-flex flex-column justify-content-center align-items-center"
        style={{ height: "100%" }}
      >
        <h1>An error occurred: {error.message}</h1>
        <div className="flexGrow">
          <Button variant="dark" onClick={resetErrorBoundary}>
            Try again
          </Button>
        </div>
      </div>
    </Container>
  );
}
