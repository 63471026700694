import { useEffect, useRef, useState } from "react";
import parse from "html-react-parser";

export default function QuestionHeader({ questionHeader }) {
  const [title, setTitle] = useState("");
  const [userRole, setUserRole] = useState(null);
  const mounted = useRef(null);

  let questionText = questionHeader.qtext;
  const isHTML = RegExp.prototype.test.bind(/(<([^>]+)>)/i);

  useEffect(() => {
    mounted.current = true;
    if (title.length > 0) return;
    if (mounted.current && Object.keys(questionHeader).length > 0) {
      setTitle(parse(questionHeader.qtext));
    }
    return () => (mounted.current = false);
  }, [questionHeader]);

  useEffect(() => {
    mounted.current = true;
    if (Array.isArray(userRole)) return;
    const userSession = JSON.parse(sessionStorage.getItem("user"));
    const { roles } = userSession;
    setUserRole(roles);
    return () => (mounted.current = false);
  }, [userRole]);

   /* HELPER FUNCTIONS */
  const createSanitizedMarkup = () => {
    if (isHTML(questionText)) {
      questionText = cleanHTML(questionText);
      return {__html: questionText};
    }
  };

  const cleanHTML = (str) => {
    /**
     * Convert the string to an HTML document
     */
    function stringToHTML () {
      let parser = new DOMParser();
      let doc = parser.parseFromString(str, 'text/html');
      return doc.body || document.createElement('body');
    }
  
    /**
     * Remove <script> elements
     */
    function removeScripts (html) {
      let scripts = html.querySelectorAll('script');
      for (let script of scripts) {
        script.remove();
      }
    }
  
    /**
     * Check if the attribute is potentially dangerous
     */
    function isPossiblyDangerous (name, value) {
      let val = value.replace(/\s+/g, '').toLowerCase();
      if (['src', 'href', 'xlink:href'].includes(name)) {
        if (val.includes('javascript:') || val.includes('data:text/html')) return true;
      }
      if (name.startsWith('on')) return true;
    }
  
    /**
     * Remove potentially dangerous attributes from an element
     */
    function removeAttributes (elem) {
  
      // Loop through each attribute
      // If it's dangerous, remove it
      let atts = elem.attributes;
      for (let {name, value} of atts) {
        if (!isPossiblyDangerous(name, value)) continue;
        elem.removeAttribute(name);
      }
  
    }
  
    /**
     * Remove dangerous stuff from the HTML document's nodes
     */
    function clean (html) {
      let nodes = html.children;
      for (let node of nodes) {
        removeAttributes(node);
        clean(node);
      }
    }
  
    // Convert the string to HTML
    let html = stringToHTML();
  
    // Sanitize it
    removeScripts(html);
    clean(html);
  
    // Return a sanitized string back
    return html.innerHTML;
  };

  return (
    <div>
      {/*
        Adds an Admin title which is the same as the question header text,
        may be a legacy part of the code, will leave here as a precaution.

        Array.isArray(userRole) && 
        userRole.includes(1) && 
        <p className="m-0">{questionHeader.admintitle}</p>
      */}
      {
        isHTML(questionText) ? 
          <div className="my-3" dangerouslySetInnerHTML={createSanitizedMarkup()} /> : 
          questionText
      } 
      {<p className="my-2">{title}</p>}
      {
        questionHeader.videocount > 0 ? 
          questionHeader.videos.map((video, i) => {
            return (
              <div
                className="text-center m-4 row justify-content-center"
                key={i}
              >
                <video
                  style={{
                    width: "auto",
                    height: "300px",
                    objectFit: "contain",
                  }}
                  alt={`Video_${i}`}
                  controls
                >
                  <source src={video.direct_url} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              </div>
            );
          }) : 
        ""
      }
      {
        questionHeader.imagecount > 0 ? 
          questionHeader.images.map((img, i) => {
            return (
              <div
                className="text-center m-4 row justify-content-center"
                key={i}
              >
                <img
                  alt={`Img_${img.imid}`}
                  style={{
                    width: "auto",
                    height: "300px",
                    objectFit: "contain",
                  }}
                  src={img.direct_url}
                  id={img.imid}
                  key={img.imid}
                  className=""
                />
              </div>
            );
          }) : 
        ""
      }
    </div>
  );
}
