/* REACT PACKAGE IMPORTS */
import { useRef, useEffect, useState } from "react";
import { Routes, Route } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

/* INTERNAL IMPORTS */
/* ADMIN PAGES */
import Sessions from "./Sessions";
import Session from "./Session";
import QuestionBank from "./QuestionBank";
import Assessments from "./Assessments";
import Assessment from "./Assessment";
import NoMatch from "./NoMatch";
import Question from "./Question";
import Profile from "./Profile";
import Settings from "./Settings";

import { fetchOrganisation } from "../Features/Sessions/Actions/SessionsActions";
import { fetchUser } from "../Features/Users/Actions/UsersActions";
import { timeAgo } from "../Components/DateTimeFormatter";

const ORGANISATION_LIST = {
  1: 'Chessish',
  2: "VICTVS",
  3: "CIPS",
  4: "IWCF",
  7: "GMC",
  8: "VICTVS DEMO",
  9: "Test",
  10: "ISVPS",
  11: "RCEM",
  12: "NALP"
};


export default function Home() {
  const [title, setTitle] = useState("");
  const [paragraph, setParagraph] = useState("");
  const [subParagraph, setSubParagraph] = useState("");

  /* Steps, step index for joyride props */
  const [run, setRun] = useState(false)
  const [steps, setSteps] = useState([]);
  const [stepIndex, setStepIndex] = useState(0);

  const { organisation } = useSelector((store) => store.auth);
  const { user } = useSelector((store) => store.users);
  const mounted = useRef(null);
  const dispatch = useDispatch();

  useEffect(() => {
    if (organisation !== 0) setSubParagraph(`Current Organisation: ${ORGANISATION_LIST[organisation]}`);
  }, [organisation]);

  useEffect(() => {
    mounted.current = true;
    if (Object.keys(user).length === 0) {
      dispatch(fetchUser());
      return;
    }
    if (mounted.current) {
      setTitle(`Welcome back, ${user.firstname}`);
      setParagraph(`Last logged in ${timeAgo(user.lastlogin)}`);
      setSubParagraph(`Current Organisation: ~`);
    }
    return () => (mounted.current = false);
  }, [dispatch, user]);

  return (
    <Routes>
      <Route path="/">
        <Route
          path="sessions"
          element={
            <Sessions
              tabs={[
                { name: "Today's sessions", value: "1" },
                { name: "All sessions", value: "2" },
                { name: "My sessions", value: "3" },
              ]}
              paragraph={paragraph}
              subParagraph={subParagraph}
              title={title}
            />
          }
        />
        <Route
          path="sessions/:id"
          element={
            <Session
              tabs={[
                { name: "Overview", value: "1" },
                { name: "Users", value: "2" },
                { name: "Results", value: "3" },
                { name: "Session notes", value: "4" },
              ]}
            />
          }
        />
        <Route
          path="question_bank"
          element={
            <QuestionBank
              tabs={[
                { name: "My questions", value: "1" },
                { name: "All questions", value: "2" },
              ]}
              paragraph={paragraph}
              subParagraph={subParagraph}
              title={title}
            />
          }
        />
        <Route
          path="question_bank/:id"
          element={
            <Question
              tabs={[
                { name: "View question", value: "1" },
                { name: "Edit question", value: "2" },
                { name: "Question notes", value: "3" },
              ]}
            />
          }
        />
        <Route
          path="assessments"
          element={
            <Assessments
              tabs={[
                { name: "My assessments", value: "1" },
                { name: "All assessments", value: "2" },
              ]}
              paragraph={paragraph}
              subParagraph={subParagraph}
              title={title}
            />
          }
        />
        <Route
          path="assessments/:id"
          element={
            <Assessment
              tabs={[
                { name: "Overview", value: "1" },
                { name: "Sections", value: "2" },
                { name: "Assessment notes", value: "3" },
              ]}
            />
          }
        />
        <Route
          path="profile"
          element={
            <Profile
              paragraph={paragraph}
              subParagraph={subParagraph}
              title={title}
              tabs={[
                { name: "My profile", value: "1" },
                // { name: "Users", value: "2" },
                // { name: "Imagery", value: "3" },
                // { name: "Notes", value: "4" },
                // { name: "Documents", value: "5" },
              ]}
            />
          }
        />
        <Route
          path="settings"
          element={
            <Settings
              setSteps={setSteps}
              setRun={setRun}
              paragraph={paragraph} 
              title={title} 
            />
          }
        />
        {/* Using path="*"" means "match anything", so this route
          acts like a catch-all for URLs that we don't have explicit
          routes for. */}
        <Route path="*" element={<NoMatch />} />
      </Route>
    </Routes>
  );
}
