/* REACT PACKAGE IMPORTS */
import { useEffect, useState, useRef } from "react";
import { Container } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

/* INTERNAL IMPORTS */
import Header from "../Components/Header";
import SubNavbar from "../Components/SubNavbar";
import Overview from "../Components/Assessments/Overview";
import FormComponent from "../Components/Form";
import EditAssessment from "../Components/Assessments/EditAssessment";
import ViewAssessment from "../Components/Assessments/ViewAssessment";
import Notes from "../Components/Notes";
import { LocalDateTime } from "../Components/DateTimeFormatter";
import {
  fetchAssessment,
  updateAssessment,
} from "../Features/Assessments/Actions/AssessmentsActions";

import edit from "../assets/group-edit.svg";
import "../styles/Question.css";

export default function Assessment({ tabs }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  let { id } = useParams();
  const examId = parseInt(id);

  const { assessment } = useSelector(store => store.assessments);

  const [isFormVisible, setFormVisible] = useState(false);
  const [paragraph, setParagraph] = useState("");
  const [title, setTitle] = useState("");
  const [apiCalled, setApiCalled] = useState(false);
  const [radioValue, setRadioValue] = useState("1");

  const mounted = useRef(null);
 

  const handleChange = (e) => {
    let value = e.currentTarget.value;
    setRadioValue(value);
  };

  const updateAssessmentHandle = (data) => {
    if (mounted.current) {
      dispatch(updateAssessment({ data, examId })).unwrap();
      setFormVisible(false);
    }
  };

  useEffect(() => {
    mounted.current = true;
    if (mounted.current) {
      const assessment_id = parseInt(id);

      if (isNaN(assessment_id)) navigate("/admin/error", { replace: true });
      else {
        dispatch(fetchAssessment(assessment_id))
          .unwrap()
          .then((response) => {
            document.title = response?.examtitle;
            setTitle(response?.examtitle);
            setApiCalled(true);
            setParagraph(
              "Assessment created by " +
                response?.createdby?.firstname +
                " " +
                response?.createdby?.lastname +
                ", " +
                LocalDateTime(response?.created)
            );
          })
          .catch(() => navigate("/admin/error", { replace: true }));
      }
    }

    return () => mounted.current = false;
  }, [id]);

  return (
    apiCalled && (
      <Container className="py-4 position-relative">
        <Header paragraph={paragraph} title={title}>
          <SubNavbar
            radioValue={radioValue}
            tabs={tabs}
            handleChange={handleChange}
          />
        </Header>

        {radioValue === "1" && (
          <>  
            <Overview>
              {Object.keys(assessment).length > 0 && (                
                <FormComponent
                  title="Update assessment"
                  selects={[]}
                  multipleValues={[]}
                  inputs={[
                    {
                      label: "Assessment title",
                      type: "textarea",
                      id: "examtitle",
                      value: assessment.examtitle,
                      required: true,
                    },
                    {
                      label: "Assessment instructions",
                      type: "textarea",
                      id: "instructions",
                      value: assessment.instructions,
                      required: false,
                    },
                    {
                      label: "Assessment duration (hh:mm)",
                      type: "time",
                      id: "duration",
                      value: assessment.duration.stringshort,
                      required: true,
                    },
                    {
                      label: "Pass mark",
                      type: "number",
                      id: "passmark",
                      value: assessment.passmark,
                      required: false,
                    },
                    {
                      label: "Pass percentage (%)",
                      type: "number",
                      id: "passpercent",
                      value: assessment.passpercent,
                      required: false,
                    },
                  ]}
                  multipleSelects={[]}
                  button="Update assessment"
                  logo={edit}
                  logoClass="position-absolute top-0 start-100 translate-middle button-edit"
                  action={updateAssessmentHandle}
                  isFormVisible={isFormVisible}
                  setFormVisible={setFormVisible}
                />
              )}
            </Overview>
            <ViewAssessment />
          </>
        )}
        {radioValue === "2" && <EditAssessment />}
        {radioValue === "3" && <Notes id={id} type="exams" title={"Assessment Notes"} />}
      </Container>
    )
  );
}
