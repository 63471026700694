/* REACT PACKAGE IMPORTS */
import { useEffect, useState, useRef } from "react";
import { Button, Form } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

/* MUI PACKAGE IMPORTS */
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";

/* INTERNAL IMPORTS */
import Modal from "../Modal";
import { deleteSchedule } from "../../Features/Sessions/Actions/SessionsActions";
import { DateTimeFormatter } from "../../Components/DateTimeFormatter";
import { updateSchedule } from "../../Features/Sessions/Actions/SessionsActions";

export default function Overview({ children }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  let { id } = useParams();
  const schId = parseInt(id);

  const [showModal, setShowModal] = useState(false);
  const [show, setShow] = useState(false);
  const [button, setButton] = useState("");
  const [msg, setMsg] = useState("");
  const { schedule } = useSelector((store) => store.sessions);
  
  const mounted = useRef(null);


  const deleteScheduleHandler = () => {
    dispatch(deleteSchedule(schId))
      .then((response) => {
        if (response.error) return;
        setShowModal(false);
        navigate("/admin/sessions", { replace: true });
      });
  };

  const handleToggleShow = () => {
    const schstate = { showanswer: !show };
    dispatch(updateSchedule({ schId: schId, schstate }));
    setShow(!show);
  };

  useEffect(() => {
    mounted.current = true;
    if (mounted.current) {
      if (Object.keys(schedule).length > 0) {
        if (schedule.showanswer) setShow(true);
      }
    }
  }, [schedule]);

  return (
    <div className="box my-4 p-4 position-relative">
      {
        schedule.description && (
        <>
          <p>{schedule.description}</p>
          <hr />
        </>
      )
      }
      <div className="d-flex align-items-center my-2">
        <p className="m-0 me-4"><strong>Assessment:</strong> {schedule?.exam?.examtitle}</p>
        <Tooltip title="Open assessment">
          <IconButton
            aria-label="Open assessment"
            onClick={() => {
              window.open(`/admin/assessments/${schedule?.exam?.exid}`);
            }}
          >
            <OpenInNewIcon fontSize="small" />
          </IconButton>
        </Tooltip>
      </div>
      <p>
        <strong>Start time:</strong> {DateTimeFormatter(schedule.starttime)}
      </p>
      {schedule.venue && (
        <p>
          <strong>Venue:</strong> {schedule.venue.vname} (
          {schedule.venue.address}, {schedule.venue.country.shortname})
        </p>
      )}
      <p>
        <strong>Time limit:</strong> {schedule?.exam?.duration?.stringshort}
      </p>
      
      {
        (schedule?.exam?.passmark) ? 
          <p><strong>Pass mark:</strong> {schedule?.exam?.passmark}</p> :
          <p><strong>Pass percent:</strong> {schedule?.exam?.passpercent}%</p>
      }
      <Form id="formAssessment">
        <Form.Group className="my-3" controlId="extratime">
          <Form.Check
            type="checkbox"
            label="Show correct answers to user when finished"
            checked={show}
            onChange={handleToggleShow}
          />
        </Form.Group>
      </Form>
      <div className="text-end">
        <Tooltip title="Delete session">
          <Button
            variant="dark"
            aria-label="Delete session"
            className="me-2"
            onClick={() => {
              setShowModal(true);
              setMsg(
                `Are you sure you want remove the schedule "${schedule?.exam?.examtitle}"? This is an irreversible action.`
              );
              setButton("Delete");
            }}
          >
            Delete session
          </Button>
        </Tooltip>
      </div>
      {children}
      <Modal
        setShowModal={setShowModal}
        show={showModal}
        handleSubmit={deleteScheduleHandler}
        msg={msg}
        button={button}
      />
    </div>
  );
}
