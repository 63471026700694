import { useState } from "react";
import { Rect, Circle, Line } from "react-konva";

const totalCoords = (coords, imgWidth, imgHeight) => {
  return coords.map((coord, i) => {
    return i % 2 === 0 ? coord * imgWidth : coord * imgHeight;
  });
};

export default function Area({
  area,
  imgWidth,
  imgHeight,
  setAreas,
  editable,
}) {
  const [isDragging, setIsDragging] = useState(null);
  const { type, id, fillColor, coords } = area;
  switch (type) {
    case "rect":
      return (
        <Rect
          key={id}
          x={coords[0] * imgWidth}
          y={coords[1] * imgHeight}
          width={(coords[2] - coords[0]) * imgWidth}
          height={(coords[5] - coords[1]) * imgHeight}
          stroke={
            fillColor.substring(0, fillColor.lastIndexOf(",")) +
            fillColor.substring(fillColor.lastIndexOf(")"))
          }
          strokeWidth={2}
          onMouseEnter={(e) => {
            var a = fillColor.substring(0, fillColor.lastIndexOf(","));
            var b = fillColor.substring(fillColor.lastIndexOf(")"));
            e.target.fill(a + b);
          }}
          onMouseLeave={(e) => {
            e.target.fill(fillColor);
          }}
          draggable={editable}
          fill={isDragging ? "black" : fillColor}
          onDragStart={() => {
            setIsDragging(true);
          }}
          onDragEnd={(e) => {
            setIsDragging(false);
            setAreas((curr) => {
              return curr.map((area) => {
                if (area.id === id) {
                  var coords = [
                    e.target.x() / imgWidth,
                    e.target.y() / imgHeight,
                    (e.target.x() + e.target.width()) / imgWidth,
                    e.target.y() / imgHeight,
                    (e.target.x() + e.target.width()) / imgWidth,
                    (e.target.y() + e.target.height()) / imgHeight,
                    e.target.x() / imgWidth,
                    (e.target.y() + e.target.height()) / imgHeight,
                  ];
                  return { ...area, coords };
                } else {
                  return area;
                }
              });
            });
          }}
          onClick={(e) => {
            if (e.target.stroke() === "blue") {
              e.target.stroke(
                fillColor.substring(0, fillColor.lastIndexOf(",")) +
                  fillColor.substring(fillColor.lastIndexOf(")"))
              );
              e.target.strokeWidth(2);
              // e.target.shadowBlur(null);
              // e.target.shadowColor(null);
            } else {
              console.log(e.target.attrs);
              console.log(area);
              e.target.stroke("blue");
              e.target.strokeWidth(4);
              // e.target.shadowBlur(10);
              // e.target.shadowColor(fillColor);
            }
          }}
        />
      );
    case "circle":
      return (
        <Circle
          key={id}
          x={coords[0] * imgWidth}
          y={coords[1] * imgHeight}
          radius={coords[2] * imgWidth}
          stroke={
            fillColor.substring(0, fillColor.lastIndexOf(",")) +
            fillColor.substring(fillColor.lastIndexOf(")"))
          }
          strokeWidth={2}
          onMouseEnter={(e) => {
            var a = fillColor.substring(0, fillColor.lastIndexOf(","));
            var b = fillColor.substring(fillColor.lastIndexOf(")"));
            e.target.fill(a + b);
          }}
          onMouseLeave={(e) => {
            e.target.fill(fillColor);
          }}
          draggable={editable}
          fill={isDragging ? "black" : fillColor}
          onDragStart={() => {
            setIsDragging(true);
          }}
          onDragEnd={(e) => {
            setIsDragging(false);
            setAreas((curr) => {
              return curr.map((area) => {
                if (area.id === id) {
                  var coords = [
                    e.target.x() / imgWidth,
                    e.target.y() / imgHeight,
                    e.target.radius() / imgWidth,
                  ];
                  return { ...area, coords };
                } else {
                  return area;
                }
              });
            });
          }}
          onClick={(e) => {
            if (e.target.stroke() === "blue") {
              e.target.stroke(
                fillColor.substring(0, fillColor.lastIndexOf(",")) +
                  fillColor.substring(fillColor.lastIndexOf(")"))
              );
              e.target.strokeWidth(2);
              // e.target.shadowBlur(null);
              // e.target.shadowColor(null);
            } else {
              console.log(e.target.attrs);
              console.log(area);
              e.target.stroke("blue");
              e.target.strokeWidth(4);
              // e.target.shadowBlur(10);
              // e.target.shadowColor(fillColor);
            }
          }}
        />
      );
    case "poly":
      return (
        <Line
          key={id}
          x={0}
          y={0}
          points={totalCoords(coords, imgWidth, imgHeight)}
          closed
          stroke={
            fillColor.substring(0, fillColor.lastIndexOf(",")) +
            fillColor.substring(fillColor.lastIndexOf(")"))
          }
          strokeWidth={2}
          onMouseEnter={(e) => {
            var a = fillColor.substring(0, fillColor.lastIndexOf(","));
            var b = fillColor.substring(fillColor.lastIndexOf(")"));
            e.target.fill(a + b);
          }}
          onMouseLeave={(e) => {
            e.target.fill(fillColor);
          }}
          draggable={editable}
          fill={isDragging ? "black" : fillColor}
          onDragStart={() => {
            setIsDragging(true);
          }}
          onDragEnd={(e) => {
            setIsDragging(false);
            setAreas((curr) => {
              return curr.map((area) => {
                if (area.id === id) {
                  var coords = area.coords.map((coord, i) => {
                    return i % 2 === 0
                      ? coord + e.target.x() / imgWidth
                      : coord + e.target.y() / imgHeight;
                  });
                  return { ...area, coords };
                } else {
                  return area;
                }
              });
            });
            e.target.x(0);
            e.target.y(0);
          }}
          onClick={(e) => {
            if (e.target.stroke() === "blue") {
              e.target.stroke(
                fillColor.substring(0, fillColor.lastIndexOf(",")) +
                  fillColor.substring(fillColor.lastIndexOf(")"))
              );
              e.target.strokeWidth(2);
              // e.target.shadowBlur(null);
              // e.target.shadowColor(null);
            } else {
              console.log(e.target.attrs);
              console.log(area);
              e.target.stroke("blue");
              e.target.strokeWidth(4);
              // e.target.shadowBlur(10);
              // e.target.shadowColor(fillColor);
            }
          }}
        />
      );
    case "spot":
      return (
        <Circle
          key={id}
          x={coords[0] * imgWidth}
          y={coords[1] * imgHeight}
          radius={0.008 * imgWidth}
          stroke={
            fillColor.substring(0, fillColor.lastIndexOf(",")) +
            fillColor.substring(fillColor.lastIndexOf(")"))
          }
          strokeWidth={5}
          onMouseEnter={(e) => {
            var a = fillColor.substring(0, fillColor.lastIndexOf(","));
            var b = fillColor.substring(fillColor.lastIndexOf(")"));
            e.target.fill(a + b);
          }}
          onMouseLeave={(e) => {
            e.target.fill(fillColor);
          }}
          draggable={editable}
          fill={isDragging ? "black" : fillColor}
          onDragStart={() => {
            setIsDragging(true);
          }}
          onDragEnd={(e) => {
            setIsDragging(false);
            setAreas((curr) => {
              return curr.map((area) => {
                if (area.id === id) {
                  var coords = [
                    e.target.x() / imgWidth,
                    e.target.y() / imgHeight,
                  ];
                  return { ...area, coords };
                } else {
                  return area;
                }
              });
            });
          }}
          onClick={(e) => {
            if (e.target.stroke() === "blue") {
              e.target.stroke(
                fillColor.substring(0, fillColor.lastIndexOf(",")) +
                  fillColor.substring(fillColor.lastIndexOf(")"))
              );
              // e.target.shadowBlur(null);
              // e.target.shadowColor(null);
            } else {
              console.log(e.target.attrs);
              console.log(area);
              e.target.stroke("blue");
              // e.target.shadowBlur(10);
              // e.target.shadowColor(fillColor);
            }
          }}
        />
      );
    default:
      return null;
  }
}
