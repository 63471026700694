/* REACT PACKAGE IMPORTS */
import { useEffect, useState, useRef } from "react";

/* INTERNAL IMPORTS */
import SingleChoice from "./View/SingleChoice";
import SortableList from "./View/SortableList";
import VideoPlayer from "./View/VideoPlayer";
import HotspotView from "./View/Hotspot";
import HotspotMap from "./View/HotspotMap";
import MultipleChoice from "./View/MultipleChoice";
import FreeWriting from "./View/FreeWriting";
import FillInBlank from "./View/FillInBlank";
import InlineChoice from "./View/InlineChoice";
import Matching from "./View/Matching";
import DropBlanks from "./View/DropBlanks";
import DragDropChoice from "./View/DragDropChoice";


export default function QuestionComponent({
  questionType,
  questionHeader,
  question,
  answers,
  setUserAnswerArray,
  setIsComplete
}) {
  const [questionComponent, setComponent] = useState(null);
  const userAnswer = answers.length > 0 ? answers.filter(answer => answer.qid === question.qid) : [];
  const mounted = useRef(null);

  useEffect(() => {
    mounted.current = true;
    if (questionComponent !== null) return;
    if (mounted.current) {
      switch (questionType) {
        case ("singlechoice"):
          setComponent(
            <SingleChoice
              question={question}
              answer={userAnswer}
              setUserAnswerArray={setUserAnswerArray}
            />
          );
          break;
        case ("multichoice"):
          setComponent(
            <MultipleChoice
              question={question}
              answer={userAnswer}
              setUserAnswerArray={setUserAnswerArray}
            />
          );
          break;
        case ("freetext"):
          setComponent(
            <FreeWriting
              question={question}
              answer={userAnswer}
              setUserAnswerArray={setUserAnswerArray}
            />
          );
          break;
        case ("sorting"):
          setComponent(
            <SortableList
              question={question}
              answer={userAnswer}
              setUserAnswerArray={setUserAnswerArray}
            />
          );
          break;
        case ("matching"):
          setComponent(
            <Matching
              question={question}
              answer={userAnswer}
              setUserAnswerArray={setUserAnswerArray}
              setIsComplete={setIsComplete}
            />
          );
          break;
        case ("fillblanks"):
          setComponent(
            <FillInBlank
              question={question}
              answer={userAnswer}
              setUserAnswerArray={setUserAnswerArray}
            />
          );
          break;
        case ("dropblanks"):
          setComponent(
            <DropBlanks
              question={question}
              answer={userAnswer}
              setUserAnswerArray={setUserAnswerArray}
            />
          );
          break;
        case ("draganddropchoice"):
          setComponent(
            <DragDropChoice
              question={question}
              answer={userAnswer}
              setUserAnswerArray={setUserAnswerArray}
            />
          );
          break;
        case ("dropdownchoice"):
          setComponent(
            <InlineChoice
              question={question}
              answer={userAnswer}
              setUserAnswerArray={setUserAnswerArray}
            />
          );
          break;
        case ("hotspot"):
          setComponent(
            <HotspotView
              questionHeader={questionHeader}
              question={question}
              answer={userAnswer}
              setUserAnswerArray={setUserAnswerArray}
            />
          );
          break;
        case ("hotspot-map"):
          setComponent(
            <HotspotMap
              question={question}
              answer={userAnswer}
              setUserAnswerArray={setUserAnswerArray}
            />
          );
          break;
        case ("video"):
          setComponent(
            <VideoPlayer
              question={question}
              answer={userAnswer}
              setUserAnswerArray={setUserAnswerArray}
            />
          );
          break;
        default:
          setComponent(null);
          console.log("Unrecognized question type");
          break;
      }
    };

    return () => (mounted.current = false);
  }, [question]);

  return questionComponent;
};