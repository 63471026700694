/* REACT PACKAGE IMPORTS */
import { useEffect, useState, useRef } from "react";
import { Container } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

/* INTERNAL IMPORTS */
import Header from "../Components/Header";
import SubNavbar from "../Components/SubNavbar";
import EditQuestion from "../Components/Questions/EditQuestion";
import ViewQuestion from "../Components/Questions/ViewQuestion";
import Notes from "../Components/Notes";
import { LocalDateTime } from "../Components/DateTimeFormatter";
import { fetchQuestionHeader } from "../Features/Questions/Actions/QuestionActions";

import "../styles/Question.css";


export default function Question({ tabs }) {

  const dispatch = useDispatch();
  const navigate = useNavigate();

  let { id } = useParams();
  const qid = parseInt(id);

  const { status, questions, questionHeader } = useSelector(store => store.question);

  const [apiCalled, setApiCalled] = useState(false);
  const [questionHeaderTitle, setQuestionHeaderTitle] = useState("Question Header");
  const [paragraph, setParagraph] = useState("");
  const [radioValue, setRadioValue] = useState("1");
  const [userAnswerArray, setUserAnswerArray] = useState([]);

  const mounted = useRef(null);

  const handleChange = (e) => {
    let value = e.currentTarget.value;
    setRadioValue(value);
  };

  useEffect(() => {
    mounted.current = true;
    if (apiCalled) return;
    if (mounted.current) {
      if (isNaN(qid)) {
        navigate("/admin/error", { replace: true });
      } else {
        dispatch(fetchQuestionHeader(qid))
          .unwrap()
          .then((response) => {
            document.title = response?.admintitle;
            setParagraph(
              "Question created by " +
                response?.createdby.firstname +
                " " +
                response?.createdby.lastname +
                ", " +
                LocalDateTime(response?.created)
            );
            setQuestionHeaderTitle(response?.admintitle);
            setApiCalled(true);
          })
          .catch(() => {
            navigate("/admin/error", { replace: true });
          });
      }
    }

    return () => (mounted.current = false);
  }, [qid, status, dispatch, apiCalled, navigate]);

  return (
    apiCalled && (
      <Container className="py-4 position-relative">
        <Header paragraph={paragraph} title={questionHeaderTitle}>
          <h1 className="visually-hidden">Question</h1>
          <SubNavbar
            radioValue={radioValue}
            tabs={tabs}
            handleChange={handleChange}
          />
        </Header>
        {radioValue === "1" && (
          <ViewQuestion
            questions={questions}
            questionHeader={questionHeader}
            setUserAnswerArray={setUserAnswerArray}
            userAnswerArray={userAnswerArray}
          />
        )}
        {
          radioValue === "2" && 
          <EditQuestion questions={questions} questionHeader={questionHeader} />
        }
        {
          radioValue === "3" && 
          <Notes id={id} type="questionheaders" title={"Question Header Notes"} />
        }
      </Container>
    )
  );
}
