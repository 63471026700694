import { useRef, useEffect } from "react";
import { Modal } from "react-bootstrap";
import PinField from "react-pin-field";
import { useSelector } from "react-redux";

export default function PinModal({
  show,
  setShowModal,
  handleSubmit,
  inputPinRef,
}) {
  const { sessionStatus } = useSelector((store) => store.sessions);
  const mounted = useRef(null);

  useEffect(() => {
    mounted.current = true;
    if (inputPinRef.current) {
      inputPinRef.current.inputs[0].focus();
    }
    return () => (mounted.current = false);
  });

  useEffect(() => {
    mounted.current = true;
    if (sessionStatus === null) {
      return;
    }
    if (mounted.current) {
      if (sessionStatus === "started") {
        setShowModal(false);
      } else if (sessionStatus === "rejected") {
        setShowModal(true);
      } else {
        return;
      }
    }
    return () => (mounted.current = false);
  }, [sessionStatus]);

  return (
    <Modal show={show} onHide={() => setShowModal(false)}>
      <Modal.Header closeButton>
        <Modal.Title>Enter your assigned pin!</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="d-grid justify-content-center">
          <PinField
            ref={inputPinRef}
            length={6}
            onComplete={handleSubmit}
            validate="0123456789"
            inputMode="numeric"
            type="password"
            className="pin-field"
          />
        </div>
      </Modal.Body>
    </Modal>
  );
}
