/* REACT PACKAGE IMPORTS */
import { useEffect, useState, useRef, useContext } from "react";
import { useSelector } from "react-redux";
import { Routes, Route, Navigate } from "react-router-dom";

/* INTERNAL IMPORTS */
// Pages
import Admin from "./Pages/Admin";
import AdminLogin from "./Pages/AdminLogin";
import Candidate from "./Pages/Candidate";
import CandidateLogin from "./Pages/CandidateLogin";
import ForgotPassword from "./Pages/ForgotPassword";
import ForgotUsername from "./Pages/ForgotUsername";
import NoMatch from "./Pages/NoMatch";
import OurFallbackComponent from "./Pages/OurFallbackComponent";
import Registration from './Pages/Registration';
import ResetPassword from "./Pages/ResetPassword";
import Unauthorized from "./Pages/Unauthorized";

// import Login from "./Pages/Login";
// import Register from "./Pages/Register";

// Components
import Layout from "./Components/Layout";
import ErrorBoundary from "./Components/ErrorBoundary";
import SimpleSnackbar from "./Components/SnackBar";
import RequireAuth from "./Components/RequireAuth";

// Styling
import "./styles/App.css";
import "./styles/theme.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { ThemeContext } from "./Theme/ThemeContext";

// Images
import logovx from "./assets/VICTVS-ONE-LOGO.png";

const ROLES = {
  Admin: 1,
  Editor: 2,
  Candidate: 3,
};


export default function App() {
  const context = useContext(ThemeContext);
  const [logoID, setLogoId] = useState(logovx);
  const [isEmbedded, setIsEmbedded] = useState(false);
  const { userInfo } = useSelector(store => store.auth);
  const mounted = useRef(null);


  useEffect(() => {
    mounted.current = true;
    if (mounted.current) {
      if (userInfo) {
        const { theme } = userInfo;
        var body = document.querySelector("body");
        if (theme.length === 0) {
          body.style.setProperty("--bkg-color", "#fafafa");
          body.style.setProperty("--bkgsecondary", "#ffffff");
          body.style.setProperty("--text-color", "#020208");
          context.toggleColorMode("light");
          setLogoId(logovx);
          return;
        } else {
          const themeObj = JSON.parse(theme);
          const primarycolor = themeObj.primarycolor;
          const secondarycolor = themeObj.secondarycolor;
          const fontcolor = themeObj.fontcolor;
          const logo = themeObj.logo;
          const mode = themeObj.mode;
          body.style.setProperty("--bkg-color", primarycolor);
          body.style.setProperty("--bkgsecondary", secondarycolor);
          body.style.setProperty("--text-color", fontcolor);
          if (mode !== null) context.toggleColorMode(mode);
          else context.toggleColorMode("light");
          if (logo !== null) setLogoId(logo);
          else setLogoId(logovx);
          return;
        }
      }
    }
  }, [userInfo, logoID, context]);

  useEffect(() => {
    const isEmbeddedIframe = window && window.parent && window.location !== window.parent.location;
    if (isEmbeddedIframe) setIsEmbedded(true);
  }, []);

  return (
    <ErrorBoundary FallbackComponent={OurFallbackComponent}>
      {/* show errors */}
      <SimpleSnackbar />

      <Routes>
        {/* public routes */}
        <Route
          path="admin-login"
          element={<AdminLogin logo={logoID} />}
        />
        <Route 
          path="login" 
          element={<CandidateLogin logo={logoID}/>} 
        />
        <Route 
          path="login?" 
          element={<CandidateLogin logo={logoID}/>} 
        />
        <Route 
          path="register" 
          element={<Registration logo={logoID} />} 
        />
        <Route
          path="forgot-password"
          element={<ForgotPassword logo={logoID} />}
        />
        <Route
          path="forgot-username"
          element={<ForgotUsername logo={logoID} />}
        />
        <Route
          path="reset-password"
          element={<ResetPassword logo={logoID} />}
        />

        {/* we want to protect these admin routes */}
        <Route path="/" element={<RequireAuth allowedRoles={[ROLES.Admin]} />}>
          <Route path="/" element={<Navigate to="/admin/sessions" />} />
          <Route path="/admin" element={<Navigate to="/admin/sessions" />} />
          <Route
            path="/admin"
            element={
              <Layout
                isEmbedded={isEmbedded}
                logo={logoID}
                tabs={["Sessions", "Assessments", "Question bank"]}
              />
            }
          >
            <Route path="*" element={<Admin />} />
            <Route path="/admin/error" element={<NoMatch />} />
          </Route>
        </Route>
        
        {/* candidate routes */}
        <Route
          path="/"
          element={<RequireAuth allowedRoles={[ROLES.Admin, ROLES.Candidate]} />}
        >
          <Route path="/" element={<Navigate to="/candidate/assessments" />} />
          <Route
            path="/candidate"
            element={<Navigate to="/candidate/assessments" />}
          />
          <Route
            path="candidate"
            element={<Layout isEmbedded={isEmbedded} logo={logoID} tabs={["Home"]} />}
          >
            <Route path="*" element={<Candidate />} />
            <Route path="/candidate/error" element={<NoMatch />} />
          </Route>
        </Route>

        {/* catch all */}
        <Route path="unauthorized" element={<Unauthorized />} />
        {/* catch all */}
        <Route path="*" element={<NoMatch />} />
      </Routes>
    </ErrorBoundary>
  );
}
