import { useState, useRef, useEffect } from "react";
import { Editor, EditorState, RichUtils } from "draft-js";
import { BootstrapButton } from "../styles/StyledComponents";
import { Offcanvas } from "react-bootstrap";
import {
  ToggleButtonGroup,
  ToggleButton,
  IconButton,
  Tooltip,
  FormControl,
  Select,
  InputLabel,
  MenuItem,
} from "@mui/material";
import FormatBoldIcon from "@mui/icons-material/FormatBold";
import FormatItalicIcon from "@mui/icons-material/FormatItalic";
import FormatUnderlinedIcon from "@mui/icons-material/FormatUnderlined";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import "draft-js/dist/Draft.css";
import "../styles/TextEditor.css";

const BLOCK_TYPES = [
  { label: " “ ” ", style: "blockquote" },
  { label: "UL", style: "unordered-list-item" },
  { label: "OL", style: "ordered-list-item" },
  { label: "{ }", style: "code-block" },
];

const HEADER_TYPES = [
  { label: "(None)", style: "unstyled" },
  { label: "H1", style: "header-one" },
  { label: "H2", style: "header-two" },
  { label: "H3", style: "header-three" },
  { label: "H4", style: "header-four" },
  { label: "H5", style: "header-five" },
  { label: "H6", style: "header-six" },
];

function getBlockStyle(block) {
  switch (block.getType()) {
    case "blockquote":
      return "RichEditor-blockquote";
    default:
      return null;
  }
}

const BlockStyleButton = ({ label, style, active, onToggle }) => {
  const [className, setClassName] = useState("");
  const onToggleHandler = (e) => {
    e.preventDefault();
    onToggle(style);
  };
  const mounted = useRef(null);

  useEffect(() => {
    mounted.current = true;
    if (mounted.current) {
      setClassName("button");
      if (active) {
        setClassName((current) => (current += " text-active"));
      }
    }
    return () => (mounted.current = false);
  }, [className, active]);

  return (
    <ToggleButton
      size="small"
      value={style}
      aria-label={style}
      className={className}
      onClick={onToggleHandler}
    >
      {label}
    </ToggleButton>
  );
};

const HeaderStyleDropdown = ({ active, onToggle, headerOptions }) => {
  const [header, setHeader] = useState(active);
  const onToggleHandler = (event) => {
    let value = event.target.value;
    setHeader(value);
    onToggle(value);
  };

  return (
    <FormControl size="small">
      <InputLabel id="demo-simple-select-helper-label">Header</InputLabel>
      <Select
        label="Header Levels"
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={header}
        onChange={onToggleHandler}
      >
        {headerOptions.map((heading, i) => {
          return (
            <MenuItem key={i} value={heading.style}>
              {heading.label}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};

const BlockStyleToolbar = ({ editorState, onToggle }) => {
  const [formats, setFormats] = useState([]);

  const selection = editorState.getSelection();
  const blockType = editorState
    .getCurrentContent()
    .getBlockForKey(selection.getStartKey())
    .getType();

  const handleFormat = (event, newFormats) => {
    setFormats(newFormats);
  };

  return (
    <div className="d-flex justify-content-center align-items-center">
      <HeaderStyleDropdown
        headerOptions={HEADER_TYPES}
        active={blockType}
        onToggle={onToggle}
      />
      <ToggleButtonGroup
        size="small"
        value={formats}
        onChange={handleFormat}
        aria-label="text formatting"
      >
        {BLOCK_TYPES.map((type) => {
          return (
            <BlockStyleButton
              active={type.style === blockType}
              label={type.label}
              onToggle={onToggle}
              style={type.style}
              key={type.label}
              type={type}
            />
          );
        })}
      </ToggleButtonGroup>
    </div>
  );
};

export default function TextEditor({ show, setShow }) {
  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );
  const [formats, setFormats] = useState([]);
  const editorRef = useRef(null);
  const [showMsg, setShowMsg] = useState(false);
  const [msg, setMsg] = useState(false);
  const [type, setType] = useState(false);
  const [isClicked, setIsClicked] = useState(false);

  const handleClick = (e) => {
    if (!isClicked) {
      setMsg("You should copy the content to the clipboard before closing.");
      setShowMsg(true);
      setType("error");
    } else {
      setIsClicked(false);
    }
  };

  const handleFormat = (event, newFormats) => {
    setFormats(newFormats);

    newFormats.includes("underlined") &&
      handleEditorChange(RichUtils.toggleInlineStyle(editorState, "UNDERLINE"));

    newFormats.includes("bold") &&
      handleEditorChange(RichUtils.toggleInlineStyle(editorState, "BOLD"));

    newFormats.includes("italic") &&
      handleEditorChange(RichUtils.toggleInlineStyle(editorState, "ITALIC"));
  };

  const handleEditorChange = (state) => {
    handleClick();
    setEditorState(state);
  };

  const handleKeyCommand = (command) => {
    const newState = RichUtils.handleKeyCommand(editorState, command);

    if (newState) {
      handleEditorChange(newState);
      return "handled";
    }

    return "not-handled";
  };

  const toggleBlockType = (blockType) => {
    handleEditorChange(RichUtils.toggleBlockType(editorState, blockType));
  };

  return (
    <Offcanvas
      show={show}
      onHide={() => setShow(false)}
      scroll={true}
      placement={"end"}
      backdrop={false}
      aria-label="Note Editor"
    >
      <Offcanvas.Header closeButton>
        <Offcanvas.Title>Note Editor</Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body>
        <div className="wrapper-class">
          <div className="toolbar-class d-flex flex-column justify-content-center align-items-center">
            <BlockStyleToolbar
              editorState={editorState}
              onToggle={toggleBlockType}
            />
            <ToggleButtonGroup
              size="small"
              value={formats}
              onChange={handleFormat}
              aria-label="text block"
              className="m-2"
            >
              <BootstrapButton value="underlined" aria-label="underlined">
                <FormatUnderlinedIcon />
              </BootstrapButton>
              <BootstrapButton value="bold" aria-label="bold">
                <FormatBoldIcon />
              </BootstrapButton>
              <BootstrapButton value="italic" aria-label="italic">
                <FormatItalicIcon />
              </BootstrapButton>
            </ToggleButtonGroup>
          </div>
          <div className="editor-class">
            <Editor
              ref={editorRef}
              blockStyleFn={getBlockStyle}
              editorState={editorState}
              onChange={handleEditorChange}
              handleKeyCommand={handleKeyCommand}
              placeholder="Write something!"
              ariaLabel="Input Field"
            />
          </div>
          <div className="d-flex justify-content-end align-items-center">
            {showMsg && <div className={type + " me-2"}>{msg}</div>}
            <Tooltip title="Copy to clipboard">
              <IconButton
                aria-label="copy"
                onClick={(e) => {
                  setShowMsg(true);
                  setIsClicked(true);
                  window.navigator.clipboard
                    .writeText(editorState.getCurrentContent().getPlainText())
                    .then(() => {
                      setMsg("Copied!");
                      setType("success");
                    })
                    .catch(() => {
                      setMsg("Something went wrong!");
                      setType("error");
                    });
                }}
              >
                <ContentCopyIcon />
              </IconButton>
            </Tooltip>
          </div>
        </div>
      </Offcanvas.Body>
    </Offcanvas>
  );
}
