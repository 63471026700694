import React, { useState, useEffect } from "react";
import { ThemeContext } from "./ThemeContext";
import { ThemeProvider, createTheme } from "@mui/material/styles";

export default function ThemeContextWrapper(props) {
  const [mode, setMode] = useState("light");
  const colorMode = React.useMemo(
    () => ({
      toggleColorMode: (newMode) => {
        setMode(newMode);
      },
    }),
    []
  );
  const theme = React.useMemo(
    () =>
      createTheme({
        palette: {
          mode,
        },
      }),
    [mode]
  );

  useEffect(() => {
    switch (mode) {
      case "dark":
        document.body.classList.add("dark");
        break;
      case "light":
      default:
        document.body.classList.remove("dark");
        break;
    }
  }, [mode]);

  return (
    <ThemeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>{props.children}</ThemeProvider>
    </ThemeContext.Provider>
  );
}
