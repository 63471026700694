import React from "react";
import ReactDOM from "react-dom";
import "./styles/index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { store } from "./App/store";
import { Provider } from "react-redux";
import ThemeContextWrapper from "./Theme/ThemeWrapper";
import { BrowserRouter, Routes, Route } from "react-router-dom";

ReactDOM.render(
  <ThemeContextWrapper>
    {/* <React.StrictMode> */}
      <BrowserRouter>
        <Provider store={store}>
          <Routes>
            <Route path="/*" element={<App />} />
          </Routes>
        </Provider>
      </BrowserRouter>
    {/* </React.StrictMode> */}
  </ThemeContextWrapper>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
